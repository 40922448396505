

.link-like, .clickable {
  cursor: pointer;
}

.not-allowed {
  cursor: not-allowed !important;
}

.no-click {
  pointer-events: none;
}

.pointer-events-all {
  pointer-events: all;
}
