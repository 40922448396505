
.btn-primary {
  color: #FFFFFF;
  font-weight: bold;
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}

.btn-primary:focus, .btn-primary.focus {
  color: #FFFFFF;
  background-color: var(--primary-color);
  border-color: var(--primary-color);
  outline: none;
}

.btn-primary:hover, .btn-primary.hover {
  color: white;
  box-shadow: none;
  background-color: var(--primary-color-darker);
  border-color: var(--primary-color-darkest);
}

.btn-primary:active, .btn-primary.active, .btn-primary:not([disabled]):not(.disabled):active, .btn-primary:not([disabled]):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: var(--primary-color-darker);
  box-shadow: none;
  background-color: var(--primary-color-light);
  border-color: var(--primary-color-light);
  border-bottom-color: var(--primary-color);
}

.btn-primary:active:hover, .btn-primary:active:focus, .btn-primary:active.focus, .btn-primary.active:hover, .btn-primary.active:focus, .btn-primary.active.focus, .btn-primary:not([disabled]):not(.disabled):active:hover, .btn-primary:not([disabled]):not(.disabled):active:focus, .btn-primary:not([disabled]):not(.disabled):active.focus, .btn-primary:not([disabled]):not(.disabled).active:hover, .btn-primary:not([disabled]):not(.disabled).active:focus, .btn-primary:not([disabled]):not(.disabled).active.focus,
.show > .btn-primary.dropdown-toggle:hover,
.show > .btn-primary.dropdown-toggle:focus,
.show > .btn-primary.dropdown-toggle.focus {
  color: var(--primary-color-darker);
  background-color: var(--primary-color-light);
  border-color: transparent;
  box-shadow: none;
}

.btn-primary:active:hover .icon, .btn-primary:active:focus .icon, .btn-primary:active.focus .icon, .btn-primary.active:hover .icon, .btn-primary.active:focus .icon, .btn-primary.active.focus .icon, .btn-primary:not([disabled]):not(.disabled):active:hover .icon, .btn-primary:not([disabled]):not(.disabled):active:focus .icon, .btn-primary:not([disabled]):not(.disabled):active.focus .icon, .btn-primary:not([disabled]):not(.disabled).active:hover .icon, .btn-primary:not([disabled]):not(.disabled).active:focus .icon, .btn-primary:not([disabled]):not(.disabled).active.focus .icon,
.show > .btn-primary.dropdown-toggle:hover .icon,
.show > .btn-primary.dropdown-toggle:focus .icon,
.show > .btn-primary.dropdown-toggle.focus .icon {
  color: #FFFFFF;
}

.btn-primary:active, .btn-primary.active, .btn-primary:not([disabled]):not(.disabled):active, .btn-primary:not([disabled]):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  background-image: none;
}

.btn-primary.disabled, .btn-primary.disabled:hover, .btn-primary.disabled:focus, .btn-primary.disabled.focus, .btn-primary.disabled:active, .btn-primary.disabled.active, .btn-primary[disabled], .btn-primary[disabled]:hover, .btn-primary[disabled]:focus, .btn-primary[disabled].focus, .btn-primary[disabled]:active, .btn-primary[disabled].active,
fieldset[disabled] .btn-primary,
fieldset[disabled] .btn-primary:hover,
fieldset[disabled] .btn-primary:focus,
fieldset[disabled] .btn-primary.focus,
fieldset[disabled] .btn-primary:active,
fieldset[disabled] .btn-primary.active {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}

.btn-primary .badge {
  color: var(--primary-color);
  background-color: #FFFFFF;
}

.btn-secondary.disabled, .btn-secondary:disabled {
  color: #646464;
}

.switch-button {
  background-color: $gray-500;
}

.switch-button:hover {
  background-color: $gray-600;
}

.switch-button:active {
  background-color: $gray-400;
}

.switch-button input[type=checkbox]:checked + span {
  background-color: var(--primary-color);
}

.switch-button:hover input[type=checkbox]:checked + span {
  background-color: var(--primary-color-dark);
}

.switch-button:active input[type=checkbox]:checked + span {
  background-color: var(--primary-color-light);
}

.switch-button.disabled,
.switch-button.disabled label,
.switch-button.disabled input[type=checkbox] + span {
  background-color: $gray-400;
  cursor: not-allowed;
}

.btn-rounded {
  border-radius: 15px;
}

.btn-wide {
  @extend .btn-lg;
  margin-bottom: $grid-gutter-width;
  box-shadow: $drop-shadow !important;
  width: 100%;
}

@media (max-width: 575.98px) {
  .btn-wide {
    margin-bottom: $mobile-grid-gutter-width;
  }
}
