

body {
  font-size: 14px;
  background-color: #ddd;
}

h1, .h1,
h2, .h2,
h3, .h3 {
  line-height: 1.4em;
}

.groupable-logo {
  font-family: groupable;
  font-size: 3rem;
  text-transform: none;
  background: var(--primary-color);
  color: white;
}

.be-content {
  position: absolute;
  min-height: unset;
  inset: 0;
  margin-top: 65px;

  display: flex;
  flex-direction: column;
}

.main-content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.main-content {
  > .main-content {
    padding: 0;
    margin: 0;
  }
}

.container-2xl {
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
}

.turbolinks-progress-bar {
  height: 5px;
  background-color: white;
}

.invalid-feedback {
  font-size: 100%;
  display: block;
}

.outline-warning {
  border-color: $warning-color;
}


a, .link-like, .clickable {
  color: var(--primary-color-darker);
}

a:hover, .link-like:hover, .clickable:hover {
  color: var(--primary-color);
}

button:focus {
  outline: none;
}

/*
.login-img {
  background-image: url(./../../../public/m2LogoV3.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  height: 100px;
  width: 325px;
  margin: auto;
}
*/

.progress-widget {
  border-top: 1px solid #e6e6e6;
}

.badge-primary {
  color: #FFFFFF;
  background-color: var(--primary-color);
  border: transparent;
  text-shadow: none;
}

.be-scroll-top {
  right: 150px;
  bottom: 14px;
}

@media (max-width: 575.98px) {
  .be-scroll-top {
    right: auto;
    left: 20px;
  }
}

.video-container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
  text-align: center;
  .video-container {
    iframe, object, embed {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}

.carousel {
  .carousel-indicators > li {
    cursor: pointer;
  }
}

dl.row {
  dt {
    margin-bottom: 7px;
  }
  dd {
    margin-bottom: 7px;
  }
}


.hidden {
  display: none;
}

/* Nicer looking keyboard shortcuts */

kbd {
  background: #eee;
  color: #555;
  border: 1px solid #ccc;
  border-radius: 3px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 1px 1px #fff inset;
}

// Contact Form honeypot
.forms_contact_form_phone {
  display: none;
}

.splash-container {
  width: calc(401px * 2 + $grid-gutter-width);
  max-width: 100%;
}

@media (max-width: 767.98px) {
  .splash-container {
    width: 401px;
    margin-top: 0;
  }
}
