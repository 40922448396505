#tc-annual-billing-table {
  margin-top: 1.5em;
  margin-bottom: 1.5em;
  border-style: solid;
  border-width: 1px;

  .row {
    .col-1, .col-2, .col-3, .col-4, .col-5, .col-6 {
      border-style: solid;
      border-width: 1px;
    }
  }
}

.empty-row {
  height: .75em;
}
#address-section {
  margin-bottom: 1.25em;

  .address-block {
    margin-left: 10%;
  }
}