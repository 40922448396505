@use "sass:color";

$primary: #0CBAB3;
$success: #34a853;
$warning: #fbbc05;
$danger: #ea4335;

:root {
  --primary-color: #0CBAB3;
  --primary-color-dark: #009f96;
  --primary-color-darker: #008C84;
  --primary-color-darkest: #{color.adjust(#008C84, $lightness: -5%, $space: hsl)};
  --primary-color-light: #C0E6EB;
  --primary-color-lighter: #{color.adjust(#dbf6ff, $lightness: -7%, $space: hsl)};
  --primary-color-lightest: #dbf6ff;

  --default-card-header-bg-top: #fff;
  --default-card-header-bg-bottom: #e3e3e3;
  --default-card-header-color: #404040;
  --default-card-header-icon: var(--primary-color);
  --default-card-header-icon-hover: var(--primary-color-darker);
  --default-card-header-text-shadow: #fff;

}

.connect-app {
  --primary-color: #0c8cbb;
  --primary-color-dark: #00749e;
  --primary-color-darker: #00749e;
  --primary-color-darkest: #00749e;

  --default-card-header-bg-top: #00749e;
  --default-card-header-bg-bottom: #0c8cbb;
  --default-card-header-color: #fff;
  --default-card-header-icon: #fff;
  --default-card-header-icon-hover: #eee;
  --default-card-header-text-shadow: rgba(0, 0, 0, .5);
}

.raiser-app {
  --primary-color: #0bba63;
  --primary-color-dark: #09914d;
  --primary-color-darker: #09914d;
  --primary-color-darkest: #09914d;
}

$success-color: #37b358;
$warning-color: #f6c163;

$light-success-color: #cfc;
$light-info-color: #ccf;
$light-warning-color: #ffc;
$light-danger-color: #fcc;

$body-color: #404040;

$drop-shadow: 0 2px 5px rgba(0, 0, 0, .2);

$card-border-radius: 12px;
$card-border-width: 1px;
$card-border-color: rgba(0, 0, 0, .4);

$rounded-top: $card-border-radius $card-border-radius 0 0;
$rounded-bottom: 0 0 $card-border-radius $card-border-radius;

$menu-border-color: rgb(0 0 0 / 12%);
$menu-border: 1px solid $menu-border-color;
$menu-drop-shadow: 0 0 6px rgba(0, 0, 0, .12);

$grid-gutter-width: 24px;
$mobile-grid-gutter-width: 20px;
$menu-caret-size: 8px;
