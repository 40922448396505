
/* ************* */
/* Widget Styles */
/* ************* */

.widget.widget-tile {
  box-shadow: $drop-shadow;
  border-top: 0;
  border-radius: $card-border-radius;
  padding-top: 12px;
  padding-bottom: 12px;
  //background-color: #b5fce3;
  background-color: var(--primary-color-lightest);
  background: linear-gradient(180deg, var(--primary-color-lightest) 0%, var(--primary-color-lighter) 100%);

  .icon {
    font-size: 4rem;
    color: rgba(0,0,0,0.2);
  }

  .data-info {
    vertical-align: middle;
    .value:not(.center) {
      float: right;
    }
    .desc {
      text-transform: uppercase;
      font-size: 12px;
    }
  }
}
