
/* Styling for input[type=color] on supported browsers */
::-moz-color-swatch {
  border: 0;
  border-radius: 3px;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::-webkit-color-swatch {
  border: 0;
  border-radius: 3px;
}

/* Grid Size Selector */
.grid-size-selector {
  display: grid;
  grid-auto-columns: 30px;
  grid-auto-rows: 30px;
  position: relative;

  .grid-size-btn {
    @extend .border;
    cursor: pointer;

    .grid-size-label {
      pointer-events: none;
      user-select: none;
      position: absolute;
      top: 50%;
      left: 0;
      width: 100%;
      margin-top: -10px;
      height: 20px;
      font-size: 20px;
      line-height: 20px;
      color: #888;
      text-align: center;
      display: none;
    }

    &:hover {
      background-color: #f5f5f5;
      .grid-size-label {
        display: block;
      }
    }
  }
}

.draggable, .ui-draggable {
  cursor: pointer;
}

.dragging, .ui-draggable-dragging {
  box-shadow: $box-shadow-sm;
  cursor: move;
}

@font-face {
  font-family: 'Deja-Vu-Sans';
  font-style: normal;
  font-weight: normal;
  src: url(./../../../public/fonts/DejaVuSans.woff2) format('woff2');
}

@font-face {
  font-family: 'Barcode-3-Of-9';
  font-style: normal;
  font-weight: normal;
  src: url(./../../../public/fonts/Free3of9.woff2) format('woff2');
  unicode-range: U+24-25, U+2B, U+2D-39, U+41-5A; // $ % + - . / 0-9 A-Z
}

@font-face {
  font-family: 'Courier';
  font-style: normal;
  font-weight: normal;
  src: local('Courier New'), local('monospace');
}

.toolbar {
  @extend .d-flex;
  @extend .justify-content-between;
  @extend .align-items-center;
}

.toolbar-group {
  @extend .p-1;
  @extend .d-flex;
  @extend .flex-wrap;
  > * {
    @extend .m-1;
  }
}

#editor-stage {
  @extend .flex-centered;
  @extend .scroll-viewport;
  box-shadow: $box-shadow-sm inset;
  background-color: #f5f5f5;
  padding: 8rem;

  #editor-document {
    //@extend .border;
    @extend .shadow;
    background-color: #ffffff;
    position: relative;
    transition: transform 500ms ease;

    .print-template-field {
      outline: 1px dashed #aaa;
      display: inline-flex;
      vertical-align: top;
      position: absolute;

      .content {
        pointer-events: none;

        appearance: none;
        background: none no-repeat center;
        background-size: 100% 100%;
        border: none;
        padding: 0;
        line-height: 1;
        resize: none;

        font-size: inherit;
        text-align: inherit;
        text-transform: inherit;

        overflow: hidden;
        word-break: break-all;

        &:focus {
          pointer-events: unset;
          background-color: #fff;
          resize: both;
        }
      }

      &.text {
        .content {
          color: inherit;
          font-family: inherit;
          font-weight: inherit;
          font-style: inherit;
          text-decoration: inherit;
        }
      }

      &.barcode {
        .content {
          font-family: Barcode-3-Of-9;
          text-transform: uppercase;
          &:focus {
            font-family: inherit;
            text-transform: none;
          }
        }
      }

      &.qrcode {
        .content {
          // a QR code gif representing "qr"
          background-color: #fff;
          background-image: url("data:image/gif;base64,R0lGODlhFQAVAKEBAAAAAP///////////yH5BAEKAAIALAAAAAAVABUAAAJPhA+hF4gKU1oNLmmqpSz3GzUeJlVfN4Vqejnl624bK7+at96TY+pzSorsgMKghTQU7TS1DIXjShKVs6f1cVraRiXQ1oTDUEfV4liJFY0PBQA7");
          background-size: 75%;
          image-rendering: pixelated;
          // older implementations use crisp-edges or optimize-speed
          image-rendering: crisp-edges;
          color: transparent;
          &:focus {
            background: #fff;
            color: inherit;
          }
        }
      }

      &.active {
        outline: 1px solid #000;
        //outline: 2px solid #4285f4;
        z-index: 1000;
      }
    }
  }
}
