
.sticky-top {
  position: sticky;
  top: 86px;
}

/* Center align block elements (inside a relatively positioned parent) */

.center-block-v {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
}

.center-block-h {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
}

.center-block {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}


/* Flexbox based centering */
.flex-centered {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* pull the bottom up 50% */
.mb-n50 {
  margin-bottom: -50%;
}
