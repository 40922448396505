
.dropdown-menu {
  border-radius: $card-border-radius;
  border: $menu-border;
  margin-right: -11px;
  padding: 6px;
  text-shadow: 0 1px 1px #fff;

  .dropdown-item {
    padding: 8px 14px;
    border-radius: 6px;
  }

  .dropdown-divider {
    margin: 6px;
  }

  /* Ensure dropdown menu remains on top */
  z-index: 1030;

  /* dropdown caret */
  &:after, &:before {
    border-bottom: $menu-caret-size solid white;
    border-left: $menu-caret-size solid transparent;
    border-right: $menu-caret-size solid transparent;
    top: -$menu-caret-size;
    content: "";
    position: absolute;
    left: 12px;
  }

  &:before {
    border-bottom-color: $menu-border-color;
    top: (-1px - $menu-caret-size);
  }

  &.dropdown-menu-right {
    &:before, &:after {
      left: auto;
      right: 12px;
    }
  }
}

.be-user-nav > li.dropdown .dropdown-menu {
  padding: 0;
  left: auto;
  right: 11px;

  > .dropdown-viewport {
    /* Allow long dropdown menu items to remain accessible */
    max-height: min(80vh, calc(100vh - 200px));
    overflow: hidden auto;
    padding: 6px;
    border-radius: $card-border-radius;

    a {
      color: #504e4e;
    }

    .divider {
      background-color: var(--primary-color);
      color: #fff;
      text-shadow: none;
      margin: 6px -6px;
      padding: 10px;
      text-align: center;
      font-weight: bold;
    }
  }
}

.be-top-header .navbar-nav > li.dropdown .dropdown-menu {
  border-radius: $card-border-radius;
  border: $menu-border;

  &:after, &:before {
    right: 13px;
    left: auto;
  }

  &:before {
    display: unset;
    border-bottom-color: $menu-border-color;
    right: 12px;
  }

  > a:hover {
    background-color: whitesmoke;
    color: inherit;
  }
}

.be-top-header .navbar-nav.be-icons-nav > li.dropdown .dropdown-menu {
  &:after, &:before {
    display: none;
  }
}


.dropdown-item.active {
  background-color: unset;
  &::after {
    content: '✓';
    float: right;
  }
}

// Make jquery-timepicker look and feel like our dropdown menus
.ui-timepicker-container {
  @extend .dropdown-menu;
  overflow: unset;
  z-index: 1000 !important;
  .ui-timepicker-viewport {
    line-height: unset;
    margin: 0;
    padding: 0;
    overflow: hidden auto;
    max-height: 300px;
  }
  .ui-menu-item {
    > a {
      @extend .dropdown-item;
      cursor: pointer;
    }
    > a.ui-state-hover {
      color: #16181b;
      background-color: $gray-100;
    }
  }
}
