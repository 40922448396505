/*!
 * beagle v1.4.0-prerelease (https://foxythemes.net)
 * Copyright 2018 Foxy Themes all rights reserved 
 */

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 992px !important; }
  .container {
    min-width: 992px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #fff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #ddd !important; } }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: transparent; }

@-ms-viewport {
  width: device-width; }

article, aside, dialog, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: "Roboto", Arial, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.428571429;
  color: #404040;
  text-align: left;
  background-color: #EEEEEE; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

dfn {
  font-style: italic; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #4285f4;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects; }
  a:hover {
    color: #0d5bdd;
    text-decoration: underline; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
    color: inherit;
    text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

pre,
code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg:not(:root) {
  overflow: hidden; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 12px 10px;
  padding-bottom: 12px 10px;
  color: #878787;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: .5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 300;
  line-height: 1.1;
  color: inherit; }

h1, .h1 {
  font-size: 2.539rem; }

h2, .h2 {
  font-size: 2.077rem; }

h3, .h3 {
  font-size: 1.769rem; }

h4, .h4 {
  font-size: 1.308rem; }

h5, .h5 {
  font-size: 1rem; }

h6, .h6 {
  font-size: 0.923rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.1; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.1; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.1; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.1; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 65%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #878787; }
  .blockquote-footer::before {
    content: "\2014 \00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #EEEEEE;
  border: 1px solid #dee2e6;
  border-radius: 2px;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #878787; }

code,
kbd,
pre,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

code {
  font-size: 87.5%;
  color: #4285f4;
  word-break: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #FFFFFF;
  background-color: #212529;
  border-radius: 2px; }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700; }

pre {
  display: block;
  font-size: 87.5%;
  color: #212529; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px; } }

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  -ms-flex-preferred-size: 0;
      flex-basis: 0;
  -ms-flex-positive: 1;
      flex-grow: 1;
  max-width: 100%; }

.col-auto {
  -ms-flex: 0 0 auto;
      flex: 0 0 auto;
  width: auto;
  max-width: none; }

.col-1 {
  -ms-flex: 0 0 8.3333333333%;
      flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%; }

.col-2 {
  -ms-flex: 0 0 16.6666666667%;
      flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%; }

.col-3 {
  -ms-flex: 0 0 25%;
      flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  -ms-flex: 0 0 33.3333333333%;
      flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%; }

.col-5 {
  -ms-flex: 0 0 41.6666666667%;
      flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%; }

.col-6 {
  -ms-flex: 0 0 50%;
      flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  -ms-flex: 0 0 58.3333333333%;
      flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%; }

.col-8 {
  -ms-flex: 0 0 66.6666666667%;
      flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%; }

.col-9 {
  -ms-flex: 0 0 75%;
      flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  -ms-flex: 0 0 83.3333333333%;
      flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%; }

.col-11 {
  -ms-flex: 0 0 91.6666666667%;
      flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%; }

.col-12 {
  -ms-flex: 0 0 100%;
      flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  -ms-flex-order: -1;
      order: -1; }

.order-last {
  -ms-flex-order: 13;
      order: 13; }

.order-0 {
  -ms-flex-order: 0;
      order: 0; }

.order-1 {
  -ms-flex-order: 1;
      order: 1; }

.order-2 {
  -ms-flex-order: 2;
      order: 2; }

.order-3 {
  -ms-flex-order: 3;
      order: 3; }

.order-4 {
  -ms-flex-order: 4;
      order: 4; }

.order-5 {
  -ms-flex-order: 5;
      order: 5; }

.order-6 {
  -ms-flex-order: 6;
      order: 6; }

.order-7 {
  -ms-flex-order: 7;
      order: 7; }

.order-8 {
  -ms-flex-order: 8;
      order: 8; }

.order-9 {
  -ms-flex-order: 9;
      order: 9; }

.order-10 {
  -ms-flex-order: 10;
      order: 10; }

.order-11 {
  -ms-flex-order: 11;
      order: 11; }

.order-12 {
  -ms-flex-order: 12;
      order: 12; }

.offset-1 {
  margin-left: 8.3333333333%; }

.offset-2 {
  margin-left: 16.6666666667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.3333333333%; }

.offset-5 {
  margin-left: 41.6666666667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.3333333333%; }

.offset-8 {
  margin-left: 66.6666666667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.3333333333%; }

.offset-11 {
  margin-left: 91.6666666667%; }

@media (min-width: 576px) {
  .col-sm {
    -ms-flex-preferred-size: 0;
        flex-basis: 0;
    -ms-flex-positive: 1;
        flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-sm-1 {
    -ms-flex: 0 0 8.3333333333%;
        flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%; }
  .col-sm-2 {
    -ms-flex: 0 0 16.6666666667%;
        flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }
  .col-sm-3 {
    -ms-flex: 0 0 25%;
        flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    -ms-flex: 0 0 33.3333333333%;
        flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }
  .col-sm-5 {
    -ms-flex: 0 0 41.6666666667%;
        flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%; }
  .col-sm-6 {
    -ms-flex: 0 0 50%;
        flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    -ms-flex: 0 0 58.3333333333%;
        flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%; }
  .col-sm-8 {
    -ms-flex: 0 0 66.6666666667%;
        flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%; }
  .col-sm-9 {
    -ms-flex: 0 0 75%;
        flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    -ms-flex: 0 0 83.3333333333%;
        flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%; }
  .col-sm-11 {
    -ms-flex: 0 0 91.6666666667%;
        flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%; }
  .col-sm-12 {
    -ms-flex: 0 0 100%;
        flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    -ms-flex-order: -1;
        order: -1; }
  .order-sm-last {
    -ms-flex-order: 13;
        order: 13; }
  .order-sm-0 {
    -ms-flex-order: 0;
        order: 0; }
  .order-sm-1 {
    -ms-flex-order: 1;
        order: 1; }
  .order-sm-2 {
    -ms-flex-order: 2;
        order: 2; }
  .order-sm-3 {
    -ms-flex-order: 3;
        order: 3; }
  .order-sm-4 {
    -ms-flex-order: 4;
        order: 4; }
  .order-sm-5 {
    -ms-flex-order: 5;
        order: 5; }
  .order-sm-6 {
    -ms-flex-order: 6;
        order: 6; }
  .order-sm-7 {
    -ms-flex-order: 7;
        order: 7; }
  .order-sm-8 {
    -ms-flex-order: 8;
        order: 8; }
  .order-sm-9 {
    -ms-flex-order: 9;
        order: 9; }
  .order-sm-10 {
    -ms-flex-order: 10;
        order: 10; }
  .order-sm-11 {
    -ms-flex-order: 11;
        order: 11; }
  .order-sm-12 {
    -ms-flex-order: 12;
        order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.3333333333%; }
  .offset-sm-2 {
    margin-left: 16.6666666667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.3333333333%; }
  .offset-sm-5 {
    margin-left: 41.6666666667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.3333333333%; }
  .offset-sm-8 {
    margin-left: 66.6666666667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.3333333333%; }
  .offset-sm-11 {
    margin-left: 91.6666666667%; } }

@media (min-width: 768px) {
  .col-md {
    -ms-flex-preferred-size: 0;
        flex-basis: 0;
    -ms-flex-positive: 1;
        flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-md-1 {
    -ms-flex: 0 0 8.3333333333%;
        flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%; }
  .col-md-2 {
    -ms-flex: 0 0 16.6666666667%;
        flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }
  .col-md-3 {
    -ms-flex: 0 0 25%;
        flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    -ms-flex: 0 0 33.3333333333%;
        flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }
  .col-md-5 {
    -ms-flex: 0 0 41.6666666667%;
        flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%; }
  .col-md-6 {
    -ms-flex: 0 0 50%;
        flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    -ms-flex: 0 0 58.3333333333%;
        flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%; }
  .col-md-8 {
    -ms-flex: 0 0 66.6666666667%;
        flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%; }
  .col-md-9 {
    -ms-flex: 0 0 75%;
        flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    -ms-flex: 0 0 83.3333333333%;
        flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%; }
  .col-md-11 {
    -ms-flex: 0 0 91.6666666667%;
        flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%; }
  .col-md-12 {
    -ms-flex: 0 0 100%;
        flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    -ms-flex-order: -1;
        order: -1; }
  .order-md-last {
    -ms-flex-order: 13;
        order: 13; }
  .order-md-0 {
    -ms-flex-order: 0;
        order: 0; }
  .order-md-1 {
    -ms-flex-order: 1;
        order: 1; }
  .order-md-2 {
    -ms-flex-order: 2;
        order: 2; }
  .order-md-3 {
    -ms-flex-order: 3;
        order: 3; }
  .order-md-4 {
    -ms-flex-order: 4;
        order: 4; }
  .order-md-5 {
    -ms-flex-order: 5;
        order: 5; }
  .order-md-6 {
    -ms-flex-order: 6;
        order: 6; }
  .order-md-7 {
    -ms-flex-order: 7;
        order: 7; }
  .order-md-8 {
    -ms-flex-order: 8;
        order: 8; }
  .order-md-9 {
    -ms-flex-order: 9;
        order: 9; }
  .order-md-10 {
    -ms-flex-order: 10;
        order: 10; }
  .order-md-11 {
    -ms-flex-order: 11;
        order: 11; }
  .order-md-12 {
    -ms-flex-order: 12;
        order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.3333333333%; }
  .offset-md-2 {
    margin-left: 16.6666666667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.3333333333%; }
  .offset-md-5 {
    margin-left: 41.6666666667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.3333333333%; }
  .offset-md-8 {
    margin-left: 66.6666666667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.3333333333%; }
  .offset-md-11 {
    margin-left: 91.6666666667%; } }

@media (min-width: 992px) {
  .col-lg {
    -ms-flex-preferred-size: 0;
        flex-basis: 0;
    -ms-flex-positive: 1;
        flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-lg-1 {
    -ms-flex: 0 0 8.3333333333%;
        flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%; }
  .col-lg-2 {
    -ms-flex: 0 0 16.6666666667%;
        flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }
  .col-lg-3 {
    -ms-flex: 0 0 25%;
        flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    -ms-flex: 0 0 33.3333333333%;
        flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }
  .col-lg-5 {
    -ms-flex: 0 0 41.6666666667%;
        flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%; }
  .col-lg-6 {
    -ms-flex: 0 0 50%;
        flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    -ms-flex: 0 0 58.3333333333%;
        flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%; }
  .col-lg-8 {
    -ms-flex: 0 0 66.6666666667%;
        flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%; }
  .col-lg-9 {
    -ms-flex: 0 0 75%;
        flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    -ms-flex: 0 0 83.3333333333%;
        flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%; }
  .col-lg-11 {
    -ms-flex: 0 0 91.6666666667%;
        flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%; }
  .col-lg-12 {
    -ms-flex: 0 0 100%;
        flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    -ms-flex-order: -1;
        order: -1; }
  .order-lg-last {
    -ms-flex-order: 13;
        order: 13; }
  .order-lg-0 {
    -ms-flex-order: 0;
        order: 0; }
  .order-lg-1 {
    -ms-flex-order: 1;
        order: 1; }
  .order-lg-2 {
    -ms-flex-order: 2;
        order: 2; }
  .order-lg-3 {
    -ms-flex-order: 3;
        order: 3; }
  .order-lg-4 {
    -ms-flex-order: 4;
        order: 4; }
  .order-lg-5 {
    -ms-flex-order: 5;
        order: 5; }
  .order-lg-6 {
    -ms-flex-order: 6;
        order: 6; }
  .order-lg-7 {
    -ms-flex-order: 7;
        order: 7; }
  .order-lg-8 {
    -ms-flex-order: 8;
        order: 8; }
  .order-lg-9 {
    -ms-flex-order: 9;
        order: 9; }
  .order-lg-10 {
    -ms-flex-order: 10;
        order: 10; }
  .order-lg-11 {
    -ms-flex-order: 11;
        order: 11; }
  .order-lg-12 {
    -ms-flex-order: 12;
        order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.3333333333%; }
  .offset-lg-2 {
    margin-left: 16.6666666667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.3333333333%; }
  .offset-lg-5 {
    margin-left: 41.6666666667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.3333333333%; }
  .offset-lg-8 {
    margin-left: 66.6666666667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.3333333333%; }
  .offset-lg-11 {
    margin-left: 91.6666666667%; } }

@media (min-width: 1200px) {
  .col-xl {
    -ms-flex-preferred-size: 0;
        flex-basis: 0;
    -ms-flex-positive: 1;
        flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-xl-1 {
    -ms-flex: 0 0 8.3333333333%;
        flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%; }
  .col-xl-2 {
    -ms-flex: 0 0 16.6666666667%;
        flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }
  .col-xl-3 {
    -ms-flex: 0 0 25%;
        flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    -ms-flex: 0 0 33.3333333333%;
        flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }
  .col-xl-5 {
    -ms-flex: 0 0 41.6666666667%;
        flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%; }
  .col-xl-6 {
    -ms-flex: 0 0 50%;
        flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    -ms-flex: 0 0 58.3333333333%;
        flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%; }
  .col-xl-8 {
    -ms-flex: 0 0 66.6666666667%;
        flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%; }
  .col-xl-9 {
    -ms-flex: 0 0 75%;
        flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    -ms-flex: 0 0 83.3333333333%;
        flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%; }
  .col-xl-11 {
    -ms-flex: 0 0 91.6666666667%;
        flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%; }
  .col-xl-12 {
    -ms-flex: 0 0 100%;
        flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    -ms-flex-order: -1;
        order: -1; }
  .order-xl-last {
    -ms-flex-order: 13;
        order: 13; }
  .order-xl-0 {
    -ms-flex-order: 0;
        order: 0; }
  .order-xl-1 {
    -ms-flex-order: 1;
        order: 1; }
  .order-xl-2 {
    -ms-flex-order: 2;
        order: 2; }
  .order-xl-3 {
    -ms-flex-order: 3;
        order: 3; }
  .order-xl-4 {
    -ms-flex-order: 4;
        order: 4; }
  .order-xl-5 {
    -ms-flex-order: 5;
        order: 5; }
  .order-xl-6 {
    -ms-flex-order: 6;
        order: 6; }
  .order-xl-7 {
    -ms-flex-order: 7;
        order: 7; }
  .order-xl-8 {
    -ms-flex-order: 8;
        order: 8; }
  .order-xl-9 {
    -ms-flex-order: 9;
        order: 9; }
  .order-xl-10 {
    -ms-flex-order: 10;
        order: 10; }
  .order-xl-11 {
    -ms-flex-order: 11;
        order: 11; }
  .order-xl-12 {
    -ms-flex-order: 12;
        order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.3333333333%; }
  .offset-xl-2 {
    margin-left: 16.6666666667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.3333333333%; }
  .offset-xl-5 {
    margin-left: 41.6666666667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.3333333333%; }
  .offset-xl-8 {
    margin-left: 66.6666666667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.3333333333%; }
  .offset-xl-11 {
    margin-left: 91.6666666667%; } }

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
  background-color: transparent; }
  .table th,
  .table td {
    padding: 12px 10px;
    vertical-align: top;
    border-top: 1px solid #dee2e6; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6; }
  .table tbody + tbody {
    border-top: 2px solid #dee2e6; }
  .table .table {
    background-color: #EEEEEE; }

.table-sm th,
.table-sm td {
  padding: 7px; }

.table-bordered {
  border: 1px solid #dee2e6; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #f5f5f5; }

.table-hover tbody tr:hover {
  background-color: #f0f0f0; }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #caddfc; }

.table-hover .table-primary:hover {
  background-color: #b2cefb; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #b2cefb; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #dddddd; }

.table-hover .table-secondary:hover {
  background-color: #d0d0d0; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #d0d0d0; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c6e7cf; }

.table-hover .table-success:hover {
  background-color: #b4dfc0; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #b4dfc0; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #caddfc; }

.table-hover .table-info:hover {
  background-color: #b2cefb; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #b2cefb; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #feecb9; }

.table-hover .table-warning:hover {
  background-color: #fee5a0; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #fee5a0; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f9cac6; }

.table-hover .table-danger:hover {
  background-color: #f7b5af; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #f7b5af; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: white; }

.table-hover .table-light:hover {
  background-color: #f2f2f2; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #f2f2f2; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c9c8c8; }

.table-hover .table-dark:hover {
  background-color: #bcbbbb; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #bcbbbb; }

.table-grey,
.table-grey > th,
.table-grey > td {
  background-color: #f1f1f1; }

.table-hover .table-grey:hover {
  background-color: #e4e4e4; }
  .table-hover .table-grey:hover > td,
  .table-hover .table-grey:hover > th {
    background-color: #e4e4e4; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: #f0f0f0; }

.table-hover .table-active:hover {
  background-color: #e3e3e3; }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: #e3e3e3; }

.table .thead-dark th {
  color: #EEEEEE;
  background-color: #212529;
  border-color: #32383e; }

.table .thead-light th {
  color: #636c72;
  background-color: #dddddd;
  border-color: #dee2e6; }

.table-dark {
  color: #EEEEEE;
  background-color: #212529; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #32383e; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar; }
  .table-responsive > .table-bordered {
    border: 0; }

.form-control {
  display: block;
  width: 100%;
  padding: 0.7692rem 0.75rem;
  font-size: 1rem;
  line-height: 1.428571429;
  color: #404040;
  background-color: #FFFFFF;
  background-clip: padding-box;
  border: 1px solid #d5d8de;
  border-radius: 2px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:focus {
    color: #404040;
    background-color: #FFFFFF;
    border-color: #4285f4;
    outline: 0;
    box-shadow: 0 0 0 0 transparent; }
  .form-control::-webkit-input-placeholder {
    color: #878787;
    opacity: 1; }
  .form-control:-ms-input-placeholder {
    color: #878787;
    opacity: 1; }
  .form-control::placeholder {
    color: #878787;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #EEEEEE;
    opacity: 1; }

select.form-control:not([size]):not([multiple]) {
  height: 3.692rem; }

select.form-control:focus::-ms-value {
  color: #404040;
  background-color: #FFFFFF; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.7692rem + 1px);
  padding-bottom: calc(0.7692rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.428571429; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.15rem;
  line-height: 1.7; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.85rem;
  line-height: 1.6; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.7692rem;
  padding-bottom: 0.7692rem;
  margin-bottom: 0;
  line-height: 1.428571429;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .input-group-sm > .form-control-plaintext.form-control,
  .input-group-sm > .input-group-prepend > .form-control-plaintext.input-group-text,
  .input-group-sm > .input-group-append > .form-control-plaintext.input-group-text,
  .input-group-sm > .input-group-prepend > .form-control-plaintext.btn,
  .input-group-sm > .input-group-append > .form-control-plaintext.btn, .form-control-plaintext.form-control-lg, .input-group-lg > .form-control-plaintext.form-control,
  .input-group-lg > .input-group-prepend > .form-control-plaintext.input-group-text,
  .input-group-lg > .input-group-append > .form-control-plaintext.input-group-text,
  .input-group-lg > .input-group-prepend > .form-control-plaintext.btn,
  .input-group-lg > .input-group-append > .form-control-plaintext.btn {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm, .input-group-sm > .form-control,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 8px;
  font-size: 0.85rem;
  line-height: 1.6;
  border-radius: 2px; }

select.form-control-sm:not([size]):not([multiple]), .input-group-sm > select.form-control:not([size]):not([multiple]),
.input-group-sm > .input-group-prepend > select.input-group-text:not([size]):not([multiple]),
.input-group-sm > .input-group-append > select.input-group-text:not([size]):not([multiple]),
.input-group-sm > .input-group-prepend > select.btn:not([size]):not([multiple]),
.input-group-sm > .input-group-append > select.btn:not([size]):not([multiple]) {
  height: 4.385rem; }

.form-control-lg, .input-group-lg > .form-control,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 12px;
  font-size: 1.15rem;
  line-height: 1.7;
  border-radius: 4px; }

select.form-control-lg:not([size]):not([multiple]), .input-group-lg > select.form-control:not([size]):not([multiple]),
.input-group-lg > .input-group-prepend > select.input-group-text:not([size]):not([multiple]),
.input-group-lg > .input-group-append > select.input-group-text:not([size]):not([multiple]),
.input-group-lg > .input-group-prepend > select.btn:not([size]):not([multiple]),
.input-group-lg > .input-group-append > select.btn:not([size]):not([multiple]) {
  height: 2.846rem; }

.form-group {
  margin-bottom: 1.538rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input:disabled ~ .form-check-label {
    color: #878787; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
      align-items: center;
  padding-left: 0;
  margin-right: 1.385rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 65%;
  color: #34a853; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(52, 168, 83, 0.8);
  border-radius: .2rem; }

.was-validated .form-control:valid, .form-control.is-valid, .was-validated
.custom-select:valid,
.custom-select.is-valid {
  border-color: #34a853; }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus, .was-validated
  .custom-select:valid:focus,
  .custom-select.is-valid:focus {
    border-color: #34a853;
    box-shadow: 0 0 0 0 rgba(52, 168, 83, 0.25); }
  .was-validated .form-control:valid ~ .valid-feedback,
  .was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback,
  .form-control.is-valid ~ .valid-tooltip, .was-validated
  .custom-select:valid ~ .valid-feedback,
  .was-validated
  .custom-select:valid ~ .valid-tooltip,
  .custom-select.is-valid ~ .valid-feedback,
  .custom-select.is-valid ~ .valid-tooltip {
    display: block; }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #34a853; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #34a853; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    background-color: #83d99a; }

.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  background-color: #48c76a; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #EEEEEE, 0 0 0 0 rgba(52, 168, 83, 0.25); }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #34a853; }
  .was-validated .custom-file-input:valid ~ .custom-file-label::before, .custom-file-input.is-valid ~ .custom-file-label::before {
    border-color: inherit; }

.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0 rgba(52, 168, 83, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 65%;
  color: #ea4335; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(234, 67, 53, 0.8);
  border-radius: .2rem; }

.was-validated .form-control:invalid, .form-control.is-invalid, .was-validated
.custom-select:invalid,
.custom-select.is-invalid {
  border-color: #ea4335; }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus, .was-validated
  .custom-select:invalid:focus,
  .custom-select.is-invalid:focus {
    border-color: #ea4335;
    box-shadow: 0 0 0 0 rgba(234, 67, 53, 0.25); }
  .was-validated .form-control:invalid ~ .invalid-feedback,
  .was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
  .form-control.is-invalid ~ .invalid-tooltip, .was-validated
  .custom-select:invalid ~ .invalid-feedback,
  .was-validated
  .custom-select:invalid ~ .invalid-tooltip,
  .custom-select.is-invalid ~ .invalid-feedback,
  .custom-select.is-invalid ~ .invalid-tooltip {
    display: block; }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #ea4335; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #ea4335; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    background-color: #f6aea8; }

.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  background-color: #ef6e63; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #EEEEEE, 0 0 0 0 rgba(234, 67, 53, 0.25); }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #ea4335; }
  .was-validated .custom-file-input:invalid ~ .custom-file-label::before, .custom-file-input.is-invalid ~ .custom-file-label::before {
    border-color: inherit; }

.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0 rgba(234, 67, 53, 0.25); }

.form-inline {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: row wrap;
      flex-flow: row wrap;
  -ms-flex-align: center;
      align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-align: center;
          align-items: center;
      -ms-flex-pack: center;
          justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: -ms-flexbox;
      display: flex;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
      -ms-flex-align: center;
          align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group {
      width: auto; }
    .form-inline .form-check {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-align: center;
          align-items: center;
      -ms-flex-pack: center;
          justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      -ms-flex-align: center;
          align-items: center;
      -ms-flex-pack: center;
          justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn {
  display: inline-block;
  font-weight: 700;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  border: 1px solid transparent;
  padding: 0.7692rem 0.75rem;
  font-size: 1rem;
  line-height: 1.428571429;
  border-radius: 2px;
  transition: none; }
  .btn:hover, .btn:focus {
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: none; }
  .btn.disabled, .btn:disabled {
    opacity: 0.65; }
  .btn:not(:disabled):not(.disabled) {
    cursor: pointer; }
  .btn:not(:disabled):not(.disabled):active, .btn:not(:disabled):not(.disabled).active {
    background-image: none; }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #FFFFFF;
  background-color: #4285f4;
  border-color: #4285f4; }
  .btn-primary:hover {
    color: #FFFFFF;
    background-color: #1e6ef2;
    border-color: #1266f1; }
  .btn-primary:focus, .btn-primary.focus {
    box-shadow: 0 0 0 0 rgba(66, 133, 244, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #FFFFFF;
    background-color: #4285f4;
    border-color: #4285f4; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #FFFFFF;
    background-color: #1266f1;
    border-color: #0e60e9; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(66, 133, 244, 0.5); }

.btn-secondary {
  color: #FFFFFF;
  background-color: #878787;
  border-color: #878787; }
  .btn-secondary:hover {
    color: #FFFFFF;
    background-color: #747474;
    border-color: #6e6d6d; }
  .btn-secondary:focus, .btn-secondary.focus {
    box-shadow: 0 0 0 0 rgba(135, 135, 135, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #FFFFFF;
    background-color: #878787;
    border-color: #878787; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #FFFFFF;
    background-color: #6e6d6d;
    border-color: #676767; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(135, 135, 135, 0.5); }

.btn-success {
  color: #FFFFFF;
  background-color: #34a853;
  border-color: #34a853; }
  .btn-success:hover {
    color: #FFFFFF;
    background-color: #2b8b45;
    border-color: #288140; }
  .btn-success:focus, .btn-success.focus {
    box-shadow: 0 0 0 0 rgba(52, 168, 83, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #FFFFFF;
    background-color: #34a853;
    border-color: #34a853; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #FFFFFF;
    background-color: #288140;
    border-color: #25773b; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(52, 168, 83, 0.5); }

.btn-info {
  color: #FFFFFF;
  background-color: #4285f4;
  border-color: #4285f4; }
  .btn-info:hover {
    color: #FFFFFF;
    background-color: #1e6ef2;
    border-color: #1266f1; }
  .btn-info:focus, .btn-info.focus {
    box-shadow: 0 0 0 0 rgba(66, 133, 244, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #FFFFFF;
    background-color: #4285f4;
    border-color: #4285f4; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #FFFFFF;
    background-color: #1266f1;
    border-color: #0e60e9; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(66, 133, 244, 0.5); }

.btn-warning {
  color: #212529;
  background-color: #fbbc05;
  border-color: #fbbc05; }
  .btn-warning:hover {
    color: #212529;
    background-color: #d6a003;
    border-color: #ca9703; }
  .btn-warning:focus, .btn-warning.focus {
    box-shadow: 0 0 0 0 rgba(251, 188, 5, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #212529;
    background-color: #fbbc05;
    border-color: #fbbc05; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #FFFFFF;
    background-color: #ca9703;
    border-color: #bd8e03; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(251, 188, 5, 0.5); }

.btn-danger {
  color: #FFFFFF;
  background-color: #ea4335;
  border-color: #ea4335; }
  .btn-danger:hover {
    color: #FFFFFF;
    background-color: #e12717;
    border-color: #d62516; }
  .btn-danger:focus, .btn-danger.focus {
    box-shadow: 0 0 0 0 rgba(234, 67, 53, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #FFFFFF;
    background-color: #ea4335;
    border-color: #ea4335; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #FFFFFF;
    background-color: #d62516;
    border-color: #ca2315; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(234, 67, 53, 0.5); }

.btn-light {
  color: #212529;
  background-color: #FFFFFF;
  border-color: #FFFFFF; }
  .btn-light:hover {
    color: #212529;
    background-color: #ececec;
    border-color: #e6e5e5; }
  .btn-light:focus, .btn-light.focus {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #212529;
    background-color: #FFFFFF;
    border-color: #FFFFFF; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #e6e5e5;
    border-color: #dfdfdf; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5); }

.btn-dark {
  color: #FFFFFF;
  background-color: #3d3c3c;
  border-color: #3d3c3c; }
  .btn-dark:hover {
    color: #FFFFFF;
    background-color: #2a2929;
    border-color: #232323; }
  .btn-dark:focus, .btn-dark.focus {
    box-shadow: 0 0 0 0 rgba(61, 60, 60, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #FFFFFF;
    background-color: #3d3c3c;
    border-color: #3d3c3c; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #FFFFFF;
    background-color: #232323;
    border-color: #1d1c1c; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(61, 60, 60, 0.5); }

.btn-grey {
  color: #212529;
  background-color: #cccccc;
  border-color: #cccccc; }
  .btn-grey:hover {
    color: #212529;
    background-color: #b9b9b9;
    border-color: #b3b2b2; }
  .btn-grey:focus, .btn-grey.focus {
    box-shadow: 0 0 0 0 rgba(204, 204, 204, 0.5); }
  .btn-grey.disabled, .btn-grey:disabled {
    color: #212529;
    background-color: #cccccc;
    border-color: #cccccc; }
  .btn-grey:not(:disabled):not(.disabled):active, .btn-grey:not(:disabled):not(.disabled).active,
  .show > .btn-grey.dropdown-toggle {
    color: #212529;
    background-color: #b3b2b2;
    border-color: #acacac; }
    .btn-grey:not(:disabled):not(.disabled):active:focus, .btn-grey:not(:disabled):not(.disabled).active:focus,
    .show > .btn-grey.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(204, 204, 204, 0.5); }

.btn-outline-primary {
  color: #4285f4;
  background-color: transparent;
  background-image: none;
  border-color: #4285f4; }
  .btn-outline-primary:hover {
    color: #FFFFFF;
    background-color: #4285f4;
    border-color: #4285f4; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0 rgba(66, 133, 244, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #4285f4;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #FFFFFF;
    background-color: #4285f4;
    border-color: #4285f4; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(66, 133, 244, 0.5); }

.btn-outline-secondary {
  color: #878787;
  background-color: transparent;
  background-image: none;
  border-color: #878787; }
  .btn-outline-secondary:hover {
    color: #FFFFFF;
    background-color: #878787;
    border-color: #878787; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0 rgba(135, 135, 135, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #878787;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #FFFFFF;
    background-color: #878787;
    border-color: #878787; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(135, 135, 135, 0.5); }

.btn-outline-success {
  color: #34a853;
  background-color: transparent;
  background-image: none;
  border-color: #34a853; }
  .btn-outline-success:hover {
    color: #FFFFFF;
    background-color: #34a853;
    border-color: #34a853; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0 rgba(52, 168, 83, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #34a853;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #FFFFFF;
    background-color: #34a853;
    border-color: #34a853; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(52, 168, 83, 0.5); }

.btn-outline-info {
  color: #4285f4;
  background-color: transparent;
  background-image: none;
  border-color: #4285f4; }
  .btn-outline-info:hover {
    color: #FFFFFF;
    background-color: #4285f4;
    border-color: #4285f4; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0 rgba(66, 133, 244, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #4285f4;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #FFFFFF;
    background-color: #4285f4;
    border-color: #4285f4; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(66, 133, 244, 0.5); }

.btn-outline-warning {
  color: #fbbc05;
  background-color: transparent;
  background-image: none;
  border-color: #fbbc05; }
  .btn-outline-warning:hover {
    color: #212529;
    background-color: #fbbc05;
    border-color: #fbbc05; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0 rgba(251, 188, 5, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #fbbc05;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #fbbc05;
    border-color: #fbbc05; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(251, 188, 5, 0.5); }

.btn-outline-danger {
  color: #ea4335;
  background-color: transparent;
  background-image: none;
  border-color: #ea4335; }
  .btn-outline-danger:hover {
    color: #FFFFFF;
    background-color: #ea4335;
    border-color: #ea4335; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0 rgba(234, 67, 53, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #ea4335;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #FFFFFF;
    background-color: #ea4335;
    border-color: #ea4335; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(234, 67, 53, 0.5); }

.btn-outline-light {
  color: #FFFFFF;
  background-color: transparent;
  background-image: none;
  border-color: #FFFFFF; }
  .btn-outline-light:hover {
    color: #212529;
    background-color: #FFFFFF;
    border-color: #FFFFFF; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #FFFFFF;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #FFFFFF;
    border-color: #FFFFFF; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5); }

.btn-outline-dark {
  color: #3d3c3c;
  background-color: transparent;
  background-image: none;
  border-color: #3d3c3c; }
  .btn-outline-dark:hover {
    color: #FFFFFF;
    background-color: #3d3c3c;
    border-color: #3d3c3c; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0 rgba(61, 60, 60, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #3d3c3c;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #FFFFFF;
    background-color: #3d3c3c;
    border-color: #3d3c3c; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(61, 60, 60, 0.5); }

.btn-outline-grey {
  color: #cccccc;
  background-color: transparent;
  background-image: none;
  border-color: #cccccc; }
  .btn-outline-grey:hover {
    color: #212529;
    background-color: #cccccc;
    border-color: #cccccc; }
  .btn-outline-grey:focus, .btn-outline-grey.focus {
    box-shadow: 0 0 0 0 rgba(204, 204, 204, 0.5); }
  .btn-outline-grey.disabled, .btn-outline-grey:disabled {
    color: #cccccc;
    background-color: transparent; }
  .btn-outline-grey:not(:disabled):not(.disabled):active, .btn-outline-grey:not(:disabled):not(.disabled).active,
  .show > .btn-outline-grey.dropdown-toggle {
    color: #212529;
    background-color: #cccccc;
    border-color: #cccccc; }
    .btn-outline-grey:not(:disabled):not(.disabled):active:focus, .btn-outline-grey:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-grey.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(204, 204, 204, 0.5); }

.btn-link {
  font-weight: 400;
  color: #4285f4;
  background-color: transparent; }
  .btn-link:hover {
    color: #0d5bdd;
    text-decoration: underline;
    background-color: transparent;
    border-color: transparent; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline;
    border-color: transparent;
    box-shadow: none; }
  .btn-link:disabled, .btn-link.disabled {
    color: #878787; }

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 12px;
  font-size: 1.15rem;
  line-height: 1.7;
  border-radius: 4px; }

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 8px;
  font-size: 0.85rem;
  line-height: 1.6;
  border-radius: 2px; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  opacity: 0;
  transition: opacity 0.15s linear; }
  .fade.show {
    opacity: 1; }

.collapse {
  display: none; }
  .collapse.show {
    display: block; }

tr.collapse.show {
  display: table-row; }

tbody.collapse.show {
  display: table-row-group; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }

.dropup,
.dropdown {
  position: relative; }

.dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent; }

.dropdown-toggle:empty::after {
  margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #404040;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #e3e3e3;
  border-radius: 2px; }

.dropup .dropdown-menu {
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  width: 0;
  height: 0;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e5e5e5; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #f5f5f5; }
  .dropdown-item.active, .dropdown-item:active {
    color: #FFFFFF;
    text-decoration: none;
    background-color: #4285f4; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #878787;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.85rem;
  color: #878787;
  white-space: nowrap; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: -ms-inline-flexbox;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    -ms-flex: 0 1 auto;
        flex: 0 1 auto; }
    .btn-group > .btn:hover,
    .btn-group-vertical > .btn:hover {
      z-index: 1; }
    .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .btn.active {
      z-index: 1; }
  .btn-group .btn + .btn,
  .btn-group .btn + .btn-group,
  .btn-group .btn-group + .btn,
  .btn-group .btn-group + .btn-group,
  .btn-group-vertical .btn + .btn,
  .btn-group-vertical .btn + .btn-group,
  .btn-group-vertical .btn-group + .btn,
  .btn-group-vertical .btn-group + .btn-group {
    margin-left: -1px; }

.btn-toolbar {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -ms-flex-pack: start;
      justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:first-child {
  margin-left: 0; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem; }
  .dropdown-toggle-split::after {
    margin-left: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 6px;
  padding-left: 6px; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 9px;
  padding-left: 9px; }

.btn-group-vertical {
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-align: start;
      align-items: flex-start;
  -ms-flex-pack: center;
      justify-content: center; }
  .btn-group-vertical .btn,
  .btn-group-vertical .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn + .btn,
  .btn-group-vertical > .btn + .btn-group,
  .btn-group-vertical > .btn-group + .btn,
  .btn-group-vertical > .btn-group + .btn-group {
    margin-top: -1px;
    margin-left: 0; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0; }
  .btn-group-toggle > .btn input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

.input-group {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -ms-flex-align: stretch;
      align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    -ms-flex: 1 1 auto;
        flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0; }
    .input-group > .form-control:focus,
    .input-group > .custom-select:focus,
    .input-group > .custom-file:focus {
      z-index: 3; }
    .input-group > .form-control + .form-control,
    .input-group > .form-control + .custom-select,
    .input-group > .form-control + .custom-file,
    .input-group > .custom-select + .form-control,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: -1px; }
  .input-group > .form-control:not(:last-child),
  .input-group > .custom-select:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group > .form-control:not(:first-child),
  .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .input-group > .custom-file {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center; }
    .input-group > .custom-file:not(:last-child) .custom-file-label,
    .input-group > .custom-file:not(:last-child) .custom-file-label::before {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .input-group > .custom-file:not(:first-child) .custom-file-label,
    .input-group > .custom-file:not(:first-child) .custom-file-label::before {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }

.input-group-prepend,
.input-group-append {
  display: -ms-flexbox;
  display: flex; }
  .input-group-prepend .btn,
  .input-group-append .btn {
    position: relative;
    z-index: 2; }
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn {
    margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  padding: 0.7692rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.428571429;
  color: #404040;
  text-align: center;
  white-space: nowrap;
  background-color: #f5f5f5;
  border: 1px solid #d5d8de;
  border-radius: 2px; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.custom-control {
  position: relative;
  display: block;
  min-height: 1.428571429rem;
  padding-left: 2.1377rem; }

.custom-control-inline {
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin-right: 1.385rem; }

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #4285f4;
    background-color: #FFFFFF; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: none; }
  .custom-control-input:active ~ .custom-control-label::before {
    color: #FFFFFF;
    background-color: #FFFFFF; }
  .custom-control-input:disabled ~ .custom-control-label {
    color: #878787; }
    .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #dddddd; }

.custom-control-label {
  margin-bottom: 0; }
  .custom-control-label::before {
    position: absolute;
    top: -0.1317142855rem;
    left: 0;
    display: block;
    width: 1.692rem;
    height: 1.692rem;
    pointer-events: none;
    content: "";
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    background-color: #FFFFFF; }
  .custom-control-label::after {
    position: absolute;
    top: -0.1317142855rem;
    left: 0;
    display: block;
    width: 1.692rem;
    height: 1.692rem;
    content: "";
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%; }

.custom-checkbox .custom-control-label::before {
  border-radius: 3px; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #FFFFFF; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: none; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  background-color: #4285f4; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%234285f4' d='M0 2h4'/%3E%3C/svg%3E"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(66, 133, 244, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(66, 133, 244, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #FFFFFF; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: none; }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(66, 133, 244, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: 3.692rem;
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  line-height: 1.428571429;
  color: #404040;
  vertical-align: middle;
  background: #FFFFFF url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%233d3c3c' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 0.75rem center;
  background-size: 8px 10px;
  border: 1px solid #d5d8de;
  border-radius: 2px;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none; }
  .custom-select:focus {
    border-color: #4285f4;
    outline: 0;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075), 0 0 5px rgba(66, 133, 244, 0.5); }
    .custom-select:focus::-ms-value {
      color: #404040;
      background-color: #FFFFFF; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none; }
  .custom-select:disabled {
    color: #878787;
    background-color: #dddddd; }
  .custom-select::-ms-expand {
    opacity: 0; }

.custom-select-sm {
  height: 4.385rem;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 75%; }

.custom-select-lg {
  height: 2.846rem;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 125%; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 3.692rem;
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: 3.692rem;
  margin: 0;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-control {
    border-color: #4285f4;
    box-shadow: 0 0 0 0 transparent; }
    .custom-file-input:focus ~ .custom-file-control::before {
      border-color: #4285f4; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: 3.692rem;
  padding: 0.7692rem 0.75rem;
  line-height: 1.428571429;
  color: #404040;
  background-color: #FFFFFF;
  border: 1px solid #d5d8de;
  border-radius: 2px; }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(3.692rem - 1px * 2);
    padding: 0.7692rem 0.75rem;
    line-height: 1.428571429;
    color: #404040;
    content: "Browse";
    background-color: #f5f5f5;
    border-left: 1px solid #d5d8de;
    border-radius: 0 2px 2px 0; }

.nav {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #878787; }

.nav-tabs {
  border-bottom: 1px solid #dee2e6; }
  .nav-tabs .nav-item {
    margin-bottom: -1px; }
  .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #dddddd #dddddd #dee2e6; }
    .nav-tabs .nav-link.disabled {
      color: #878787;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #636c72;
    background-color: #EEEEEE;
    border-color: #dee2e6 #dee2e6 #EEEEEE; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 2px; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #FFFFFF;
  background-color: #4285f4; }

.nav-fill .nav-item {
  -ms-flex: 1 1 auto;
      flex: 1 1 auto;
  text-align: center; }

.nav-justified .nav-item {
  -ms-flex-preferred-size: 0;
      flex-basis: 0;
  -ms-flex-positive: 1;
      flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: justify;
      justify-content: space-between;
  padding: 0.5rem 1rem; }
  .navbar > .container,
  .navbar > .container-fluid {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-pack: justify;
        justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.3928571428rem;
  padding-bottom: 0.3928571428rem;
  margin-right: 1rem;
  font-size: 1.15rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  -ms-flex-preferred-size: 100%;
      flex-basis: 100%;
  -ms-flex-positive: 1;
      flex-grow: 1;
  -ms-flex-align: center;
      align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.15rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 2px; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }
  .navbar-toggler:not(:disabled):not(.disabled) {
    cursor: pointer; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%; }

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  .navbar-expand-sm {
    -ms-flex-flow: row nowrap;
        flex-flow: row nowrap;
    -ms-flex-pack: start;
        justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      -ms-flex-direction: row;
          flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .dropdown-menu-right {
        right: 0;
        left: auto; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid {
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-collapse {
      display: -ms-flexbox !important;
      display: flex !important;
      -ms-flex-preferred-size: auto;
          flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; }
    .navbar-expand-sm .dropup .dropdown-menu {
      top: auto;
      bottom: 100%; } }

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    -ms-flex-flow: row nowrap;
        flex-flow: row nowrap;
    -ms-flex-pack: start;
        justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      -ms-flex-direction: row;
          flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .dropdown-menu-right {
        right: 0;
        left: auto; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid {
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap; }
    .navbar-expand-md .navbar-collapse {
      display: -ms-flexbox !important;
      display: flex !important;
      -ms-flex-preferred-size: auto;
          flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; }
    .navbar-expand-md .dropup .dropdown-menu {
      top: auto;
      bottom: 100%; } }

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    -ms-flex-flow: row nowrap;
        flex-flow: row nowrap;
    -ms-flex-pack: start;
        justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      -ms-flex-direction: row;
          flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .dropdown-menu-right {
        right: 0;
        left: auto; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid {
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-collapse {
      display: -ms-flexbox !important;
      display: flex !important;
      -ms-flex-preferred-size: auto;
          flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; }
    .navbar-expand-lg .dropup .dropdown-menu {
      top: auto;
      bottom: 100%; } }

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    -ms-flex-flow: row nowrap;
        flex-flow: row nowrap;
    -ms-flex-pack: start;
        justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      -ms-flex-direction: row;
          flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .dropdown-menu-right {
        right: 0;
        left: auto; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid {
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-collapse {
      display: -ms-flexbox !important;
      display: flex !important;
      -ms-flex-preferred-size: auto;
          flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; }
    .navbar-expand-xl .dropup .dropdown-menu {
      top: auto;
      bottom: 100%; } }

.navbar-expand {
  -ms-flex-flow: row nowrap;
      flex-flow: row nowrap;
  -ms-flex-pack: start;
      justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    -ms-flex-direction: row;
        flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .dropdown-menu-right {
      right: 0;
      left: auto; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap; }
  .navbar-expand .navbar-collapse {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
        flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }
  .navbar-expand .dropup .dropdown-menu {
    top: auto;
    bottom: 100%; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #FFFFFF; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #FFFFFF; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #FFFFFF; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-text a {
    color: #FFFFFF; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #FFFFFF; }

.card {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #FFFFFF;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 3px; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group:first-child .list-group-item:first-child {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px; }
  .card > .list-group:last-child .list-group-item:last-child {
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px; }

.card-body {
  -ms-flex: 1 1 auto;
      flex: 1 1 auto;
  padding: 1.538rem; }

.card-title {
  margin-bottom: 0.7692rem; }

.card-subtitle {
  margin-top: -0.3846rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.538rem; }

.card-header {
  padding: 0.7692rem 1.538rem;
  margin-bottom: 0;
  background-color: #FFFFFF;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  .card-header:first-child {
    border-radius: calc(3px - 1px) calc(3px - 1px) 0 0; }
  .card-header + .list-group .list-group-item:first-child {
    border-top: 0; }

.card-footer {
  padding: 0.7692rem 1.538rem;
  background-color: #FFFFFF;
  border-top: 1px solid rgba(0, 0, 0, 0.125); }
  .card-footer:last-child {
    border-radius: 0 0 calc(3px - 1px) calc(3px - 1px); }

.card-header-tabs {
  margin-right: -0.769rem;
  margin-bottom: -0.7692rem;
  margin-left: -0.769rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.769rem;
  margin-left: -0.769rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem; }

.card-img {
  width: 100%;
  border-radius: calc(3px - 1px); }

.card-img-top {
  width: 100%;
  border-top-left-radius: calc(3px - 1px);
  border-top-right-radius: calc(3px - 1px); }

.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(3px - 1px);
  border-bottom-left-radius: calc(3px - 1px); }

.card-deck {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column; }
  .card-deck .card {
    margin-bottom: 15px; }
  @media (min-width: 576px) {
    .card-deck {
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
      margin-right: -15px;
      margin-left: -15px; }
      .card-deck .card {
        display: -ms-flexbox;
        display: flex;
        -ms-flex: 1 0 0%;
            flex: 1 0 0%;
        -ms-flex-direction: column;
            flex-direction: column;
        margin-right: 15px;
        margin-bottom: 0;
        margin-left: 15px; } }

.card-group {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column; }
  .card-group > .card {
    margin-bottom: 15px; }
  @media (min-width: 576px) {
    .card-group {
      -ms-flex-flow: row wrap;
          flex-flow: row wrap; }
      .card-group > .card {
        -ms-flex: 1 0 0%;
            flex: 1 0 0%;
        margin-bottom: 0; }
        .card-group > .card + .card {
          margin-left: 0;
          border-left: 0; }
        .card-group > .card:first-child {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0; }
          .card-group > .card:first-child .card-img-top,
          .card-group > .card:first-child .card-header {
            border-top-right-radius: 0; }
          .card-group > .card:first-child .card-img-bottom,
          .card-group > .card:first-child .card-footer {
            border-bottom-right-radius: 0; }
        .card-group > .card:last-child {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0; }
          .card-group > .card:last-child .card-img-top,
          .card-group > .card:last-child .card-header {
            border-top-left-radius: 0; }
          .card-group > .card:last-child .card-img-bottom,
          .card-group > .card:last-child .card-footer {
            border-bottom-left-radius: 0; }
        .card-group > .card:only-child {
          border-radius: 3px; }
          .card-group > .card:only-child .card-img-top,
          .card-group > .card:only-child .card-header {
            border-top-left-radius: 3px;
            border-top-right-radius: 3px; }
          .card-group > .card:only-child .card-img-bottom,
          .card-group > .card:only-child .card-footer {
            border-bottom-right-radius: 3px;
            border-bottom-left-radius: 3px; }
        .card-group > .card:not(:first-child):not(:last-child):not(:only-child) {
          border-radius: 0; }
          .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-img-top,
          .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-img-bottom,
          .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-header,
          .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-footer {
            border-radius: 0; } }

.card-columns .card {
  margin-bottom: 0.7692rem; }

@media (min-width: 576px) {
  .card-columns {
    -webkit-column-count: 3;
            column-count: 3;
    -webkit-column-gap: 1.25rem;
            column-gap: 1.25rem; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.breadcrumb {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  padding: 7px 20px;
  margin-bottom: 1rem;
  list-style: none;
  background-color: whitesmoke;
  border-radius: 2px; }

.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.6154rem;
  padding-left: 0.6154rem;
  color: #878787;
  content: "/"; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none; }

.breadcrumb-item.active {
  color: #4d4c4c; }

.pagination {
  display: -ms-flexbox;
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 2px; }

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #404040;
  background-color: #fff;
  border: 1px solid #dee2e6; }
  .page-link:hover {
    color: #0d5bdd;
    text-decoration: none;
    background-color: #ececec;
    border-color: #dee2e6; }
  .page-link:focus {
    z-index: 2;
    outline: 0;
    box-shadow: 0 0 0 0 transparent; }
  .page-link:not(:disabled):not(.disabled) {
    cursor: pointer; }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px; }

.page-item:last-child .page-link {
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px; }

.page-item.active .page-link {
  z-index: 1;
  color: #FFFFFF;
  background-color: #4285f4;
  border-color: #4285f4; }

.page-item.disabled .page-link {
  color: #878787;
  pointer-events: none;
  cursor: auto;
  background-color: #FFFFFF;
  border-color: #dee2e6; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.15rem;
  line-height: 1.7; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.85rem;
  line-height: 1.6; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px; }

.badge {
  display: inline-block;
  padding: 0.07692rem 0.6154rem;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 2px; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.7em;
  padding-left: 0.7em;
  border-radius: 10rem; }

.badge-primary {
  color: #FFFFFF;
  background-color: #4285f4; }
  .badge-primary[href]:hover, .badge-primary[href]:focus {
    color: #FFFFFF;
    text-decoration: none;
    background-color: #1266f1; }

.badge-secondary {
  color: #FFFFFF;
  background-color: #878787; }
  .badge-secondary[href]:hover, .badge-secondary[href]:focus {
    color: #FFFFFF;
    text-decoration: none;
    background-color: #6e6d6d; }

.badge-success {
  color: #FFFFFF;
  background-color: #34a853; }
  .badge-success[href]:hover, .badge-success[href]:focus {
    color: #FFFFFF;
    text-decoration: none;
    background-color: #288140; }

.badge-info {
  color: #FFFFFF;
  background-color: #4285f4; }
  .badge-info[href]:hover, .badge-info[href]:focus {
    color: #FFFFFF;
    text-decoration: none;
    background-color: #1266f1; }

.badge-warning {
  color: #212529;
  background-color: #fbbc05; }
  .badge-warning[href]:hover, .badge-warning[href]:focus {
    color: #212529;
    text-decoration: none;
    background-color: #ca9703; }

.badge-danger {
  color: #FFFFFF;
  background-color: #ea4335; }
  .badge-danger[href]:hover, .badge-danger[href]:focus {
    color: #FFFFFF;
    text-decoration: none;
    background-color: #d62516; }

.badge-light {
  color: #212529;
  background-color: #FFFFFF; }
  .badge-light[href]:hover, .badge-light[href]:focus {
    color: #212529;
    text-decoration: none;
    background-color: #e6e5e5; }

.badge-dark {
  color: #FFFFFF;
  background-color: #3d3c3c; }
  .badge-dark[href]:hover, .badge-dark[href]:focus {
    color: #FFFFFF;
    text-decoration: none;
    background-color: #232323; }

.badge-grey {
  color: #212529;
  background-color: #cccccc; }
  .badge-grey[href]:hover, .badge-grey[href]:focus {
    color: #212529;
    text-decoration: none;
    background-color: #b3b2b2; }

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #dddddd;
  border-radius: 4px; }
  @media (min-width: 576px) {
    .jumbotron {
      padding: 4rem 2rem; } }

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0; }

.alert {
  position: relative;
  padding: 1.385rem 1.385rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 2px; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 4.27rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 1.385rem 1.385rem;
    color: inherit; }

.alert-primary {
  color: #22457f;
  background-color: #d9e7fd;
  border-color: #caddfc; }
  .alert-primary hr {
    border-top-color: #b2cefb; }
  .alert-primary .alert-link {
    color: #172f57; }

.alert-secondary {
  color: #464646;
  background-color: #e7e7e7;
  border-color: #dddddd; }
  .alert-secondary hr {
    border-top-color: #d0d0d0; }
  .alert-secondary .alert-link {
    color: #2d2c2c; }

.alert-success {
  color: #1b572b;
  background-color: #d6eedd;
  border-color: #c6e7cf; }
  .alert-success hr {
    border-top-color: #b4dfc0; }
  .alert-success .alert-link {
    color: #0f3018; }

.alert-info {
  color: #22457f;
  background-color: #d9e7fd;
  border-color: #caddfc; }
  .alert-info hr {
    border-top-color: #b2cefb; }
  .alert-info .alert-link {
    color: #172f57; }

.alert-warning {
  color: #836203;
  background-color: #fef2cd;
  border-color: #feecb9; }
  .alert-warning hr {
    border-top-color: #fee5a0; }
  .alert-warning .alert-link {
    color: #513d02; }

.alert-danger {
  color: #7a231c;
  background-color: #fbd9d7;
  border-color: #f9cac6; }
  .alert-danger hr {
    border-top-color: #f7b5af; }
  .alert-danger .alert-link {
    color: #511712; }

.alert-light {
  color: #858585;
  background-color: white;
  border-color: white; }
  .alert-light hr {
    border-top-color: #f2f2f2; }
  .alert-light .alert-link {
    color: #6c6b6b; }

.alert-dark {
  color: #201f1f;
  background-color: #d8d8d8;
  border-color: #c9c8c8; }
  .alert-dark hr {
    border-top-color: #bcbbbb; }
  .alert-dark .alert-link {
    color: #060606; }

.alert-grey {
  color: #6a6a6a;
  background-color: whitesmoke;
  border-color: #f1f1f1; }
  .alert-grey hr {
    border-top-color: #e4e4e4; }
  .alert-grey .alert-link {
    color: #515050; }

@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 3.077rem 0; }
  to {
    background-position: 0 0; } }

@keyframes progress-bar-stripes {
  from {
    background-position: 3.077rem 0; }
  to {
    background-position: 0 0; } }

.progress {
  display: -ms-flexbox;
  display: flex;
  height: 3.077rem;
  overflow: hidden;
  font-size: 0.9231rem;
  background-color: #f5f5f5;
  border-radius: 2px; }

.progress-bar {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-pack: center;
      justify-content: center;
  color: #FFFFFF;
  text-align: center;
  background-color: #4285f4;
  transition: width 0.6s ease; }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 3.077rem 3.077rem; }

.progress-bar-animated {
  -webkit-animation: progress-bar-stripes 2s linear infinite;
          animation: progress-bar-stripes 2s linear infinite; }

.media {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
      align-items: flex-start; }

.media-body {
  -ms-flex: 1 1 0%;
      flex: 1 1 0%; }

.list-group {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  padding-left: 0;
  margin-bottom: 0; }

.list-group-item-action {
  width: 100%;
  color: #636c72;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    color: #636c72;
    text-decoration: none;
    background-color: #f5f5f5; }
  .list-group-item-action:active {
    color: #404040;
    background-color: #dddddd; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .list-group-item:first-child {
    border-top-left-radius: 2px;
    border-top-right-radius: 2px; }
  .list-group-item:last-child {
    margin-bottom: 0;
    border-bottom-right-radius: 2px;
    border-bottom-left-radius: 2px; }
  .list-group-item:hover, .list-group-item:focus {
    z-index: 1;
    text-decoration: none; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #878787;
    background-color: #ececec; }
  .list-group-item.active {
    z-index: 2;
    color: #FFFFFF;
    background-color: #4285f4;
    border-color: #4285f4; }

.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0; }

.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0; }

.list-group-flush:last-child .list-group-item:last-child {
  border-bottom: 0; }

.list-group-item-primary {
  color: #22457f;
  background-color: #caddfc; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #22457f;
    background-color: #b2cefb; }
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #22457f;
    border-color: #22457f; }

.list-group-item-secondary {
  color: #464646;
  background-color: #dddddd; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #464646;
    background-color: #d0d0d0; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #464646;
    border-color: #464646; }

.list-group-item-success {
  color: #1b572b;
  background-color: #c6e7cf; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #1b572b;
    background-color: #b4dfc0; }
  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #1b572b;
    border-color: #1b572b; }

.list-group-item-info {
  color: #22457f;
  background-color: #caddfc; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #22457f;
    background-color: #b2cefb; }
  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #22457f;
    border-color: #22457f; }

.list-group-item-warning {
  color: #836203;
  background-color: #feecb9; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #836203;
    background-color: #fee5a0; }
  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #836203;
    border-color: #836203; }

.list-group-item-danger {
  color: #7a231c;
  background-color: #f9cac6; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #7a231c;
    background-color: #f7b5af; }
  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #7a231c;
    border-color: #7a231c; }

.list-group-item-light {
  color: #858585;
  background-color: white; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #858585;
    background-color: #f2f2f2; }
  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #858585;
    border-color: #858585; }

.list-group-item-dark {
  color: #201f1f;
  background-color: #c9c8c8; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #201f1f;
    background-color: #bcbbbb; }
  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #201f1f;
    border-color: #201f1f; }

.list-group-item-grey {
  color: #6a6a6a;
  background-color: #f1f1f1; }
  .list-group-item-grey.list-group-item-action:hover, .list-group-item-grey.list-group-item-action:focus {
    color: #6a6a6a;
    background-color: #e4e4e4; }
  .list-group-item-grey.list-group-item-action.active {
    color: #fff;
    background-color: #6a6a6a;
    border-color: #6a6a6a; }

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5; }
  .close:hover, .close:focus {
    color: #000;
    text-decoration: none;
    opacity: .75; }
  .close:not(:disabled):not(.disabled) {
    cursor: pointer; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none; }

.modal-open {
  overflow: hidden; }

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  outline: 0; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: -webkit-transform 0.3s ease-out;
    transition: transform 0.3s ease-out;
    transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
    -webkit-transform: translate(0, -25%);
            transform: translate(0, -25%); }
  .modal.show .modal-dialog {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); }

.modal-dialog-centered {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  min-height: calc(100% - (0.5rem * 2)); }

.modal-content {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #FFFFFF;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
      align-items: flex-start;
  -ms-flex-pack: justify;
      justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid #dddddd;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px; }
  .modal-header .close {
    padding: 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.428571429; }

.modal-body {
  position: relative;
  -ms-flex: 1 1 auto;
      flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: end;
      justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #dddddd; }
  .modal-footer > :not(:first-child) {
    margin-left: .25rem; }
  .modal-footer > :not(:last-child) {
    margin-right: .25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 600px;
    margin: 1.75rem auto; }
  .modal-dialog-centered {
    min-height: calc(100% - (1.75rem * 2)); }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg {
    max-width: 700px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: "Roboto", Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.428571429;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.85rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #000000; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #000000; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #000000; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #000000; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #FFFFFF;
  text-align: center;
  background-color: #000000;
  border-radius: 2px; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: "Roboto", Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.428571429;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.85rem;
  word-wrap: break-word;
  background-color: #FFFFFF;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px; }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 4px; }
    .popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem; }
  .bs-popover-top .arrow, .bs-popover-auto[x-placement^="top"] .arrow {
    bottom: calc((0.5rem + 1px) * -1); }
  .bs-popover-top .arrow::before, .bs-popover-auto[x-placement^="top"] .arrow::before,
  .bs-popover-top .arrow::after, .bs-popover-auto[x-placement^="top"] .arrow::after {
    border-width: 0.5rem 0.5rem 0; }
  .bs-popover-top .arrow::before, .bs-popover-auto[x-placement^="top"] .arrow::before {
    bottom: 0;
    border-top-color: rgba(0, 0, 0, 0.25); }
  .bs-popover-top .arrow::after, .bs-popover-auto[x-placement^="top"] .arrow::after {
    bottom: 1px;
    border-top-color: #FFFFFF; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem; }
  .bs-popover-right .arrow, .bs-popover-auto[x-placement^="right"] .arrow {
    left: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 4px 0; }
  .bs-popover-right .arrow::before, .bs-popover-auto[x-placement^="right"] .arrow::before,
  .bs-popover-right .arrow::after, .bs-popover-auto[x-placement^="right"] .arrow::after {
    border-width: 0.5rem 0.5rem 0.5rem 0; }
  .bs-popover-right .arrow::before, .bs-popover-auto[x-placement^="right"] .arrow::before {
    left: 0;
    border-right-color: rgba(0, 0, 0, 0.25); }
  .bs-popover-right .arrow::after, .bs-popover-auto[x-placement^="right"] .arrow::after {
    left: 1px;
    border-right-color: #FFFFFF; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem; }
  .bs-popover-bottom .arrow, .bs-popover-auto[x-placement^="bottom"] .arrow {
    top: calc((0.5rem + 1px) * -1); }
  .bs-popover-bottom .arrow::before, .bs-popover-auto[x-placement^="bottom"] .arrow::before,
  .bs-popover-bottom .arrow::after, .bs-popover-auto[x-placement^="bottom"] .arrow::after {
    border-width: 0 0.5rem 0.5rem 0.5rem; }
  .bs-popover-bottom .arrow::before, .bs-popover-auto[x-placement^="bottom"] .arrow::before {
    top: 0;
    border-bottom-color: rgba(0, 0, 0, 0.25); }
  .bs-popover-bottom .arrow::after, .bs-popover-auto[x-placement^="bottom"] .arrow::after {
    top: 1px;
    border-bottom-color: #FFFFFF; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem; }
  .bs-popover-left .arrow, .bs-popover-auto[x-placement^="left"] .arrow {
    right: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 4px 0; }
  .bs-popover-left .arrow::before, .bs-popover-auto[x-placement^="left"] .arrow::before,
  .bs-popover-left .arrow::after, .bs-popover-auto[x-placement^="left"] .arrow::after {
    border-width: 0.5rem 0 0.5rem 0.5rem; }
  .bs-popover-left .arrow::before, .bs-popover-auto[x-placement^="left"] .arrow::before {
    right: 0;
    border-left-color: rgba(0, 0, 0, 0.25); }
  .bs-popover-left .arrow::after, .bs-popover-auto[x-placement^="left"] .arrow::after {
    right: 1px;
    border-left-color: #FFFFFF; }

.popover-header {
  padding: 0.6154rem 1.077rem;
  margin-bottom: 0;
  font-size: 1rem;
  color: inherit;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(4px - 1px);
  border-top-right-radius: calc(4px - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 0.6154rem 1.077rem;
  color: #404040; }

.carousel {
  position: relative; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }

.carousel-item {
  position: relative;
  display: none;
  -ms-flex-align: center;
      align-items: center;
  width: 100%;
  transition: -webkit-transform 0.6s ease;
  transition: transform 0.6s ease;
  transition: transform 0.6s ease, -webkit-transform 0.6s ease;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  -webkit-perspective: 1000px;
          perspective: 1000px; }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next,
.carousel-item-prev {
  position: absolute;
  top: 0; }

.carousel-item-next.carousel-item-left,
.carousel-item-prev.carousel-item-right {
  -webkit-transform: translateX(0);
          transform: translateX(0); }
  @supports ((-webkit-transform-style: preserve-3d) or (transform-style: preserve-3d)) {
    .carousel-item-next.carousel-item-left,
    .carousel-item-prev.carousel-item-right {
      -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0); } }

.carousel-item-next,
.active.carousel-item-right {
  -webkit-transform: translateX(100%);
          transform: translateX(100%); }
  @supports ((-webkit-transform-style: preserve-3d) or (transform-style: preserve-3d)) {
    .carousel-item-next,
    .active.carousel-item-right {
      -webkit-transform: translate3d(100%, 0, 0);
              transform: translate3d(100%, 0, 0); } }

.carousel-item-prev,
.active.carousel-item-left {
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%); }
  @supports ((-webkit-transform-style: preserve-3d) or (transform-style: preserve-3d)) {
    .carousel-item-prev,
    .active.carousel-item-left {
      -webkit-transform: translate3d(-100%, 0, 0);
              transform: translate3d(-100%, 0, 0); } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  width: 15%;
  color: #FFFFFF;
  text-align: center;
  opacity: 0.5; }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #FFFFFF;
    text-decoration: none;
    outline: 0;
    opacity: .9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: transparent no-repeat center center;
  background-size: 100% 100%; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23FFFFFF' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23FFFFFF' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 10px;
  left: 0;
  z-index: 15;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    position: relative;
    -ms-flex: 0 1 auto;
        flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    background-color: rgba(255, 255, 255, 0.5); }
    .carousel-indicators li::before {
      position: absolute;
      top: -10px;
      left: 0;
      display: inline-block;
      width: 100%;
      height: 10px;
      content: ""; }
    .carousel-indicators li::after {
      position: absolute;
      bottom: -10px;
      left: 0;
      display: inline-block;
      width: 100%;
      height: 10px;
      content: ""; }
  .carousel-indicators .active {
    background-color: #FFFFFF; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #FFFFFF;
  text-align: center; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #4285f4 !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #1266f1 !important; }

.bg-secondary {
  background-color: #878787 !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #6e6d6d !important; }

.bg-success {
  background-color: #34a853 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #288140 !important; }

.bg-info {
  background-color: #4285f4 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #1266f1 !important; }

.bg-warning {
  background-color: #fbbc05 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #ca9703 !important; }

.bg-danger {
  background-color: #ea4335 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #d62516 !important; }

.bg-light {
  background-color: #FFFFFF !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #e6e5e5 !important; }

.bg-dark {
  background-color: #3d3c3c !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #232323 !important; }

.bg-grey {
  background-color: #cccccc !important; }

a.bg-grey:hover, a.bg-grey:focus,
button.bg-grey:hover,
button.bg-grey:focus {
  background-color: #b3b2b2 !important; }

.bg-white {
  background-color: #FFFFFF !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #4285f4 !important; }

.border-secondary {
  border-color: #878787 !important; }

.border-success {
  border-color: #34a853 !important; }

.border-info {
  border-color: #4285f4 !important; }

.border-warning {
  border-color: #fbbc05 !important; }

.border-danger {
  border-color: #ea4335 !important; }

.border-light {
  border-color: #FFFFFF !important; }

.border-dark {
  border-color: #3d3c3c !important; }

.border-grey {
  border-color: #cccccc !important; }

.border-white {
  border-color: #FFFFFF !important; }

.rounded {
  border-radius: 2px !important; }

.rounded-top {
  border-top-left-radius: 2px !important;
  border-top-right-radius: 2px !important; }

.rounded-right {
  border-top-right-radius: 2px !important;
  border-bottom-right-radius: 2px !important; }

.rounded-bottom {
  border-bottom-right-radius: 2px !important;
  border-bottom-left-radius: 2px !important; }

.rounded-left {
  border-top-left-radius: 2px !important;
  border-bottom-left-radius: 2px !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: -ms-flexbox !important;
  display: flex !important; }

.d-inline-flex {
  display: -ms-inline-flexbox !important;
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-sm-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-md-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-lg-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-xl-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-print-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.8571428571%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  -ms-flex-direction: row !important;
      flex-direction: row !important; }

.flex-column {
  -ms-flex-direction: column !important;
      flex-direction: column !important; }

.flex-row-reverse {
  -ms-flex-direction: row-reverse !important;
      flex-direction: row-reverse !important; }

.flex-column-reverse {
  -ms-flex-direction: column-reverse !important;
      flex-direction: column-reverse !important; }

.flex-wrap {
  -ms-flex-wrap: wrap !important;
      flex-wrap: wrap !important; }

.flex-nowrap {
  -ms-flex-wrap: nowrap !important;
      flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  -ms-flex-wrap: wrap-reverse !important;
      flex-wrap: wrap-reverse !important; }

.justify-content-start {
  -ms-flex-pack: start !important;
      justify-content: flex-start !important; }

.justify-content-end {
  -ms-flex-pack: end !important;
      justify-content: flex-end !important; }

.justify-content-center {
  -ms-flex-pack: center !important;
      justify-content: center !important; }

.justify-content-between {
  -ms-flex-pack: justify !important;
      justify-content: space-between !important; }

.justify-content-around {
  -ms-flex-pack: distribute !important;
      justify-content: space-around !important; }

.align-items-start {
  -ms-flex-align: start !important;
      align-items: flex-start !important; }

.align-items-end {
  -ms-flex-align: end !important;
      align-items: flex-end !important; }

.align-items-center {
  -ms-flex-align: center !important;
      align-items: center !important; }

.align-items-baseline {
  -ms-flex-align: baseline !important;
      align-items: baseline !important; }

.align-items-stretch {
  -ms-flex-align: stretch !important;
      align-items: stretch !important; }

.align-content-start {
  -ms-flex-line-pack: start !important;
      align-content: flex-start !important; }

.align-content-end {
  -ms-flex-line-pack: end !important;
      align-content: flex-end !important; }

.align-content-center {
  -ms-flex-line-pack: center !important;
      align-content: center !important; }

.align-content-between {
  -ms-flex-line-pack: justify !important;
      align-content: space-between !important; }

.align-content-around {
  -ms-flex-line-pack: distribute !important;
      align-content: space-around !important; }

.align-content-stretch {
  -ms-flex-line-pack: stretch !important;
      align-content: stretch !important; }

.align-self-auto {
  -ms-flex-item-align: auto !important;
      -ms-grid-row-align: auto !important;
      align-self: auto !important; }

.align-self-start {
  -ms-flex-item-align: start !important;
      align-self: flex-start !important; }

.align-self-end {
  -ms-flex-item-align: end !important;
      align-self: flex-end !important; }

.align-self-center {
  -ms-flex-item-align: center !important;
      -ms-grid-row-align: center !important;
      align-self: center !important; }

.align-self-baseline {
  -ms-flex-item-align: baseline !important;
      align-self: baseline !important; }

.align-self-stretch {
  -ms-flex-item-align: stretch !important;
      -ms-grid-row-align: stretch !important;
      align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    -ms-flex-direction: row !important;
        flex-direction: row !important; }
  .flex-sm-column {
    -ms-flex-direction: column !important;
        flex-direction: column !important; }
  .flex-sm-row-reverse {
    -ms-flex-direction: row-reverse !important;
        flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    -ms-flex-direction: column-reverse !important;
        flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important; }
  .justify-content-sm-start {
    -ms-flex-pack: start !important;
        justify-content: flex-start !important; }
  .justify-content-sm-end {
    -ms-flex-pack: end !important;
        justify-content: flex-end !important; }
  .justify-content-sm-center {
    -ms-flex-pack: center !important;
        justify-content: center !important; }
  .justify-content-sm-between {
    -ms-flex-pack: justify !important;
        justify-content: space-between !important; }
  .justify-content-sm-around {
    -ms-flex-pack: distribute !important;
        justify-content: space-around !important; }
  .align-items-sm-start {
    -ms-flex-align: start !important;
        align-items: flex-start !important; }
  .align-items-sm-end {
    -ms-flex-align: end !important;
        align-items: flex-end !important; }
  .align-items-sm-center {
    -ms-flex-align: center !important;
        align-items: center !important; }
  .align-items-sm-baseline {
    -ms-flex-align: baseline !important;
        align-items: baseline !important; }
  .align-items-sm-stretch {
    -ms-flex-align: stretch !important;
        align-items: stretch !important; }
  .align-content-sm-start {
    -ms-flex-line-pack: start !important;
        align-content: flex-start !important; }
  .align-content-sm-end {
    -ms-flex-line-pack: end !important;
        align-content: flex-end !important; }
  .align-content-sm-center {
    -ms-flex-line-pack: center !important;
        align-content: center !important; }
  .align-content-sm-between {
    -ms-flex-line-pack: justify !important;
        align-content: space-between !important; }
  .align-content-sm-around {
    -ms-flex-line-pack: distribute !important;
        align-content: space-around !important; }
  .align-content-sm-stretch {
    -ms-flex-line-pack: stretch !important;
        align-content: stretch !important; }
  .align-self-sm-auto {
    -ms-flex-item-align: auto !important;
        -ms-grid-row-align: auto !important;
        align-self: auto !important; }
  .align-self-sm-start {
    -ms-flex-item-align: start !important;
        align-self: flex-start !important; }
  .align-self-sm-end {
    -ms-flex-item-align: end !important;
        align-self: flex-end !important; }
  .align-self-sm-center {
    -ms-flex-item-align: center !important;
        -ms-grid-row-align: center !important;
        align-self: center !important; }
  .align-self-sm-baseline {
    -ms-flex-item-align: baseline !important;
        align-self: baseline !important; }
  .align-self-sm-stretch {
    -ms-flex-item-align: stretch !important;
        -ms-grid-row-align: stretch !important;
        align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    -ms-flex-direction: row !important;
        flex-direction: row !important; }
  .flex-md-column {
    -ms-flex-direction: column !important;
        flex-direction: column !important; }
  .flex-md-row-reverse {
    -ms-flex-direction: row-reverse !important;
        flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    -ms-flex-direction: column-reverse !important;
        flex-direction: column-reverse !important; }
  .flex-md-wrap {
    -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important; }
  .flex-md-nowrap {
    -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important; }
  .justify-content-md-start {
    -ms-flex-pack: start !important;
        justify-content: flex-start !important; }
  .justify-content-md-end {
    -ms-flex-pack: end !important;
        justify-content: flex-end !important; }
  .justify-content-md-center {
    -ms-flex-pack: center !important;
        justify-content: center !important; }
  .justify-content-md-between {
    -ms-flex-pack: justify !important;
        justify-content: space-between !important; }
  .justify-content-md-around {
    -ms-flex-pack: distribute !important;
        justify-content: space-around !important; }
  .align-items-md-start {
    -ms-flex-align: start !important;
        align-items: flex-start !important; }
  .align-items-md-end {
    -ms-flex-align: end !important;
        align-items: flex-end !important; }
  .align-items-md-center {
    -ms-flex-align: center !important;
        align-items: center !important; }
  .align-items-md-baseline {
    -ms-flex-align: baseline !important;
        align-items: baseline !important; }
  .align-items-md-stretch {
    -ms-flex-align: stretch !important;
        align-items: stretch !important; }
  .align-content-md-start {
    -ms-flex-line-pack: start !important;
        align-content: flex-start !important; }
  .align-content-md-end {
    -ms-flex-line-pack: end !important;
        align-content: flex-end !important; }
  .align-content-md-center {
    -ms-flex-line-pack: center !important;
        align-content: center !important; }
  .align-content-md-between {
    -ms-flex-line-pack: justify !important;
        align-content: space-between !important; }
  .align-content-md-around {
    -ms-flex-line-pack: distribute !important;
        align-content: space-around !important; }
  .align-content-md-stretch {
    -ms-flex-line-pack: stretch !important;
        align-content: stretch !important; }
  .align-self-md-auto {
    -ms-flex-item-align: auto !important;
        -ms-grid-row-align: auto !important;
        align-self: auto !important; }
  .align-self-md-start {
    -ms-flex-item-align: start !important;
        align-self: flex-start !important; }
  .align-self-md-end {
    -ms-flex-item-align: end !important;
        align-self: flex-end !important; }
  .align-self-md-center {
    -ms-flex-item-align: center !important;
        -ms-grid-row-align: center !important;
        align-self: center !important; }
  .align-self-md-baseline {
    -ms-flex-item-align: baseline !important;
        align-self: baseline !important; }
  .align-self-md-stretch {
    -ms-flex-item-align: stretch !important;
        -ms-grid-row-align: stretch !important;
        align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    -ms-flex-direction: row !important;
        flex-direction: row !important; }
  .flex-lg-column {
    -ms-flex-direction: column !important;
        flex-direction: column !important; }
  .flex-lg-row-reverse {
    -ms-flex-direction: row-reverse !important;
        flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    -ms-flex-direction: column-reverse !important;
        flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important; }
  .justify-content-lg-start {
    -ms-flex-pack: start !important;
        justify-content: flex-start !important; }
  .justify-content-lg-end {
    -ms-flex-pack: end !important;
        justify-content: flex-end !important; }
  .justify-content-lg-center {
    -ms-flex-pack: center !important;
        justify-content: center !important; }
  .justify-content-lg-between {
    -ms-flex-pack: justify !important;
        justify-content: space-between !important; }
  .justify-content-lg-around {
    -ms-flex-pack: distribute !important;
        justify-content: space-around !important; }
  .align-items-lg-start {
    -ms-flex-align: start !important;
        align-items: flex-start !important; }
  .align-items-lg-end {
    -ms-flex-align: end !important;
        align-items: flex-end !important; }
  .align-items-lg-center {
    -ms-flex-align: center !important;
        align-items: center !important; }
  .align-items-lg-baseline {
    -ms-flex-align: baseline !important;
        align-items: baseline !important; }
  .align-items-lg-stretch {
    -ms-flex-align: stretch !important;
        align-items: stretch !important; }
  .align-content-lg-start {
    -ms-flex-line-pack: start !important;
        align-content: flex-start !important; }
  .align-content-lg-end {
    -ms-flex-line-pack: end !important;
        align-content: flex-end !important; }
  .align-content-lg-center {
    -ms-flex-line-pack: center !important;
        align-content: center !important; }
  .align-content-lg-between {
    -ms-flex-line-pack: justify !important;
        align-content: space-between !important; }
  .align-content-lg-around {
    -ms-flex-line-pack: distribute !important;
        align-content: space-around !important; }
  .align-content-lg-stretch {
    -ms-flex-line-pack: stretch !important;
        align-content: stretch !important; }
  .align-self-lg-auto {
    -ms-flex-item-align: auto !important;
        -ms-grid-row-align: auto !important;
        align-self: auto !important; }
  .align-self-lg-start {
    -ms-flex-item-align: start !important;
        align-self: flex-start !important; }
  .align-self-lg-end {
    -ms-flex-item-align: end !important;
        align-self: flex-end !important; }
  .align-self-lg-center {
    -ms-flex-item-align: center !important;
        -ms-grid-row-align: center !important;
        align-self: center !important; }
  .align-self-lg-baseline {
    -ms-flex-item-align: baseline !important;
        align-self: baseline !important; }
  .align-self-lg-stretch {
    -ms-flex-item-align: stretch !important;
        -ms-grid-row-align: stretch !important;
        align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    -ms-flex-direction: row !important;
        flex-direction: row !important; }
  .flex-xl-column {
    -ms-flex-direction: column !important;
        flex-direction: column !important; }
  .flex-xl-row-reverse {
    -ms-flex-direction: row-reverse !important;
        flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    -ms-flex-direction: column-reverse !important;
        flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important; }
  .justify-content-xl-start {
    -ms-flex-pack: start !important;
        justify-content: flex-start !important; }
  .justify-content-xl-end {
    -ms-flex-pack: end !important;
        justify-content: flex-end !important; }
  .justify-content-xl-center {
    -ms-flex-pack: center !important;
        justify-content: center !important; }
  .justify-content-xl-between {
    -ms-flex-pack: justify !important;
        justify-content: space-between !important; }
  .justify-content-xl-around {
    -ms-flex-pack: distribute !important;
        justify-content: space-around !important; }
  .align-items-xl-start {
    -ms-flex-align: start !important;
        align-items: flex-start !important; }
  .align-items-xl-end {
    -ms-flex-align: end !important;
        align-items: flex-end !important; }
  .align-items-xl-center {
    -ms-flex-align: center !important;
        align-items: center !important; }
  .align-items-xl-baseline {
    -ms-flex-align: baseline !important;
        align-items: baseline !important; }
  .align-items-xl-stretch {
    -ms-flex-align: stretch !important;
        align-items: stretch !important; }
  .align-content-xl-start {
    -ms-flex-line-pack: start !important;
        align-content: flex-start !important; }
  .align-content-xl-end {
    -ms-flex-line-pack: end !important;
        align-content: flex-end !important; }
  .align-content-xl-center {
    -ms-flex-line-pack: center !important;
        align-content: center !important; }
  .align-content-xl-between {
    -ms-flex-line-pack: justify !important;
        align-content: space-between !important; }
  .align-content-xl-around {
    -ms-flex-line-pack: distribute !important;
        align-content: space-around !important; }
  .align-content-xl-stretch {
    -ms-flex-line-pack: stretch !important;
        align-content: stretch !important; }
  .align-self-xl-auto {
    -ms-flex-item-align: auto !important;
        -ms-grid-row-align: auto !important;
        align-self: auto !important; }
  .align-self-xl-start {
    -ms-flex-item-align: start !important;
        align-self: flex-start !important; }
  .align-self-xl-end {
    -ms-flex-item-align: end !important;
        align-self: flex-end !important; }
  .align-self-xl-center {
    -ms-flex-item-align: center !important;
        -ms-grid-row-align: center !important;
        align-self: center !important; }
  .align-self-xl-baseline {
    -ms-flex-item-align: baseline !important;
        align-self: baseline !important; }
  .align-self-xl-stretch {
    -ms-flex-item-align: stretch !important;
        -ms-grid-row-align: stretch !important;
        align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  -webkit-clip-path: inset(50%);
          clip-path: inset(50%);
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
  -webkit-clip-path: none;
          clip-path: none; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.3846153846rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.3846153846rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.3846153846rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.3846153846rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.3846153846rem !important; }

.m-2 {
  margin: 0.7692307692rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.7692307692rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.7692307692rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.7692307692rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.7692307692rem !important; }

.m-3 {
  margin: 1.154rem !important; }

.mt-3,
.my-3 {
  margin-top: 1.154rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1.154rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1.154rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1.154rem !important; }

.m-4 {
  margin: 1.539rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.539rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.539rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.539rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.539rem !important; }

.m-5 {
  margin: 1.923rem !important; }

.mt-5,
.my-5 {
  margin-top: 1.923rem !important; }

.mr-5,
.mx-5 {
  margin-right: 1.923rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 1.923rem !important; }

.ml-5,
.mx-5 {
  margin-left: 1.923rem !important; }

.m-6 {
  margin: 2.308rem !important; }

.mt-6,
.my-6 {
  margin-top: 2.308rem !important; }

.mr-6,
.mx-6 {
  margin-right: 2.308rem !important; }

.mb-6,
.my-6 {
  margin-bottom: 2.308rem !important; }

.ml-6,
.mx-6 {
  margin-left: 2.308rem !important; }

.m-7 {
  margin: 3.077rem !important; }

.mt-7,
.my-7 {
  margin-top: 3.077rem !important; }

.mr-7,
.mx-7 {
  margin-right: 3.077rem !important; }

.mb-7,
.my-7 {
  margin-bottom: 3.077rem !important; }

.ml-7,
.mx-7 {
  margin-left: 3.077rem !important; }

.m-8 {
  margin: 3.851rem !important; }

.mt-8,
.my-8 {
  margin-top: 3.851rem !important; }

.mr-8,
.mx-8 {
  margin-right: 3.851rem !important; }

.mb-8,
.my-8 {
  margin-bottom: 3.851rem !important; }

.ml-8,
.mx-8 {
  margin-left: 3.851rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.3846153846rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.3846153846rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.3846153846rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.3846153846rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.3846153846rem !important; }

.p-2 {
  padding: 0.7692307692rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.7692307692rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.7692307692rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.7692307692rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.7692307692rem !important; }

.p-3 {
  padding: 1.154rem !important; }

.pt-3,
.py-3 {
  padding-top: 1.154rem !important; }

.pr-3,
.px-3 {
  padding-right: 1.154rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1.154rem !important; }

.pl-3,
.px-3 {
  padding-left: 1.154rem !important; }

.p-4 {
  padding: 1.539rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.539rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.539rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.539rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.539rem !important; }

.p-5 {
  padding: 1.923rem !important; }

.pt-5,
.py-5 {
  padding-top: 1.923rem !important; }

.pr-5,
.px-5 {
  padding-right: 1.923rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 1.923rem !important; }

.pl-5,
.px-5 {
  padding-left: 1.923rem !important; }

.p-6 {
  padding: 2.308rem !important; }

.pt-6,
.py-6 {
  padding-top: 2.308rem !important; }

.pr-6,
.px-6 {
  padding-right: 2.308rem !important; }

.pb-6,
.py-6 {
  padding-bottom: 2.308rem !important; }

.pl-6,
.px-6 {
  padding-left: 2.308rem !important; }

.p-7 {
  padding: 3.077rem !important; }

.pt-7,
.py-7 {
  padding-top: 3.077rem !important; }

.pr-7,
.px-7 {
  padding-right: 3.077rem !important; }

.pb-7,
.py-7 {
  padding-bottom: 3.077rem !important; }

.pl-7,
.px-7 {
  padding-left: 3.077rem !important; }

.p-8 {
  padding: 3.851rem !important; }

.pt-8,
.py-8 {
  padding-top: 3.851rem !important; }

.pr-8,
.px-8 {
  padding-right: 3.851rem !important; }

.pb-8,
.py-8 {
  padding-bottom: 3.851rem !important; }

.pl-8,
.px-8 {
  padding-left: 3.851rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.3846153846rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.3846153846rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.3846153846rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.3846153846rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.3846153846rem !important; }
  .m-sm-2 {
    margin: 0.7692307692rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.7692307692rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.7692307692rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.7692307692rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.7692307692rem !important; }
  .m-sm-3 {
    margin: 1.154rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1.154rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1.154rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1.154rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1.154rem !important; }
  .m-sm-4 {
    margin: 1.539rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.539rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.539rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.539rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.539rem !important; }
  .m-sm-5 {
    margin: 1.923rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 1.923rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 1.923rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 1.923rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 1.923rem !important; }
  .m-sm-6 {
    margin: 2.308rem !important; }
  .mt-sm-6,
  .my-sm-6 {
    margin-top: 2.308rem !important; }
  .mr-sm-6,
  .mx-sm-6 {
    margin-right: 2.308rem !important; }
  .mb-sm-6,
  .my-sm-6 {
    margin-bottom: 2.308rem !important; }
  .ml-sm-6,
  .mx-sm-6 {
    margin-left: 2.308rem !important; }
  .m-sm-7 {
    margin: 3.077rem !important; }
  .mt-sm-7,
  .my-sm-7 {
    margin-top: 3.077rem !important; }
  .mr-sm-7,
  .mx-sm-7 {
    margin-right: 3.077rem !important; }
  .mb-sm-7,
  .my-sm-7 {
    margin-bottom: 3.077rem !important; }
  .ml-sm-7,
  .mx-sm-7 {
    margin-left: 3.077rem !important; }
  .m-sm-8 {
    margin: 3.851rem !important; }
  .mt-sm-8,
  .my-sm-8 {
    margin-top: 3.851rem !important; }
  .mr-sm-8,
  .mx-sm-8 {
    margin-right: 3.851rem !important; }
  .mb-sm-8,
  .my-sm-8 {
    margin-bottom: 3.851rem !important; }
  .ml-sm-8,
  .mx-sm-8 {
    margin-left: 3.851rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.3846153846rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.3846153846rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.3846153846rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.3846153846rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.3846153846rem !important; }
  .p-sm-2 {
    padding: 0.7692307692rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.7692307692rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.7692307692rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.7692307692rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.7692307692rem !important; }
  .p-sm-3 {
    padding: 1.154rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1.154rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1.154rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1.154rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1.154rem !important; }
  .p-sm-4 {
    padding: 1.539rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.539rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.539rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.539rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.539rem !important; }
  .p-sm-5 {
    padding: 1.923rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 1.923rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 1.923rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 1.923rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 1.923rem !important; }
  .p-sm-6 {
    padding: 2.308rem !important; }
  .pt-sm-6,
  .py-sm-6 {
    padding-top: 2.308rem !important; }
  .pr-sm-6,
  .px-sm-6 {
    padding-right: 2.308rem !important; }
  .pb-sm-6,
  .py-sm-6 {
    padding-bottom: 2.308rem !important; }
  .pl-sm-6,
  .px-sm-6 {
    padding-left: 2.308rem !important; }
  .p-sm-7 {
    padding: 3.077rem !important; }
  .pt-sm-7,
  .py-sm-7 {
    padding-top: 3.077rem !important; }
  .pr-sm-7,
  .px-sm-7 {
    padding-right: 3.077rem !important; }
  .pb-sm-7,
  .py-sm-7 {
    padding-bottom: 3.077rem !important; }
  .pl-sm-7,
  .px-sm-7 {
    padding-left: 3.077rem !important; }
  .p-sm-8 {
    padding: 3.851rem !important; }
  .pt-sm-8,
  .py-sm-8 {
    padding-top: 3.851rem !important; }
  .pr-sm-8,
  .px-sm-8 {
    padding-right: 3.851rem !important; }
  .pb-sm-8,
  .py-sm-8 {
    padding-bottom: 3.851rem !important; }
  .pl-sm-8,
  .px-sm-8 {
    padding-left: 3.851rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.3846153846rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.3846153846rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.3846153846rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.3846153846rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.3846153846rem !important; }
  .m-md-2 {
    margin: 0.7692307692rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.7692307692rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.7692307692rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.7692307692rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.7692307692rem !important; }
  .m-md-3 {
    margin: 1.154rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1.154rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1.154rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1.154rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1.154rem !important; }
  .m-md-4 {
    margin: 1.539rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.539rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.539rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.539rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.539rem !important; }
  .m-md-5 {
    margin: 1.923rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 1.923rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 1.923rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 1.923rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 1.923rem !important; }
  .m-md-6 {
    margin: 2.308rem !important; }
  .mt-md-6,
  .my-md-6 {
    margin-top: 2.308rem !important; }
  .mr-md-6,
  .mx-md-6 {
    margin-right: 2.308rem !important; }
  .mb-md-6,
  .my-md-6 {
    margin-bottom: 2.308rem !important; }
  .ml-md-6,
  .mx-md-6 {
    margin-left: 2.308rem !important; }
  .m-md-7 {
    margin: 3.077rem !important; }
  .mt-md-7,
  .my-md-7 {
    margin-top: 3.077rem !important; }
  .mr-md-7,
  .mx-md-7 {
    margin-right: 3.077rem !important; }
  .mb-md-7,
  .my-md-7 {
    margin-bottom: 3.077rem !important; }
  .ml-md-7,
  .mx-md-7 {
    margin-left: 3.077rem !important; }
  .m-md-8 {
    margin: 3.851rem !important; }
  .mt-md-8,
  .my-md-8 {
    margin-top: 3.851rem !important; }
  .mr-md-8,
  .mx-md-8 {
    margin-right: 3.851rem !important; }
  .mb-md-8,
  .my-md-8 {
    margin-bottom: 3.851rem !important; }
  .ml-md-8,
  .mx-md-8 {
    margin-left: 3.851rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.3846153846rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.3846153846rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.3846153846rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.3846153846rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.3846153846rem !important; }
  .p-md-2 {
    padding: 0.7692307692rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.7692307692rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.7692307692rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.7692307692rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.7692307692rem !important; }
  .p-md-3 {
    padding: 1.154rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1.154rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1.154rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1.154rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1.154rem !important; }
  .p-md-4 {
    padding: 1.539rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.539rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.539rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.539rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.539rem !important; }
  .p-md-5 {
    padding: 1.923rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 1.923rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 1.923rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 1.923rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 1.923rem !important; }
  .p-md-6 {
    padding: 2.308rem !important; }
  .pt-md-6,
  .py-md-6 {
    padding-top: 2.308rem !important; }
  .pr-md-6,
  .px-md-6 {
    padding-right: 2.308rem !important; }
  .pb-md-6,
  .py-md-6 {
    padding-bottom: 2.308rem !important; }
  .pl-md-6,
  .px-md-6 {
    padding-left: 2.308rem !important; }
  .p-md-7 {
    padding: 3.077rem !important; }
  .pt-md-7,
  .py-md-7 {
    padding-top: 3.077rem !important; }
  .pr-md-7,
  .px-md-7 {
    padding-right: 3.077rem !important; }
  .pb-md-7,
  .py-md-7 {
    padding-bottom: 3.077rem !important; }
  .pl-md-7,
  .px-md-7 {
    padding-left: 3.077rem !important; }
  .p-md-8 {
    padding: 3.851rem !important; }
  .pt-md-8,
  .py-md-8 {
    padding-top: 3.851rem !important; }
  .pr-md-8,
  .px-md-8 {
    padding-right: 3.851rem !important; }
  .pb-md-8,
  .py-md-8 {
    padding-bottom: 3.851rem !important; }
  .pl-md-8,
  .px-md-8 {
    padding-left: 3.851rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.3846153846rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.3846153846rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.3846153846rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.3846153846rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.3846153846rem !important; }
  .m-lg-2 {
    margin: 0.7692307692rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.7692307692rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.7692307692rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.7692307692rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.7692307692rem !important; }
  .m-lg-3 {
    margin: 1.154rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1.154rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1.154rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1.154rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1.154rem !important; }
  .m-lg-4 {
    margin: 1.539rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.539rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.539rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.539rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.539rem !important; }
  .m-lg-5 {
    margin: 1.923rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 1.923rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 1.923rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 1.923rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 1.923rem !important; }
  .m-lg-6 {
    margin: 2.308rem !important; }
  .mt-lg-6,
  .my-lg-6 {
    margin-top: 2.308rem !important; }
  .mr-lg-6,
  .mx-lg-6 {
    margin-right: 2.308rem !important; }
  .mb-lg-6,
  .my-lg-6 {
    margin-bottom: 2.308rem !important; }
  .ml-lg-6,
  .mx-lg-6 {
    margin-left: 2.308rem !important; }
  .m-lg-7 {
    margin: 3.077rem !important; }
  .mt-lg-7,
  .my-lg-7 {
    margin-top: 3.077rem !important; }
  .mr-lg-7,
  .mx-lg-7 {
    margin-right: 3.077rem !important; }
  .mb-lg-7,
  .my-lg-7 {
    margin-bottom: 3.077rem !important; }
  .ml-lg-7,
  .mx-lg-7 {
    margin-left: 3.077rem !important; }
  .m-lg-8 {
    margin: 3.851rem !important; }
  .mt-lg-8,
  .my-lg-8 {
    margin-top: 3.851rem !important; }
  .mr-lg-8,
  .mx-lg-8 {
    margin-right: 3.851rem !important; }
  .mb-lg-8,
  .my-lg-8 {
    margin-bottom: 3.851rem !important; }
  .ml-lg-8,
  .mx-lg-8 {
    margin-left: 3.851rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.3846153846rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.3846153846rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.3846153846rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.3846153846rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.3846153846rem !important; }
  .p-lg-2 {
    padding: 0.7692307692rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.7692307692rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.7692307692rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.7692307692rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.7692307692rem !important; }
  .p-lg-3 {
    padding: 1.154rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1.154rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1.154rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1.154rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1.154rem !important; }
  .p-lg-4 {
    padding: 1.539rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.539rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.539rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.539rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.539rem !important; }
  .p-lg-5 {
    padding: 1.923rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 1.923rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 1.923rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 1.923rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 1.923rem !important; }
  .p-lg-6 {
    padding: 2.308rem !important; }
  .pt-lg-6,
  .py-lg-6 {
    padding-top: 2.308rem !important; }
  .pr-lg-6,
  .px-lg-6 {
    padding-right: 2.308rem !important; }
  .pb-lg-6,
  .py-lg-6 {
    padding-bottom: 2.308rem !important; }
  .pl-lg-6,
  .px-lg-6 {
    padding-left: 2.308rem !important; }
  .p-lg-7 {
    padding: 3.077rem !important; }
  .pt-lg-7,
  .py-lg-7 {
    padding-top: 3.077rem !important; }
  .pr-lg-7,
  .px-lg-7 {
    padding-right: 3.077rem !important; }
  .pb-lg-7,
  .py-lg-7 {
    padding-bottom: 3.077rem !important; }
  .pl-lg-7,
  .px-lg-7 {
    padding-left: 3.077rem !important; }
  .p-lg-8 {
    padding: 3.851rem !important; }
  .pt-lg-8,
  .py-lg-8 {
    padding-top: 3.851rem !important; }
  .pr-lg-8,
  .px-lg-8 {
    padding-right: 3.851rem !important; }
  .pb-lg-8,
  .py-lg-8 {
    padding-bottom: 3.851rem !important; }
  .pl-lg-8,
  .px-lg-8 {
    padding-left: 3.851rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.3846153846rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.3846153846rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.3846153846rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.3846153846rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.3846153846rem !important; }
  .m-xl-2 {
    margin: 0.7692307692rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.7692307692rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.7692307692rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.7692307692rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.7692307692rem !important; }
  .m-xl-3 {
    margin: 1.154rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1.154rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1.154rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1.154rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1.154rem !important; }
  .m-xl-4 {
    margin: 1.539rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.539rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.539rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.539rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.539rem !important; }
  .m-xl-5 {
    margin: 1.923rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 1.923rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 1.923rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 1.923rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 1.923rem !important; }
  .m-xl-6 {
    margin: 2.308rem !important; }
  .mt-xl-6,
  .my-xl-6 {
    margin-top: 2.308rem !important; }
  .mr-xl-6,
  .mx-xl-6 {
    margin-right: 2.308rem !important; }
  .mb-xl-6,
  .my-xl-6 {
    margin-bottom: 2.308rem !important; }
  .ml-xl-6,
  .mx-xl-6 {
    margin-left: 2.308rem !important; }
  .m-xl-7 {
    margin: 3.077rem !important; }
  .mt-xl-7,
  .my-xl-7 {
    margin-top: 3.077rem !important; }
  .mr-xl-7,
  .mx-xl-7 {
    margin-right: 3.077rem !important; }
  .mb-xl-7,
  .my-xl-7 {
    margin-bottom: 3.077rem !important; }
  .ml-xl-7,
  .mx-xl-7 {
    margin-left: 3.077rem !important; }
  .m-xl-8 {
    margin: 3.851rem !important; }
  .mt-xl-8,
  .my-xl-8 {
    margin-top: 3.851rem !important; }
  .mr-xl-8,
  .mx-xl-8 {
    margin-right: 3.851rem !important; }
  .mb-xl-8,
  .my-xl-8 {
    margin-bottom: 3.851rem !important; }
  .ml-xl-8,
  .mx-xl-8 {
    margin-left: 3.851rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.3846153846rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.3846153846rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.3846153846rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.3846153846rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.3846153846rem !important; }
  .p-xl-2 {
    padding: 0.7692307692rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.7692307692rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.7692307692rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.7692307692rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.7692307692rem !important; }
  .p-xl-3 {
    padding: 1.154rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1.154rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1.154rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1.154rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1.154rem !important; }
  .p-xl-4 {
    padding: 1.539rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.539rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.539rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.539rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.539rem !important; }
  .p-xl-5 {
    padding: 1.923rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 1.923rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 1.923rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 1.923rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 1.923rem !important; }
  .p-xl-6 {
    padding: 2.308rem !important; }
  .pt-xl-6,
  .py-xl-6 {
    padding-top: 2.308rem !important; }
  .pr-xl-6,
  .px-xl-6 {
    padding-right: 2.308rem !important; }
  .pb-xl-6,
  .py-xl-6 {
    padding-bottom: 2.308rem !important; }
  .pl-xl-6,
  .px-xl-6 {
    padding-left: 2.308rem !important; }
  .p-xl-7 {
    padding: 3.077rem !important; }
  .pt-xl-7,
  .py-xl-7 {
    padding-top: 3.077rem !important; }
  .pr-xl-7,
  .px-xl-7 {
    padding-right: 3.077rem !important; }
  .pb-xl-7,
  .py-xl-7 {
    padding-bottom: 3.077rem !important; }
  .pl-xl-7,
  .px-xl-7 {
    padding-left: 3.077rem !important; }
  .p-xl-8 {
    padding: 3.851rem !important; }
  .pt-xl-8,
  .py-xl-8 {
    padding-top: 3.851rem !important; }
  .pr-xl-8,
  .px-xl-8 {
    padding-right: 3.851rem !important; }
  .pb-xl-8,
  .py-xl-8 {
    padding-bottom: 3.851rem !important; }
  .pl-xl-8,
  .px-xl-8 {
    padding-left: 3.851rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.text-justify {
  text-align: justify !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #4285f4 !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #1266f1 !important; }

.text-secondary {
  color: #878787 !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #6e6d6d !important; }

.text-success {
  color: #34a853 !important; }

a.text-success:hover, a.text-success:focus {
  color: #288140 !important; }

.text-info {
  color: #4285f4 !important; }

a.text-info:hover, a.text-info:focus {
  color: #1266f1 !important; }

.text-warning {
  color: #fbbc05 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #ca9703 !important; }

.text-danger {
  color: #ea4335 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #d62516 !important; }

.text-light {
  color: #FFFFFF !important; }

a.text-light:hover, a.text-light:focus {
  color: #e6e5e5 !important; }

.text-dark {
  color: #3d3c3c !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #232323 !important; }

.text-grey {
  color: #cccccc !important; }

a.text-grey:hover, a.text-grey:focus {
  color: #b3b2b2 !important; }

.text-muted {
  color: #878787 !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

/* General styles for the modal */
/* 
Styles for the html/body for special modal where we want 3d effects
Note that we need a container wrapping all content on the page for the 
perspective effects (not including the modals and the overlay).
*/
.modal-open {
  overflow: hidden; }

.modal-perspective,
.modal-perspective body {
  height: 100%;
  overflow: hidden; }

.modal-perspective body {
  background: #222;
  -webkit-perspective: 600px;
  perspective: 600px; }

.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1050;
  visibility: hidden;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden; }

.modal-show {
  visibility: visible;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 1051; }

.modal-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  visibility: hidden;
  top: 0;
  left: 0;
  z-index: 1050;
  opacity: 0;
  background: rgba(0, 0, 0, 0.5);
  transition: all 0.3s; }

.modal-show ~ .modal-overlay {
  opacity: 1;
  visibility: visible; }

/* Content styles */
.modal-content {
  position: relative;
  border-radius: 3px;
  margin: 30px auto;
  max-width: 630px;
  min-width: 320px; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

/* Effect 1: Fade in and scale up */
.modal-effect-1 .modal-content {
  -webkit-transform: scale(0.7);
  transform: scale(0.7);
  opacity: 0;
  transition: all 0.3s; }

.modal-show.modal-effect-1 .modal-content {
  -webkit-transform: scale(1);
  transform: scale(1);
  opacity: 1; }

/* Effect 2: Slide from the right */
.modal-effect-2 .modal-content {
  -webkit-transform: translateX(20%);
  transform: translateX(20%);
  opacity: 0;
  transition: all 0.3s cubic-bezier(0.25, 0.5, 0.5, 0.9); }

.modal-show.modal-effect-2 .modal-content {
  -webkit-transform: translateX(0);
  transform: translateX(0);
  opacity: 1; }

/* Effect 3: Slide from the bottom */
.modal-effect-3 .modal-content {
  -webkit-transform: translateY(20%);
  transform: translateY(20%);
  opacity: 0;
  transition: all 0.3s; }

.modal-show.modal-effect-3 .modal-content {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  opacity: 1; }

/* Effect 4: Newspaper */
.modal-effect-4 .modal-content {
  -webkit-transform: scale(0) rotate(720deg);
  transform: scale(0) rotate(720deg);
  opacity: 0; }

.modal-show.modal-effect-4 ~ .modal-overlay,
.modal-effect-4 .modal-content {
  transition: all 0.5s; }

.modal-show.modal-effect-4 .modal-content {
  -webkit-transform: scale(1) rotate(0deg);
  transform: scale(1) rotate(0deg);
  opacity: 1; }

/* Effect 5: fall */
.modal-effect-5.modal-container {
  -webkit-perspective: 1300px;
  perspective: 1300px; }

.modal-effect-5 .modal-content {
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform: translateZ(600px) rotateX(20deg);
  transform: translateZ(600px) rotateX(20deg);
  opacity: 0; }

.modal-show.modal-effect-5 .modal-content {
  transition: all 0.3s ease-in;
  -webkit-transform: translateZ(0px) rotateX(0deg);
  transform: translateZ(0px) rotateX(0deg);
  opacity: 1; }

/* Effect 6: side fall */
.modal-effect-6.modal-container {
  -webkit-perspective: 1300px;
  perspective: 1300px; }

.modal-effect-6 .modal-content {
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform: translate(30%) translateZ(600px) rotate(10deg);
  transform: translate(30%) translateZ(600px) rotate(10deg);
  opacity: 0; }

.modal-show.modal-effect-6 .modal-content {
  transition: all 0.3s ease-in;
  -webkit-transform: translate(0%) translateZ(0) rotate(0deg);
  transform: translate(0%) translateZ(0) rotate(0deg);
  opacity: 1; }

/* Effect 7:  slide and stick to top */
.modal-effect-7 {
  top: 0; }

.modal-effect-7 .modal-content {
  margin-top: 0;
  -webkit-transform: translateY(-200%);
  transform: translateY(-200%);
  transition: all .3s;
  opacity: 0; }

.modal-show.modal-effect-7 .modal-content {
  -webkit-transform: translateY(0%);
  transform: translateY(0%);
  border-radius: 0 0 3px 3px;
  opacity: 1; }

/* Effect 8: 3D flip horizontal */
.modal-effect-8.modal-container {
  -webkit-perspective: 1300px;
  perspective: 1300px; }

.modal-effect-8 .modal-content {
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform: rotateY(-70deg);
  transform: rotateY(-70deg);
  transition: all 0.3s;
  opacity: 0; }

.modal-show.modal-effect-8 .modal-content {
  -webkit-transform: rotateY(0deg);
  transform: rotateY(0deg);
  opacity: 1; }

/* Effect 9: 3D flip vertical */
.modal-effect-9.modal-container {
  -webkit-perspective: 1300px;
  perspective: 1300px; }

.modal-effect-9 .modal-content {
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform: rotateX(-70deg);
  transform: rotateX(-70deg);
  transition: all 0.3s;
  opacity: 0; }

.modal-show.modal-effect-9 .modal-content {
  -webkit-transform: rotateX(0deg);
  transform: rotateX(0deg);
  opacity: 1; }

/* Effect 10: 3D sign */
.modal-effect-10.modal-container {
  -webkit-perspective: 1300px;
  perspective: 1300px; }

.modal-effect-10 .modal-content {
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform: rotateX(-60deg);
  transform: rotateX(-60deg);
  -webkit-transform-origin: 50% 0;
  transform-origin: 50% 0;
  opacity: 0;
  transition: all 0.3s; }

.modal-show.modal-effect-10 .modal-content {
  -webkit-transform: rotateX(0deg);
  transform: rotateX(0deg);
  opacity: 1; }

/* Effect 11: Super scaled */
.modal-effect-11 .modal-content {
  -webkit-transform: scale(2);
  transform: scale(2);
  opacity: 0;
  transition: all 0.3s; }

.modal-show.modal-effect-11 .modal-content {
  -webkit-transform: scale(1);
  transform: scale(1);
  opacity: 1; }

/* Effect 12:  Just me */
.modal-effect-12 .modal-content {
  -webkit-transform: scale(0.8);
  transform: scale(0.8);
  opacity: 0;
  transition: all 0.3s; }

.modal-show.modal-effect-12 ~ .modal-overlay {
  background: #000; }

.modal-show.modal-effect-12 .modal-content {
  -webkit-transform: scale(1);
  transform: scale(1);
  opacity: 1; }

/* Effect 13: 3D slit */
.modal-effect-13.modal-container {
  -webkit-perspective: 1300px;
  perspective: 1300px; }

.modal-effect-13 .modal-content {
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform: translateZ(-3000px) rotateY(90deg);
  transform: translateZ(-3000px) rotateY(90deg);
  opacity: 0; }

.modal-show.modal-effect-13 .modal-content {
  -webkit-animation: slit .7s forwards ease-out;
  animation: slit .7s forwards ease-out; }

@-webkit-keyframes slit {
  50% {
    -webkit-transform: translateZ(-250px) rotateY(89deg);
    opacity: .5;
    -webkit-animation-timing-function: ease-out; }
  100% {
    -webkit-transform: translateZ(0) rotateY(0deg);
    opacity: 1; } }

@keyframes slit {
  50% {
    -webkit-transform: translateZ(-250px) rotateY(89deg);
            transform: translateZ(-250px) rotateY(89deg);
    opacity: 1;
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in; }
  100% {
    -webkit-transform: translateZ(0) rotateY(0deg);
            transform: translateZ(0) rotateY(0deg);
    opacity: 1; } }

/* Effect 14:  3D Rotate from bottom */
.modal-effect-14.modal-container {
  -webkit-perspective: 1300px;
  perspective: 1300px; }

.modal-effect-14 .modal-content {
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform: translateY(100%) rotateX(90deg);
  transform: translateY(100%) rotateX(90deg);
  -webkit-transform-origin: 0 100%;
  transform-origin: 0 100%;
  opacity: 0;
  transition: all 0.3s ease-out; }

.modal-show.modal-effect-14 .modal-content {
  -webkit-transform: translateY(0%) rotateX(0deg);
  transform: translateY(0%) rotateX(0deg);
  opacity: 1; }

/* Effect 15:  3D Rotate in from left */
.modal-effect-15.modal-container {
  -webkit-perspective: 1300px;
  perspective: 1300px; }

.modal-effect-15 .modal-content {
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform: translateZ(100px) translateX(-30%) rotateY(90deg);
  transform: translateZ(100px) translateX(-30%) rotateY(90deg);
  -webkit-transform-origin: 0 100%;
  transform-origin: 0 100%;
  opacity: 0;
  transition: all 0.3s; }

.modal-show.modal-effect-15 .modal-content {
  -webkit-transform: translateZ(0px) translateX(0%) rotateY(0deg);
  transform: translateZ(0px) translateX(0%) rotateY(0deg);
  opacity: 1; }

/* Effect 16:  Blur */
.modal-show.modal-effect-16 ~ .modal-overlay {
  background: rgba(180, 46, 32, 0.5); }

.modal-show.modal-effect-16 ~ .container {
  -webkit-filter: blur(3px);
  -moz-filter: blur(3px);
  filter: blur(3px); }

.modal-effect-16 .modal-content {
  -webkit-transform: translateY(-5%);
  transform: translateY(-5%);
  opacity: 0; }

.modal-show.modal-effect-16 ~ .container,
.modal-effect-16 .modal-content {
  transition: all 0.3s; }

.modal-show.modal-effect-16 .modal-content {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  opacity: 1; }

/* Effect 17:  Slide in from bottom with perspective on container */
.modal-show.modal-effect-17 ~ .container {
  height: 100%;
  overflow: hidden;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s; }

.modal-show.modal-effect-17 ~ .container,
.modal-show.modal-effect-17 ~ .modal-overlay {
  -webkit-transform: rotateX(-2deg);
  transform: rotateX(-2deg);
  -webkit-transform-origin: 50% 0%;
  transform-origin: 50% 0%;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d; }

.modal-effect-17 .modal-content {
  opacity: 0;
  -webkit-transform: translateY(200%);
  transform: translateY(200%); }

.modal-show.modal-effect-17 .modal-content {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
  transition: all 0.3s 0.2s; }

/* Effect 18:  Slide from right with perspective on container */
.modal-show.modal-effect-18 ~ .container {
  height: 100%;
  overflow: hidden; }

.modal-show.modal-effect-18 ~ .modal-overlay {
  background: rgba(143, 27, 15, 0.8);
  transition: all 0.5s; }

.modal-show.modal-effect-18 ~ .container,
.modal-show.modal-effect-18 ~ .modal-overlay {
  -webkit-transform-style: preserve-3d;
  -webkit-transform-origin: 0% 50%;
  -webkit-animation: rotateRightSideFirst 0.5s forwards ease-in;
  -moz-transform-style: preserve-3d;
  -moz-transform-origin: 0% 50%;
  -moz-animation: rotateRightSideFirst 0.5s forwards ease-in;
  transform-style: preserve-3d;
  transform-origin: 0% 50%;
  animation: rotateRightSideFirst 0.5s forwards ease-in; }

@-webkit-keyframes rotateRightSideFirst {
  50% {
    -webkit-transform: translateZ(-50px) rotateY(5deg);
    -webkit-animation-timing-function: ease-out; }
  100% {
    -webkit-transform: translateZ(-200px); } }

@keyframes rotateRightSideFirst {
  50% {
    -webkit-transform: translateZ(-50px) rotateY(5deg);
            transform: translateZ(-50px) rotateY(5deg);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out; }
  100% {
    -webkit-transform: translateZ(-200px);
            transform: translateZ(-200px); } }

.modal-effect-18 .modal-content {
  -webkit-transform: translateX(200%);
  transform: translateX(200%);
  opacity: 0; }

.modal-show.modal-effect-18 .modal-content {
  -webkit-transform: translateX(0);
  transform: translateX(0);
  opacity: 1;
  transition: all 0.5s 0.1s; }

/*------------------------------------------------------------------
[General elements style]
*/
html {
  height: 100%;
  overflow-x: hidden;
  font-size: 13px; }

body {
  padding: 0;
  background-color: #EEEEEE;
  height: 100%;
  line-height: 1.42857143; }
  body.be-alt-bg {
    background-color: #e9e9e9; }

a:hover, a:focus {
  text-decoration: none;
  outline: none; }

p {
  font-size: inherit;
  line-height: 21px; }

canvas {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none; }

/*------------------------------------------------------------------
[Fonts - Roboto]
*/
@font-face {
  font-family: 'Roboto';
  src: url("./../../../vendor/beagle-v1.4.2/BS4/dist/html/assets/lib/roboto/Roboto-Thin.eot");
  src: local("Roboto Thin"), local("Roboto-Thin"), url("./../../../vendor/beagle-v1.4.2/BS4/dist/html/assets/lib/roboto/Roboto-Thin.eot?#iefix") format("embedded-opentype"), url("./../../../vendor/beagle-v1.4.2/BS4/dist/html/assets/lib/roboto/Roboto-Thin.woff2") format("woff2"), url("./../../../vendor/beagle-v1.4.2/BS4/dist/html/assets/lib/roboto/Roboto-Thin.woff") format("woff"), url("./../../../vendor/beagle-v1.4.2/BS4/dist/html/assets/lib/roboto/Roboto-Thin.ttf") format("truetype"), url("./../../../vendor/beagle-v1.4.2/BS4/dist/html/assets/lib/roboto/Roboto-Thin.svg#Roboto") format("svg");
  font-weight: 100;
  font-style: normal; }

@font-face {
  font-family: "Roboto-Thin";
  src: url("./../../../vendor/beagle-v1.4.2/BS4/dist/html/assets/lib/roboto/Roboto-Thin.eot");
  src: local("Roboto Thin"), local("Roboto-Thin"), url("./../../../vendor/beagle-v1.4.2/BS4/dist/html/assets/lib/roboto/Roboto-Thin.eot?#iefix") format("embedded-opentype"), url("./../../../vendor/beagle-v1.4.2/BS4/dist/html/assets/lib/roboto/Roboto-Thin.woff2") format("woff2"), url("./../../../vendor/beagle-v1.4.2/BS4/dist/html/assets/lib/roboto/Roboto-Thin.woff") format("woff"), url("./../../../vendor/beagle-v1.4.2/BS4/dist/html/assets/lib/roboto/Roboto-Thin.ttf") format("truetype"), url("./../../../vendor/beagle-v1.4.2/BS4/dist/html/assets/lib/roboto/Roboto-Thin.svg#Roboto") format("svg"); }

@font-face {
  font-family: 'Roboto';
  src: url("./../../../vendor/beagle-v1.4.2/BS4/dist/html/assets/lib/roboto/Roboto-ThinItalic.eot");
  src: local("Roboto ThinItalic"), local("Roboto-ThinItalic"), url("./../../../vendor/beagle-v1.4.2/BS4/dist/html/assets/lib/roboto/Roboto-ThinItalic.eot?#iefix") format("embedded-opentype"), url("./../../../vendor/beagle-v1.4.2/BS4/dist/html/assets/lib/roboto/Roboto-ThinItalic.woff2") format("woff2"), url("./../../../vendor/beagle-v1.4.2/BS4/dist/html/assets/lib/roboto/Roboto-ThinItalic.woff") format("woff"), url("./../../../vendor/beagle-v1.4.2/BS4/dist/html/assets/lib/roboto/Roboto-ThinItalic.ttf") format("truetype"), url("./../../../vendor/beagle-v1.4.2/BS4/dist/html/assets/lib/roboto/Roboto-ThinItalic.svg#Roboto") format("svg");
  font-weight: 100;
  font-style: italic; }

@font-face {
  font-family: "Roboto-ThinItalic";
  src: url("./../../../vendor/beagle-v1.4.2/BS4/dist/html/assets/lib/roboto/Roboto-ThinItalic.eot");
  src: local("Roboto ThinItalic"), local("Roboto-ThinItalic"), url("./../../../vendor/beagle-v1.4.2/BS4/dist/html/assets/lib/roboto/Roboto-ThinItalic.eot?#iefix") format("embedded-opentype"), url("./../../../vendor/beagle-v1.4.2/BS4/dist/html/assets/lib/roboto/Roboto-ThinItalic.woff2") format("woff2"), url("./../../../vendor/beagle-v1.4.2/BS4/dist/html/assets/lib/roboto/Roboto-ThinItalic.woff") format("woff"), url("./../../../vendor/beagle-v1.4.2/BS4/dist/html/assets/lib/roboto/Roboto-ThinItalic.ttf") format("truetype"), url("./../../../vendor/beagle-v1.4.2/BS4/dist/html/assets/lib/roboto/Roboto-ThinItalic.svg#Roboto") format("svg"); }

@font-face {
  font-family: 'Roboto';
  src: url("./../../../vendor/beagle-v1.4.2/BS4/dist/html/assets/lib/roboto/Roboto-Light.eot");
  src: local("Roboto Light"), local("Roboto-Light"), url("./../../../vendor/beagle-v1.4.2/BS4/dist/html/assets/lib/roboto/Roboto-Light.eot?#iefix") format("embedded-opentype"), url("./../../../vendor/beagle-v1.4.2/BS4/dist/html/assets/lib/roboto/Roboto-Light.woff2") format("woff2"), url("./../../../vendor/beagle-v1.4.2/BS4/dist/html/assets/lib/roboto/Roboto-Light.woff") format("woff"), url("./../../../vendor/beagle-v1.4.2/BS4/dist/html/assets/lib/roboto/Roboto-Light.ttf") format("truetype"), url("./../../../vendor/beagle-v1.4.2/BS4/dist/html/assets/lib/roboto/Roboto-Light.svg#Roboto") format("svg");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: "Roboto-Light";
  src: url("./../../../vendor/beagle-v1.4.2/BS4/dist/html/assets/lib/roboto/Roboto-Light.eot");
  src: local("Roboto Light"), local("Roboto-Light"), url("./../../../vendor/beagle-v1.4.2/BS4/dist/html/assets/lib/roboto/Roboto-Light.eot?#iefix") format("embedded-opentype"), url("./../../../vendor/beagle-v1.4.2/BS4/dist/html/assets/lib/roboto/Roboto-Light.woff2") format("woff2"), url("./../../../vendor/beagle-v1.4.2/BS4/dist/html/assets/lib/roboto/Roboto-Light.woff") format("woff"), url("./../../../vendor/beagle-v1.4.2/BS4/dist/html/assets/lib/roboto/Roboto-Light.ttf") format("truetype"), url("./../../../vendor/beagle-v1.4.2/BS4/dist/html/assets/lib/roboto/Roboto-Light.svg#Roboto") format("svg"); }

@font-face {
  font-family: 'Roboto';
  src: url("./../../../vendor/beagle-v1.4.2/BS4/dist/html/assets/lib/roboto/Roboto-LightItalic.eot");
  src: local("Roboto LightItalic"), local("Roboto-LightItalic"), url("./../../../vendor/beagle-v1.4.2/BS4/dist/html/assets/lib/roboto/Roboto-LightItalic.eot?#iefix") format("embedded-opentype"), url("./../../../vendor/beagle-v1.4.2/BS4/dist/html/assets/lib/roboto/Roboto-LightItalic.woff2") format("woff2"), url("./../../../vendor/beagle-v1.4.2/BS4/dist/html/assets/lib/roboto/Roboto-LightItalic.woff") format("woff"), url("./../../../vendor/beagle-v1.4.2/BS4/dist/html/assets/lib/roboto/Roboto-LightItalic.ttf") format("truetype"), url("./../../../vendor/beagle-v1.4.2/BS4/dist/html/assets/lib/roboto/Roboto-LightItalic.svg#Roboto") format("svg");
  font-weight: 300;
  font-style: italic; }

@font-face {
  font-family: "Roboto-LightItalic";
  src: url("./../../../vendor/beagle-v1.4.2/BS4/dist/html/assets/lib/roboto/Roboto-LightItalic.eot");
  src: local("Roboto LightItalic"), local("Roboto-LightItalic"), url("./../../../vendor/beagle-v1.4.2/BS4/dist/html/assets/lib/roboto/Roboto-LightItalic.eot?#iefix") format("embedded-opentype"), url("./../../../vendor/beagle-v1.4.2/BS4/dist/html/assets/lib/roboto/Roboto-LightItalic.woff2") format("woff2"), url("./../../../vendor/beagle-v1.4.2/BS4/dist/html/assets/lib/roboto/Roboto-LightItalic.woff") format("woff"), url("./../../../vendor/beagle-v1.4.2/BS4/dist/html/assets/lib/roboto/Roboto-LightItalic.ttf") format("truetype"), url("./../../../vendor/beagle-v1.4.2/BS4/dist/html/assets/lib/roboto/Roboto-LightItalic.svg#Roboto") format("svg"); }

@font-face {
  font-family: 'Roboto';
  src: url("./../../../vendor/beagle-v1.4.2/BS4/dist/html/assets/lib/roboto/Roboto-Regular.eot");
  src: local("Roboto Regular"), local("Roboto-Regular"), url("./../../../vendor/beagle-v1.4.2/BS4/dist/html/assets/lib/roboto/Roboto-Regular.eot?#iefix") format("embedded-opentype"), url("./../../../vendor/beagle-v1.4.2/BS4/dist/html/assets/lib/roboto/Roboto-Regular.woff2") format("woff2"), url("./../../../vendor/beagle-v1.4.2/BS4/dist/html/assets/lib/roboto/Roboto-Regular.woff") format("woff"), url("./../../../vendor/beagle-v1.4.2/BS4/dist/html/assets/lib/roboto/Roboto-Regular.ttf") format("truetype"), url("./../../../vendor/beagle-v1.4.2/BS4/dist/html/assets/lib/roboto/Roboto-Regular.svg#Roboto") format("svg");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: "Roboto-Regular";
  src: url("./../../../vendor/beagle-v1.4.2/BS4/dist/html/assets/lib/roboto/Roboto-Regular.eot");
  src: local("Roboto Regular"), local("Roboto-Regular"), url("./../../../vendor/beagle-v1.4.2/BS4/dist/html/assets/lib/roboto/Roboto-Regular.eot?#iefix") format("embedded-opentype"), url("./../../../vendor/beagle-v1.4.2/BS4/dist/html/assets/lib/roboto/Roboto-Regular.woff2") format("woff2"), url("./../../../vendor/beagle-v1.4.2/BS4/dist/html/assets/lib/roboto/Roboto-Regular.woff") format("woff"), url("./../../../vendor/beagle-v1.4.2/BS4/dist/html/assets/lib/roboto/Roboto-Regular.ttf") format("truetype"), url("./../../../vendor/beagle-v1.4.2/BS4/dist/html/assets/lib/roboto/Roboto-Regular.svg#Roboto") format("svg"); }

@font-face {
  font-family: 'Roboto';
  src: url("./../../../vendor/beagle-v1.4.2/BS4/dist/html/assets/lib/roboto/Roboto-RegularItalic.eot");
  src: local("Roboto RegularItalic"), local("Roboto-RegularItalic"), url("./../../../vendor/beagle-v1.4.2/BS4/dist/html/assets/lib/roboto/Roboto-RegularItalic.eot?#iefix") format("embedded-opentype"), url("./../../../vendor/beagle-v1.4.2/BS4/dist/html/assets/lib/roboto/Roboto-RegularItalic.woff2") format("woff2"), url("./../../../vendor/beagle-v1.4.2/BS4/dist/html/assets/lib/roboto/Roboto-RegularItalic.woff") format("woff"), url("./../../../vendor/beagle-v1.4.2/BS4/dist/html/assets/lib/roboto/Roboto-RegularItalic.ttf") format("truetype"), url("./../../../vendor/beagle-v1.4.2/BS4/dist/html/assets/lib/roboto/Roboto-RegularItalic.svg#Roboto") format("svg");
  font-weight: 400;
  font-style: italic; }

@font-face {
  font-family: "Roboto-RegularItalic";
  src: url("./../../../vendor/beagle-v1.4.2/BS4/dist/html/assets/lib/roboto/Roboto-RegularItalic.eot");
  src: local("Roboto RegularItalic"), local("Roboto-RegularItalic"), url("./../../../vendor/beagle-v1.4.2/BS4/dist/html/assets/lib/roboto/Roboto-RegularItalic.eot?#iefix") format("embedded-opentype"), url("./../../../vendor/beagle-v1.4.2/BS4/dist/html/assets/lib/roboto/Roboto-RegularItalic.woff2") format("woff2"), url("./../../../vendor/beagle-v1.4.2/BS4/dist/html/assets/lib/roboto/Roboto-RegularItalic.woff") format("woff"), url("./../../../vendor/beagle-v1.4.2/BS4/dist/html/assets/lib/roboto/Roboto-RegularItalic.ttf") format("truetype"), url("./../../../vendor/beagle-v1.4.2/BS4/dist/html/assets/lib/roboto/Roboto-RegularItalic.svg#Roboto") format("svg"); }

@font-face {
  font-family: 'Roboto';
  src: url("./../../../vendor/beagle-v1.4.2/BS4/dist/html/assets/lib/roboto/Roboto-Medium.eot");
  src: local("Roboto Medium"), local("Roboto-Medium"), url("./../../../vendor/beagle-v1.4.2/BS4/dist/html/assets/lib/roboto/Roboto-Medium.eot?#iefix") format("embedded-opentype"), url("./../../../vendor/beagle-v1.4.2/BS4/dist/html/assets/lib/roboto/Roboto-Medium.woff2") format("woff2"), url("./../../../vendor/beagle-v1.4.2/BS4/dist/html/assets/lib/roboto/Roboto-Medium.woff") format("woff"), url("./../../../vendor/beagle-v1.4.2/BS4/dist/html/assets/lib/roboto/Roboto-Medium.ttf") format("truetype"), url("./../../../vendor/beagle-v1.4.2/BS4/dist/html/assets/lib/roboto/Roboto-Medium.svg#Roboto") format("svg");
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: "Roboto-Medium";
  src: url("./../../../vendor/beagle-v1.4.2/BS4/dist/html/assets/lib/roboto/Roboto-Medium.eot");
  src: local("Roboto Medium"), local("Roboto-Medium"), url("./../../../vendor/beagle-v1.4.2/BS4/dist/html/assets/lib/roboto/Roboto-Medium.eot?#iefix") format("embedded-opentype"), url("./../../../vendor/beagle-v1.4.2/BS4/dist/html/assets/lib/roboto/Roboto-Medium.woff2") format("woff2"), url("./../../../vendor/beagle-v1.4.2/BS4/dist/html/assets/lib/roboto/Roboto-Medium.woff") format("woff"), url("./../../../vendor/beagle-v1.4.2/BS4/dist/html/assets/lib/roboto/Roboto-Medium.ttf") format("truetype"), url("./../../../vendor/beagle-v1.4.2/BS4/dist/html/assets/lib/roboto/Roboto-Medium.svg#Roboto") format("svg"); }

@font-face {
  font-family: 'Roboto';
  src: url("./../../../vendor/beagle-v1.4.2/BS4/dist/html/assets/lib/roboto/Roboto-MediumItalic.eot");
  src: local("Roboto MediumItalic"), local("Roboto-MediumItalic"), url("./../../../vendor/beagle-v1.4.2/BS4/dist/html/assets/lib/roboto/Roboto-MediumItalic.eot?#iefix") format("embedded-opentype"), url("./../../../vendor/beagle-v1.4.2/BS4/dist/html/assets/lib/roboto/Roboto-MediumItalic.woff2") format("woff2"), url("./../../../vendor/beagle-v1.4.2/BS4/dist/html/assets/lib/roboto/Roboto-MediumItalic.woff") format("woff"), url("./../../../vendor/beagle-v1.4.2/BS4/dist/html/assets/lib/roboto/Roboto-MediumItalic.ttf") format("truetype"), url("./../../../vendor/beagle-v1.4.2/BS4/dist/html/assets/lib/roboto/Roboto-MediumItalic.svg#Roboto") format("svg");
  font-weight: 500;
  font-style: italic; }

@font-face {
  font-family: "Roboto-MediumItalic";
  src: url("./../../../vendor/beagle-v1.4.2/BS4/dist/html/assets/lib/roboto/Roboto-MediumItalic.eot");
  src: local("Roboto MediumItalic"), local("Roboto-MediumItalic"), url("./../../../vendor/beagle-v1.4.2/BS4/dist/html/assets/lib/roboto/Roboto-MediumItalic.eot?#iefix") format("embedded-opentype"), url("./../../../vendor/beagle-v1.4.2/BS4/dist/html/assets/lib/roboto/Roboto-MediumItalic.woff2") format("woff2"), url("./../../../vendor/beagle-v1.4.2/BS4/dist/html/assets/lib/roboto/Roboto-MediumItalic.woff") format("woff"), url("./../../../vendor/beagle-v1.4.2/BS4/dist/html/assets/lib/roboto/Roboto-MediumItalic.ttf") format("truetype"), url("./../../../vendor/beagle-v1.4.2/BS4/dist/html/assets/lib/roboto/Roboto-MediumItalic.svg#Roboto") format("svg"); }

@font-face {
  font-family: 'Roboto';
  src: url("./../../../vendor/beagle-v1.4.2/BS4/dist/html/assets/lib/roboto/Roboto-Bold.eot");
  src: local("Roboto Bold"), local("Roboto-Bold"), url("./../../../vendor/beagle-v1.4.2/BS4/dist/html/assets/lib/roboto/Roboto-Bold.eot?#iefix") format("embedded-opentype"), url("./../../../vendor/beagle-v1.4.2/BS4/dist/html/assets/lib/roboto/Roboto-Bold.woff2") format("woff2"), url("./../../../vendor/beagle-v1.4.2/BS4/dist/html/assets/lib/roboto/Roboto-Bold.woff") format("woff"), url("./../../../vendor/beagle-v1.4.2/BS4/dist/html/assets/lib/roboto/Roboto-Bold.ttf") format("truetype"), url("./../../../vendor/beagle-v1.4.2/BS4/dist/html/assets/lib/roboto/Roboto-Bold.svg#Roboto") format("svg");
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: "Roboto-Bold";
  src: url("./../../../vendor/beagle-v1.4.2/BS4/dist/html/assets/lib/roboto/Roboto-Bold.eot");
  src: local("Roboto Bold"), local("Roboto-Bold"), url("./../../../vendor/beagle-v1.4.2/BS4/dist/html/assets/lib/roboto/Roboto-Bold.eot?#iefix") format("embedded-opentype"), url("./../../../vendor/beagle-v1.4.2/BS4/dist/html/assets/lib/roboto/Roboto-Bold.woff2") format("woff2"), url("./../../../vendor/beagle-v1.4.2/BS4/dist/html/assets/lib/roboto/Roboto-Bold.woff") format("woff"), url("./../../../vendor/beagle-v1.4.2/BS4/dist/html/assets/lib/roboto/Roboto-Bold.ttf") format("truetype"), url("./../../../vendor/beagle-v1.4.2/BS4/dist/html/assets/lib/roboto/Roboto-Bold.svg#Roboto") format("svg"); }

@font-face {
  font-family: 'Roboto';
  src: url("./../../../vendor/beagle-v1.4.2/BS4/dist/html/assets/lib/roboto/Roboto-BoldItalic.eot");
  src: local("Roboto BoldItalic"), local("Roboto-BoldItalic"), url("./../../../vendor/beagle-v1.4.2/BS4/dist/html/assets/lib/roboto/Roboto-BoldItalic.eot?#iefix") format("embedded-opentype"), url("./../../../vendor/beagle-v1.4.2/BS4/dist/html/assets/lib/roboto/Roboto-BoldItalic.woff2") format("woff2"), url("./../../../vendor/beagle-v1.4.2/BS4/dist/html/assets/lib/roboto/Roboto-BoldItalic.woff") format("woff"), url("./../../../vendor/beagle-v1.4.2/BS4/dist/html/assets/lib/roboto/Roboto-BoldItalic.ttf") format("truetype"), url("./../../../vendor/beagle-v1.4.2/BS4/dist/html/assets/lib/roboto/Roboto-BoldItalic.svg#Roboto") format("svg");
  font-weight: 700;
  font-style: italic; }

@font-face {
  font-family: "Roboto-BoldItalic";
  src: url("./../../../vendor/beagle-v1.4.2/BS4/dist/html/assets/lib/roboto/Roboto-BoldItalic.eot");
  src: local("Roboto BoldItalic"), local("Roboto-BoldItalic"), url("./../../../vendor/beagle-v1.4.2/BS4/dist/html/assets/lib/roboto/Roboto-BoldItalic.eot?#iefix") format("embedded-opentype"), url("./../../../vendor/beagle-v1.4.2/BS4/dist/html/assets/lib/roboto/Roboto-BoldItalic.woff2") format("woff2"), url("./../../../vendor/beagle-v1.4.2/BS4/dist/html/assets/lib/roboto/Roboto-BoldItalic.woff") format("woff"), url("./../../../vendor/beagle-v1.4.2/BS4/dist/html/assets/lib/roboto/Roboto-BoldItalic.ttf") format("truetype"), url("./../../../vendor/beagle-v1.4.2/BS4/dist/html/assets/lib/roboto/Roboto-BoldItalic.svg#Roboto") format("svg"); }

@font-face {
  font-family: 'Roboto';
  src: url("./../../../vendor/beagle-v1.4.2/BS4/dist/html/assets/lib/roboto/Roboto-Black.eot");
  src: local("Roboto Black"), local("Roboto-Black"), url("./../../../vendor/beagle-v1.4.2/BS4/dist/html/assets/lib/roboto/Roboto-Black.eot?#iefix") format("embedded-opentype"), url("./../../../vendor/beagle-v1.4.2/BS4/dist/html/assets/lib/roboto/Roboto-Black.woff2") format("woff2"), url("./../../../vendor/beagle-v1.4.2/BS4/dist/html/assets/lib/roboto/Roboto-Black.woff") format("woff"), url("./../../../vendor/beagle-v1.4.2/BS4/dist/html/assets/lib/roboto/Roboto-Black.ttf") format("truetype"), url("./../../../vendor/beagle-v1.4.2/BS4/dist/html/assets/lib/roboto/Roboto-Black.svg#Roboto") format("svg");
  font-weight: 900;
  font-style: normal; }

@font-face {
  font-family: "Roboto-Black";
  src: url("./../../../vendor/beagle-v1.4.2/BS4/dist/html/assets/lib/roboto/Roboto-Black.eot");
  src: local("Roboto Black"), local("Roboto-Black"), url("./../../../vendor/beagle-v1.4.2/BS4/dist/html/assets/lib/roboto/Roboto-Black.eot?#iefix") format("embedded-opentype"), url("./../../../vendor/beagle-v1.4.2/BS4/dist/html/assets/lib/roboto/Roboto-Black.woff2") format("woff2"), url("./../../../vendor/beagle-v1.4.2/BS4/dist/html/assets/lib/roboto/Roboto-Black.woff") format("woff"), url("./../../../vendor/beagle-v1.4.2/BS4/dist/html/assets/lib/roboto/Roboto-Black.ttf") format("truetype"), url("./../../../vendor/beagle-v1.4.2/BS4/dist/html/assets/lib/roboto/Roboto-Black.svg#Roboto") format("svg"); }

@font-face {
  font-family: 'Roboto';
  src: url("./../../../vendor/beagle-v1.4.2/BS4/dist/html/assets/lib/roboto/Roboto-BlackItalic.eot");
  src: local("Roboto BlackItalic"), local("Roboto-BlackItalic"), url("./../../../vendor/beagle-v1.4.2/BS4/dist/html/assets/lib/roboto/Roboto-BlackItalic.eot?#iefix") format("embedded-opentype"), url("./../../../vendor/beagle-v1.4.2/BS4/dist/html/assets/lib/roboto/Roboto-BlackItalic.woff2") format("woff2"), url("./../../../vendor/beagle-v1.4.2/BS4/dist/html/assets/lib/roboto/Roboto-BlackItalic.woff") format("woff"), url("./../../../vendor/beagle-v1.4.2/BS4/dist/html/assets/lib/roboto/Roboto-BlackItalic.ttf") format("truetype"), url("./../../../vendor/beagle-v1.4.2/BS4/dist/html/assets/lib/roboto/Roboto-BlackItalic.svg#Roboto") format("svg");
  font-weight: 900;
  font-style: italic; }

@font-face {
  font-family: "Roboto-BlackItalic";
  src: url("./../../../vendor/beagle-v1.4.2/BS4/dist/html/assets/lib/roboto/Roboto-BlackItalic.eot");
  src: local("Roboto BlackItalic"), local("Roboto-BlackItalic"), url("./../../../vendor/beagle-v1.4.2/BS4/dist/html/assets/lib/roboto/Roboto-BlackItalic.eot?#iefix") format("embedded-opentype"), url("./../../../vendor/beagle-v1.4.2/BS4/dist/html/assets/lib/roboto/Roboto-BlackItalic.woff2") format("woff2"), url("./../../../vendor/beagle-v1.4.2/BS4/dist/html/assets/lib/roboto/Roboto-BlackItalic.woff") format("woff"), url("./../../../vendor/beagle-v1.4.2/BS4/dist/html/assets/lib/roboto/Roboto-BlackItalic.ttf") format("truetype"), url("./../../../vendor/beagle-v1.4.2/BS4/dist/html/assets/lib/roboto/Roboto-BlackItalic.svg#Roboto") format("svg"); }

/*------------------------------------------------------------------
	[Main wrapper]
*/
.be-wrapper {
  min-height: 100%;
  padding-top: 61px; }
  @media (max-width: 575.98px) {
    .be-wrapper {
      overflow-x: hidden; } }
  @media print {
    .be-wrapper {
      padding-top: 0; } }
  @media (min-width: 768px) {
    .be-wrapper.be-boxed-layout {
      max-width: 1200px;
      position: relative;
      margin: 0 auto;
      overflow: hidden;
      background-color: #f1f1f1;
      display: -ms-flexbox;
      display: flex;
      box-shadow: 0px 0px 7px 2px rgba(0, 0, 0, 0.05); } }

/*------------------------------------------------------------------
	[Top header]
*/
.be-top-header {
  background-color: #FFFFFF;
  border: 0;
  margin-bottom: 0;
  border-bottom: 1px solid #e6e5e5;
  padding: 0; }
  @media (max-width: 767.98px) {
    .be-top-header {
      border-bottom-width: 0;
      position: relative;
      min-height: 0; } }
  .be-top-header .container-fluid {
    padding: 0; }
    @media (max-width: 767.98px) {
      .be-nosidebar-left .be-top-header .container-fluid {
        display: block; }
        .be-nosidebar-left .be-top-header .container-fluid .navbar-collapse {
          -ms-flex-align: baseline;
              align-items: baseline; }
          .be-nosidebar-left .be-top-header .container-fluid .navbar-collapse.collapse {
            display: none !important; }
            .be-nosidebar-left .be-top-header .container-fluid .navbar-collapse.collapse.show {
              display: block !important; } }
  .be-top-header .be-navbar-header {
    display: -ms-flexbox;
    display: flex;
    width: 230px; }
    .be-collapsible-sidebar .be-top-header .be-navbar-header {
      -ms-flex-pack: justify;
          justify-content: space-between; }
    @media (max-width: 991.98px) {
      .be-nosidebar-left .be-top-header .be-navbar-header {
        width: auto; } }
    .be-top-header .be-navbar-header .navbar-brand {
      line-height: 60px;
      height: 60px;
      width: 142px;
      background-image: url("./../../../vendor/beagle-v1.4.2/BS4/dist/html/assets/img/logo.png");
      background-repeat: no-repeat;
      background-position: 20px center;
      padding: 0 20px;
      margin: 0;
      display: block; }
      @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
        .be-top-header .be-navbar-header .navbar-brand {
          background-image: url("./../../../vendor/beagle-v1.4.2/BS4/dist/html/assets/img/logo-xx.png");
          background-size: 102px 27px; } }
      @media (max-width: 767.98px) {
        .be-top-header .be-navbar-header .navbar-brand {
          display: none; } }
      @media (max-width: 991.98px) {
        .be-top-header .be-navbar-header .navbar-brand {
          margin-right: 10px; } }
      .be-nosidebar-left .be-top-header .be-navbar-header .navbar-brand {
        width: 142px; }
    .be-top-header .be-navbar-header .be-toggle-left-sidebar {
      display: inline-block;
      text-align: center;
      line-height: 60px;
      float: right;
      padding: 0 15px; }
      .be-top-header .be-navbar-header .be-toggle-left-sidebar .icon {
        font-size: 1.615rem;
        color: #5a5959;
        line-height: 25px;
        vertical-align: middle; }
    @media (max-width: 767.98px) {
      .be-top-header .be-navbar-header {
        display: none; } }
  .be-top-header .page-title {
    font-size: 1.462rem;
    line-height: 60px;
    float: left;
    -ms-flex: 1 1 auto;
        flex: 1 1 auto;
    padding: 0 20px; }
    .be-nosidebar-left .be-top-header .page-title {
      padding-left: 10px; }
    @media (max-width: 767.98px) {
      .be-top-header .page-title {
        display: none; } }
  .be-top-header .navbar-nav > li > a.nav-link {
    line-height: 60px;
    color: #5a5959;
    font-size: 14px;
    padding: 0 18px;
    outline: none; }
    .be-top-header .navbar-nav > li > a.nav-link:hover, .be-top-header .navbar-nav > li > a.nav-link:focus {
      color: #5a5959; }
    @media (max-width: 991.98px) {
      .be-top-header .navbar-nav > li > a.nav-link {
        padding: 0 24px; } }
  .be-top-header .navbar-nav > li.show > a {
    color: #4285f4; }
    .be-top-header .navbar-nav > li.show > a:focus, .be-top-header .navbar-nav > li.show > a:active, .be-top-header .navbar-nav > li.show > a:hover {
      color: #4285f4; }
  .be-top-header .navbar-nav > li.dropdown > a .mdi-caret-down {
    font-size: 1.769rem;
    vertical-align: middle;
    margin-left: 3px; }
  .be-top-header .navbar-nav > li.dropdown .dropdown-menu {
    border-radius: 3px;
    margin-top: 12px;
    line-height: 20px; }
    .be-top-header .navbar-nav > li.dropdown .dropdown-menu > a {
      color: #504e4e;
      padding: 10px 20px;
      min-width: 175px;
      line-height: 18px; }
      .be-top-header .navbar-nav > li.dropdown .dropdown-menu > a:hover {
        background-color: #4285f4;
        color: #FFFFFF; }
      .be-top-header .navbar-nav > li.dropdown .dropdown-menu > a:focus, .be-top-header .navbar-nav > li.dropdown .dropdown-menu > a:active {
        background-color: transparent;
        color: #504e4e; }
    .be-top-header .navbar-nav > li.dropdown .dropdown-menu:after, .be-top-header .navbar-nav > li.dropdown .dropdown-menu:before {
      border-bottom: 8px solid white;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      top: -8px;
      content: "";
      display: inline-block;
      left: 42px;
      margin-left: -6px;
      position: absolute; }
    .be-top-header .navbar-nav > li.dropdown .dropdown-menu:before {
      border-bottom-width: 9px;
      border-left-width: 9px;
      border-right-width: 9px;
      border-bottom-color: #e3e3e3;
      margin-left: -7px;
      top: -9px; }
  .be-top-header .navbar-collapse {
    border-width: 0; }
    @media (max-width: 767.98px) {
      .be-top-header .navbar-collapse {
        border-top: 1px solid #f5f5f5;
        max-height: none; } }
    @media (max-width: 767.98px) {
      .be-top-header .navbar-collapse .navbar-nav {
        background-color: #fafafa;
        margin: 0;
        padding: 15px 0; }
        .be-nosidebar-left .be-top-header .navbar-collapse .navbar-nav {
          width: 100%;
          -ms-flex-direction: column;
              flex-direction: column; }
        .be-top-header .navbar-collapse .navbar-nav > li > a {
          display: block;
          padding: 0 20px;
          color: #646464;
          font-size: 1.077rem;
          line-height: 38px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap; }
          .be-top-header .navbar-collapse .navbar-nav > li > a .icon {
            line-height: 18px;
            font-size: 19px;
            min-width: 19px;
            margin-right: 9px;
            text-align: center;
            vertical-align: middle; }
        .be-top-header .navbar-collapse .navbar-nav > li.dropdown .mdi-caret-down {
          float: right;
          line-height: 38px;
          color: #c5c5c5; }
        .be-top-header .navbar-collapse .navbar-nav > li.dropdown .dropdown-menu {
          background-color: #eeeeee;
          padding: 10px 0;
          border-top: 1px solid #e6e6e6;
          border-right: 0;
          border-bottom: 1px solid #e6e6e6;
          border-left: 0;
          border-radius: 0; }
          .be-nosidebar-left .be-top-header .navbar-collapse .navbar-nav > li.dropdown .dropdown-menu {
            position: static;
            float: none;
            width: auto;
            box-shadow: none; }
          .be-top-header .navbar-collapse .navbar-nav > li.dropdown .dropdown-menu:before, .be-top-header .navbar-collapse .navbar-nav > li.dropdown .dropdown-menu:after {
            display: none; }
          .be-top-header .navbar-collapse .navbar-nav > li.dropdown .dropdown-menu > a {
            color: #646464;
            padding: 8px 15px 8px 49px;
            padding-left: 23px; } }
  .be-top-header .search-container {
    position: relative;
    -ms-flex: 1 1 auto;
        flex: 1 1 auto;
    display: inline-block;
    height: 60px;
    padding: 10px 20px; }
    .be-top-header .search-container .input-group {
      max-width: 800px; }
    @media (max-width: 767.98px) {
      .be-top-header .search-container {
        display: none; } }
    .be-top-header .search-container .btn {
      height: 37px;
      font-size: 1rem;
      padding: 4px 12px;
      border-radius: 0; }
  .be-top-header .search-input {
    border-width: 1px;
    border-right: 0;
    color: #404040; }
    @media (max-width: 767.98px) {
      .be-top-header .search-input {
        background-color: #FFFFFF;
        color: #404040; } }
    .be-top-header .search-input:focus {
      background-color: #FFFFFF;
      color: #807f7f; }
      .be-top-header .search-input:focus::-webkit-input-placeholder {
        color: #737373; }
      .be-top-header .search-input:focus:-ms-input-placeholder {
        color: #737373; }
      .be-top-header .search-input:focus::placeholder {
        color: #737373; }
  .be-top-header .be-right-navbar {
    padding-right: 20px;
    width: calc(100% - 230px); }
    .be-top-header .be-right-navbar-flex {
      display: -ms-flexbox;
      display: flex; }
    .be-top-header .be-right-navbar .navbar-nav {
      background-color: transparent; }
      @media (max-width: 767.98px) {
        .be-top-header .be-right-navbar .navbar-nav > li > a.nav-link {
          color: #FFFFFF; }
          .be-top-header .be-right-navbar .navbar-nav > li > a.nav-link:focus, .be-top-header .be-right-navbar .navbar-nav > li > a.nav-link:active {
            color: #FFFFFF; }
        .be-top-header .be-right-navbar .navbar-nav > li.show > a.nav-link {
          color: #FFFFFF; }
          .be-top-header .be-right-navbar .navbar-nav > li.show > a.nav-link:focus, .be-top-header .be-right-navbar .navbar-nav > li.show > a.nav-link:active, .be-top-header .be-right-navbar .navbar-nav > li.show > a.nav-link:hover {
            color: #FFFFFF; } }
    @media (max-width: 767.98px) {
      .be-top-header .be-right-navbar {
        padding-right: 0;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-pack: justify;
            justify-content: space-between;
        border-bottom: 1px solid #4285f4;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        background-color: #4285f4;
        z-index: 1030; } }
    @media (max-width: 767.98px) {
      .be-offcanvas-menu .be-top-header .be-right-navbar {
        padding-right: 5px;
        position: relative; } }
    .be-nosidebar-left .be-top-header .be-right-navbar {
      width: auto;
      -ms-flex-order: 2;
          order: 2; }
      @media (max-width: 767.98px) {
        .be-nosidebar-left .be-top-header .be-right-navbar {
          width: 100%; } }
  @media (max-width: 767.98px) {
    .be-top-header .be-toggle-right-sidebar {
      margin-right: 0;
      padding-left: 5px;
      padding-right: 9px; } }
  .be-top-header .be-toggle-top-header-menu {
    display: none;
    background-color: #FFFFFF;
    padding: 16px 10px;
    color: #404040;
    font-weight: 300;
    font-size: 1.077rem;
    border-bottom: 1px solid #f5f5f5; }
    .be-top-header .be-toggle-top-header-menu:before {
      content: '\f2f8';
      float: right;
      font-family: "Material Icons";
      font-size: 2.077rem;
      line-height: 22px;
      color: #c5c5c5; }
    .be-top-header .be-toggle-top-header-menu.collapsed {
      border-bottom: 1px solid #e3e3e3;
      transition: border-bottom-color 0.4s ease; }
      .be-top-header .be-toggle-top-header-menu.collapsed:before {
        content: '\f2f2'; }
    @media (max-width: 767.98px) {
      .be-top-header .be-toggle-top-header-menu {
        display: block; } }
  @media (max-width: 767.98px) {
    .be-offcanvas-menu .be-top-header {
      position: fixed; } }
  @media (max-width: 767.98px) {
    .be-offcanvas-menu .be-top-header .container-fluid {
      display: -ms-flexbox;
      display: flex; } }
  .be-offcanvas-menu .be-top-header .be-navbar-header {
    width: 230px; }
    @media (max-width: 767.98px) {
      .be-offcanvas-menu .be-top-header .be-navbar-header {
        display: block;
        width: auto;
        background-color: #4285f4; } }
    .be-offcanvas-menu .be-top-header .be-navbar-header .be-toggle-left-sidebar {
      padding-left: 25px; }
      @media (max-width: 767.98px) {
        .be-offcanvas-menu .be-top-header .be-navbar-header .be-toggle-left-sidebar {
          padding-left: 15px;
          padding-right: 13px; } }
      .be-offcanvas-menu .be-top-header .be-navbar-header .be-toggle-left-sidebar .icon {
        color: #737373;
        font-size: 1.846rem; }
        @media (max-width: 767.98px) {
          .be-offcanvas-menu .be-top-header .be-navbar-header .be-toggle-left-sidebar .icon {
            color: #FFFFFF;
            overflow: hidden; } }
  .be-offcanvas-menu .be-top-header .navbar-brand {
    width: 142px; }
  .be-offcanvas-menu .be-top-header .be-toggle-left-sidebar {
    display: inline-block;
    text-align: center;
    line-height: 60px;
    float: left; }
    @media (max-width: 767.98px) {
      .be-offcanvas-menu .be-top-header .be-toggle-left-sidebar {
        line-height: 62px;
        margin-top: -1px; } }
    .be-offcanvas-menu .be-top-header .be-toggle-left-sidebar .icon {
      font-size: 1.615rem;
      color: #5a5959;
      line-height: 25px;
      vertical-align: middle; }
  .be-color-header .be-top-header {
    background-color: #4285f4;
    border-bottom-color: #4285f4; }
    .be-color-header .be-top-header .be-navbar-header .navbar-brand {
      background-image: url("./../../../vendor/beagle-v1.4.2/BS4/dist/html/assets/img/logo-white.png"); }
      @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
        .be-color-header .be-top-header .be-navbar-header .navbar-brand {
          background-image: url("./../../../vendor/beagle-v1.4.2/BS4/dist/html/assets/img/logo-white-xx.png"); } }
    .be-color-header .be-top-header .page-title {
      color: #FFFFFF; }
    .be-color-header .be-top-header .be-right-navbar .navbar-nav > li > a {
      color: #FFFFFF; }
    .be-color-header .be-top-header .be-right-navbar .be-icons-nav > li.dropdown > a .icon {
      color: #FFFFFF; }
    .be-color-header .be-top-header .be-right-navbar .be-icons-nav > li.dropdown > a .indicator {
      background-color: #FFFFFF; }
  .be-color-header-success .be-top-header {
    background-color: #34a853;
    border-bottom-color: #34a853; }
    @media (max-width: 767.98px) {
      .be-color-header-success .be-top-header .be-right-navbar {
        background-color: #34a853;
        border-bottom-color: #34a853; } }
  .be-color-header-warning .be-top-header {
    background-color: #fbbc05;
    border-bottom-color: #fbbc05; }
    @media (max-width: 767.98px) {
      .be-color-header-warning .be-top-header .be-right-navbar {
        background-color: #fbbc05;
        border-bottom-color: #fbbc05; } }
  .be-color-header-danger .be-top-header {
    background-color: #ea4335;
    border-bottom-color: #ea4335; }
    @media (max-width: 767.98px) {
      .be-color-header-danger .be-top-header .be-right-navbar {
        background-color: #ea4335;
        border-bottom-color: #ea4335; } }
  @media (min-width: 768px) {
    .be-boxed-layout .be-top-header {
      max-width: 1200px;
      margin: 0 auto;
      z-index: 1032; } }

.be-top-header .be-icons-nav {
  margin-right: 20px; }

@media (min-width: 768px) {
  .be-right-navbar-flex .be-icons-nav {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto; } }

@media (max-width: 991.98px) {
  .be-top-header .be-icons-nav {
    margin-right: 10px; } }

.be-icons-nav > li.dropdown {
  position: static; }
  .be-icons-nav > li.dropdown > a {
    position: relative; }
    .navbar-expand .be-icons-nav > li.dropdown > a {
      padding: 0 10px; }
    .be-icons-nav > li.dropdown > a .icon {
      font-size: 1.846rem;
      vertical-align: middle;
      color: #737373; }
      .be-icons-nav > li.dropdown > a .icon.mdi-comment-more {
        font-size: 1.538rem;
        position: relative;
        top: 1px; }
    .be-icons-nav > li.dropdown > a .indicator {
      background-color: #4285f4;
      border-radius: 50%;
      display: block;
      height: 6px;
      width: 6px;
      position: absolute;
      top: 18px;
      right: 6px; }
    .be-icons-nav > li.dropdown > a:after {
      display: none;
      font-size: 1.231rem;
      margin-left: 6px; }
      @media (max-width: 767.98px) {
        .be-icons-nav > li.dropdown > a:after {
          display: inline-block; } }
  .be-icons-nav > li.dropdown .dropdown-toggle:after {
    content: '';
    margin: 0;
    border: 0; }
  .be-icons-nav > li.dropdown.show > a:after, .be-icons-nav > li.dropdown.show > a:before {
    border-bottom: 8px solid #FFFFFF;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    bottom: -13px;
    content: "";
    display: inline-block;
    left: 50%;
    margin-left: -8px;
    position: absolute;
    z-index: 1001; }
  .be-icons-nav > li.dropdown.show > a:before {
    border-bottom-width: 9px;
    border-left-width: 9px;
    border-right-width: 9px;
    border-bottom-color: #e3e3e3;
    margin-left: -9px;
    bottom: -12px; }
  .be-icons-nav > li.dropdown .dropdown-menu {
    width: 300px;
    border-radius: 3px;
    padding: 0;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.08);
    border: 1px solid #e0e0e0;
    box-sizing: content-box; }
    .be-icons-nav > li.dropdown .dropdown-menu .title {
      font-size: 1rem;
      color: #8a8a8a;
      text-align: center;
      padding: 10px 0 8px;
      border-bottom: 1px solid #ebebeb; }
      .be-icons-nav > li.dropdown .dropdown-menu .title .badge {
        font-size: 0.6962rem;
        line-height: 10px;
        vertical-align: middle;
        margin-left: 4px;
        background-color: #e4e4e4;
        color: #8a8a8a;
        min-width: 17px;
        height: 17px;
        text-align: center;
        padding: 3px 6px; }
        .be-icons-nav > li.dropdown .dropdown-menu .title .badge-new {
          background-color: #4285f4;
          border-color: #4285f4;
          color: #FFFFFF; }
    .be-icons-nav > li.dropdown .dropdown-menu .footer a {
      background-color: #fafafa;
      font-size: 1rem;
      color: #8a8a8a;
      text-align: center;
      padding: 10px 0;
      border-top: 1px solid #ebebeb;
      display: block;
      outline: none; }
      .be-icons-nav > li.dropdown .dropdown-menu .footer a:hover {
        background-color: whitesmoke; }
    .be-top-header .be-icons-nav > li.dropdown .dropdown-menu:after, .be-top-header .be-icons-nav > li.dropdown .dropdown-menu:before {
      display: none; }
  .be-icons-nav > li.dropdown.show > a {
    background-color: transparent; }
    .be-icons-nav > li.dropdown.show > a .icon {
      color: #4285f4; }
      @media (max-width: 767.98px) {
        .be-icons-nav > li.dropdown.show > a .icon {
          color: #FFFFFF; } }

@media (max-width: 767.98px) {
  .be-icons-nav {
    float: right;
    -ms-flex: 0 0 auto;
        flex: 0 0 auto; }
    .be-top-header .be-icons-nav {
      margin: 0 5px 0 0; }
    .be-icons-nav > li.dropdown {
      display: inline-block;
      margin-left: 3px; }
      .navbar-expand .be-icons-nav > li.dropdown > a {
        padding: 0 7px; }
      .be-icons-nav > li.dropdown > a .icon {
        color: #FFFFFF; }
      .be-icons-nav > li.dropdown > a .indicator {
        right: 2px;
        background-color: #FFFFFF; } }

@media (max-width: 575.98px) {
  .be-icons-nav > li.dropdown .dropdown-menu {
    left: 0;
    top: 60px;
    width: 100%;
    box-shadow: none;
    border-width: 0; }
    .be-top-header .be-icons-nav > li.dropdown .dropdown-menu {
      border-radius: 0;
      margin-top: 1px; }
    .be-icons-nav > li.dropdown .dropdown-menu .footer a {
      border-bottom: 1px solid #ebebeb; }
  .be-icons-nav > li.dropdown.show > a:before {
    display: none; }
  .be-icons-nav > li.dropdown.show > a:after {
    bottom: -1px; } }

.be-user-nav {
  margin-right: 0; }
  @media (min-width: 768px) {
    .be-right-navbar-flex .be-user-nav {
      -ms-flex-order: 1;
          order: 1; } }
  .be-user-nav > li.dropdown > a {
    font-size: 0; }
    .navbar-expand .be-user-nav > li.dropdown > a {
      padding-left: 5px;
      padding-right: 5px; }
      @media (max-width: 991.98px) {
        .navbar-expand .be-user-nav > li.dropdown > a {
          padding-left: 10px; } }
    .be-user-nav > li.dropdown > a img {
      max-width: 32px;
      max-height: 32px;
      border-radius: 50%; }
    .be-user-nav > li.dropdown > a .user-name {
      margin-left: 8px;
      display: none;
      font-size: 1.077rem;
      line-height: 20px;
      vertical-align: middle; }
    .be-user-nav > li.dropdown > a:after {
      display: none;
      font-size: 1.231rem;
      margin-left: 6px; }
      @media (max-width: 767.98px) {
        .be-user-nav > li.dropdown > a:after {
          display: inline-block; } }
  .be-user-nav > li.dropdown .dropdown-menu {
    width: 220px;
    padding: 0 0 7px;
    left: -178px; }
    @media (max-width: 991.98px) {
      .be-user-nav > li.dropdown .dropdown-menu {
        left: -173px; } }
    .be-top-header .be-user-nav > li.dropdown .dropdown-menu a:focus, .be-top-header .be-user-nav > li.dropdown .dropdown-menu a:active {
      background-color: transparent;
      color: #504e4e; }
    .be-top-header .be-user-nav > li.dropdown .dropdown-menu a:hover {
      background-color: whitesmoke;
      color: #504e4e; }
    .be-user-nav > li.dropdown .dropdown-menu a .icon {
      vertical-align: middle;
      margin-right: 6px; }
    .be-user-nav > li.dropdown .dropdown-menu .user-info {
      background-color: #4285f4;
      color: #FFFFFF;
      overflow: hidden;
      padding: 20px 15px 14px;
      border-radius: 2px 2px 0 0;
      margin-bottom: 7px; }
      .be-user-nav > li.dropdown .dropdown-menu .user-info .user-name {
        display: block;
        font-size: 1.462rem;
        line-height: 22px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden; }
      .be-user-nav > li.dropdown .dropdown-menu .user-info .user-position {
        display: block;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        padding-left: 13px;
        position: relative; }
        .be-user-nav > li.dropdown .dropdown-menu .user-info .user-position:after {
          content: "";
          position: absolute;
          height: 7px;
          width: 7px;
          left: 0;
          top: 5px;
          border: 1px solid #f0f0f0;
          border-radius: 50%;
          background-color: transparent;
          display: block; }
        .be-user-nav > li.dropdown .dropdown-menu .user-info .user-position.online:after {
          background-color: #6cd187;
          border-color: #6cd187; }
        .be-user-nav > li.dropdown .dropdown-menu .user-info .user-position.away:after {
          background-color: #fcd050;
          border-color: #fcd050; }
        .be-user-nav > li.dropdown .dropdown-menu .user-info .user-position.busy:after {
          background-color: #f39088;
          border-color: #f39088; }
      .be-user-nav > li.dropdown .dropdown-menu .user-info .switch-status {
        float: right;
        margin-top: 5px; }
    .be-top-header .be-user-nav > li.dropdown .dropdown-menu:after {
      left: auto;
      right: 13px;
      border-bottom-color: #4285f4; }
    .be-top-header .be-user-nav > li.dropdown .dropdown-menu:before {
      display: none; }
  @media (max-width: 767.98px) {
    .be-user-nav {
      margin: 0;
      display: inline-block;
      -ms-flex: 0 1 auto;
          flex: 0 1 auto;
      overflow: hidden; }
      .be-user-nav > li.dropdown > a {
        -ms-flex-align: center;
            align-items: center;
        display: -ms-flexbox;
        display: flex;
        height: 60px;
        white-space: nowrap; }
        .be-user-nav > li.dropdown > a:after {
          color: #FFFFFF; }
        .be-user-nav > li.dropdown > a img {
          -ms-flex: 0 0 auto;
              flex: 0 0 auto; }
        .be-user-nav > li.dropdown > a .user-name {
          display: block;
          -ms-flex: 1 1 100%;
              flex: 1 1 100%;
          overflow: hidden;
          text-overflow: ellipsis; }
      .be-user-nav > li.dropdown .dropdown-menu {
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
        background-color: #FFFFFF;
        position: fixed;
        top: 60px;
        left: 0;
        margin-left: 10px;
        margin-right: 10px;
        max-width: 220px; }
        .be-top-header .be-user-nav > li.dropdown .dropdown-menu:after {
          right: auto;
          left: 20px; } }
  @media (max-width: 767.98px) {
    .be-offcanvas-menu .be-user-nav > li.dropdown > a {
      padding-left: 0; }
      .be-offcanvas-menu .be-user-nav > li.dropdown > a img {
        display: none; }
      .be-offcanvas-menu .be-user-nav > li.dropdown > a .user-name {
        margin-left: 0; } }

.be-notifications {
  padding-top: 0; }
  .float-right .be-notifications {
    left: auto;
    right: 25px; }
  .be-notifications .be-scroller {
    position: relative;
    height: 222px; }
    .be-notifications .be-scroller > .content > ul {
      list-style: none;
      padding-left: 0; }
  .be-notifications .notification {
    overflow: hidden;
    border-bottom: 1px solid #ebebeb; }
    .be-notifications .notification > a {
      padding: 15px;
      display: block;
      overflow: hidden; }
      .be-notifications .notification > a .image {
        height: 38px;
        width: 38px;
        border-radius: 50%;
        background-color: #e4e4e4;
        float: left;
        text-align: center;
        overflow: hidden; }
        .be-notifications .notification > a .image img {
          height: 38px;
          width: 38px;
          font-size: 1.923rem;
          color: #979595;
          line-height: 36px; }
      .be-notifications .notification > a .notification-info {
        padding-left: 50px;
        line-height: 14px; }
        .be-notifications .notification > a .notification-info .text {
          font-weight: 500;
          color: #8a8a8a;
          font-size: .9231rem;
          line-height: 16px; }
        .be-notifications .notification > a .notification-info .user-name {
          color: #4285f4;
          font-size: 1rem; }
        .be-notifications .notification > a .notification-info .date {
          color: #8a8a8a;
          display: block;
          font-size: .6923rem;
          margin-top: 4px;
          text-transform: uppercase; }
    .be-notifications .notification.notification-unread {
      background-color: #ebf2fe;
      border-bottom: 1px solid #dce9fd; }
      .be-notifications .notification.notification-unread > a {
        position: relative; }
        .be-notifications .notification.notification-unread > a:after {
          content: '';
          display: block;
          position: absolute;
          right: 15px;
          top: 20px;
          width: 6px;
          height: 6px;
          background-color: #4285f4;
          border-radius: 50%; }
    .be-notifications .notification:hover {
      background-color: #4285f4; }
      .be-notifications .notification:hover > a {
        color: #FFFFFF; }
        .be-notifications .notification:hover > a .logo {
          background-color: #206ff2; }
          .be-notifications .notification:hover > a .logo .icon {
            color: #FFFFFF; }
        .be-notifications .notification:hover > a .notification-info .circle {
          background: #FFFFFF; }
        .be-notifications .notification:hover > a .notification-info .text, .be-notifications .notification:hover > a .notification-info .date, .be-notifications .notification:hover > a .notification-info .user-name {
          color: #FFFFFF; }
        .be-notifications .notification:hover > a:after {
          background-color: #FFFFFF; }
    .be-notifications .notification:last-child {
      border-bottom: 0; }

@media (max-width: 575.98px) {
  .navbar-nav .show .be-notifications {
    position: absolute;
    background-color: #FFFFFF; } }

@media (max-width: 575.98px) {
  .navbar-nav .show .be-notifications {
    position: fixed; } }

.be-connections {
  min-width: 304px; }
  .float-right .be-connections {
    left: auto;
    right: 25px; }
  .be-connections .content {
    padding: 20px 15px; }
    .be-connections .content .row {
      margin: 0; }
      .be-connections .content .row > div {
        padding-left: 5px;
        padding-right: 5px; }
  .be-connections .connection-item {
    display: block;
    border-radius: 3px;
    line-height: 34px;
    text-align: center;
    padding: 15px 0 9px;
    display: block;
    border: 1px solid transparent;
    color: #737373; }
    .be-connections .connection-item span {
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap; }
    .be-connections .connection-item img {
      max-height: 35px;
      max-width: 35px; }
    .be-connections .connection-item:hover {
      background-color: transparent;
      border-color: #ebebeb; }

@media (max-width: 767.98px) {
  .float-right .be-connections {
    right: 5px; }
  .navbar-nav .show .be-connections {
    position: absolute;
    background-color: #FFFFFF; } }

@media (max-width: 575.98px) {
  .be-connections {
    min-width: 260px; }
    .navbar-nav .show .be-connections {
      position: fixed; }
    .be-connections .content {
      padding-left: 10px;
      padding-right: 10px; } }

/*------------------------------------------------------------------
	[Left sidebar]
*/
.be-left-sidebar {
  background-color: #f5f5f5;
  width: 230px;
  left: 0;
  position: absolute;
  border-right: 1px solid #e3e3e3; }
  @media print {
    .be-left-sidebar {
      display: none; } }
  .be-collapsible-sidebar-collapsed .be-left-sidebar {
    width: 60px; }
    @media (max-width: 767.98px) {
      .be-collapsible-sidebar-collapsed .be-left-sidebar {
        width: 100%; } }
  .be-left-sidebar .left-sidebar-wrapper {
    display: table;
    table-layout: fixed;
    width: 100%;
    height: 100%; }
  .be-left-sidebar .left-sidebar-spacer {
    height: 100%;
    display: table-row; }
    @media (min-width: 768px) {
      .be-left-sidebar .left-sidebar-spacer {
        display: table-row; } }
  .be-left-sidebar .left-sidebar-scroll {
    position: relative;
    height: 100%;
    width: 100%;
    overflow: hidden; }
  .be-left-sidebar .left-sidebar-content {
    position: relative;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding-top: 8px; }
    .be-collapsible-sidebar-collapsed .be-left-sidebar .left-sidebar-content {
      padding-top: 20px; }
  .be-left-sidebar .left-sidebar-toggle {
    display: none;
    background-color: #FFFFFF;
    padding: 16px 10px;
    color: #404040;
    font-weight: 300;
    font-size: 1.077rem; }
    .be-left-sidebar .left-sidebar-toggle:before {
      content: '\f2f2';
      float: right;
      font-family: "Material Icons";
      font-size: 2.077rem;
      line-height: 22px;
      color: #c5c5c5; }
    .be-left-sidebar .left-sidebar-toggle.open:before {
      content: '\f2f8'; }
    @media (max-width: 767.98px) {
      .be-left-sidebar .left-sidebar-toggle {
        display: block; } }
  .be-left-sidebar .sidebar-elements {
    margin: 0;
    padding: 0; }
    .be-left-sidebar .sidebar-elements .divider {
      padding: 20px 20px 0;
      color: #b0b0b0;
      line-height: 30px;
      font-weight: 600;
      text-transform: uppercase;
      font-size: .8462rem; }
      @media (min-width: 768px) {
        .be-collapsible-sidebar-collapsed .be-left-sidebar .sidebar-elements .divider {
          padding: 40px 0 0;
          height: 0;
          overflow: hidden;
          text-indent: -9999px; }
          .be-collapsible-sidebar-collapsed .be-left-sidebar .sidebar-elements .divider:first-child {
            display: none; } }
    .be-left-sidebar .sidebar-elements > li {
      list-style: none; }
      .be-left-sidebar .sidebar-elements > li > a {
        display: block;
        padding: 0 20px;
        color: #646464;
        font-size: 1.077rem;
        line-height: 38px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap; }
        .be-collapsible-sidebar-collapsed .be-left-sidebar .sidebar-elements > li > a {
          text-overflow: clip; }
        .be-left-sidebar .sidebar-elements > li > a .icon {
          line-height: 18px;
          font-size: 19px;
          min-width: 19px;
          margin-right: 9px;
          text-align: center;
          vertical-align: middle; }
        @media (min-width: 768px) {
          .be-collapsible-sidebar-collapsed .be-left-sidebar .sidebar-elements > li > a span {
            display: none; } }
        .be-left-sidebar .sidebar-elements > li > a:hover {
          background-color: #f0f0f0; }
          .be-left-sidebar .sidebar-elements > li > a:hover > span {
            color: #3d3d3d; }
      .be-left-sidebar .sidebar-elements > li > ul {
        border-top: 1px solid #e6e6e6;
        border-bottom: 1px solid #e6e6e6; }
        .be-collapsible-sidebar .be-left-sidebar .sidebar-elements > li > ul .nav-items .content > ul {
          display: block; }
        @media (min-width: 768px) {
          .be-collapsible-sidebar-collapsed .be-left-sidebar .sidebar-elements > li > ul {
            border-top-width: 0;
            border-bottom-width: 0; } }
      .be-left-sidebar .sidebar-elements > li ul {
        display: none;
        background-color: #eeeeee;
        padding: 10px 0;
        list-style: none;
        line-height: 20px; }
        .be-left-sidebar .sidebar-elements > li ul .title {
          display: none; }
        .be-collapsible-sidebar .be-left-sidebar .sidebar-elements > li ul .content > ul {
          padding-left: 0; }
        @media (min-width: 768px) {
          .be-collapsible-sidebar-collapsed .be-left-sidebar .sidebar-elements > li ul.visible {
            display: table;
            table-layout: fixed;
            position: fixed;
            left: 60px;
            top: 61px;
            height: 100%;
            z-index: 1031;
            padding: 0 0 61px;
            list-style: none;
            width: 232px;
            border-right: 1px solid #e6e6e6; }
            .be-collapsible-sidebar-collapsed .be-left-sidebar .sidebar-elements > li ul.visible .title {
              display: block;
              padding: 30px 25px;
              font-size: 1.385rem;
              color: #737373; }
            .be-collapsible-sidebar-collapsed .be-left-sidebar .sidebar-elements > li ul.visible .nav-items {
              display: table-row;
              height: 100%; }
              .be-collapsible-sidebar-collapsed .be-left-sidebar .sidebar-elements > li ul.visible .nav-items .be-scroller {
                display: table-cell;
                position: relative;
                height: 100%; }
                .be-collapsible-sidebar-collapsed .be-left-sidebar .sidebar-elements > li ul.visible .nav-items .be-scroller > .content {
                  position: absolute;
                  top: 0;
                  bottom: 0;
                  width: 100%;
                  height: 100%; }
              .be-collapsible-sidebar-collapsed .be-left-sidebar .sidebar-elements > li ul.visible .nav-items .content > ul {
                border-color: transparent;
                padding: 0; }
                .be-collapsible-sidebar-collapsed .be-left-sidebar .sidebar-elements > li ul.visible .nav-items .content > ul > li > a {
                  padding-left: 25px; }
                .be-collapsible-sidebar-collapsed .be-left-sidebar .sidebar-elements > li ul.visible .nav-items .content > ul > li > ul > li > a {
                  padding-left: 35px; }
                .be-collapsible-sidebar-collapsed .be-left-sidebar .sidebar-elements > li ul.visible .nav-items .content > ul > li > ul > li > ul > li > a {
                  padding-left: 45px; }
                .be-collapsible-sidebar-collapsed .be-left-sidebar .sidebar-elements > li ul.visible .nav-items .content > ul > li > ul > li > ul > li > ul > li > a {
                  padding-left: 55px; }
                .be-collapsible-sidebar-collapsed .be-left-sidebar .sidebar-elements > li ul.visible .nav-items .content > ul > li > ul > li > ul > li > ul > li > ul > li > a {
                  padding-left: 65px; } }
        .be-left-sidebar .sidebar-elements > li ul li > a {
          padding: 8px 15px 8px 49px;
          color: #646464;
          font-size: 13px;
          display: block;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap; }
          .be-left-sidebar .sidebar-elements > li ul li > a .badge {
            border-radius: 2px;
            font-weight: 500;
            line-height: 9px;
            font-size: 0.85rem;
            padding: 4px 7px;
            margin-left: 4px;
            margin-top: 1px; }
          .be-left-sidebar .sidebar-elements > li ul li > a:hover {
            background-color: #e7e7e7; }
        .be-left-sidebar .sidebar-elements > li ul li.active > a {
          color: #4285f4; }
        .be-left-sidebar .sidebar-elements > li ul li ul {
          padding-top: 0;
          padding-bottom: 0;
          padding-left: 0; }
        .be-left-sidebar .sidebar-elements > li ul li > ul {
          padding: 5px 0; }
        .be-left-sidebar .sidebar-elements > li ul li > ul > li ul {
          padding: 5px 0; }
        .be-left-sidebar .sidebar-elements > li ul li > ul > li > a {
          padding-left: 59px; }
        .be-left-sidebar .sidebar-elements > li ul li > ul > li > ul > li > a {
          padding-left: 69px; }
        .be-left-sidebar .sidebar-elements > li ul li > ul > li > ul > li > ul > li > a {
          padding-left: 79px; }
        .be-left-sidebar .sidebar-elements > li ul li > ul > li > ul > li > ul > li > ul > li > a {
          padding-left: 89px; }
      .be-left-sidebar .sidebar-elements > li.active > a {
        padding-left: 20px;
        font-weight: 500;
        color: #4285f4; }
        .be-left-sidebar .sidebar-elements > li.active > a > span {
          color: #4285f4; }
      .be-left-sidebar .sidebar-elements > li.open > ul,
      .be-left-sidebar .sidebar-elements > li.open > ul .nav-items .content > ul {
        display: block; }
      @media (min-width: 768px) {
        .be-collapsible-sidebar-collapsed .be-left-sidebar .sidebar-elements > li.open > ul {
          display: none; }
          .be-collapsible-sidebar-collapsed .be-left-sidebar .sidebar-elements > li.open > ul.visible {
            display: table; } }
    .be-left-sidebar .sidebar-elements li.parent > a:before {
      content: '\f2f2';
      float: right;
      font-family: "Material Icons";
      font-size: 1.615rem;
      color: #c5c5c5; }
      @media (min-width: 768px) {
        .be-collapsible-sidebar-collapsed .be-left-sidebar .sidebar-elements li.parent > a:before {
          display: none; } }
    .be-left-sidebar .sidebar-elements li.parent.open > a:before {
      content: '\f2f8'; }
    .be-left-sidebar .sidebar-elements li.open > ul {
      display: block; }
    @media (min-width: 768px) {
      .be-collapsible-sidebar-collapsed .be-left-sidebar .sidebar-elements .sub-menu.visible li.parent > a:before {
        display: block; } }
  @media (min-width: 768px) {
    .be-boxed-layout .be-left-sidebar {
      -ms-flex: 0 0 230px;
          flex: 0 0 230px;
      position: relative; }
      .be-boxed-layout .be-left-sidebar:before {
        position: absolute; } }
  .be-left-sidebar:before {
    content: "";
    position: fixed;
    z-index: -1;
    width: inherit;
    top: 0;
    left: 0;
    bottom: 0;
    background-color: inherit;
    border-right: 1px solid #e3e3e3; }
    @media (max-width: 767.98px) {
      .be-left-sidebar:before {
        display: none; } }
  @media (max-width: 767.98px) {
    .be-left-sidebar {
      width: 100%;
      background-color: #fafafa;
      border-bottom: 1px solid #e3e3e3;
      border-right-width: 0;
      position: relative; }
      .be-left-sidebar .left-sidebar-wrapper,
      .be-left-sidebar .left-sidebar-spacer,
      .be-left-sidebar .left-sidebar-scroll,
      .be-left-sidebar .left-sidebar-content {
        display: block;
        position: relative;
        overflow: visible; }
      .be-left-sidebar .left-sidebar-spacer {
        display: none;
        border-top: 2px solid #f5f5f5; }
        .be-left-sidebar .left-sidebar-spacer.open {
          display: block; }
      .be-left-sidebar .left-sidebar-content {
        padding-bottom: 20px; }
      .be-left-sidebar .sidebar-elements > li > a {
        text-align: left;
        position: relative; }
        .be-left-sidebar .sidebar-elements > li > a:hover {
          background-color: transparent; }
          .be-left-sidebar .sidebar-elements > li > a:hover > span {
            color: inherit; }
      .be-left-sidebar .sidebar-elements > li > ul {
        position: relative;
        height: auto;
        width: 100%;
        left: 0; }
        .be-left-sidebar .sidebar-elements > li > ul li > a:hover {
          background-color: transparent; }
        .be-left-sidebar .sidebar-elements > li > ul li > a:active {
          background-color: #e7e7e7; }
        .be-left-sidebar .sidebar-elements > li > ul > li.title {
          display: none; }
        .be-left-sidebar .sidebar-elements > li > ul > li.nav-items {
          display: block;
          height: auto; }
          .be-left-sidebar .sidebar-elements > li > ul > li.nav-items > .be-scroller {
            height: auto;
            overflow: visible; }
            .be-left-sidebar .sidebar-elements > li > ul > li.nav-items > .be-scroller > .content {
              padding-top: 0;
              overflow: visible;
              position: relative; }
      .be-left-sidebar .sidebar-elements > li:hover {
        background-color: inherit; }
        .be-left-sidebar .sidebar-elements > li:hover > ul {
          display: none; }
      .be-left-sidebar .sidebar-elements li.open > ul {
        display: block; } }
  .be-fixed-sidebar .be-left-sidebar {
    height: 100%;
    margin-top: 61px;
    padding-bottom: 61px;
    position: fixed;
    top: 0;
    bottom: 0;
    z-index: 1029; }
    .be-fixed-sidebar .be-left-sidebar .left-sidebar-content {
      position: absolute; }
      @media (max-width: 767.98px) {
        .be-fixed-sidebar .be-left-sidebar .left-sidebar-content {
          position: relative; } }
    @media (max-width: 767.98px) {
      .be-fixed-sidebar .be-left-sidebar {
        margin-top: 0;
        position: static;
        height: auto;
        margin-top: 0;
        padding-bottom: 0; } }
    .be-fixed-sidebar .be-left-sidebar:before {
      top: 60px; }
  .be-offcanvas-menu .be-left-sidebar {
    position: fixed;
    height: 100%;
    top: 0;
    left: -240px;
    z-index: 1031;
    background-color: #FFFFFF;
    box-shadow: 0 6px 18px 0 rgba(0, 0, 0, 0.18);
    border-right-width: 0; }
    @media (max-width: 767.98px) {
      .be-offcanvas-menu .be-left-sidebar {
        width: 230px;
        left: 0;
        -webkit-transform: translate3d(-105%, 0, 0);
                transform: translate3d(-105%, 0, 0); } }
    .be-offcanvas-menu .be-left-sidebar:before {
      left: -230px;
      border-right-width: 0; }
    @media (max-width: 767.98px) {
      .be-offcanvas-menu .be-left-sidebar .left-sidebar-toggle {
        display: none; } }
    .be-offcanvas-menu .be-left-sidebar .be-toggle-left-sidebar {
      display: inline-block;
      text-align: center;
      line-height: 60px;
      float: left; }
      .be-offcanvas-menu .be-left-sidebar .be-toggle-left-sidebar .icon {
        padding-left: 25px;
        font-size: 1.846rem;
        color: #737373;
        line-height: 25px;
        vertical-align: middle; }
    .be-offcanvas-menu .be-left-sidebar .menu-logo {
      height: 60px;
      width: 150px;
      background-image: url("./../../../vendor/beagle-v1.4.2/BS4/dist/html/assets/img/logo.png");
      background-repeat: no-repeat;
      background-position: 20px center;
      display: block;
      float: left; }
  .be-fixed-sidebar.be-offcanvas-menu .be-left-sidebar {
    margin-top: 61px;
    padding-bottom: 61px; }
  .be-animate .be-left-sidebar {
    transition: all 0.35s ease; }
  @media (max-width: 767.98px) {
    .be-offcanvas-menu .be-left-sidebar .left-sidebar-wrapper {
      display: table; } }
  @media (max-width: 767.98px) {
    .be-offcanvas-menu .be-left-sidebar .left-sidebar-spacer {
      display: table-row;
      border-top-width: 0; } }
  @media (max-width: 767.98px) {
    .be-fixed-sidebar.be-offcanvas-menu .be-left-sidebar .left-sidebar-content {
      position: absolute; } }
  @media (max-width: 767.98px) {
    .be-offcanvas-menu .be-left-sidebar .progress-widget {
      display: block; } }
  @media (max-width: 767.98px) {
    .be-offcanvas-menu .be-left-sidebar .be-navbar-header {
      display: block; } }
  .be-offcanvas-menu .be-left-sidebar .be-navbar-header .navbar-brand {
    padding-left: 0;
    background-position: 0 center; }
  .be-left-sidebar .left-sidebar-top {
    border-bottom: 1px solid #ededed; }
    .be-left-sidebar .left-sidebar-top::after {
      display: block;
      clear: both;
      content: ""; }

@media (min-width: 768px) {
  body.open-left-sidebar .be-left-sidebar {
    left: 0; } }

@media (max-width: 767.98px) and (max-width: 767.98px) {
  body.open-left-sidebar .be-wrapper {
    overflow: hidden; }
  body.open-left-sidebar .be-left-sidebar {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

.be-left-sidebar .progress-widget {
  padding: 20px;
  cursor: pointer; }
  .be-collapsible-sidebar-collapsed .be-left-sidebar .progress-widget {
    padding: 13px 14px;
    margin-top: 25px; }
  .be-fixed-sidebar .be-left-sidebar .progress-widget {
    margin-top: 0; }
  .be-left-sidebar .progress-widget .progress-data {
    color: #646464; }
  .be-left-sidebar .progress-widget .progress-value {
    float: right; }
    .be-collapsible-sidebar-collapsed .be-left-sidebar .progress-widget .progress-value {
      float: none;
      display: block;
      text-align: center;
      font-size: .8462rem; }
  .be-collapsible-sidebar-collapsed .be-left-sidebar .progress-widget .name {
    display: none; }
  .be-left-sidebar .progress-widget .progress {
    margin: 10px 0 0;
    height: 8px;
    background-color: #ebebeb;
    border-radius: 2px; }
    .be-collapsible-sidebar-collapsed .be-left-sidebar .progress-widget .progress {
      height: 5px;
      margin-top: 7px; }
  .be-left-sidebar .progress-widget:hover {
    background-color: #f0f0f0; }
    .be-left-sidebar .progress-widget:hover .progress {
      background-color: #e6e6e6; }
  @media (max-width: 767.98px) {
    .be-left-sidebar .progress-widget {
      display: none; } }

/*------------------------------------------------------------------
  [Right sidebar]
*/
.be-right-sidebar {
  position: fixed;
  background-color: #FAFAFA;
  margin-top: 61px;
  padding-bottom: 61px;
  width: 290px;
  height: 100%;
  right: -290px;
  top: 0;
  bottom: 0;
  border-left: 1px solid #e8e8e8;
  z-index: 1031; }
  .be-animate .be-right-sidebar {
    transition: all 0.35s ease; }
  @media (min-width: 768px) {
    .be-boxed-layout .be-right-sidebar {
      position: absolute; } }

@media (min-width: 768px) {
  body.open-right-sidebar .be-right-sidebar {
    right: 0; } }

@media (max-width: 767.98px) and (max-width: 767.98px) {
  body.open-right-sidebar .be-wrapper {
    overflow: hidden; }
  body.open-right-sidebar .be-right-sidebar {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

.be-right-sidebar .sb-content {
  display: table;
  table-layout: fixed;
  height: 100%;
  width: 100%; }

.be-right-sidebar .tab-navigation .nav-tabs {
  background-color: transparent;
  border-bottom: 0;
  padding: 0; }
  .be-right-sidebar .tab-navigation .nav-tabs > li.nav-item {
    margin-right: 0; }
    .be-right-sidebar .tab-navigation .nav-tabs > li.nav-item > a.nav-link {
      background-color: #f2f2f2;
      border-width: 0;
      padding: 15px 10px;
      min-width: auto;
      font-size: 1.077rem; }
      .be-right-sidebar .tab-navigation .nav-tabs > li.nav-item > a.nav-link .icon {
        font-size: 1.923rem;
        color: #aaaaaa; }
      .be-right-sidebar .tab-navigation .nav-tabs > li.nav-item > a.nav-link:hover {
        border: 0;
        background-color: #f6f6f6;
        color: #3b3b3b; }
      .be-right-sidebar .tab-navigation .nav-tabs > li.nav-item > a.nav-link:focus {
        border: 0; }
      .be-right-sidebar .tab-navigation .nav-tabs > li.nav-item > a.nav-link.active {
        color: #4285f4;
        background-color: transparent; }

@media (max-width: 767.98px) {
  .be-right-sidebar .tab-navigation .nav-justified > li {
    display: table-cell; }
    .be-right-sidebar .tab-navigation .nav-justified > li > a {
      margin-bottom: 0;
      min-width: 40px; } }

.be-right-sidebar .tab-panel {
  height: 100%;
  display: table-row; }
  .be-right-sidebar .tab-panel .tab-content {
    height: 100%;
    position: relative;
    width: 100%;
    padding: 0;
    background: transparent;
    display: table-cell;
    overflow: hidden; }
    .be-right-sidebar .tab-panel .tab-content .tab-pane {
      height: 100%;
      position: relative;
      width: 100%; }
      .be-right-sidebar .tab-panel .tab-content .tab-pane > .content {
        overflow: auto;
        bottom: 0;
        left: 0;
        position: absolute;
        right: 0;
        top: 0; }
      .be-right-sidebar .tab-panel .tab-content .tab-pane h2 {
        font-size: 1.154rem;
        font-weight: 400;
        padding-bottom: 15px;
        border-bottom: 1px solid #ebebeb;
        padding-left: 5px;
        margin-bottom: 20px;
        margin-top: 18px; }
    .be-right-sidebar .tab-panel .tab-content .bottom-input {
      position: relative;
      border-top: 1px solid #f2f2f2; }
      .be-right-sidebar .tab-panel .tab-content .bottom-input > input {
        border: 0;
        background-color: #FAFAFA;
        color: #404040;
        width: 100%;
        font-size: 1rem;
        font-weight: 400;
        padding: 15px 20px;
        outline: none; }
      .be-right-sidebar .tab-panel .tab-content .bottom-input > span {
        position: absolute;
        color: #aaaaaa;
        right: 15px;
        top: 50%;
        font-size: 1.538rem;
        margin-top: -10px;
        cursor: pointer; }
        .be-right-sidebar .tab-panel .tab-content .bottom-input > span:hover {
          color: #d1d1d1; }

@media (max-width: 767.98px) {
  .be-right-sidebar {
    width: 290px;
    right: 0;
    -webkit-transform: translate3d(100%, 0, 0);
            transform: translate3d(100%, 0, 0);
    margin-top: 0;
    padding-bottom: 0; }
    .be-right-sidebar .tab-panel .tab-content > .chat {
      width: 100%; }
      .be-right-sidebar .tab-panel .tab-content > .chat .chat-contacts, .be-right-sidebar .tab-panel .tab-content > .chat .chat-window {
        width: 100%; }
      .be-right-sidebar .tab-panel .tab-content > .chat .chat-window {
        left: 100%; }
      .be-right-sidebar .tab-panel .tab-content > .chat.chat-opened .chat-contacts {
        left: -100%; } }

@media (max-width: 320px) {
  .be-right-sidebar {
    width: 90%; } }

.tab-chat {
  white-space: nowrap;
  font-size: 0;
  width: 290px; }
  .tab-chat h2 {
    margin: 30px 20px 15px;
    line-height: 1.1; }
  .tab-chat .contact-list {
    font-size: .9231rem; }
    .tab-chat .contact-list .user a {
      display: block;
      padding: 11px 25px;
      overflow: hidden; }
      .tab-chat .contact-list .user a img {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        float: left; }
      .tab-chat .contact-list .user a .user-data, .tab-chat .contact-list .user a .user-data2 {
        padding-left: 40px;
        line-height: 16px; }
        .tab-chat .contact-list .user a .user-data .status, .tab-chat .contact-list .user a .user-data2 .status {
          background-color: #00b777;
          border-radius: 50%;
          float: right;
          width: 7px;
          height: 7px;
          position: relative;
          top: 5px; }
          .tab-chat .contact-list .user a .user-data .status.offline, .tab-chat .contact-list .user a .user-data2 .status.offline {
            background-color: #e65a5a; }
          .tab-chat .contact-list .user a .user-data .status.away, .tab-chat .contact-list .user a .user-data2 .status.away {
            background-color: #f2c329; }
        .tab-chat .contact-list .user a .user-data .name, .tab-chat .contact-list .user a .user-data2 .name {
          color: #404040;
          font-weight: 500;
          font-size: 1rem; }
        .tab-chat .contact-list .user a .user-data .message, .tab-chat .contact-list .user a .user-data2 .message {
          display: block;
          color: #999999;
          font-size: 1rem; }
      .tab-chat .contact-list .user a .user-data2 {
        margin-top: 7px; }
      .tab-chat .contact-list .user a:hover {
        background-color: whitesmoke; }
    .tab-chat .contact-list-recent {
      margin-bottom: 30px; }
  .tab-chat .chat-contacts, .tab-chat .chat-window {
    height: 100%;
    width: 100%;
    display: table;
    table-layout: fixed;
    position: absolute;
    left: 0;
    transition: left 0.3s ease; }
  .tab-chat .chat-contacts .chat-sections {
    height: 100%;
    display: table-row; }
    .tab-chat .chat-contacts .chat-sections .be-scroller {
      position: relative;
      width: 100%;
      height: 100%;
      overflow: hidden; }
    .tab-chat .chat-contacts .chat-sections .content {
      position: absolute;
      width: 100%;
      top: 0;
      bottom: 0; }
  .tab-chat .chat-contacts .search {
    position: relative; }
  .tab-chat .chat-window {
    height: 100%;
    left: 290px;
    display: table;
    table-layout: fixed; }
    .tab-chat .chat-window .title {
      padding: 20px 10px;
      padding-right: 20px;
      overflow: hidden; }
      .tab-chat .chat-window .title .icon {
        font-size: 2.077rem;
        width: 27px;
        text-align: center;
        color: #b3b2b2;
        position: relative;
        vertical-align: middle;
        cursor: pointer; }
      .tab-chat .chat-window .title .user {
        display: inline-block;
        float: right;
        text-align: right;
        line-height: 13px; }
        .tab-chat .chat-window .title .user img {
          height: 35px;
          width: 35x;
          border-radius: 50%;
          float: right;
          margin-left: 10px; }
        .tab-chat .chat-window .title .user h2 {
          display: inline-block;
          margin: 0;
          padding: 0;
          border: 0; }
        .tab-chat .chat-window .title .user span {
          display: block;
          font-size: .9231rem;
          color: #999999; }
    .tab-chat .chat-window .chat-messages {
      height: 100%;
      display: table-row;
      font-size: 1rem; }
      .tab-chat .chat-window .chat-messages .be-scroller {
        position: relative;
        width: 100%;
        height: 100%;
        overflow: hidden;
        outline: none; }
      .tab-chat .chat-window .chat-messages .content {
        position: absolute;
        width: 100%;
        top: 0;
        bottom: 0; }
      .tab-chat .chat-window .chat-messages ul {
        outline: none;
        margin: 0;
        padding: 0; }
        .tab-chat .chat-window .chat-messages ul > li {
          display: block;
          list-style: none;
          padding: 9px 16px; }
          .tab-chat .chat-window .chat-messages ul > li .msg {
            position: relative;
            display: inline-block;
            background-color: #4c8bf5;
            color: #FFFFFF;
            font-size: 1rem;
            padding: 6px 8px;
            border-radius: 3px;
            white-space: normal;
            text-align: left;
            margin-left: 40px; }
            .tab-chat .chat-window .chat-messages ul > li .msg:after {
              content: "";
              position: absolute;
              right: -5px;
              top: 7px;
              border-bottom: 6px solid transparent;
              border-left: 6px solid #4c8bf5;
              border-top: 6px solid transparent; }
          .tab-chat .chat-window .chat-messages ul > li.friend .msg {
            background-color: #f0f0f0;
            color: #595858;
            margin-left: 0;
            margin-right: 40px; }
            .tab-chat .chat-window .chat-messages ul > li.friend .msg:after {
              right: auto;
              left: -5px;
              border-left-width: 0;
              border-right: 5px solid #f0f0f0; }
          .tab-chat .chat-window .chat-messages ul > li.self {
            text-align: right; }
    .tab-chat .chat-window .chat-input {
      position: relative; }
      .tab-chat .chat-window .chat-input .input-wrapper {
        border-top: 1px solid #f2f2f2; }
        .tab-chat .chat-window .chat-input .input-wrapper > input {
          border: 0;
          background-color: #FAFAFA;
          color: #b3b2b2;
          width: 100%;
          font-size: 1rem;
          font-weight: 300;
          padding: 15px 20px;
          padding-left: 42px;
          padding-right: 40px;
          outline: none; }
        .tab-chat .chat-window .chat-input .input-wrapper > span {
          position: absolute;
          color: #aaaaaa;
          right: 15px;
          top: 50%;
          font-size: 1.538rem;
          margin-top: -10px;
          cursor: pointer; }
          .tab-chat .chat-window .chat-input .input-wrapper > span:hover {
            color: #d1d1d1; }
        .tab-chat .chat-window .chat-input .input-wrapper .photo {
          left: 15px;
          font-size: 1.692rem;
          top: 50%;
          right: auto; }
  .tab-chat.chat-opened .chat-contacts {
    left: -290px; }
  .tab-chat.chat-opened .chat-window {
    left: 0; }

.tab-todo .todo-container {
  height: 100%;
  display: table;
  table-layout: fixed;
  width: 100%; }

.tab-todo .todo-wrapper {
  display: table-row;
  height: 100%; }

.tab-todo .be-scroller {
  position: relative;
  overflow: hidden;
  height: 100%; }

.tab-todo .todo-content {
  padding: 10px 20px 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.tab-todo .category-title {
  display: block;
  font-size: 1.154rem;
  font-weight: 400;
  color: gray;
  padding-top: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #ebebeb;
  margin-bottom: 15px; }

.todo-list {
  list-style: none;
  padding: 4px 0 1px;
  margin-bottom: 15px; }
  .todo-list > li {
    margin: 0;
    padding: 0; }
    .todo-list > li .custom-checkbox {
      display: block;
      margin-right: 4px; }
      .todo-list > li .custom-checkbox.custom-control {
        margin-bottom: .7rem; }
      .todo-list > li .custom-checkbox input[type="checkbox"] ~ .custom-control-label {
        display: block;
        cursor: pointer;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 1.8; }
        .todo-list > li .custom-checkbox input[type="checkbox"] ~ .custom-control-label::before {
          margin-top: 5px;
          margin-right: 8px; }
      .todo-list > li .custom-checkbox input[type="checkbox"]:checked ~ .custom-control-label {
        text-decoration: line-through;
        color: #999999; }
        .todo-list > li .custom-checkbox input[type="checkbox"]:checked ~ .custom-control-label::after {
          margin-top: 5px; }
      .todo-list > li .custom-checkbox .delete {
        display: none;
        float: right;
        font-size: 1.308rem;
        color: #b3b3b3;
        line-height: 22px;
        margin-left: 5px;
        cursor: pointer; }
        .todo-list > li .custom-checkbox .delete:hover {
          color: gray; }
      .todo-list > li .custom-checkbox:hover .delete {
        display: inline-block; }

.tab-settings .settings-wrapper {
  position: relative;
  height: 100%;
  overflow: hidden; }

.tab-settings .be-scroller {
  padding: 10px 20px 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.tab-settings .category-title {
  display: block;
  font-size: 1.154rem;
  font-weight: 400;
  color: gray;
  padding-top: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #ebebeb;
  margin-bottom: 15px; }

.settings-list {
  list-style: none;
  padding-left: 0;
  margin-bottom: 15px; }
  .settings-list > li {
    margin: 0;
    padding: 5px 0;
    line-height: 24px;
    font-size: 1.077rem; }
    .settings-list > li .name {
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap; }
    .settings-list > li .switch-button {
      float: right;
      margin-left: 5px; }

/*------------------------------------------------------------------
  [Main content]
*/
.be-content {
  position: relative;
  left: 0;
  margin-left: 230px;
  min-height: 100%; }
  @media (max-width: 767.98px) {
    .be-content {
      margin-left: 0; } }
  @media print {
    .be-content {
      margin-left: 0; } }
  .be-nosidebar-left .be-content {
    margin-left: 0; }
  .be-collapsible-sidebar-collapsed .be-content {
    margin-left: 60px; }
    @media (max-width: 767.98px) {
      .be-collapsible-sidebar-collapsed .be-content {
        margin-left: 0; } }
  .be-offcanvas-menu .be-content {
    margin-left: 0; }
  @media (min-width: 768px) {
    .be-boxed-layout .be-content {
      margin-left: 0;
      overflow: hidden; } }

.main-content {
  padding: 25px; }
  @media (max-width: 991.98px) {
    .main-content {
      padding-left: 25px;
      padding-right: 25px;
      padding-top: 30px; } }
  @media (max-width: 767.98px) {
    .main-content {
      height: 100%;
      overflow: auto;
      -webkit-overflow-scrolling: touch;
      padding-left: 15px;
      padding-right: 15px;
      padding-top: 20px; } }
  @media (max-width: 575.98px) {
    .main-content {
      padding-right: 10px;
      padding-left: 10px; } }
  .be-no-padding .main-content {
    padding: 0; }

/*------------------------------------------------------------------
  [Page head]
*/
.page-head {
  padding: 20px 25px 10px;
  position: relative; }
  @media print {
    .page-head {
      display: none; } }
  .page-head-title {
    display: block;
    margin-bottom: 5px;
    margin-top: 6px;
    font-weight: 300;
    letter-spacing: -1px;
    font-size: 2.154rem;
    color: #6b6b6b;
    line-height: 36px; }
  .page-head-description {
    display: block;
    color: #6e6e6e;
    font-size: 1.077rem;
    font-weight: 300; }
  .page-head .breadcrumb {
    background-color: transparent;
    padding: 3px 0;
    margin-bottom: 7px;
    line-height: 16px; }
    .page-head .breadcrumb > li {
      font-size: 1.077rem;
      font-weight: 300; }
      .page-head .breadcrumb > li + li:before {
        padding: 0 5px;
        color: #a3a3a3; }
      .page-head .breadcrumb > li > a {
        color: #5e5e5e; }
        .page-head .breadcrumb > li > a:hover {
          color: #303030; }
    .page-head .breadcrumb > .active {
      color: #7a7a7a; }
  @media (max-width: 767.98px) {
    .page-head {
      padding: 18px 10px 0;
      display: none; }
      .page-head-title {
        font-size: 1.846rem;
        line-height: 28px; } }

/*------------------------------------------------------------------
  [Page aside]
*/
.be-aside .main-content {
  margin-left: 280px;
  width: auto; }
  @media (max-width: 767.98px) {
    .be-aside .main-content {
      margin-left: inherit; } }

.be-aside .be-content {
  height: 100%; }

.be-aside .content {
  height: 100%; }

.page-aside {
  background: none repeat scroll 0 0 #FFFFFF;
  width: 280px;
  height: 100%;
  position: fixed;
  top: 0;
  left: 230px;
  border-right: 1px solid #e6e5e5;
  margin-top: 61px;
  padding-bottom: 61px; }
  .no-border .page-aside {
    border-width: 0; }
  .page-aside .be-scroller {
    position: relative;
    height: 100%;
    width: 100%; }
  .page-aside .content {
    padding: 20px 22px;
    position: relative; }
  @media (max-width: 767.98px) {
    .page-aside {
      width: 100%;
      display: block;
      height: auto;
      position: relative;
      margin-top: 0;
      padding-bottom: 0;
      left: 0;
      border-bottom: 2px solid #f2f2f2; } }

.aside-header {
  margin-bottom: 5px; }
  .aside-header .title {
    display: block;
    margin: 16px 0 0;
    font-size: 27px;
    line-height: 27px;
    font-weight: 300; }
  .aside-header .navbar-toggle {
    background: transparent;
    display: none;
    outline: none;
    border: 0;
    padding: 0 11px 0 0;
    text-align: right;
    margin: 0;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute; }
    @media (max-width: 767.98px) {
      .aside-header .navbar-toggle {
        display: block; } }
    .aside-header .navbar-toggle .icon {
      font-size: 1.538rem;
      color: #c0bfbf; }
  .aside-header .description {
    color: #5a5959;
    margin: 0; }

.aside-nav {
  display: block;
  visibility: visible; }
  .aside-nav .nav li a {
    color: #404040;
    padding: 13px 22px; }
  .aside-nav .nav li.active a {
    color: #4285f4; }
    .aside-nav .nav li.active a .icon {
      color: #4285f4; }
  .aside-nav .nav li .badge {
    float: right;
    font-size: .9231rem;
    font-weight: 300;
    padding: 1px 7px; }
  .aside-nav .nav li .icon {
    font-size: 1.462rem;
    vertical-align: middle;
    text-align: center;
    min-width: 19px;
    margin-right: 3px;
    color: #616161; }
  .aside-nav .title {
    display: block;
    color: #999999;
    font-size: .8462rem;
    font-weight: 700;
    text-transform: uppercase;
    margin: 20px 0 0;
    padding: 8px 22px 4px; }
  @media (max-width: 767.98px) {
    .aside-nav {
      border-bottom: 1px solid #e6e5e5; }
      .aside-nav.collapse {
        display: none; }
      .aside-nav.show {
        display: block; } }

.be-aside-header {
  background-color: #FFFFFF;
  padding: 37px 25px; }
  @media (min-width: 768px) and (max-width: 991.98px) {
    .be-aside-header {
      padding: 20px 25px; } }

.be-aside-header-title {
  display: block;
  margin: 3px 0 0;
  font-size: 1.769rem;
  font-weight: 300; }
  @media (max-width: 991.98px) {
    .be-aside-header-title {
      margin-bottom: 10px; } }
  .be-aside-header-title .icon {
    font-size: 1.769rem;
    color: #737373; }
  .be-aside-header-title .new-messages {
    font-size: 1rem;
    color: #8d8c8c;
    margin-left: 3px; }

.be-aside-header-filters {
  padding: 0 20px 30px;
  border-bottom: 1px solid #e6e5e5;
  background-color: #FFFFFF;
  display: table;
  width: 100%; }
  .be-aside-header-filters .dropdown-toggle:after {
    display: inline-block; }
  .be-aside-header-filters > div {
    display: table-cell;
    vertical-align: middle; }
    @media (max-width: 991.98px) {
      .be-aside-header-filters > div {
        vertical-align: top; } }
  @media (max-width: 991.98px) {
    .be-aside-header-filters .btn-group + .btn-group {
      margin-left: 0; } }
  .be-aside-header-filters .be-select-all.be-checkbox {
    display: inline-block;
    vertical-align: middle;
    padding: 0; }
    .be-aside-header-filters .be-select-all.be-checkbox label:before {
      margin-right: 18px; }
  .be-aside-header-filters .be-aside-header-filters-right {
    text-align: right; }
  .be-aside-header-filters input {
    margin-right: 8px; }

.be-aside-pagination-indicator {
  display: inline-block;
  vertical-align: middle;
  margin-right: 13px; }
  @media (min-width: 768px) and (max-width: 991.98px) {
    .be-aside-pagination-indicator {
      margin-right: 0; } }

.be-aside-pagination-nav .btn {
  min-width: 30px; }
  .be-aside-pagination-nav .btn i {
    font-size: 1.615rem;
    vertical-align: middle; }

/*------------------------------------------------------------------
  [Splash screen]
*/
.be-splash-screen {
  background-color: #eee; }
  .be-splash-screen .be-wrapper {
    padding-top: 0; }
    .be-splash-screen .be-wrapper .be-content {
      margin: 0; }

.splash-container {
  max-width: 401px;
  margin: 50px auto; }
  .splash-container .card {
    margin-bottom: 30px; }
    .splash-container .card .card-header {
      text-align: center;
      margin-bottom: 20px;
      padding-top: 40px;
      padding-bottom: 0; }
    .splash-container .card .card-body {
      padding: 20px 30px 15px; }
      .splash-container .card .card-body .custom-checkbox {
        display: inline-block;
        margin: 0; }
  @media (max-width: 767.98px) {
    .splash-container {
      margin-top: 50px; } }

.splash-title {
  text-align: center;
  display: block;
  font-size: 1.308rem;
  font-weight: 300; }

.splash-description {
  text-align: center;
  display: block;
  line-height: 20px;
  font-size: 1rem;
  color: #5a5959;
  margin-top: 11px;
  padding-bottom: 10px; }

.splash-footer {
  text-align: center; }

/*------------------------------------------------------------------
	[Scroll to top button]
*/
.be-scroll-top {
  cursor: pointer;
  position: fixed;
  bottom: 10px;
  right: 10px;
  color: #FFFFFF;
  background-color: rgba(0, 0, 0, 0.6);
  padding: 0 14px;
  display: none;
  transition: background-color .2s ease;
  border-radius: 4px;
  z-index: 1071; }
  @media print {
    .be-scroll-top {
      display: none !important; } }
  .be-scroll-top:hover {
    background-color: rgba(0, 0, 0, 0.8); }
  .be-scroll-top:hover, .be-scroll-top:focus {
    color: #FFFFFF; }
  .be-scroll-top:before {
    content: '\f2fc';
    font-family: "Material Icons";
    font-size: 2.538rem;
    display: inline-block;
    vertical-align: middle; }

/*------------------------------------------------------------------
  [Sub Navigation]
*/
.be-sub-navigation {
  background-color: #f5f5f5;
  border-color: #e3e3e3;
  border-width: 0;
  border-bottom: 1px solid #e6e5e5;
  padding: 0;
  margin-bottom: 18px; }
  @media (max-width: 767.98px) {
    .be-sub-navigation {
      background-color: #FFFFFF;
      border-bottom-width: 0;
      display: block; } }
  .be-sub-navigation .navbar-collapse {
    border-color: #e3e3e3;
    padding: 0;
    -ms-flex-pack: center;
        justify-content: center; }
    @media (max-width: 767.98px) {
      .be-sub-navigation .navbar-collapse {
        border-top: 1px solid #f5f5f5; } }
  .be-sub-navigation .navbar-nav {
    display: -ms-flexbox;
    display: flex;
    float: none;
    -ms-flex-pack: center;
        justify-content: center; }
    @media (max-width: 767.98px) {
      .be-sub-navigation .navbar-nav {
        width: 100%;
        -ms-flex-direction: column;
            flex-direction: column;
        margin: 0;
        padding: 15px 0;
        background-color: #fafafa; } }
    .be-sub-navigation .navbar-nav > li.nav-item > a.nav-link:hover {
      color: #333; }
    .be-sub-navigation .navbar-nav > li.nav-item.show {
      display: block; }
      .be-sub-navigation .navbar-nav > li.nav-item.show > a, .be-sub-navigation .navbar-nav > li.nav-item.show > a:focus {
        color: #333333; }
      .be-sub-navigation .navbar-nav > li.nav-item.show > a:hover {
        color: #4285f4; }
    .be-sub-navigation .navbar-nav > li.nav-item.dropdown .dropdown-menu {
      margin-top: 12px; }
      .be-sub-navigation .navbar-nav > li.nav-item.dropdown .dropdown-menu:after, .be-sub-navigation .navbar-nav > li.nav-item.dropdown .dropdown-menu:before {
        border-bottom: 8px solid white;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        top: -8px;
        content: "";
        display: inline-block;
        left: 42px;
        margin-left: -6px;
        position: absolute; }
      .be-sub-navigation .navbar-nav > li.nav-item.dropdown .dropdown-menu:before {
        border-bottom-width: 9px;
        border-left-width: 9px;
        border-right-width: 9px;
        border-bottom-color: #e3e3e3;
        margin-left: -7px;
        top: -9px; }
    .be-sub-navigation .navbar-nav > li.nav-item > a.nav-link {
      padding: 16px 15px;
      line-height: 18px;
      color: #777; }
      @media (max-width: 767.98px) {
        .be-sub-navigation .navbar-nav > li.nav-item > a.nav-link {
          font-size: 1.077rem;
          padding: 10px 20px;
          color: #646464; } }
      .be-sub-navigation .navbar-nav > li.nav-item > a.nav-link.dropdown-toggle .mdi-caret-down {
        font-size: 1.385rem;
        vertical-align: middle;
        margin-left: 3px;
        margin-top: -2px; }
        @media (max-width: 767.98px) {
          .be-sub-navigation .navbar-nav > li.nav-item > a.nav-link.dropdown-toggle .mdi-caret-down {
            color: #c5c5c5;
            float: right;
            line-height: 1.3; } }
      .be-sub-navigation .navbar-nav > li.nav-item > a.nav-link.dropdown-toggle:after {
        display: none; }
    @media (max-width: 767.98px) {
      .be-sub-navigation .navbar-nav > li.nav-item.dropdown .dropdown-menu {
        background-color: #eeeeee;
        padding: 10px 0;
        border-top: 1px solid #e6e6e6;
        border-right: 0;
        border-bottom: 1px solid #e6e6e6;
        border-left: 0;
        border-radius: 0;
        position: static;
        float: none;
        width: auto;
        box-shadow: none; }
        .be-sub-navigation .navbar-nav > li.nav-item.dropdown .dropdown-menu:before, .be-sub-navigation .navbar-nav > li.nav-item.dropdown .dropdown-menu:after {
          display: none; }
        .be-sub-navigation .navbar-nav > li.nav-item.dropdown .dropdown-menu > a {
          color: #646464;
          padding: 8px 15px 8px 49px;
          padding-left: 22px; } }

.be-sub-navigation-collapse {
  padding: 16px 10px;
  color: #404040;
  border-bottom: 1px solid #f5f5f5;
  display: none; }
  .be-sub-navigation-collapse.collapsed {
    border-bottom: 1px solid #e3e3e3;
    transition: border-bottom-color 0.4s ease; }
    .be-sub-navigation-collapse.collapsed:before {
      content: '\f2f2'; }
  .be-sub-navigation-collapse:before {
    content: '\f2f8';
    float: right;
    font-family: 'Material Icons';
    font-size: 2.077rem;
    line-height: 22px;
    color: #c5c5c5; }
  .be-sub-navigation-collapse:focus {
    color: #404040; }
  .be-sub-navigation-collapse:hover {
    color: #333333; }
  @media (max-width: 767.98px) {
    .be-sub-navigation-collapse {
      display: block;
      width: 100%;
      font-size: 1.077rem;
      font-weight: 300; } }

/*------------------------------------------------------------------
  [Forms]
*/
/*------------------------------------------------------------------
  [Switch button]
*/
.switch-button {
  display: inline-block;
  border-radius: 50px;
  background-color: #b3b2b2;
  width: 60px;
  height: 27px;
  padding: 4px;
  position: relative;
  overflow: hidden;
  vertical-align: middle; }
  .switch-button input[type="checkbox"] {
    display: none; }
  .switch-button label {
    border-radius: 50%;
    box-shadow: 0 0 1px 1px #FFF inset;
    background-color: #FFFFFF;
    margin: 0;
    height: 19px;
    width: 19px;
    z-index: 1;
    display: inline-block;
    cursor: pointer;
    background-clip: padding-box; }
    .switch-button label:before {
      position: absolute;
      font-size: .8462rem;
      font-weight: 600;
      z-index: 0;
      content: "OFF";
      right: 0;
      display: block;
      width: 100%;
      height: 100%;
      line-height: 27px;
      top: 0;
      text-align: right;
      padding-right: 10px;
      color: #FFFFFF; }
  .switch-button input[type="checkbox"]:checked + span {
    background-color: #4285f4;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 4px; }
    .switch-button input[type="checkbox"]:checked + span label {
      float: right;
      border-color: #2a75f3; }
      .switch-button input[type="checkbox"]:checked + span label:before {
        position: absolute;
        font-size: .8462rem;
        font-weight: 600;
        z-index: 0;
        content: "ON";
        color: #FFFFFF;
        left: 0;
        text-align: left;
        padding-left: 10px; }
  .switch-button.switch-button-yesno label:before {
    content: "NO"; }
  .switch-button.switch-button-yesno input[type="checkbox"]:checked + span label:before {
    content: "YES"; }
  .switch-button.switch-button-xs {
    height: 20px;
    width: 53px;
    line-height: 16px;
    width: 50px; }
    .switch-button.switch-button-xs label {
      height: 12px;
      width: 12px; }
      .switch-button.switch-button-xs label:before {
        line-height: 21px; }
  .switch-button.switch-button-sm {
    height: 24px;
    width: 57px;
    line-height: 20px; }
    .switch-button.switch-button-sm label {
      height: 16px;
      width: 16px; }
      .switch-button.switch-button-sm label:before {
        line-height: 25px; }
  .switch-button.switch-button-lg {
    height: 30px;
    width: 63px;
    line-height: 26px;
    width: 65px; }
    .switch-button.switch-button-lg label {
      height: 22px;
      width: 22px; }
      .switch-button.switch-button-lg label:before {
        line-height: 31px; }
  .switch-button.switch-button-success {
    background-color: #b3b2b2; }
    .switch-button.switch-button-success label {
      border-color: #ababab; }
    .switch-button.switch-button-success input[type="checkbox"]:checked + span {
      background-color: #34a853; }
      .switch-button.switch-button-success input[type="checkbox"]:checked + span label {
        border-color: #2e9549; }
  .switch-button.switch-button-warning {
    background-color: #b3b2b2; }
    .switch-button.switch-button-warning label {
      border-color: #ababab; }
    .switch-button.switch-button-warning input[type="checkbox"]:checked + span {
      background-color: #fbbc05; }
      .switch-button.switch-button-warning input[type="checkbox"]:checked + span label {
        border-color: #e3aa04; }
  .switch-button.switch-button-danger {
    background-color: #b3b2b2; }
    .switch-button.switch-button-danger label {
      border-color: #ababab; }
    .switch-button.switch-button-danger input[type="checkbox"]:checked + span {
      background-color: #ea4335; }
      .switch-button.switch-button-danger input[type="checkbox"]:checked + span label {
        border-color: #e82e1e; }

/*------------------------------------------------------------------
  [Elements]
*/
/*------------------------------------------------------------------
  [Widgets]
*/
.widget {
  background-color: #FFFFFF;
  padding: 20px;
  margin-bottom: 25px;
  border-radius: 3px; }
  .widget.widget-fullwidth {
    padding: 0; }
    .widget.widget-fullwidth .widget-head {
      padding: 20px 20px 10px;
      margin-bottom: 0; }
      @media (max-width: 575.98px) {
        .widget.widget-fullwidth .widget-head {
          padding-left: 15px;
          padding-left: 15px; } }
    .widget.widget-fullwidth .widget-chart-container .counter {
      left: 20px; }
    .widget.widget-fullwidth .chart-table table {
      margin-bottom: 0; }
  .widget.widget-small {
    margin-bottom: 20px; }
    .widget.widget-small.widget-fullwidth > .widget-head {
      padding: 20px 15px 10px; }
  .widget th {
    border-top: 0; }

.widget-head {
  margin-bottom: 20px;
  display: block;
  border-radius: 3px 3px 0 0; }
  .widget-head::after {
    display: block;
    clear: both;
    content: ""; }
  .widget-head .title {
    display: block;
    font-size: 1.385rem;
    font-weight: 300; }
  .widget-head .description {
    display: block;
    color: #9e9e9e;
    line-height: 22px;
    font-weight: 300; }
  .widget-head .tools {
    float: right;
    margin-left: 10px; }
    .widget-head .tools .icon {
      display: inline-block;
      font-size: 1.538rem;
      line-height: 25px;
      vertical-align: middle;
      cursor: pointer;
      color: #bababa;
      min-width: 1.538rem;
      text-align: center; }
      .widget-head .tools .icon + .icon {
        margin-left: 2px; }
      .widget-head .tools .icon:hover {
        color: #a6a6a6; }
    .widget-head .tools .value {
      font-size: 1.846rem;
      line-height: 22px; }
    .widget-head .tools .dropdown {
      display: inline-block; }
    .widget-head .tools .dropdown-menu {
      right: 0;
      left: auto; }
  .widget-head .button-toolbar {
    float: right; }
    @media (max-width: 767.98px) {
      .widget-head .button-toolbar .btn-group {
        margin-bottom: 5px; } }
    .widget-head .button-toolbar .btn-group + * {
      margin-left: 7px; }
    .widget-head .button-toolbar .dropdown-menu {
      right: 0;
      left: auto; }

.widget-chart-container {
  position: relative; }
  .widget-chart-container .counter {
    min-height: auto;
    top: 0;
    left: 0;
    position: absolute;
    z-index: 2; }
    .widget-chart-container .counter-inline {
      position: relative;
      display: inline-block; }
    .widget-chart-container .counter .value {
      font-size: 1.846rem; }
    .widget-chart-container .counter .desc {
      text-transform: uppercase;
      font-size: .8462rem;
      color: #9d9d9d; }
  .widget-chart-container .counter-big .value {
    display: block;
    font-size: 2.077rem;
    line-height: 2.077rem;
    font-weight: 300;
    margin-bottom: 5px; }
  .widget-chart-container .indicator-equal .icon {
    color: #4285f4; }
  .widget-chart-container .indicator-positive .icon {
    color: #34a853; }
  .widget-chart-container .indicator-negative .icon {
    color: #ea4335; }
  .widget-chart-container .indicator .icon {
    font-size: 1.923rem;
    margin-right: 7px;
    line-height: 24px;
    vertical-align: middle; }
  .widget-chart-container .indicator .number {
    font-size: 1.538rem;
    vertical-align: middle; }

.widget-chart-info::after {
  display: block;
  clear: both;
  content: ""; }

@media (max-width: 767.98px) {
  .widget-chart-info + .widget-counter-group {
    margin-top: 15px; } }

.widget-fullwidth .widget-chart-info {
  padding: 9px 20px 0; }

.widget-counter-group {
  padding: 0 20px 10px; }
  .widget-counter-group-right {
    text-align: right; }
  .widget-counter-group .counter {
    position: static;
    display: inline-block; }
    .widget-counter-group .counter + .counter {
      margin-left: 20px; }

/*------------------------------------------------------------------
  [Charts]
*/
.chart-legend table {
  width: 100%; }
  .chart-legend table tr td {
    padding: 6px 0;
    vertical-align: middle; }

.chart-legend-color > span {
  display: block;
  background-color: #f2f2f2;
  height: 10px;
  width: 10px; }

.chart-legend-value {
  text-align: right; }

.chart-legend-horizontal {
  margin: 0;
  padding: 0;
  list-style: none;
  line-height: 14px; }
  .chart-legend-horizontal > li {
    display: inline-block; }
    .chart-legend-horizontal > li + li {
      margin-left: 14px; }
    .chart-legend-horizontal > li > span {
      display: inline-block;
      height: 10px;
      width: 10px;
      border-radius: 50%;
      background-color: #f2f2f2;
      margin-right: 2px; }

.chart-pie-counter {
  diplay: block;
  position: absolute;
  top: 50%;
  width: 100%;
  text-align: center;
  line-height: 3.846rem;
  margin-top: -1.923rem;
  font-size: 3.846rem;
  font-weight: 300; }

/*------------------------------------------------------------------
  [Colors]
*/
.clr-primary {
  background-color: #4285f4; }

.clr-success {
  background-color: #34a853; }

.clr-warning {
  background-color: #fbbc05; }

.clr-danger {
  background-color: #ea4335; }

.clr-grey {
  background-color: #cccccc; }

/*------------------------------------------------------------------
  [Grid]
*/
.bs-grid-block {
  border: 2px dashed #c3c3c3;
  font-weight: 300;
  font-size: 1.538rem;
  color: #807e7e;
  display: table;
  table-layout: fixed;
  width: 100%;
  margin-bottom: 40px; }
  .bs-grid-block .content {
    display: table-cell;
    height: 177px;
    text-align: center;
    vertical-align: middle; }
    .bs-grid-block .content .size {
      display: block; }
    .bs-grid-block .content .device {
      line-height: 0; }
      .bs-grid-block .content .device:after {
        font-size: 1.154rem;
        content: 'Large Desktop';
        color: #4285f4; }
        @media (max-width: 1199.98px) {
          .bs-grid-block .content .device:after {
            content: 'Desktop';
            color: #4285f4; } }
        @media (max-width: 991.98px) {
          .bs-grid-block .content .device:after {
            content: 'Laptop';
            color: #4285f4; } }
        @media (max-width: 767.98px) {
          .bs-grid-block .content .device:after {
            content: 'Tablet';
            color: #34a853; } }
        @media (max-width: 575.98px) {
          .bs-grid-block .content .device:after {
            content: 'Phone';
            color: #34a853; } }

/*------------------------------------------------------------------
  [Icons]
*/
.icon-container {
  background-color: #FFFFFF;
  padding: 20px;
  cursor: default; }
  .icon-container .icon {
    transition: background-color 300ms ease-in-out;
    background-color: whitesmoke;
    border-radius: 50%;
    display: inline-block;
    height: 50px;
    width: 50px;
    margin-right: 8px;
    padding: 12px;
    padding: 13px;
    text-align: center;
    vertical-align: middle; }
    .icon-container .icon span {
      transition: color 300ms ease-in-out;
      font-size: 1.923rem;
      color: #696767; }
    .icon-container .icon:hover {
      background-color: #4285f4; }
      .icon-container .icon:hover span {
        color: #FFFFFF; }
  .icon-container .icon-class {
    transition: color 300ms ease-in-out;
    font-size: 1.385rem;
    font-weight: 300;
    vertical-align: middle;
    color: #696767; }
  .icon-container:hover .icon {
    background-color: #4285f4; }
    .icon-container:hover .icon span {
      color: #FFFFFF; }
  .icon-container:hover .icon-class {
    color: #4285f4; }

.be-icons-list.hide-icons .icon-category .row > div {
  display: none; }
  .be-icons-list.hide-icons .icon-category .row > div.icon-visible {
    display: block; }

/*------------------------------------------------------------------
  Loader
*/
.be-loading {
  position: relative; }
  .be-loading:after {
    position: absolute;
    display: block;
    visibility: hidden;
    opacity: 0;
    content: "";
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(255, 255, 255, 0.7);
    transition: all .2s ease;
    z-index: 2; }
  .be-loading .be-spinner {
    display: none; }
  .be-loading-active:after {
    visibility: visible;
    opacity: 1; }
  .be-loading-active .be-spinner {
    display: block; }

.be-loading .be-spinner {
  position: absolute;
  top: 50%;
  right: 50%;
  margin-top: -20px;
  margin-right: -20px;
  z-index: 3; }

.be-spinner svg {
  -webkit-animation: be-spinner-rotation 1.4s linear infinite;
          animation: be-spinner-rotation 1.4s linear infinite;
  stroke: #4285f4; }
  .be-spinner svg .circle {
    stroke-dasharray: 187;
    stroke-dashoffset: 0;
    -webkit-transform-origin: center;
            transform-origin: center;
    -webkit-animation: be-spinner-turn 1.4s ease-in-out infinite;
            animation: be-spinner-turn 1.4s ease-in-out infinite; }

@-webkit-keyframes be-spinner-rotation {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(270deg);
            transform: rotate(270deg); } }

@keyframes be-spinner-rotation {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(270deg);
            transform: rotate(270deg); } }

@-webkit-keyframes be-spinner-turn {
  0% {
    stroke-dashoffset: 187; }
  50% {
    stroke-dashoffset: 46.75;
    -webkit-transform: rotate(135deg);
            transform: rotate(135deg); }
  100% {
    stroke-dashoffset: 187;
    -webkit-transform: rotate(450deg);
            transform: rotate(450deg); } }

@keyframes be-spinner-turn {
  0% {
    stroke-dashoffset: 187; }
  50% {
    stroke-dashoffset: 46.75;
    -webkit-transform: rotate(135deg);
            transform: rotate(135deg); }
  100% {
    stroke-dashoffset: 187;
    -webkit-transform: rotate(450deg);
            transform: rotate(450deg); } }

/*------------------------------------------------------------------
  [Code]
*/
/* Pretty printing styles. Used with prettify.js. */
.prettyprint {
  font-size: .9231rem;
  line-height: 1.5; }

/* Light Theme*/
pre {
  color: #555555; }
  pre .pln {
    color: #777777; }
  pre .str {
    color: #DD1144; }
  pre .kwd {
    color: #445588; }
  pre .com {
    color: #b8b6b1; }
  pre .typ {
    color: #008080; }
  pre .lit {
    color: #DD1144; }
  pre .pun,
  pre .opn,
  pre .clo {
    color: #949494; }
  pre .tag {
    color: #4285F4; }
  pre .atn {
    color: #ef6e62; }
  pre .atv {
    color: #697ce8; }
  pre .dec,
  pre .var {
    color: #DD1144; }
  pre .fun {
    color: #445588; }

/*Dark Theme*/
pre.be-code-dark {
  background-color: #4d4342;
  color: #FFFFFF; }
  pre.be-code-dark .pln {
    color: #ffffff; }
  pre.be-code-dark .str {
    color: #b5db99; }
  pre.be-code-dark .kwd {
    color: #e9c865; }
  pre.be-code-dark .com {
    color: #c3b8b7; }
  pre.be-code-dark .typ {
    color: #FF9787; }
  pre.be-code-dark .lit {
    color: #b5db99; }
  pre.be-code-dark .pun,
  pre.be-code-dark .opn,
  pre.be-code-dark .clo {
    color: #ffffff; }
  pre.be-code-dark .tag {
    color: #e9c865; }
  pre.be-code-dark .atn {
    color: #FF9787; }
  pre.be-code-dark .atv {
    color: #b5db99; }
  pre.be-code-dark .dec,
  pre.be-code-dark .var {
    color: #b5db99; }
  pre.be-code-dark .fun {
    color: #e9c865; }

/* Specify class=linenums on a pre to get line numbering */
ol.linenums {
  margin-top: 0;
  margin-bottom: 0; }

/* IE indents via margin-left */
li.L0,
li.L1,
li.L2,
li.L3,
li.L5,
li.L6,
li.L7,
li.L8 {
  list-style-type: none; }

/* Alternate shading for lines */
li.L1,
li.L3,
li.L5,
li.L7,
li.L9 {
  background: #eee; }

/*------------------------------------------------------------------
  [Dividers inside panels]
*/
.card-divider {
  margin: 10px 0;
  border-top: 1px solid #f2f2f2; }
  .card-divider-lg {
    margin: 15px 0; }
  .card-divider-xl {
    margin: 20px 0; }

/*------------------------------------------------------------------
  [Pricing tables]
*/
.pricing-tables {
  margin: 30px 0 20px; }
  .pricing-tables > div {
    padding-left: 10px;
    padding-right: 10px; }

.pricing-table {
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.04);
  border-radius: 3px;
  border-top: 3px solid #c9c9c9;
  padding: 35px 20px;
  background: #FFFFFF;
  text-align: center;
  margin: 0 0 20px; }
  .pricing-table-image {
    margin-bottom: 15px; }
    .pricing-table-image svg path {
      fill: #a6a6a6; }
  .pricing-table-title {
    font-size: 1.538rem;
    font-weight: 300; }
  .pricing-table-price {
    padding: 5px 0 20px; }
    .pricing-table-price .currency {
      vertical-align: top; }
    .pricing-table-price .value {
      font-size: 35px;
      line-height: 40px; }
    .pricing-table-price .frecuency {
      font-size: 1.538rem;
      font-weight: 300; }
  .pricing-table-features {
    margin: 0 0 25px;
    padding: 0;
    list-style: none; }
    .pricing-table-features > li {
      font-size: 1.077rem;
      font-weight: 300;
      line-height: 33px; }
  .pricing-table .btn {
    line-height: 36px;
    min-width: 120px; }

.pricing-table-primary {
  border-color: #4285f4; }
  .pricing-table-primary .pricing-table-image svg path {
    fill: #4285f4; }
  .pricing-table-primary.pricing-table-color {
    background-color: #5f99f5;
    border-color: #5f99f5; }
    .pricing-table-primary.pricing-table-color .card-divider {
      border-top-color: #3d84f3; }

.pricing-table-warning {
  border-color: #fbbc05; }
  .pricing-table-warning .pricing-table-image svg path {
    fill: #fbbc05; }
  .pricing-table-warning.pricing-table-color {
    background-color: #f6c163;
    border-color: #f6c163; }
    .pricing-table-warning.pricing-table-color .card-divider {
      border-top-color: #f4b441; }

.pricing-table-success {
  border-color: #34a853; }
  .pricing-table-success .pricing-table-image svg path {
    fill: #34a853; }
  .pricing-table-success.pricing-table-color {
    background-color: #37b358;
    border-color: #37b358; }
    .pricing-table-success.pricing-table-color .card-divider {
      border-top-color: #2f984b; }

.pricing-table-danger {
  border-color: #ea4335; }
  .pricing-table-danger .pricing-table-image svg path {
    fill: #ea4335; }
  .pricing-table-danger.pricing-table-color {
    background-color: #eb6357;
    border-color: #eb6357; }
    .pricing-table-danger.pricing-table-color .card-divider {
      border-top-color: #e74537; }

.pricing-table-color {
  color: #FFFFFF; }
  .pricing-table-color .pricing-table-image svg path {
    fill: #FFFFFF; }
  .pricing-table-color .card-divider {
    border-top-color: #FFFFFF; }

.be-option-buttons {
  display: -ms-flexbox;
  display: flex;
  list-style: none;
  padding: 0;
  -ms-flex-pack: distribute;
      justify-content: space-around; }

.be-option-button {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  text-align: center;
  color: #404040;
  padding: 12px 21px;
  border-radius: 3px;
  min-width: 107px; }
  .be-option-button:hover {
    background-color: #4285f4;
    color: #FFFFFF; }
  .be-option-button .icon {
    font-size: 1.615rem;
    font-weight: 400; }

/*------------------------------------------------------------------
  [Timeline]
*/
.timeline {
  margin: 0 auto 40px;
  padding: 0;
  padding-bottom: 20px;
  max-width: 840px;
  position: relative; }
  @media (max-width: 767.98px) {
    .timeline {
      padding: 0; } }
  .timeline:before {
    content: '';
    position: absolute;
    top: 40px;
    bottom: 0;
    width: 2px;
    background: #dbdbdb;
    left: 170px;
    margin-left: -1px; }
    @media (max-width: 767.98px) {
      .timeline:before {
        left: 50%;
        margin-left: -1px;
        top: -35px; } }

.timeline-item {
  position: relative;
  padding-left: 210px;
  list-style: none;
  margin-bottom: 30px; }
  @media (max-width: 767.98px) {
    .timeline-item {
      padding-left: 0;
      margin-top: 60px;
      margin-bottom: 0; } }
  .timeline-item:before {
    content: '';
    position: absolute;
    top: 34.5px;
    left: 162px;
    border: 2px solid #cccccc;
    width: 16px;
    height: 16px;
    background-color: #ededed;
    border-radius: 50px; }
    @media (max-width: 767.98px) {
      .timeline-item:before {
        display: none; } }
  .timeline-item:nth-child(4n+1):before {
    border-color: #4285f4; }
  .timeline-item:nth-child(4n+2):before {
    border-color: #fbbc05; }
  .timeline-item:nth-child(4n+3):before {
    border-color: #34a853; }
  .timeline-item:nth-child(4n+4):before {
    border-color: #ea4335; }
  .timeline-variant .timeline-item {
    padding-left: 0;
    margin: 30px 0 0; }
    @media (max-width: 767.98px) {
      .timeline-variant .timeline-item {
        margin-top: 60px; } }
    .timeline-variant .timeline-item.right {
      right: 0;
      padding-left: calc(50% + 45px); }
      @media (max-width: 767.98px) {
        .timeline-variant .timeline-item.right {
          padding: 0; } }
    .timeline-variant .timeline-item.left {
      left: 0;
      padding-right: calc(50% + 45px); }
      @media (max-width: 767.98px) {
        .timeline-variant .timeline-item.left {
          padding: 0; } }
      .timeline-variant .timeline-item.left .timeline-content:after {
        right: -5px;
        left: inherit; }
      .timeline-variant .timeline-item.left .timeline-content:before {
        right: -5px;
        left: inherit; }
      .timeline-variant .timeline-item.left .timeline-icon {
        left: initial;
        right: -67px; }
        @media (max-width: 767.98px) {
          .timeline-variant .timeline-item.left .timeline-icon {
            left: 50%;
            margin-left: -18px;
            top: -48px; } }
    .timeline-variant .timeline-item:before {
      display: none; }

.timeline-date {
  position: absolute;
  display: block;
  left: 0;
  top: 34px;
  width: 145px;
  text-align: right;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }
  @media (max-width: 767.98px) {
    .timeline-date {
      width: 100%;
      left: 0;
      top: -40px;
      text-align: center; }
      .timeline-date span {
        background-color: #e0e0e0;
        padding: 5px 8px;
        border-radius: 4px;
        line-height: 25px; } }

.timeline-content {
  position: relative;
  background-color: #FFFFFF;
  padding: 25px 30px 25px 28px;
  border-radius: 3px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.07);
  min-height: 85px; }
  .timeline-item-gallery .timeline-content {
    padding-bottom: 15px; }
  @media (max-width: 575.98px) {
    .timeline-content {
      padding: 15px 18px; } }
  .timeline-content:after {
    content: "";
    position: absolute;
    width: 10px;
    height: 10px;
    background-color: transparent;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    top: 37.5px;
    left: -5px;
    box-shadow: -1px 1px 5px rgba(0, 0, 0, 0.07);
    z-index: -1; }
    @media (max-width: 767.98px) {
      .timeline-content:after {
        display: none; } }
  .timeline-content:before {
    content: "";
    position: absolute;
    width: 10px;
    height: 10px;
    background-color: #fff;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    top: 37.5px;
    left: -5px; }
    @media (max-width: 767.98px) {
      .timeline-content:before {
        display: none; } }

.timeline-avatar {
  width: 35px;
  height: 35px;
  display: block;
  border-radius: 50px;
  overflow: hidden;
  vertical-align: middle;
  float: left; }
  .timeline-avatar img {
    width: 100%;
    height: 100%; }

.timeline-header {
  display: block;
  padding: 7px 0 0 46px; }
  @media (max-width: 767.98px) {
    .timeline-header {
      padding-top: 0; } }
  .timeline-variant .timeline-header {
    padding-top: 0;
    line-height: 16px; }

.timeline-autor {
  display: inline-block;
  font-weight: 700; }

.timeline-activity {
  display: inline-block;
  margin: 0; }
  .timeline-variant .timeline-activity {
    display: inline;
    line-height: 16px; }

.timeline-time {
  float: right;
  color: #ababab; }
  .timeline-variant .timeline-time {
    float: none;
    display: block;
    line-height: 21px; }

.timeline-summary {
  color: #8a8a8a;
  margin-top: 4px; }
  .timeline-summary p {
    margin-bottom: 0; }
  .timeline-variant .timeline-summary {
    margin-top: 20px; }
    .timeline-variant .timeline-summary p {
      line-height: 16px; }

.timeline-gallery {
  margin-top: 12px; }
  .timeline-variant .timeline-gallery {
    margin-top: 20px; }
  .timeline-gallery .gallery-thumbnail {
    display: inline-block;
    max-width: 100px;
    margin-right: 10px;
    margin-bottom: 10px; }

.timeline-blockquote {
  margin: 10px 0 0 5px;
  padding: 4px 10px;
  border-left: 4px solid #e0e0e0;
  color: #8a8a8a; }
  .timeline-variant .timeline-blockquote {
    margin-top: 20px; }
  .timeline-blockquote p {
    font-size: 1rem; }
  .timeline-blockquote footer {
    color: #8a8a8a; }

/*Load more button*/
@media (max-width: 767.98px) {
  .timeline-loadmore {
    text-align: center;
    padding: 0;
    margin-bottom: 40px;
    margin-top: 40px; } }

.timeline-loadmore .load-more-btn {
  background-color: #dbdbdb;
  border-radius: 2px;
  padding: 5px 9px;
  color: #424242;
  outline: none;
  font-size: 0.92308rem;
  font-weight: 500;
  position: absolute;
  left: 131px; }
  @media (max-width: 767.98px) {
    .timeline-loadmore .load-more-btn {
      position: relative;
      left: 0; } }

.timeline-loadmore:before {
  display: none; }

.timeline-variant .timeline-loadmore {
  text-align: center;
  margin-bottom: 40px; }
  .timeline-variant .timeline-loadmore .load-more-btn {
    position: relative;
    left: initial; }

/*------------------------------------------------------------------
  [Detailed items content like gallery & blockquote]
*/
.timeline-item-detailed .timeline-time {
  line-height: 21px; }

.timeline-item-detailed .timeline-header {
  line-height: 21px; }
  .timeline-variant .timeline-item-detailed .timeline-header {
    border-bottom: 1px solid #EEEEEE;
    padding-bottom: 15px; }

.timeline-item-detailed .timeline-avatar {
  vertical-align: top; }

.timeline-variant {
  max-width: none;
  padding-bottom: 0; }
  .timeline-variant:before {
    left: 50%;
    z-index: -1;
    top: 70px; }

.timeline-month {
  width: 100%;
  text-align: center;
  list-style: none;
  margin: 30px 0 15px;
  padding: 11px 0 12px;
  font-size: 1.385rem;
  background-color: #EEEEEE;
  font-weight: 300; }
  .timeline-month:first-child {
    margin-top: 0;
    padding-bottom: 5px; }

.timeline-icon {
  position: absolute;
  color: #FFFFFF;
  top: 20px;
  left: -67px;
  width: 44px;
  height: 44px;
  font-size: 1.538rem;
  border: 2px solid #FFFFFF;
  text-align: center;
  background-color: #ededed;
  border-radius: 50%; }
  @media (max-width: 767.98px) {
    .timeline-icon {
      left: 50%;
      margin-left: -18px;
      top: -48px;
      width: 38px;
      height: 38px;
      font-size: 1.385rem; } }
  .timeline-icon > .icon {
    line-height: 40px;
    vertical-align: middle; }
    @media (max-width: 767.98px) {
      .timeline-icon > .icon {
        line-height: 34px; } }
  .file .timeline-icon {
    background-color: #4285f4; }
  .comment .timeline-icon {
    background-color: #fbbc05; }
  .gallery .timeline-icon {
    background-color: #34a853; }
  .quote .timeline-icon {
    background-color: #ea4335; }

.table-filters-container {
  margin: 0;
  border-bottom: 1px solid #e6e5e5; }
  .table-filters-container .custom-checkbox {
    white-space: nowrap; }

.table-filters {
  padding: 25px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column; }

.table-filter-title {
  -ms-flex: 0 0 auto;
      flex: 0 0 auto;
  margin-bottom: 20px;
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 700;
  color: #787878; }

#slider-value {
  font-size: 1.462rem;
  font-weight: 400; }

.filter-container {
  padding: 5px 0 15px; }

/*------------------------------------------------------------------
  [Invoice]
*/
.invoice {
  background-color: #FFFFFF;
  padding: 100px 100px 70px;
  color: #8c8c8c; }
  @media (max-width: 991.98px) {
    .invoice {
      padding: 50px 50px 35px; } }
  @media (max-width: 575.98px) {
    .invoice {
      padding: 35px 25px 15px; } }

.invoice-header {
  margin-bottom: 100px; }
  @media (max-width: 991.98px) {
    .invoice-header {
      margin-bottom: 50px; } }
  @media (max-width: 575.98px) {
    .invoice-header > div {
      float: none;
      width: 100%; } }

.invoice-logo {
  height: 54px;
  min-width: 204px;
  background-image: url("./../../../vendor/beagle-v1.4.2/BS4/dist/html/assets/img/logo-xx.png");
  background-repeat: no-repeat;
  background-position: 0; }
  @media (max-width: 991.98px) {
    .invoice-logo {
      height: 27px;
      width: 102px;
      background-image: url("./../../../vendor/beagle-v1.4.2/BS4/dist/html/assets/img/logo.png"); } }
  @media (max-width: 575.98px) {
    .invoice-logo {
      margin: 0 auto 20px;
      float: none;
      min-width: auto; } }
  @media print {
    .invoice-logo {
      height: 27px !important;
      width: 102px !important;
      background-image: url("./../../../vendor/beagle-v1.4.2/BS4/dist/html/assets/img/logo.png") !important;
      display: block !important;
      background-repeat: no-repeat !important; } }

.invoice-order {
  text-align: right; }
  @media (max-width: 575.98px) {
    .invoice-order {
      text-align: center; } }

.invoice-id {
  display: block;
  font-size: 2.308rem;
  line-height: 2.308rem; }
  @media (max-width: 991.98px) {
    .invoice-id {
      font-size: 1.154rem;
      line-height: 1.3849rem; } }

.incoice-date {
  display: block;
  font-size: 1.385rem; }
  @media (max-width: 991.98px) {
    .incoice-date {
      font-size: 1rem; } }

.invoice-data {
  margin-bottom: 110px; }
  @media (max-width: 991.98px) {
    .invoice-data {
      margin-bottom: 55px; } }
  @media (max-width: 575.98px) {
    .invoice-data > div {
      float: none;
      width: 100%; } }

@media (max-width: 575.98px) {
  .invoice-person {
    text-align: center; } }

.invoice-person span {
  font-size: 1.385rem;
  line-height: 2rem;
  display: block; }
  @media (max-width: 991.98px) {
    .invoice-person span {
      font-size: 1.0004rem;
      line-height: 1.5385rem; } }

.invoice-person .name {
  font-weight: 500; }

.invoice-person:last-child {
  text-align: right; }
  @media (max-width: 575.98px) {
    .invoice-person:last-child {
      text-align: center; } }

.invoice-payment-direction {
  text-align: center;
  position: relative;
  padding-top: 20px; }
  @media (max-width: 575.98px) {
    .invoice-payment-direction {
      padding: 20px; } }
  .invoice-payment-direction .icon {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background-color: #f7f7f7;
    font-size: 3.846rem;
    color: #c2c2c2;
    line-height: 80px; }
    @media (max-width: 991.98px) {
      .invoice-payment-direction .icon {
        width: 40px;
        height: 40px;
        line-height: 40px;
        font-size: 1.923rem; } }
    @media (max-width: 575.98px) {
      .invoice-payment-direction .icon {
        -webkit-transform: rotate(90deg);
                transform: rotate(90deg); } }
    @media print {
      .invoice-payment-direction .icon {
        background-color: #f7f7f7 !important;
        color: #c2c2c2 !important; } }

.invoice-details {
  width: 100%;
  font-size: 1.231rem; }
  @media (max-width: 991.98px) {
    .invoice-details {
      font-size: 0.8464rem; } }
  .invoice-details tr > td {
    padding: 20px 0; }
    @media (max-width: 991.98px) {
      .invoice-details tr > td {
        padding: 15px 0; } }
  .invoice-details th {
    text-align: right;
    padding-bottom: 8px;
    border-bottom: 1px solid #f0f0f0; }
    .invoice-details th:first-child {
      text-align: left; }
  .invoice-details td.description, .invoice-details td.hours, .invoice-details td.amount, .invoice-details td.summary {
    border-bottom: 1px solid #f0f0f0; }
  .invoice-details td.hours {
    text-align: right; }
  .invoice-details td.amount {
    text-align: right; }
  .invoice-details td.summary {
    color: #c4c4c4; }
  .invoice-details td.total {
    color: #8c8c8c;
    font-weight: 500; }
  .invoice-details td.total-value {
    font-size: 1.692rem;
    color: #4285f4; }
    @media (max-width: 991.98px) {
      .invoice-details td.total-value {
        font-size: 0.8464rem; } }

.invoice-payment-method {
  margin-bottom: 75px; }
  @media (max-width: 991.98px) {
    .invoice-payment-method {
      margin-bottom: 37px; } }
  .invoice-payment-method span {
    font-size: 1.385rem;
    line-height: 2rem;
    display: block; }
    @media (max-width: 991.98px) {
      .invoice-payment-method span {
        font-size: 1.0004rem;
        line-height: 1.5385rem; } }
  .invoice-payment-method .title {
    font-weight: 500; }

.invoice-message {
  font-size: 1.231rem;
  margin-bottom: 62px; }
  @media (max-width: 991.98px) {
    .invoice-message {
      font-size: 1rem; } }
  @media (max-width: 575.98px) {
    .invoice-message {
      margin-bottom: 31px; } }
  .invoice-message .title {
    font-weight: 500;
    text-transform: uppercase;
    display: block;
    margin-bottom: 12px; }
  .invoice-message p {
    line-height: 2rem; }
    @media (max-width: 991.98px) {
      .invoice-message p {
        line-height: 1.5385rem; } }

.invoice-company-info {
  margin-bottom: 70px; }
  @media (max-width: 767.98px) {
    .invoice-company-info {
      margin-bottom: 35px; } }
  .invoice-company-info > div {
    border-left: 1px solid #f0f0f0;
    padding: 8px 40px; }
    @media (max-width: 767.98px) {
      .invoice-company-info > div {
        border: none;
        padding: 8px 15px; } }
    .invoice-company-info > div:first-child {
      border-left: none; }
  .invoice-company-info .summary {
    padding: 0 40px 0;
    line-height: 16px; }
    @media (max-width: 767.98px) {
      .invoice-company-info .summary {
        padding: 8px 15px; } }
    .invoice-company-info .summary .title {
      color: #8c8c8c;
      font-size: 1.077rem;
      line-height: 21px;
      font-weight: 500; }
    .invoice-company-info .summary p {
      line-height: 16px; }
  @media (max-width: 991.98px) {
    .invoice-company-info .phone {
      border-left-width: 0; } }

.invoice-footer {
  text-align: center; }
  .invoice-footer .btn {
    min-width: 96px;
    font-size: 1.077rem; }
  @media print {
    .invoice-footer {
      display: none !important; } }

/*------------------------------------------------------------------
  [Bootstrap elements]
*/
/*------------------------------------------------------------------
  [Bootstrap grid]
*/
@media (max-width: 575.98px) {
  .row {
    margin-left: -10px;
    margin-right: -10px; } }

/*------------------------------------------------------------------
  [Images]
*/
.img-rounded {
  border-radius: 4px; }

/*------------------------------------------------------------------
  [Code]
*/
pre {
  padding: 20px;
  border: 0;
  border-radius: 0;
  background-color: #fafafa;
  font-size: .9231rem;
  margin: 0 0 9px; }
  pre code {
    white-space: pre-wrap; }

/*------------------------------------------------------------------
  [Buttons]
*/
.btn {
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.05);
  border: 1px solid transparent;
  padding: 0 10px;
  font-size: 1rem;
  line-height: 28px;
  border-radius: 2px; }
  .btn:active, .btn.active {
    box-shadow: none; }
  .btn:active:focus {
    outline: none; }

.btn-secondary, .btn-light {
  color: #404040;
  background-color: #FFFFFF;
  border-color: #dbdbdb; }
  .btn-secondary:focus, .btn-secondary.focus, .btn-light:focus, .btn-light.focus {
    color: #404040;
    background-color: #FFFFFF;
    border-color: #dbdbdb;
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.05); }
  .btn-secondary:hover, .btn-secondary.hover, .btn-light:hover, .btn-light.hover {
    color: #404040;
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);
    background-color: #FFFFFF;
    border-color: #bababa; }
  .btn-secondary:active, .btn-secondary.active, .btn-secondary:not([disabled]):not(.disabled):active, .btn-secondary:not([disabled]):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle, .btn-light:active, .btn-light.active, .btn-light:not([disabled]):not(.disabled):active, .btn-light:not([disabled]):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    box-shadow: inset 0 2px 0 rgba(0, 0, 0, 0.06);
    color: #272626;
    background-color: #e3e3e3;
    border-color: #bababa; }
    .btn-secondary:active:hover, .btn-secondary:active:focus, .btn-secondary:active.focus, .btn-secondary.active:hover, .btn-secondary.active:focus, .btn-secondary.active.focus, .btn-secondary:not([disabled]):not(.disabled):active:hover, .btn-secondary:not([disabled]):not(.disabled):active:focus, .btn-secondary:not([disabled]):not(.disabled):active.focus, .btn-secondary:not([disabled]):not(.disabled).active:hover, .btn-secondary:not([disabled]):not(.disabled).active:focus, .btn-secondary:not([disabled]):not(.disabled).active.focus,
    .show > .btn-secondary.dropdown-toggle:hover,
    .show > .btn-secondary.dropdown-toggle:focus,
    .show > .btn-secondary.dropdown-toggle.focus, .btn-light:active:hover, .btn-light:active:focus, .btn-light:active.focus, .btn-light.active:hover, .btn-light.active:focus, .btn-light.active.focus, .btn-light:not([disabled]):not(.disabled):active:hover, .btn-light:not([disabled]):not(.disabled):active:focus, .btn-light:not([disabled]):not(.disabled):active.focus, .btn-light:not([disabled]):not(.disabled).active:hover, .btn-light:not([disabled]):not(.disabled).active:focus, .btn-light:not([disabled]):not(.disabled).active.focus,
    .show > .btn-light.dropdown-toggle:hover,
    .show > .btn-light.dropdown-toggle:focus,
    .show > .btn-light.dropdown-toggle.focus {
      color: #272626;
      background-color: #e3e3e3;
      border-color: #bababa;
      box-shadow: inset 0 2px 0 rgba(0, 0, 0, 0.06); }
  .btn-secondary:active, .btn-secondary.active,
  .show > .btn-secondary.dropdown-toggle, .btn-light:active, .btn-light.active,
  .show > .btn-light.dropdown-toggle {
    background-image: none; }
  .btn-secondary.disabled, .btn-secondary.disabled:hover, .btn-secondary.disabled:focus, .btn-secondary.disabled.focus, .btn-secondary.disabled:active, .btn-secondary.disabled.active, .btn-secondary[disabled], .btn-secondary[disabled]:hover, .btn-secondary[disabled]:focus, .btn-secondary[disabled].focus, .btn-secondary[disabled]:active, .btn-secondary[disabled].active,
  fieldset[disabled] .btn-secondary,
  fieldset[disabled] .btn-secondary:hover,
  fieldset[disabled] .btn-secondary:focus,
  fieldset[disabled] .btn-secondary.focus,
  fieldset[disabled] .btn-secondary:active,
  fieldset[disabled] .btn-secondary.active, .btn-light.disabled, .btn-light.disabled:hover, .btn-light.disabled:focus, .btn-light.disabled.focus, .btn-light.disabled:active, .btn-light.disabled.active, .btn-light[disabled], .btn-light[disabled]:hover, .btn-light[disabled]:focus, .btn-light[disabled].focus, .btn-light[disabled]:active, .btn-light[disabled].active,
  fieldset[disabled] .btn-light,
  fieldset[disabled] .btn-light:hover,
  fieldset[disabled] .btn-light:focus,
  fieldset[disabled] .btn-light.focus,
  fieldset[disabled] .btn-light:active,
  fieldset[disabled] .btn-light.active {
    background-color: #FFFFFF;
    border-color: #dbdbdb; }
  .btn-secondary .icon, .btn-light .icon {
    color: #646464; }
  .btn-secondary:active .icon, .btn-light:active .icon {
    color: #4a4a4a; }
  .btn-secondary .badge, .btn-light .badge {
    color: #FFFFFF;
    background-color: #404040; }

.btn-primary {
  color: #FFFFFF;
  background-color: #4285f4;
  border-color: #4285f4; }
  .btn-primary:focus, .btn-primary.focus {
    color: #FFFFFF;
    background-color: #4285f4;
    border-color: #4285f4;
    outline: none; }
  .btn-primary:hover, .btn-primary.hover {
    color: #FFFFFF;
    box-shadow: inset 0 -1px 0 #1266f1;
    background-color: #4c8bf5;
    border-color: #4c8bf5;
    border-bottom-color: #1266f1; }
  .btn-primary:active, .btn-primary.active, .btn-primary:not([disabled]):not(.disabled):active, .btn-primary:not([disabled]):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #FFFFFF;
    box-shadow: inset 0 2px 0 #1266f1;
    background-color: #2572f2;
    border-color: #2572f2;
    border-top-color: #0c57d3; }
    .btn-primary:active:hover, .btn-primary:active:focus, .btn-primary:active.focus, .btn-primary.active:hover, .btn-primary.active:focus, .btn-primary.active.focus, .btn-primary:not([disabled]):not(.disabled):active:hover, .btn-primary:not([disabled]):not(.disabled):active:focus, .btn-primary:not([disabled]):not(.disabled):active.focus, .btn-primary:not([disabled]):not(.disabled).active:hover, .btn-primary:not([disabled]):not(.disabled).active:focus, .btn-primary:not([disabled]):not(.disabled).active.focus,
    .show > .btn-primary.dropdown-toggle:hover,
    .show > .btn-primary.dropdown-toggle:focus,
    .show > .btn-primary.dropdown-toggle.focus {
      color: #FFFFFF;
      background-color: #2572f2;
      border-color: transparent;
      border-top-color: #0c57d3;
      box-shadow: inset 0 2px 0 #1266f1; }
      .btn-primary:active:hover .icon, .btn-primary:active:focus .icon, .btn-primary:active.focus .icon, .btn-primary.active:hover .icon, .btn-primary.active:focus .icon, .btn-primary.active.focus .icon, .btn-primary:not([disabled]):not(.disabled):active:hover .icon, .btn-primary:not([disabled]):not(.disabled):active:focus .icon, .btn-primary:not([disabled]):not(.disabled):active.focus .icon, .btn-primary:not([disabled]):not(.disabled).active:hover .icon, .btn-primary:not([disabled]):not(.disabled).active:focus .icon, .btn-primary:not([disabled]):not(.disabled).active.focus .icon,
      .show > .btn-primary.dropdown-toggle:hover .icon,
      .show > .btn-primary.dropdown-toggle:focus .icon,
      .show > .btn-primary.dropdown-toggle.focus .icon {
        color: #FFFFFF; }
  .btn-primary:active, .btn-primary.active, .btn-primary:not([disabled]):not(.disabled):active, .btn-primary:not([disabled]):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    background-image: none; }
  .btn-primary.disabled, .btn-primary.disabled:hover, .btn-primary.disabled:focus, .btn-primary.disabled.focus, .btn-primary.disabled:active, .btn-primary.disabled.active, .btn-primary[disabled], .btn-primary[disabled]:hover, .btn-primary[disabled]:focus, .btn-primary[disabled].focus, .btn-primary[disabled]:active, .btn-primary[disabled].active,
  fieldset[disabled] .btn-primary,
  fieldset[disabled] .btn-primary:hover,
  fieldset[disabled] .btn-primary:focus,
  fieldset[disabled] .btn-primary.focus,
  fieldset[disabled] .btn-primary:active,
  fieldset[disabled] .btn-primary.active {
    background-color: #4285f4;
    border-color: #4285f4; }
  .btn-primary .badge {
    color: #4285f4;
    background-color: #FFFFFF; }

.btn-danger {
  color: #FFFFFF;
  background-color: #ea4335;
  border-color: #ea4335; }
  .btn-danger:focus, .btn-danger.focus {
    color: #FFFFFF;
    background-color: #ea4335;
    border-color: #ea4335;
    outline: none; }
  .btn-danger:hover, .btn-danger.hover {
    color: #FFFFFF;
    box-shadow: inset 0 -1px 0 #d62516;
    background-color: #eb4c3e;
    border-color: #eb4c3e;
    border-bottom-color: #d62516; }
  .btn-danger:active, .btn-danger.active, .btn-danger:not([disabled]):not(.disabled):active, .btn-danger:not([disabled]):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #FFFFFF;
    box-shadow: inset 0 2px 0 #d62516;
    background-color: #e72919;
    border-color: #e72919;
    border-top-color: #b51f13; }
    .btn-danger:active:hover, .btn-danger:active:focus, .btn-danger:active.focus, .btn-danger.active:hover, .btn-danger.active:focus, .btn-danger.active.focus, .btn-danger:not([disabled]):not(.disabled):active:hover, .btn-danger:not([disabled]):not(.disabled):active:focus, .btn-danger:not([disabled]):not(.disabled):active.focus, .btn-danger:not([disabled]):not(.disabled).active:hover, .btn-danger:not([disabled]):not(.disabled).active:focus, .btn-danger:not([disabled]):not(.disabled).active.focus,
    .show > .btn-danger.dropdown-toggle:hover,
    .show > .btn-danger.dropdown-toggle:focus,
    .show > .btn-danger.dropdown-toggle.focus {
      color: #FFFFFF;
      background-color: #e72919;
      border-color: transparent;
      border-top-color: #b51f13;
      box-shadow: inset 0 2px 0 #d62516; }
      .btn-danger:active:hover .icon, .btn-danger:active:focus .icon, .btn-danger:active.focus .icon, .btn-danger.active:hover .icon, .btn-danger.active:focus .icon, .btn-danger.active.focus .icon, .btn-danger:not([disabled]):not(.disabled):active:hover .icon, .btn-danger:not([disabled]):not(.disabled):active:focus .icon, .btn-danger:not([disabled]):not(.disabled):active.focus .icon, .btn-danger:not([disabled]):not(.disabled).active:hover .icon, .btn-danger:not([disabled]):not(.disabled).active:focus .icon, .btn-danger:not([disabled]):not(.disabled).active.focus .icon,
      .show > .btn-danger.dropdown-toggle:hover .icon,
      .show > .btn-danger.dropdown-toggle:focus .icon,
      .show > .btn-danger.dropdown-toggle.focus .icon {
        color: #FFFFFF; }
  .btn-danger:active, .btn-danger.active, .btn-danger:not([disabled]):not(.disabled):active, .btn-danger:not([disabled]):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    background-image: none; }
  .btn-danger.disabled, .btn-danger.disabled:hover, .btn-danger.disabled:focus, .btn-danger.disabled.focus, .btn-danger.disabled:active, .btn-danger.disabled.active, .btn-danger[disabled], .btn-danger[disabled]:hover, .btn-danger[disabled]:focus, .btn-danger[disabled].focus, .btn-danger[disabled]:active, .btn-danger[disabled].active,
  fieldset[disabled] .btn-danger,
  fieldset[disabled] .btn-danger:hover,
  fieldset[disabled] .btn-danger:focus,
  fieldset[disabled] .btn-danger.focus,
  fieldset[disabled] .btn-danger:active,
  fieldset[disabled] .btn-danger.active {
    background-color: #ea4335;
    border-color: #ea4335; }
  .btn-danger .badge {
    color: #ea4335;
    background-color: #FFFFFF; }

.btn-warning {
  color: #FFFFFF;
  background-color: #fbbc05;
  border-color: #fbbc05; }
  .btn-warning:focus, .btn-warning.focus {
    color: #FFFFFF;
    background-color: #fbbc05;
    border-color: #fbbc05;
    outline: none; }
  .btn-warning:hover, .btn-warning.hover {
    color: #FFFFFF;
    box-shadow: inset 0 -1px 0 #ca9703;
    background-color: #fbbf0f;
    border-color: #fbbf0f;
    border-bottom-color: #ca9703; }
  .btn-warning:active, .btn-warning.active, .btn-warning:not([disabled]):not(.disabled):active, .btn-warning:not([disabled]):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #FFFFFF;
    box-shadow: inset 0 2px 0 #ca9703;
    background-color: #dea604;
    border-color: #dea604;
    border-top-color: #a77d03; }
    .btn-warning:active:hover, .btn-warning:active:focus, .btn-warning:active.focus, .btn-warning.active:hover, .btn-warning.active:focus, .btn-warning.active.focus, .btn-warning:not([disabled]):not(.disabled):active:hover, .btn-warning:not([disabled]):not(.disabled):active:focus, .btn-warning:not([disabled]):not(.disabled):active.focus, .btn-warning:not([disabled]):not(.disabled).active:hover, .btn-warning:not([disabled]):not(.disabled).active:focus, .btn-warning:not([disabled]):not(.disabled).active.focus,
    .show > .btn-warning.dropdown-toggle:hover,
    .show > .btn-warning.dropdown-toggle:focus,
    .show > .btn-warning.dropdown-toggle.focus {
      color: #FFFFFF;
      background-color: #dea604;
      border-color: transparent;
      border-top-color: #a77d03;
      box-shadow: inset 0 2px 0 #ca9703; }
      .btn-warning:active:hover .icon, .btn-warning:active:focus .icon, .btn-warning:active.focus .icon, .btn-warning.active:hover .icon, .btn-warning.active:focus .icon, .btn-warning.active.focus .icon, .btn-warning:not([disabled]):not(.disabled):active:hover .icon, .btn-warning:not([disabled]):not(.disabled):active:focus .icon, .btn-warning:not([disabled]):not(.disabled):active.focus .icon, .btn-warning:not([disabled]):not(.disabled).active:hover .icon, .btn-warning:not([disabled]):not(.disabled).active:focus .icon, .btn-warning:not([disabled]):not(.disabled).active.focus .icon,
      .show > .btn-warning.dropdown-toggle:hover .icon,
      .show > .btn-warning.dropdown-toggle:focus .icon,
      .show > .btn-warning.dropdown-toggle.focus .icon {
        color: #FFFFFF; }
  .btn-warning:active, .btn-warning.active, .btn-warning:not([disabled]):not(.disabled):active, .btn-warning:not([disabled]):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    background-image: none; }
  .btn-warning.disabled, .btn-warning.disabled:hover, .btn-warning.disabled:focus, .btn-warning.disabled.focus, .btn-warning.disabled:active, .btn-warning.disabled.active, .btn-warning[disabled], .btn-warning[disabled]:hover, .btn-warning[disabled]:focus, .btn-warning[disabled].focus, .btn-warning[disabled]:active, .btn-warning[disabled].active,
  fieldset[disabled] .btn-warning,
  fieldset[disabled] .btn-warning:hover,
  fieldset[disabled] .btn-warning:focus,
  fieldset[disabled] .btn-warning.focus,
  fieldset[disabled] .btn-warning:active,
  fieldset[disabled] .btn-warning.active {
    background-color: #fbbc05;
    border-color: #fbbc05; }
  .btn-warning .badge {
    color: #fbbc05;
    background-color: #FFFFFF; }

.btn-success {
  color: #FFFFFF;
  background-color: #34a853;
  border-color: #34a853; }
  .btn-success:focus, .btn-success.focus {
    color: #FFFFFF;
    background-color: #34a853;
    border-color: #34a853;
    outline: none; }
  .btn-success:hover, .btn-success.hover {
    color: #FFFFFF;
    box-shadow: inset 0 -1px 0 #288140;
    background-color: #36b057;
    border-color: #36b057;
    border-bottom-color: #288140; }
  .btn-success:active, .btn-success.active, .btn-success:not([disabled]):not(.disabled):active, .btn-success:not([disabled]):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #FFFFFF;
    box-shadow: inset 0 2px 0 #288140;
    background-color: #2d9147;
    border-color: #2d9147;
    border-top-color: #206632; }
    .btn-success:active:hover, .btn-success:active:focus, .btn-success:active.focus, .btn-success.active:hover, .btn-success.active:focus, .btn-success.active.focus, .btn-success:not([disabled]):not(.disabled):active:hover, .btn-success:not([disabled]):not(.disabled):active:focus, .btn-success:not([disabled]):not(.disabled):active.focus, .btn-success:not([disabled]):not(.disabled).active:hover, .btn-success:not([disabled]):not(.disabled).active:focus, .btn-success:not([disabled]):not(.disabled).active.focus,
    .show > .btn-success.dropdown-toggle:hover,
    .show > .btn-success.dropdown-toggle:focus,
    .show > .btn-success.dropdown-toggle.focus {
      color: #FFFFFF;
      background-color: #2d9147;
      border-color: transparent;
      border-top-color: #206632;
      box-shadow: inset 0 2px 0 #288140; }
      .btn-success:active:hover .icon, .btn-success:active:focus .icon, .btn-success:active.focus .icon, .btn-success.active:hover .icon, .btn-success.active:focus .icon, .btn-success.active.focus .icon, .btn-success:not([disabled]):not(.disabled):active:hover .icon, .btn-success:not([disabled]):not(.disabled):active:focus .icon, .btn-success:not([disabled]):not(.disabled):active.focus .icon, .btn-success:not([disabled]):not(.disabled).active:hover .icon, .btn-success:not([disabled]):not(.disabled).active:focus .icon, .btn-success:not([disabled]):not(.disabled).active.focus .icon,
      .show > .btn-success.dropdown-toggle:hover .icon,
      .show > .btn-success.dropdown-toggle:focus .icon,
      .show > .btn-success.dropdown-toggle.focus .icon {
        color: #FFFFFF; }
  .btn-success:active, .btn-success.active, .btn-success:not([disabled]):not(.disabled):active, .btn-success:not([disabled]):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    background-image: none; }
  .btn-success.disabled, .btn-success.disabled:hover, .btn-success.disabled:focus, .btn-success.disabled.focus, .btn-success.disabled:active, .btn-success.disabled.active, .btn-success[disabled], .btn-success[disabled]:hover, .btn-success[disabled]:focus, .btn-success[disabled].focus, .btn-success[disabled]:active, .btn-success[disabled].active,
  fieldset[disabled] .btn-success,
  fieldset[disabled] .btn-success:hover,
  fieldset[disabled] .btn-success:focus,
  fieldset[disabled] .btn-success.focus,
  fieldset[disabled] .btn-success:active,
  fieldset[disabled] .btn-success.active {
    background-color: #34a853;
    border-color: #34a853; }
  .btn-success .badge {
    color: #34a853;
    background-color: #FFFFFF; }

.btn-xs {
  padding: 0 6px;
  font-size: 0.8462rem;
  line-height: 18px;
  border-radius: 1px; }

.btn-sm, .btn-group-sm > .btn {
  padding: 0 8px;
  font-size: 1rem;
  line-height: 22px;
  border-radius: 2px; }

.btn-lg, .btn-group-lg > .btn {
  padding: 0 12px;
  font-size: 1.1538rem;
  line-height: 38px;
  border-radius: 3px;
  font-weight: 500; }

.btn-xl {
  padding: 0 12px;
  font-size: 1.1538rem;
  line-height: 43px;
  border-radius: 3px;
  font-weight: 500; }

.btn-rounded {
  border-radius: 15px; }
  .btn-rounded.btn-xs {
    border-radius: 10px; }
  .btn-rounded.btn-sm, .btn-group-sm > .btn-rounded.btn {
    border-radius: 12px; }
  .btn-rounded.btn-lg, .btn-group-lg > .btn-rounded.btn {
    border-radius: 20px; }

.btn-hspace {
  margin-right: 5px; }

.btn-vspace {
  margin-bottom: 5px; }

.btn-space {
  margin-right: 5px;
  margin-bottom: 5px; }

.btn-facebook .icon {
  color: #5d82d1; }

.btn-facebook:active .icon, .btn-facebook.active .icon,
.show > .btn-facebook.dropdown-toggle .icon {
  color: #5d82d1; }

.btn-facebook:active:hover .icon, .btn-facebook:active:focus .icon, .btn-facebook:active.focus .icon, .btn-facebook.active:hover .icon, .btn-facebook.active:focus .icon, .btn-facebook.active.focus .icon,
.show > .btn-facebook.dropdown-toggle:hover .icon,
.show > .btn-facebook.dropdown-toggle:focus .icon,
.show > .btn-facebook.dropdown-toggle.focus .icon {
  color: #5d82d1; }

.btn-twitter .icon {
  color: #50bff5; }

.btn-twitter:active .icon, .btn-twitter.active .icon,
.show > .btn-twitter.dropdown-toggle .icon {
  color: #50bff5; }

.btn-twitter:active:hover .icon, .btn-twitter:active:focus .icon, .btn-twitter:active.focus .icon, .btn-twitter.active:hover .icon, .btn-twitter.active:focus .icon, .btn-twitter.active.focus .icon,
.show > .btn-twitter.dropdown-toggle:hover .icon,
.show > .btn-twitter.dropdown-toggle:focus .icon,
.show > .btn-twitter.dropdown-toggle.focus .icon {
  color: #50bff5; }

.btn-google-plus .icon {
  color: #eb5e4c; }

.btn-google-plus:active .icon, .btn-google-plus.active .icon,
.show > .btn-google-plus.dropdown-toggle .icon {
  color: #eb5e4c; }

.btn-google-plus:active:hover .icon, .btn-google-plus:active:focus .icon, .btn-google-plus:active.focus .icon, .btn-google-plus.active:hover .icon, .btn-google-plus.active:focus .icon, .btn-google-plus.active.focus .icon,
.show > .btn-google-plus.dropdown-toggle:hover .icon,
.show > .btn-google-plus.dropdown-toggle:focus .icon,
.show > .btn-google-plus.dropdown-toggle.focus .icon {
  color: #eb5e4c; }

.btn-dribbble .icon {
  color: #f7659c; }

.btn-dribbble:active .icon, .btn-dribbble.active .icon,
.show > .btn-dribbble.dropdown-toggle .icon {
  color: #f7659c; }

.btn-dribbble:active:hover .icon, .btn-dribbble:active:focus .icon, .btn-dribbble:active.focus .icon, .btn-dribbble.active:hover .icon, .btn-dribbble.active:focus .icon, .btn-dribbble.active.focus .icon,
.show > .btn-dribbble.dropdown-toggle:hover .icon,
.show > .btn-dribbble.dropdown-toggle:focus .icon,
.show > .btn-dribbble.dropdown-toggle.focus .icon {
  color: #f7659c; }

.btn-flickr .icon {
  color: #0063dc; }

.btn-flickr:active .icon, .btn-flickr.active .icon,
.show > .btn-flickr.dropdown-toggle .icon {
  color: #0063dc; }

.btn-flickr:active:hover .icon, .btn-flickr:active:focus .icon, .btn-flickr:active.focus .icon, .btn-flickr.active:hover .icon, .btn-flickr.active:focus .icon, .btn-flickr.active.focus .icon,
.show > .btn-flickr.dropdown-toggle:hover .icon,
.show > .btn-flickr.dropdown-toggle:focus .icon,
.show > .btn-flickr.dropdown-toggle.focus .icon {
  color: #0063dc; }

.btn-linkedin .icon {
  color: #238cc8; }

.btn-linkedin:active .icon, .btn-linkedin.active .icon,
.show > .btn-linkedin.dropdown-toggle .icon {
  color: #238cc8; }

.btn-linkedin:active:hover .icon, .btn-linkedin:active:focus .icon, .btn-linkedin:active.focus .icon, .btn-linkedin.active:hover .icon, .btn-linkedin.active:focus .icon, .btn-linkedin.active.focus .icon,
.show > .btn-linkedin.dropdown-toggle:hover .icon,
.show > .btn-linkedin.dropdown-toggle:focus .icon,
.show > .btn-linkedin.dropdown-toggle.focus .icon {
  color: #238cc8; }

.btn-youtube .icon {
  color: #ef4e41; }

.btn-youtube:active .icon, .btn-youtube.active .icon,
.show > .btn-youtube.dropdown-toggle .icon {
  color: #ef4e41; }

.btn-youtube:active:hover .icon, .btn-youtube:active:focus .icon, .btn-youtube:active.focus .icon, .btn-youtube.active:hover .icon, .btn-youtube.active:focus .icon, .btn-youtube.active.focus .icon,
.show > .btn-youtube.dropdown-toggle:hover .icon,
.show > .btn-youtube.dropdown-toggle:focus .icon,
.show > .btn-youtube.dropdown-toggle.focus .icon {
  color: #ef4e41; }

.btn-pinterest .icon {
  color: #e13138; }

.btn-pinterest:active .icon, .btn-pinterest.active .icon,
.show > .btn-pinterest.dropdown-toggle .icon {
  color: #e13138; }

.btn-pinterest:active:hover .icon, .btn-pinterest:active:focus .icon, .btn-pinterest:active.focus .icon, .btn-pinterest.active:hover .icon, .btn-pinterest.active:focus .icon, .btn-pinterest.active.focus .icon,
.show > .btn-pinterest.dropdown-toggle:hover .icon,
.show > .btn-pinterest.dropdown-toggle:focus .icon,
.show > .btn-pinterest.dropdown-toggle.focus .icon {
  color: #e13138; }

.btn-github .icon {
  color: #333; }

.btn-github:active .icon, .btn-github.active .icon,
.show > .btn-github.dropdown-toggle .icon {
  color: #333; }

.btn-github:active:hover .icon, .btn-github:active:focus .icon, .btn-github:active.focus .icon, .btn-github.active:hover .icon, .btn-github.active:focus .icon, .btn-github.active.focus .icon,
.show > .btn-github.dropdown-toggle:hover .icon,
.show > .btn-github.dropdown-toggle:focus .icon,
.show > .btn-github.dropdown-toggle.focus .icon {
  color: #333; }

.btn-tumblr .icon {
  color: #426d9b; }

.btn-tumblr:active .icon, .btn-tumblr.active .icon,
.show > .btn-tumblr.dropdown-toggle .icon {
  color: #426d9b; }

.btn-tumblr:active:hover .icon, .btn-tumblr:active:focus .icon, .btn-tumblr:active.focus .icon, .btn-tumblr.active:hover .icon, .btn-tumblr.active:focus .icon, .btn-tumblr.active.focus .icon,
.show > .btn-tumblr.dropdown-toggle:hover .icon,
.show > .btn-tumblr.dropdown-toggle:focus .icon,
.show > .btn-tumblr.dropdown-toggle.focus .icon {
  color: #426d9b; }

.btn-dropbox .icon {
  color: #007EE5; }

.btn-dropbox:active .icon, .btn-dropbox.active .icon,
.show > .btn-dropbox.dropdown-toggle .icon {
  color: #007EE5; }

.btn-dropbox:active:hover .icon, .btn-dropbox:active:focus .icon, .btn-dropbox:active.focus .icon, .btn-dropbox.active:hover .icon, .btn-dropbox.active:focus .icon, .btn-dropbox.active.focus .icon,
.show > .btn-dropbox.dropdown-toggle:hover .icon,
.show > .btn-dropbox.dropdown-toggle:focus .icon,
.show > .btn-dropbox.dropdown-toggle.focus .icon {
  color: #007EE5; }

.btn-evernote .icon {
  color: #2DBE60; }

.btn-evernote:active .icon, .btn-evernote.active .icon,
.show > .btn-evernote.dropdown-toggle .icon {
  color: #2DBE60; }

.btn-evernote:active:hover .icon, .btn-evernote:active:focus .icon, .btn-evernote:active.focus .icon, .btn-evernote.active:hover .icon, .btn-evernote.active:focus .icon, .btn-evernote.active.focus .icon,
.show > .btn-evernote.dropdown-toggle:hover .icon,
.show > .btn-evernote.dropdown-toggle:focus .icon,
.show > .btn-evernote.dropdown-toggle.focus .icon {
  color: #2DBE60; }

.btn-social {
  min-width: 31px;
  padding: 0 8px; }
  .btn-social .icon {
    font-size: 1.308rem; }

.btn-color.btn-facebook {
  color: #FFFFFF;
  background-color: #5d82d1;
  border-color: #5d82d1; }
  .btn-color.btn-facebook .icon {
    color: #FFFFFF; }
  .btn-color.btn-facebook:focus, .btn-color.btn-facebook.focus {
    color: #FFFFFF;
    background-color: #5d82d1;
    border-color: #5d82d1;
    outline: none; }
  .btn-color.btn-facebook:hover, .btn-color.btn-facebook.hover {
    color: #FFFFFF;
    box-shadow: inset 0 -1px 0 #3864c3;
    background-color: #6588d3;
    border-color: #6588d3;
    border-bottom-color: #3864c3; }
  .btn-color.btn-facebook:active, .btn-color.btn-facebook.active, .btn-color.btn-facebook:not([disabled]):not(.disabled):active, .btn-color.btn-facebook:not([disabled]):not(.disabled).active,
  .show > .btn-color.btn-facebook.dropdown-toggle {
    color: #FFFFFF;
    box-shadow: inset 0 2px 0 #3864c3;
    background-color: #4570ca;
    border-color: #4570ca;
    border-top-color: #3056a8; }
    .btn-color.btn-facebook:active:hover, .btn-color.btn-facebook:active:focus, .btn-color.btn-facebook:active.focus, .btn-color.btn-facebook.active:hover, .btn-color.btn-facebook.active:focus, .btn-color.btn-facebook.active.focus, .btn-color.btn-facebook:not([disabled]):not(.disabled):active:hover, .btn-color.btn-facebook:not([disabled]):not(.disabled):active:focus, .btn-color.btn-facebook:not([disabled]):not(.disabled):active.focus, .btn-color.btn-facebook:not([disabled]):not(.disabled).active:hover, .btn-color.btn-facebook:not([disabled]):not(.disabled).active:focus, .btn-color.btn-facebook:not([disabled]):not(.disabled).active.focus,
    .show > .btn-color.btn-facebook.dropdown-toggle:hover,
    .show > .btn-color.btn-facebook.dropdown-toggle:focus,
    .show > .btn-color.btn-facebook.dropdown-toggle.focus {
      color: #FFFFFF;
      background-color: #4570ca;
      border-color: transparent;
      border-top-color: #3056a8;
      box-shadow: inset 0 2px 0 #3864c3; }
      .btn-color.btn-facebook:active:hover .icon, .btn-color.btn-facebook:active:focus .icon, .btn-color.btn-facebook:active.focus .icon, .btn-color.btn-facebook.active:hover .icon, .btn-color.btn-facebook.active:focus .icon, .btn-color.btn-facebook.active.focus .icon, .btn-color.btn-facebook:not([disabled]):not(.disabled):active:hover .icon, .btn-color.btn-facebook:not([disabled]):not(.disabled):active:focus .icon, .btn-color.btn-facebook:not([disabled]):not(.disabled):active.focus .icon, .btn-color.btn-facebook:not([disabled]):not(.disabled).active:hover .icon, .btn-color.btn-facebook:not([disabled]):not(.disabled).active:focus .icon, .btn-color.btn-facebook:not([disabled]):not(.disabled).active.focus .icon,
      .show > .btn-color.btn-facebook.dropdown-toggle:hover .icon,
      .show > .btn-color.btn-facebook.dropdown-toggle:focus .icon,
      .show > .btn-color.btn-facebook.dropdown-toggle.focus .icon {
        color: #FFFFFF; }
  .btn-color.btn-facebook:active, .btn-color.btn-facebook.active, .btn-color.btn-facebook:not([disabled]):not(.disabled):active, .btn-color.btn-facebook:not([disabled]):not(.disabled).active,
  .show > .btn-color.btn-facebook.dropdown-toggle {
    background-image: none; }
  .btn-color.btn-facebook.disabled, .btn-color.btn-facebook.disabled:hover, .btn-color.btn-facebook.disabled:focus, .btn-color.btn-facebook.disabled.focus, .btn-color.btn-facebook.disabled:active, .btn-color.btn-facebook.disabled.active, .btn-color.btn-facebook[disabled], .btn-color.btn-facebook[disabled]:hover, .btn-color.btn-facebook[disabled]:focus, .btn-color.btn-facebook[disabled].focus, .btn-color.btn-facebook[disabled]:active, .btn-color.btn-facebook[disabled].active,
  fieldset[disabled] .btn-color.btn-facebook,
  fieldset[disabled] .btn-color.btn-facebook:hover,
  fieldset[disabled] .btn-color.btn-facebook:focus,
  fieldset[disabled] .btn-color.btn-facebook.focus,
  fieldset[disabled] .btn-color.btn-facebook:active,
  fieldset[disabled] .btn-color.btn-facebook.active {
    background-color: #5d82d1;
    border-color: #5d82d1; }
  .btn-color.btn-facebook .badge {
    color: #5d82d1;
    background-color: #FFFFFF; }

.btn-color.btn-twitter {
  color: #FFFFFF;
  background-color: #50bff5;
  border-color: #50bff5; }
  .btn-color.btn-twitter .icon {
    color: #FFFFFF; }
  .btn-color.btn-twitter:focus, .btn-color.btn-twitter.focus {
    color: #FFFFFF;
    background-color: #50bff5;
    border-color: #50bff5;
    outline: none; }
  .btn-color.btn-twitter:hover, .btn-color.btn-twitter.hover {
    color: #FFFFFF;
    box-shadow: inset 0 -1px 0 #20adf2;
    background-color: #5ac3f6;
    border-color: #5ac3f6;
    border-bottom-color: #20adf2; }
  .btn-color.btn-twitter:active, .btn-color.btn-twitter.active, .btn-color.btn-twitter:not([disabled]):not(.disabled):active, .btn-color.btn-twitter:not([disabled]):not(.disabled).active,
  .show > .btn-color.btn-twitter.dropdown-toggle {
    color: #FFFFFF;
    box-shadow: inset 0 2px 0 #20adf2;
    background-color: #33b4f3;
    border-color: #33b4f3;
    border-top-color: #0d9ce1; }
    .btn-color.btn-twitter:active:hover, .btn-color.btn-twitter:active:focus, .btn-color.btn-twitter:active.focus, .btn-color.btn-twitter.active:hover, .btn-color.btn-twitter.active:focus, .btn-color.btn-twitter.active.focus, .btn-color.btn-twitter:not([disabled]):not(.disabled):active:hover, .btn-color.btn-twitter:not([disabled]):not(.disabled):active:focus, .btn-color.btn-twitter:not([disabled]):not(.disabled):active.focus, .btn-color.btn-twitter:not([disabled]):not(.disabled).active:hover, .btn-color.btn-twitter:not([disabled]):not(.disabled).active:focus, .btn-color.btn-twitter:not([disabled]):not(.disabled).active.focus,
    .show > .btn-color.btn-twitter.dropdown-toggle:hover,
    .show > .btn-color.btn-twitter.dropdown-toggle:focus,
    .show > .btn-color.btn-twitter.dropdown-toggle.focus {
      color: #FFFFFF;
      background-color: #33b4f3;
      border-color: transparent;
      border-top-color: #0d9ce1;
      box-shadow: inset 0 2px 0 #20adf2; }
      .btn-color.btn-twitter:active:hover .icon, .btn-color.btn-twitter:active:focus .icon, .btn-color.btn-twitter:active.focus .icon, .btn-color.btn-twitter.active:hover .icon, .btn-color.btn-twitter.active:focus .icon, .btn-color.btn-twitter.active.focus .icon, .btn-color.btn-twitter:not([disabled]):not(.disabled):active:hover .icon, .btn-color.btn-twitter:not([disabled]):not(.disabled):active:focus .icon, .btn-color.btn-twitter:not([disabled]):not(.disabled):active.focus .icon, .btn-color.btn-twitter:not([disabled]):not(.disabled).active:hover .icon, .btn-color.btn-twitter:not([disabled]):not(.disabled).active:focus .icon, .btn-color.btn-twitter:not([disabled]):not(.disabled).active.focus .icon,
      .show > .btn-color.btn-twitter.dropdown-toggle:hover .icon,
      .show > .btn-color.btn-twitter.dropdown-toggle:focus .icon,
      .show > .btn-color.btn-twitter.dropdown-toggle.focus .icon {
        color: #FFFFFF; }
  .btn-color.btn-twitter:active, .btn-color.btn-twitter.active, .btn-color.btn-twitter:not([disabled]):not(.disabled):active, .btn-color.btn-twitter:not([disabled]):not(.disabled).active,
  .show > .btn-color.btn-twitter.dropdown-toggle {
    background-image: none; }
  .btn-color.btn-twitter.disabled, .btn-color.btn-twitter.disabled:hover, .btn-color.btn-twitter.disabled:focus, .btn-color.btn-twitter.disabled.focus, .btn-color.btn-twitter.disabled:active, .btn-color.btn-twitter.disabled.active, .btn-color.btn-twitter[disabled], .btn-color.btn-twitter[disabled]:hover, .btn-color.btn-twitter[disabled]:focus, .btn-color.btn-twitter[disabled].focus, .btn-color.btn-twitter[disabled]:active, .btn-color.btn-twitter[disabled].active,
  fieldset[disabled] .btn-color.btn-twitter,
  fieldset[disabled] .btn-color.btn-twitter:hover,
  fieldset[disabled] .btn-color.btn-twitter:focus,
  fieldset[disabled] .btn-color.btn-twitter.focus,
  fieldset[disabled] .btn-color.btn-twitter:active,
  fieldset[disabled] .btn-color.btn-twitter.active {
    background-color: #50bff5;
    border-color: #50bff5; }
  .btn-color.btn-twitter .badge {
    color: #50bff5;
    background-color: #FFFFFF; }

.btn-color.btn-google-plus {
  color: #FFFFFF;
  background-color: #eb5e4c;
  border-color: #eb5e4c; }
  .btn-color.btn-google-plus .icon {
    color: #FFFFFF; }
  .btn-color.btn-google-plus:focus, .btn-color.btn-google-plus.focus {
    color: #FFFFFF;
    background-color: #eb5e4c;
    border-color: #eb5e4c;
    outline: none; }
  .btn-color.btn-google-plus:hover, .btn-color.btn-google-plus.hover {
    color: #FFFFFF;
    box-shadow: inset 0 -1px 0 #e6351e;
    background-color: #ec6655;
    border-color: #ec6655;
    border-bottom-color: #e6351e; }
  .btn-color.btn-google-plus:active, .btn-color.btn-google-plus.active, .btn-color.btn-google-plus:not([disabled]):not(.disabled):active, .btn-color.btn-google-plus:not([disabled]):not(.disabled).active,
  .show > .btn-color.btn-google-plus.dropdown-toggle {
    color: #FFFFFF;
    box-shadow: inset 0 2px 0 #e6351e;
    background-color: #e84530;
    border-color: #e84530;
    border-top-color: #ca2b17; }
    .btn-color.btn-google-plus:active:hover, .btn-color.btn-google-plus:active:focus, .btn-color.btn-google-plus:active.focus, .btn-color.btn-google-plus.active:hover, .btn-color.btn-google-plus.active:focus, .btn-color.btn-google-plus.active.focus, .btn-color.btn-google-plus:not([disabled]):not(.disabled):active:hover, .btn-color.btn-google-plus:not([disabled]):not(.disabled):active:focus, .btn-color.btn-google-plus:not([disabled]):not(.disabled):active.focus, .btn-color.btn-google-plus:not([disabled]):not(.disabled).active:hover, .btn-color.btn-google-plus:not([disabled]):not(.disabled).active:focus, .btn-color.btn-google-plus:not([disabled]):not(.disabled).active.focus,
    .show > .btn-color.btn-google-plus.dropdown-toggle:hover,
    .show > .btn-color.btn-google-plus.dropdown-toggle:focus,
    .show > .btn-color.btn-google-plus.dropdown-toggle.focus {
      color: #FFFFFF;
      background-color: #e84530;
      border-color: transparent;
      border-top-color: #ca2b17;
      box-shadow: inset 0 2px 0 #e6351e; }
      .btn-color.btn-google-plus:active:hover .icon, .btn-color.btn-google-plus:active:focus .icon, .btn-color.btn-google-plus:active.focus .icon, .btn-color.btn-google-plus.active:hover .icon, .btn-color.btn-google-plus.active:focus .icon, .btn-color.btn-google-plus.active.focus .icon, .btn-color.btn-google-plus:not([disabled]):not(.disabled):active:hover .icon, .btn-color.btn-google-plus:not([disabled]):not(.disabled):active:focus .icon, .btn-color.btn-google-plus:not([disabled]):not(.disabled):active.focus .icon, .btn-color.btn-google-plus:not([disabled]):not(.disabled).active:hover .icon, .btn-color.btn-google-plus:not([disabled]):not(.disabled).active:focus .icon, .btn-color.btn-google-plus:not([disabled]):not(.disabled).active.focus .icon,
      .show > .btn-color.btn-google-plus.dropdown-toggle:hover .icon,
      .show > .btn-color.btn-google-plus.dropdown-toggle:focus .icon,
      .show > .btn-color.btn-google-plus.dropdown-toggle.focus .icon {
        color: #FFFFFF; }
  .btn-color.btn-google-plus:active, .btn-color.btn-google-plus.active, .btn-color.btn-google-plus:not([disabled]):not(.disabled):active, .btn-color.btn-google-plus:not([disabled]):not(.disabled).active,
  .show > .btn-color.btn-google-plus.dropdown-toggle {
    background-image: none; }
  .btn-color.btn-google-plus.disabled, .btn-color.btn-google-plus.disabled:hover, .btn-color.btn-google-plus.disabled:focus, .btn-color.btn-google-plus.disabled.focus, .btn-color.btn-google-plus.disabled:active, .btn-color.btn-google-plus.disabled.active, .btn-color.btn-google-plus[disabled], .btn-color.btn-google-plus[disabled]:hover, .btn-color.btn-google-plus[disabled]:focus, .btn-color.btn-google-plus[disabled].focus, .btn-color.btn-google-plus[disabled]:active, .btn-color.btn-google-plus[disabled].active,
  fieldset[disabled] .btn-color.btn-google-plus,
  fieldset[disabled] .btn-color.btn-google-plus:hover,
  fieldset[disabled] .btn-color.btn-google-plus:focus,
  fieldset[disabled] .btn-color.btn-google-plus.focus,
  fieldset[disabled] .btn-color.btn-google-plus:active,
  fieldset[disabled] .btn-color.btn-google-plus.active {
    background-color: #eb5e4c;
    border-color: #eb5e4c; }
  .btn-color.btn-google-plus .badge {
    color: #eb5e4c;
    background-color: #FFFFFF; }

.btn-color.btn-dribbble {
  color: #FFFFFF;
  background-color: #f7659c;
  border-color: #f7659c; }
  .btn-color.btn-dribbble .icon {
    color: #FFFFFF; }
  .btn-color.btn-dribbble:focus, .btn-color.btn-dribbble.focus {
    color: #FFFFFF;
    background-color: #f7659c;
    border-color: #f7659c;
    outline: none; }
  .btn-color.btn-dribbble:hover, .btn-color.btn-dribbble.hover {
    color: #FFFFFF;
    box-shadow: inset 0 -1px 0 #f4357d;
    background-color: #f86fa2;
    border-color: #f86fa2;
    border-bottom-color: #f4357d; }
  .btn-color.btn-dribbble:active, .btn-color.btn-dribbble.active, .btn-color.btn-dribbble:not([disabled]):not(.disabled):active, .btn-color.btn-dribbble:not([disabled]):not(.disabled).active,
  .show > .btn-color.btn-dribbble.dropdown-toggle {
    color: #FFFFFF;
    box-shadow: inset 0 2px 0 #f4357d;
    background-color: #f54889;
    border-color: #f54889;
    border-top-color: #f31367; }
    .btn-color.btn-dribbble:active:hover, .btn-color.btn-dribbble:active:focus, .btn-color.btn-dribbble:active.focus, .btn-color.btn-dribbble.active:hover, .btn-color.btn-dribbble.active:focus, .btn-color.btn-dribbble.active.focus, .btn-color.btn-dribbble:not([disabled]):not(.disabled):active:hover, .btn-color.btn-dribbble:not([disabled]):not(.disabled):active:focus, .btn-color.btn-dribbble:not([disabled]):not(.disabled):active.focus, .btn-color.btn-dribbble:not([disabled]):not(.disabled).active:hover, .btn-color.btn-dribbble:not([disabled]):not(.disabled).active:focus, .btn-color.btn-dribbble:not([disabled]):not(.disabled).active.focus,
    .show > .btn-color.btn-dribbble.dropdown-toggle:hover,
    .show > .btn-color.btn-dribbble.dropdown-toggle:focus,
    .show > .btn-color.btn-dribbble.dropdown-toggle.focus {
      color: #FFFFFF;
      background-color: #f54889;
      border-color: transparent;
      border-top-color: #f31367;
      box-shadow: inset 0 2px 0 #f4357d; }
      .btn-color.btn-dribbble:active:hover .icon, .btn-color.btn-dribbble:active:focus .icon, .btn-color.btn-dribbble:active.focus .icon, .btn-color.btn-dribbble.active:hover .icon, .btn-color.btn-dribbble.active:focus .icon, .btn-color.btn-dribbble.active.focus .icon, .btn-color.btn-dribbble:not([disabled]):not(.disabled):active:hover .icon, .btn-color.btn-dribbble:not([disabled]):not(.disabled):active:focus .icon, .btn-color.btn-dribbble:not([disabled]):not(.disabled):active.focus .icon, .btn-color.btn-dribbble:not([disabled]):not(.disabled).active:hover .icon, .btn-color.btn-dribbble:not([disabled]):not(.disabled).active:focus .icon, .btn-color.btn-dribbble:not([disabled]):not(.disabled).active.focus .icon,
      .show > .btn-color.btn-dribbble.dropdown-toggle:hover .icon,
      .show > .btn-color.btn-dribbble.dropdown-toggle:focus .icon,
      .show > .btn-color.btn-dribbble.dropdown-toggle.focus .icon {
        color: #FFFFFF; }
  .btn-color.btn-dribbble:active, .btn-color.btn-dribbble.active, .btn-color.btn-dribbble:not([disabled]):not(.disabled):active, .btn-color.btn-dribbble:not([disabled]):not(.disabled).active,
  .show > .btn-color.btn-dribbble.dropdown-toggle {
    background-image: none; }
  .btn-color.btn-dribbble.disabled, .btn-color.btn-dribbble.disabled:hover, .btn-color.btn-dribbble.disabled:focus, .btn-color.btn-dribbble.disabled.focus, .btn-color.btn-dribbble.disabled:active, .btn-color.btn-dribbble.disabled.active, .btn-color.btn-dribbble[disabled], .btn-color.btn-dribbble[disabled]:hover, .btn-color.btn-dribbble[disabled]:focus, .btn-color.btn-dribbble[disabled].focus, .btn-color.btn-dribbble[disabled]:active, .btn-color.btn-dribbble[disabled].active,
  fieldset[disabled] .btn-color.btn-dribbble,
  fieldset[disabled] .btn-color.btn-dribbble:hover,
  fieldset[disabled] .btn-color.btn-dribbble:focus,
  fieldset[disabled] .btn-color.btn-dribbble.focus,
  fieldset[disabled] .btn-color.btn-dribbble:active,
  fieldset[disabled] .btn-color.btn-dribbble.active {
    background-color: #f7659c;
    border-color: #f7659c; }
  .btn-color.btn-dribbble .badge {
    color: #f7659c;
    background-color: #FFFFFF; }

.btn-color.btn-flickr {
  color: #FFFFFF;
  background-color: #0063dc;
  border-color: #0063dc; }
  .btn-color.btn-flickr .icon {
    color: #FFFFFF; }
  .btn-color.btn-flickr:focus, .btn-color.btn-flickr.focus {
    color: #FFFFFF;
    background-color: #0063dc;
    border-color: #0063dc;
    outline: none; }
  .btn-color.btn-flickr:hover, .btn-color.btn-flickr.hover {
    color: #FFFFFF;
    box-shadow: inset 0 -1px 0 #004ca9;
    background-color: #0068e6;
    border-color: #0068e6;
    border-bottom-color: #004ca9; }
  .btn-color.btn-flickr:active, .btn-color.btn-flickr.active, .btn-color.btn-flickr:not([disabled]):not(.disabled):active, .btn-color.btn-flickr:not([disabled]):not(.disabled).active,
  .show > .btn-color.btn-flickr.dropdown-toggle {
    color: #FFFFFF;
    box-shadow: inset 0 2px 0 #004ca9;
    background-color: #0055bd;
    border-color: #0055bd;
    border-top-color: #003c85; }
    .btn-color.btn-flickr:active:hover, .btn-color.btn-flickr:active:focus, .btn-color.btn-flickr:active.focus, .btn-color.btn-flickr.active:hover, .btn-color.btn-flickr.active:focus, .btn-color.btn-flickr.active.focus, .btn-color.btn-flickr:not([disabled]):not(.disabled):active:hover, .btn-color.btn-flickr:not([disabled]):not(.disabled):active:focus, .btn-color.btn-flickr:not([disabled]):not(.disabled):active.focus, .btn-color.btn-flickr:not([disabled]):not(.disabled).active:hover, .btn-color.btn-flickr:not([disabled]):not(.disabled).active:focus, .btn-color.btn-flickr:not([disabled]):not(.disabled).active.focus,
    .show > .btn-color.btn-flickr.dropdown-toggle:hover,
    .show > .btn-color.btn-flickr.dropdown-toggle:focus,
    .show > .btn-color.btn-flickr.dropdown-toggle.focus {
      color: #FFFFFF;
      background-color: #0055bd;
      border-color: transparent;
      border-top-color: #003c85;
      box-shadow: inset 0 2px 0 #004ca9; }
      .btn-color.btn-flickr:active:hover .icon, .btn-color.btn-flickr:active:focus .icon, .btn-color.btn-flickr:active.focus .icon, .btn-color.btn-flickr.active:hover .icon, .btn-color.btn-flickr.active:focus .icon, .btn-color.btn-flickr.active.focus .icon, .btn-color.btn-flickr:not([disabled]):not(.disabled):active:hover .icon, .btn-color.btn-flickr:not([disabled]):not(.disabled):active:focus .icon, .btn-color.btn-flickr:not([disabled]):not(.disabled):active.focus .icon, .btn-color.btn-flickr:not([disabled]):not(.disabled).active:hover .icon, .btn-color.btn-flickr:not([disabled]):not(.disabled).active:focus .icon, .btn-color.btn-flickr:not([disabled]):not(.disabled).active.focus .icon,
      .show > .btn-color.btn-flickr.dropdown-toggle:hover .icon,
      .show > .btn-color.btn-flickr.dropdown-toggle:focus .icon,
      .show > .btn-color.btn-flickr.dropdown-toggle.focus .icon {
        color: #FFFFFF; }
  .btn-color.btn-flickr:active, .btn-color.btn-flickr.active, .btn-color.btn-flickr:not([disabled]):not(.disabled):active, .btn-color.btn-flickr:not([disabled]):not(.disabled).active,
  .show > .btn-color.btn-flickr.dropdown-toggle {
    background-image: none; }
  .btn-color.btn-flickr.disabled, .btn-color.btn-flickr.disabled:hover, .btn-color.btn-flickr.disabled:focus, .btn-color.btn-flickr.disabled.focus, .btn-color.btn-flickr.disabled:active, .btn-color.btn-flickr.disabled.active, .btn-color.btn-flickr[disabled], .btn-color.btn-flickr[disabled]:hover, .btn-color.btn-flickr[disabled]:focus, .btn-color.btn-flickr[disabled].focus, .btn-color.btn-flickr[disabled]:active, .btn-color.btn-flickr[disabled].active,
  fieldset[disabled] .btn-color.btn-flickr,
  fieldset[disabled] .btn-color.btn-flickr:hover,
  fieldset[disabled] .btn-color.btn-flickr:focus,
  fieldset[disabled] .btn-color.btn-flickr.focus,
  fieldset[disabled] .btn-color.btn-flickr:active,
  fieldset[disabled] .btn-color.btn-flickr.active {
    background-color: #0063dc;
    border-color: #0063dc; }
  .btn-color.btn-flickr .badge {
    color: #0063dc;
    background-color: #FFFFFF; }

.btn-color.btn-linkedin {
  color: #FFFFFF;
  background-color: #238cc8;
  border-color: #238cc8; }
  .btn-color.btn-linkedin .icon {
    color: #FFFFFF; }
  .btn-color.btn-linkedin:focus, .btn-color.btn-linkedin.focus {
    color: #FFFFFF;
    background-color: #238cc8;
    border-color: #238cc8;
    outline: none; }
  .btn-color.btn-linkedin:hover, .btn-color.btn-linkedin.hover {
    color: #FFFFFF;
    box-shadow: inset 0 -1px 0 #1b6e9d;
    background-color: #2592d1;
    border-color: #2592d1;
    border-bottom-color: #1b6e9d; }
  .btn-color.btn-linkedin:active, .btn-color.btn-linkedin.active, .btn-color.btn-linkedin:not([disabled]):not(.disabled):active, .btn-color.btn-linkedin:not([disabled]):not(.disabled).active,
  .show > .btn-color.btn-linkedin.dropdown-toggle {
    color: #FFFFFF;
    box-shadow: inset 0 2px 0 #1b6e9d;
    background-color: #1e7aae;
    border-color: #1e7aae;
    border-top-color: #16587e; }
    .btn-color.btn-linkedin:active:hover, .btn-color.btn-linkedin:active:focus, .btn-color.btn-linkedin:active.focus, .btn-color.btn-linkedin.active:hover, .btn-color.btn-linkedin.active:focus, .btn-color.btn-linkedin.active.focus, .btn-color.btn-linkedin:not([disabled]):not(.disabled):active:hover, .btn-color.btn-linkedin:not([disabled]):not(.disabled):active:focus, .btn-color.btn-linkedin:not([disabled]):not(.disabled):active.focus, .btn-color.btn-linkedin:not([disabled]):not(.disabled).active:hover, .btn-color.btn-linkedin:not([disabled]):not(.disabled).active:focus, .btn-color.btn-linkedin:not([disabled]):not(.disabled).active.focus,
    .show > .btn-color.btn-linkedin.dropdown-toggle:hover,
    .show > .btn-color.btn-linkedin.dropdown-toggle:focus,
    .show > .btn-color.btn-linkedin.dropdown-toggle.focus {
      color: #FFFFFF;
      background-color: #1e7aae;
      border-color: transparent;
      border-top-color: #16587e;
      box-shadow: inset 0 2px 0 #1b6e9d; }
      .btn-color.btn-linkedin:active:hover .icon, .btn-color.btn-linkedin:active:focus .icon, .btn-color.btn-linkedin:active.focus .icon, .btn-color.btn-linkedin.active:hover .icon, .btn-color.btn-linkedin.active:focus .icon, .btn-color.btn-linkedin.active.focus .icon, .btn-color.btn-linkedin:not([disabled]):not(.disabled):active:hover .icon, .btn-color.btn-linkedin:not([disabled]):not(.disabled):active:focus .icon, .btn-color.btn-linkedin:not([disabled]):not(.disabled):active.focus .icon, .btn-color.btn-linkedin:not([disabled]):not(.disabled).active:hover .icon, .btn-color.btn-linkedin:not([disabled]):not(.disabled).active:focus .icon, .btn-color.btn-linkedin:not([disabled]):not(.disabled).active.focus .icon,
      .show > .btn-color.btn-linkedin.dropdown-toggle:hover .icon,
      .show > .btn-color.btn-linkedin.dropdown-toggle:focus .icon,
      .show > .btn-color.btn-linkedin.dropdown-toggle.focus .icon {
        color: #FFFFFF; }
  .btn-color.btn-linkedin:active, .btn-color.btn-linkedin.active, .btn-color.btn-linkedin:not([disabled]):not(.disabled):active, .btn-color.btn-linkedin:not([disabled]):not(.disabled).active,
  .show > .btn-color.btn-linkedin.dropdown-toggle {
    background-image: none; }
  .btn-color.btn-linkedin.disabled, .btn-color.btn-linkedin.disabled:hover, .btn-color.btn-linkedin.disabled:focus, .btn-color.btn-linkedin.disabled.focus, .btn-color.btn-linkedin.disabled:active, .btn-color.btn-linkedin.disabled.active, .btn-color.btn-linkedin[disabled], .btn-color.btn-linkedin[disabled]:hover, .btn-color.btn-linkedin[disabled]:focus, .btn-color.btn-linkedin[disabled].focus, .btn-color.btn-linkedin[disabled]:active, .btn-color.btn-linkedin[disabled].active,
  fieldset[disabled] .btn-color.btn-linkedin,
  fieldset[disabled] .btn-color.btn-linkedin:hover,
  fieldset[disabled] .btn-color.btn-linkedin:focus,
  fieldset[disabled] .btn-color.btn-linkedin.focus,
  fieldset[disabled] .btn-color.btn-linkedin:active,
  fieldset[disabled] .btn-color.btn-linkedin.active {
    background-color: #238cc8;
    border-color: #238cc8; }
  .btn-color.btn-linkedin .badge {
    color: #238cc8;
    background-color: #FFFFFF; }

.btn-color.btn-youtube {
  color: #FFFFFF;
  background-color: #ef4e41;
  border-color: #ef4e41; }
  .btn-color.btn-youtube .icon {
    color: #FFFFFF; }
  .btn-color.btn-youtube:focus, .btn-color.btn-youtube.focus {
    color: #FFFFFF;
    background-color: #ef4e41;
    border-color: #ef4e41;
    outline: none; }
  .btn-color.btn-youtube:hover, .btn-color.btn-youtube.hover {
    color: #FFFFFF;
    box-shadow: inset 0 -1px 0 #e92414;
    background-color: #f0574a;
    border-color: #f0574a;
    border-bottom-color: #e92414; }
  .btn-color.btn-youtube:active, .btn-color.btn-youtube.active, .btn-color.btn-youtube:not([disabled]):not(.disabled):active, .btn-color.btn-youtube:not([disabled]):not(.disabled).active,
  .show > .btn-color.btn-youtube.dropdown-toggle {
    color: #FFFFFF;
    box-shadow: inset 0 2px 0 #e92414;
    background-color: #ed3425;
    border-color: #ed3425;
    border-top-color: #c81f11; }
    .btn-color.btn-youtube:active:hover, .btn-color.btn-youtube:active:focus, .btn-color.btn-youtube:active.focus, .btn-color.btn-youtube.active:hover, .btn-color.btn-youtube.active:focus, .btn-color.btn-youtube.active.focus, .btn-color.btn-youtube:not([disabled]):not(.disabled):active:hover, .btn-color.btn-youtube:not([disabled]):not(.disabled):active:focus, .btn-color.btn-youtube:not([disabled]):not(.disabled):active.focus, .btn-color.btn-youtube:not([disabled]):not(.disabled).active:hover, .btn-color.btn-youtube:not([disabled]):not(.disabled).active:focus, .btn-color.btn-youtube:not([disabled]):not(.disabled).active.focus,
    .show > .btn-color.btn-youtube.dropdown-toggle:hover,
    .show > .btn-color.btn-youtube.dropdown-toggle:focus,
    .show > .btn-color.btn-youtube.dropdown-toggle.focus {
      color: #FFFFFF;
      background-color: #ed3425;
      border-color: transparent;
      border-top-color: #c81f11;
      box-shadow: inset 0 2px 0 #e92414; }
      .btn-color.btn-youtube:active:hover .icon, .btn-color.btn-youtube:active:focus .icon, .btn-color.btn-youtube:active.focus .icon, .btn-color.btn-youtube.active:hover .icon, .btn-color.btn-youtube.active:focus .icon, .btn-color.btn-youtube.active.focus .icon, .btn-color.btn-youtube:not([disabled]):not(.disabled):active:hover .icon, .btn-color.btn-youtube:not([disabled]):not(.disabled):active:focus .icon, .btn-color.btn-youtube:not([disabled]):not(.disabled):active.focus .icon, .btn-color.btn-youtube:not([disabled]):not(.disabled).active:hover .icon, .btn-color.btn-youtube:not([disabled]):not(.disabled).active:focus .icon, .btn-color.btn-youtube:not([disabled]):not(.disabled).active.focus .icon,
      .show > .btn-color.btn-youtube.dropdown-toggle:hover .icon,
      .show > .btn-color.btn-youtube.dropdown-toggle:focus .icon,
      .show > .btn-color.btn-youtube.dropdown-toggle.focus .icon {
        color: #FFFFFF; }
  .btn-color.btn-youtube:active, .btn-color.btn-youtube.active, .btn-color.btn-youtube:not([disabled]):not(.disabled):active, .btn-color.btn-youtube:not([disabled]):not(.disabled).active,
  .show > .btn-color.btn-youtube.dropdown-toggle {
    background-image: none; }
  .btn-color.btn-youtube.disabled, .btn-color.btn-youtube.disabled:hover, .btn-color.btn-youtube.disabled:focus, .btn-color.btn-youtube.disabled.focus, .btn-color.btn-youtube.disabled:active, .btn-color.btn-youtube.disabled.active, .btn-color.btn-youtube[disabled], .btn-color.btn-youtube[disabled]:hover, .btn-color.btn-youtube[disabled]:focus, .btn-color.btn-youtube[disabled].focus, .btn-color.btn-youtube[disabled]:active, .btn-color.btn-youtube[disabled].active,
  fieldset[disabled] .btn-color.btn-youtube,
  fieldset[disabled] .btn-color.btn-youtube:hover,
  fieldset[disabled] .btn-color.btn-youtube:focus,
  fieldset[disabled] .btn-color.btn-youtube.focus,
  fieldset[disabled] .btn-color.btn-youtube:active,
  fieldset[disabled] .btn-color.btn-youtube.active {
    background-color: #ef4e41;
    border-color: #ef4e41; }
  .btn-color.btn-youtube .badge {
    color: #ef4e41;
    background-color: #FFFFFF; }

.btn-color.btn-pinterest {
  color: #FFFFFF;
  background-color: #e13138;
  border-color: #e13138; }
  .btn-color.btn-pinterest .icon {
    color: #FFFFFF; }
  .btn-color.btn-pinterest:focus, .btn-color.btn-pinterest.focus {
    color: #FFFFFF;
    background-color: #e13138;
    border-color: #e13138;
    outline: none; }
  .btn-color.btn-pinterest:hover, .btn-color.btn-pinterest.hover {
    color: #FFFFFF;
    box-shadow: inset 0 -1px 0 #c31c23;
    background-color: #e23a41;
    border-color: #e23a41;
    border-bottom-color: #c31c23; }
  .btn-color.btn-pinterest:active, .btn-color.btn-pinterest.active, .btn-color.btn-pinterest:not([disabled]):not(.disabled):active, .btn-color.btn-pinterest:not([disabled]):not(.disabled).active,
  .show > .btn-color.btn-pinterest.dropdown-toggle {
    color: #FFFFFF;
    box-shadow: inset 0 2px 0 #c31c23;
    background-color: #d41f26;
    border-color: #d41f26;
    border-top-color: #a3181d; }
    .btn-color.btn-pinterest:active:hover, .btn-color.btn-pinterest:active:focus, .btn-color.btn-pinterest:active.focus, .btn-color.btn-pinterest.active:hover, .btn-color.btn-pinterest.active:focus, .btn-color.btn-pinterest.active.focus, .btn-color.btn-pinterest:not([disabled]):not(.disabled):active:hover, .btn-color.btn-pinterest:not([disabled]):not(.disabled):active:focus, .btn-color.btn-pinterest:not([disabled]):not(.disabled):active.focus, .btn-color.btn-pinterest:not([disabled]):not(.disabled).active:hover, .btn-color.btn-pinterest:not([disabled]):not(.disabled).active:focus, .btn-color.btn-pinterest:not([disabled]):not(.disabled).active.focus,
    .show > .btn-color.btn-pinterest.dropdown-toggle:hover,
    .show > .btn-color.btn-pinterest.dropdown-toggle:focus,
    .show > .btn-color.btn-pinterest.dropdown-toggle.focus {
      color: #FFFFFF;
      background-color: #d41f26;
      border-color: transparent;
      border-top-color: #a3181d;
      box-shadow: inset 0 2px 0 #c31c23; }
      .btn-color.btn-pinterest:active:hover .icon, .btn-color.btn-pinterest:active:focus .icon, .btn-color.btn-pinterest:active.focus .icon, .btn-color.btn-pinterest.active:hover .icon, .btn-color.btn-pinterest.active:focus .icon, .btn-color.btn-pinterest.active.focus .icon, .btn-color.btn-pinterest:not([disabled]):not(.disabled):active:hover .icon, .btn-color.btn-pinterest:not([disabled]):not(.disabled):active:focus .icon, .btn-color.btn-pinterest:not([disabled]):not(.disabled):active.focus .icon, .btn-color.btn-pinterest:not([disabled]):not(.disabled).active:hover .icon, .btn-color.btn-pinterest:not([disabled]):not(.disabled).active:focus .icon, .btn-color.btn-pinterest:not([disabled]):not(.disabled).active.focus .icon,
      .show > .btn-color.btn-pinterest.dropdown-toggle:hover .icon,
      .show > .btn-color.btn-pinterest.dropdown-toggle:focus .icon,
      .show > .btn-color.btn-pinterest.dropdown-toggle.focus .icon {
        color: #FFFFFF; }
  .btn-color.btn-pinterest:active, .btn-color.btn-pinterest.active, .btn-color.btn-pinterest:not([disabled]):not(.disabled):active, .btn-color.btn-pinterest:not([disabled]):not(.disabled).active,
  .show > .btn-color.btn-pinterest.dropdown-toggle {
    background-image: none; }
  .btn-color.btn-pinterest.disabled, .btn-color.btn-pinterest.disabled:hover, .btn-color.btn-pinterest.disabled:focus, .btn-color.btn-pinterest.disabled.focus, .btn-color.btn-pinterest.disabled:active, .btn-color.btn-pinterest.disabled.active, .btn-color.btn-pinterest[disabled], .btn-color.btn-pinterest[disabled]:hover, .btn-color.btn-pinterest[disabled]:focus, .btn-color.btn-pinterest[disabled].focus, .btn-color.btn-pinterest[disabled]:active, .btn-color.btn-pinterest[disabled].active,
  fieldset[disabled] .btn-color.btn-pinterest,
  fieldset[disabled] .btn-color.btn-pinterest:hover,
  fieldset[disabled] .btn-color.btn-pinterest:focus,
  fieldset[disabled] .btn-color.btn-pinterest.focus,
  fieldset[disabled] .btn-color.btn-pinterest:active,
  fieldset[disabled] .btn-color.btn-pinterest.active {
    background-color: #e13138;
    border-color: #e13138; }
  .btn-color.btn-pinterest .badge {
    color: #e13138;
    background-color: #FFFFFF; }

.btn-color.btn-github {
  color: #FFFFFF;
  background-color: #333;
  border-color: #333; }
  .btn-color.btn-github .icon {
    color: #FFFFFF; }
  .btn-color.btn-github:focus, .btn-color.btn-github.focus {
    color: #FFFFFF;
    background-color: #333;
    border-color: #333;
    outline: none; }
  .btn-color.btn-github:hover, .btn-color.btn-github.hover {
    color: #FFFFFF;
    box-shadow: inset 0 -1px 0 #1a1919;
    background-color: #383838;
    border-color: #383838;
    border-bottom-color: #1a1919; }
  .btn-color.btn-github:active, .btn-color.btn-github.active, .btn-color.btn-github:not([disabled]):not(.disabled):active, .btn-color.btn-github:not([disabled]):not(.disabled).active,
  .show > .btn-color.btn-github.dropdown-toggle {
    color: #FFFFFF;
    box-shadow: inset 0 2px 0 #1a1919;
    background-color: #242424;
    border-color: #242424;
    border-top-color: #080808; }
    .btn-color.btn-github:active:hover, .btn-color.btn-github:active:focus, .btn-color.btn-github:active.focus, .btn-color.btn-github.active:hover, .btn-color.btn-github.active:focus, .btn-color.btn-github.active.focus, .btn-color.btn-github:not([disabled]):not(.disabled):active:hover, .btn-color.btn-github:not([disabled]):not(.disabled):active:focus, .btn-color.btn-github:not([disabled]):not(.disabled):active.focus, .btn-color.btn-github:not([disabled]):not(.disabled).active:hover, .btn-color.btn-github:not([disabled]):not(.disabled).active:focus, .btn-color.btn-github:not([disabled]):not(.disabled).active.focus,
    .show > .btn-color.btn-github.dropdown-toggle:hover,
    .show > .btn-color.btn-github.dropdown-toggle:focus,
    .show > .btn-color.btn-github.dropdown-toggle.focus {
      color: #FFFFFF;
      background-color: #242424;
      border-color: transparent;
      border-top-color: #080808;
      box-shadow: inset 0 2px 0 #1a1919; }
      .btn-color.btn-github:active:hover .icon, .btn-color.btn-github:active:focus .icon, .btn-color.btn-github:active.focus .icon, .btn-color.btn-github.active:hover .icon, .btn-color.btn-github.active:focus .icon, .btn-color.btn-github.active.focus .icon, .btn-color.btn-github:not([disabled]):not(.disabled):active:hover .icon, .btn-color.btn-github:not([disabled]):not(.disabled):active:focus .icon, .btn-color.btn-github:not([disabled]):not(.disabled):active.focus .icon, .btn-color.btn-github:not([disabled]):not(.disabled).active:hover .icon, .btn-color.btn-github:not([disabled]):not(.disabled).active:focus .icon, .btn-color.btn-github:not([disabled]):not(.disabled).active.focus .icon,
      .show > .btn-color.btn-github.dropdown-toggle:hover .icon,
      .show > .btn-color.btn-github.dropdown-toggle:focus .icon,
      .show > .btn-color.btn-github.dropdown-toggle.focus .icon {
        color: #FFFFFF; }
  .btn-color.btn-github:active, .btn-color.btn-github.active, .btn-color.btn-github:not([disabled]):not(.disabled):active, .btn-color.btn-github:not([disabled]):not(.disabled).active,
  .show > .btn-color.btn-github.dropdown-toggle {
    background-image: none; }
  .btn-color.btn-github.disabled, .btn-color.btn-github.disabled:hover, .btn-color.btn-github.disabled:focus, .btn-color.btn-github.disabled.focus, .btn-color.btn-github.disabled:active, .btn-color.btn-github.disabled.active, .btn-color.btn-github[disabled], .btn-color.btn-github[disabled]:hover, .btn-color.btn-github[disabled]:focus, .btn-color.btn-github[disabled].focus, .btn-color.btn-github[disabled]:active, .btn-color.btn-github[disabled].active,
  fieldset[disabled] .btn-color.btn-github,
  fieldset[disabled] .btn-color.btn-github:hover,
  fieldset[disabled] .btn-color.btn-github:focus,
  fieldset[disabled] .btn-color.btn-github.focus,
  fieldset[disabled] .btn-color.btn-github:active,
  fieldset[disabled] .btn-color.btn-github.active {
    background-color: #333;
    border-color: #333; }
  .btn-color.btn-github .badge {
    color: #333;
    background-color: #FFFFFF; }

.btn-color.btn-tumblr {
  color: #FFFFFF;
  background-color: #426d9b;
  border-color: #426d9b; }
  .btn-color.btn-tumblr .icon {
    color: #FFFFFF; }
  .btn-color.btn-tumblr:focus, .btn-color.btn-tumblr.focus {
    color: #FFFFFF;
    background-color: #426d9b;
    border-color: #426d9b;
    outline: none; }
  .btn-color.btn-tumblr:hover, .btn-color.btn-tumblr.hover {
    color: #FFFFFF;
    box-shadow: inset 0 -1px 0 #335477;
    background-color: #4572a2;
    border-color: #4572a2;
    border-bottom-color: #335477; }
  .btn-color.btn-tumblr:active, .btn-color.btn-tumblr.active, .btn-color.btn-tumblr:not([disabled]):not(.disabled):active, .btn-color.btn-tumblr:not([disabled]):not(.disabled).active,
  .show > .btn-color.btn-tumblr.dropdown-toggle {
    color: #FFFFFF;
    box-shadow: inset 0 2px 0 #335477;
    background-color: #395e86;
    border-color: #395e86;
    border-top-color: #28425e; }
    .btn-color.btn-tumblr:active:hover, .btn-color.btn-tumblr:active:focus, .btn-color.btn-tumblr:active.focus, .btn-color.btn-tumblr.active:hover, .btn-color.btn-tumblr.active:focus, .btn-color.btn-tumblr.active.focus, .btn-color.btn-tumblr:not([disabled]):not(.disabled):active:hover, .btn-color.btn-tumblr:not([disabled]):not(.disabled):active:focus, .btn-color.btn-tumblr:not([disabled]):not(.disabled):active.focus, .btn-color.btn-tumblr:not([disabled]):not(.disabled).active:hover, .btn-color.btn-tumblr:not([disabled]):not(.disabled).active:focus, .btn-color.btn-tumblr:not([disabled]):not(.disabled).active.focus,
    .show > .btn-color.btn-tumblr.dropdown-toggle:hover,
    .show > .btn-color.btn-tumblr.dropdown-toggle:focus,
    .show > .btn-color.btn-tumblr.dropdown-toggle.focus {
      color: #FFFFFF;
      background-color: #395e86;
      border-color: transparent;
      border-top-color: #28425e;
      box-shadow: inset 0 2px 0 #335477; }
      .btn-color.btn-tumblr:active:hover .icon, .btn-color.btn-tumblr:active:focus .icon, .btn-color.btn-tumblr:active.focus .icon, .btn-color.btn-tumblr.active:hover .icon, .btn-color.btn-tumblr.active:focus .icon, .btn-color.btn-tumblr.active.focus .icon, .btn-color.btn-tumblr:not([disabled]):not(.disabled):active:hover .icon, .btn-color.btn-tumblr:not([disabled]):not(.disabled):active:focus .icon, .btn-color.btn-tumblr:not([disabled]):not(.disabled):active.focus .icon, .btn-color.btn-tumblr:not([disabled]):not(.disabled).active:hover .icon, .btn-color.btn-tumblr:not([disabled]):not(.disabled).active:focus .icon, .btn-color.btn-tumblr:not([disabled]):not(.disabled).active.focus .icon,
      .show > .btn-color.btn-tumblr.dropdown-toggle:hover .icon,
      .show > .btn-color.btn-tumblr.dropdown-toggle:focus .icon,
      .show > .btn-color.btn-tumblr.dropdown-toggle.focus .icon {
        color: #FFFFFF; }
  .btn-color.btn-tumblr:active, .btn-color.btn-tumblr.active, .btn-color.btn-tumblr:not([disabled]):not(.disabled):active, .btn-color.btn-tumblr:not([disabled]):not(.disabled).active,
  .show > .btn-color.btn-tumblr.dropdown-toggle {
    background-image: none; }
  .btn-color.btn-tumblr.disabled, .btn-color.btn-tumblr.disabled:hover, .btn-color.btn-tumblr.disabled:focus, .btn-color.btn-tumblr.disabled.focus, .btn-color.btn-tumblr.disabled:active, .btn-color.btn-tumblr.disabled.active, .btn-color.btn-tumblr[disabled], .btn-color.btn-tumblr[disabled]:hover, .btn-color.btn-tumblr[disabled]:focus, .btn-color.btn-tumblr[disabled].focus, .btn-color.btn-tumblr[disabled]:active, .btn-color.btn-tumblr[disabled].active,
  fieldset[disabled] .btn-color.btn-tumblr,
  fieldset[disabled] .btn-color.btn-tumblr:hover,
  fieldset[disabled] .btn-color.btn-tumblr:focus,
  fieldset[disabled] .btn-color.btn-tumblr.focus,
  fieldset[disabled] .btn-color.btn-tumblr:active,
  fieldset[disabled] .btn-color.btn-tumblr.active {
    background-color: #426d9b;
    border-color: #426d9b; }
  .btn-color.btn-tumblr .badge {
    color: #426d9b;
    background-color: #FFFFFF; }

.btn-color.btn-dropbox {
  color: #FFFFFF;
  background-color: #007EE5;
  border-color: #007EE5; }
  .btn-color.btn-dropbox .icon {
    color: #FFFFFF; }
  .btn-color.btn-dropbox:focus, .btn-color.btn-dropbox.focus {
    color: #FFFFFF;
    background-color: #007EE5;
    border-color: #007EE5;
    outline: none; }
  .btn-color.btn-dropbox:hover, .btn-color.btn-dropbox.hover {
    color: #FFFFFF;
    box-shadow: inset 0 -1px 0 #0062b2;
    background-color: #0084ef;
    border-color: #0084ef;
    border-bottom-color: #0062b2; }
  .btn-color.btn-dropbox:active, .btn-color.btn-dropbox.active, .btn-color.btn-dropbox:not([disabled]):not(.disabled):active, .btn-color.btn-dropbox:not([disabled]):not(.disabled).active,
  .show > .btn-color.btn-dropbox.dropdown-toggle {
    color: #FFFFFF;
    box-shadow: inset 0 2px 0 #0062b2;
    background-color: #006dc6;
    border-color: #006dc6;
    border-top-color: #004e8e; }
    .btn-color.btn-dropbox:active:hover, .btn-color.btn-dropbox:active:focus, .btn-color.btn-dropbox:active.focus, .btn-color.btn-dropbox.active:hover, .btn-color.btn-dropbox.active:focus, .btn-color.btn-dropbox.active.focus, .btn-color.btn-dropbox:not([disabled]):not(.disabled):active:hover, .btn-color.btn-dropbox:not([disabled]):not(.disabled):active:focus, .btn-color.btn-dropbox:not([disabled]):not(.disabled):active.focus, .btn-color.btn-dropbox:not([disabled]):not(.disabled).active:hover, .btn-color.btn-dropbox:not([disabled]):not(.disabled).active:focus, .btn-color.btn-dropbox:not([disabled]):not(.disabled).active.focus,
    .show > .btn-color.btn-dropbox.dropdown-toggle:hover,
    .show > .btn-color.btn-dropbox.dropdown-toggle:focus,
    .show > .btn-color.btn-dropbox.dropdown-toggle.focus {
      color: #FFFFFF;
      background-color: #006dc6;
      border-color: transparent;
      border-top-color: #004e8e;
      box-shadow: inset 0 2px 0 #0062b2; }
      .btn-color.btn-dropbox:active:hover .icon, .btn-color.btn-dropbox:active:focus .icon, .btn-color.btn-dropbox:active.focus .icon, .btn-color.btn-dropbox.active:hover .icon, .btn-color.btn-dropbox.active:focus .icon, .btn-color.btn-dropbox.active.focus .icon, .btn-color.btn-dropbox:not([disabled]):not(.disabled):active:hover .icon, .btn-color.btn-dropbox:not([disabled]):not(.disabled):active:focus .icon, .btn-color.btn-dropbox:not([disabled]):not(.disabled):active.focus .icon, .btn-color.btn-dropbox:not([disabled]):not(.disabled).active:hover .icon, .btn-color.btn-dropbox:not([disabled]):not(.disabled).active:focus .icon, .btn-color.btn-dropbox:not([disabled]):not(.disabled).active.focus .icon,
      .show > .btn-color.btn-dropbox.dropdown-toggle:hover .icon,
      .show > .btn-color.btn-dropbox.dropdown-toggle:focus .icon,
      .show > .btn-color.btn-dropbox.dropdown-toggle.focus .icon {
        color: #FFFFFF; }
  .btn-color.btn-dropbox:active, .btn-color.btn-dropbox.active, .btn-color.btn-dropbox:not([disabled]):not(.disabled):active, .btn-color.btn-dropbox:not([disabled]):not(.disabled).active,
  .show > .btn-color.btn-dropbox.dropdown-toggle {
    background-image: none; }
  .btn-color.btn-dropbox.disabled, .btn-color.btn-dropbox.disabled:hover, .btn-color.btn-dropbox.disabled:focus, .btn-color.btn-dropbox.disabled.focus, .btn-color.btn-dropbox.disabled:active, .btn-color.btn-dropbox.disabled.active, .btn-color.btn-dropbox[disabled], .btn-color.btn-dropbox[disabled]:hover, .btn-color.btn-dropbox[disabled]:focus, .btn-color.btn-dropbox[disabled].focus, .btn-color.btn-dropbox[disabled]:active, .btn-color.btn-dropbox[disabled].active,
  fieldset[disabled] .btn-color.btn-dropbox,
  fieldset[disabled] .btn-color.btn-dropbox:hover,
  fieldset[disabled] .btn-color.btn-dropbox:focus,
  fieldset[disabled] .btn-color.btn-dropbox.focus,
  fieldset[disabled] .btn-color.btn-dropbox:active,
  fieldset[disabled] .btn-color.btn-dropbox.active {
    background-color: #007EE5;
    border-color: #007EE5; }
  .btn-color.btn-dropbox .badge {
    color: #007EE5;
    background-color: #FFFFFF; }

.btn-color.btn-evernote {
  color: #FFFFFF;
  background-color: #2DBE60;
  border-color: #2DBE60; }
  .btn-color.btn-evernote .icon {
    color: #FFFFFF; }
  .btn-color.btn-evernote:focus, .btn-color.btn-evernote.focus {
    color: #FFFFFF;
    background-color: #2DBE60;
    border-color: #2DBE60;
    outline: none; }
  .btn-color.btn-evernote:hover, .btn-color.btn-evernote.hover {
    color: #FFFFFF;
    box-shadow: inset 0 -1px 0 #23954b;
    background-color: #2fc664;
    border-color: #2fc664;
    border-bottom-color: #23954b; }
  .btn-color.btn-evernote:active, .btn-color.btn-evernote.active, .btn-color.btn-evernote:not([disabled]):not(.disabled):active, .btn-color.btn-evernote:not([disabled]):not(.disabled).active,
  .show > .btn-color.btn-evernote.dropdown-toggle {
    color: #FFFFFF;
    box-shadow: inset 0 2px 0 #23954b;
    background-color: #27a553;
    border-color: #27a553;
    border-top-color: #1c783d; }
    .btn-color.btn-evernote:active:hover, .btn-color.btn-evernote:active:focus, .btn-color.btn-evernote:active.focus, .btn-color.btn-evernote.active:hover, .btn-color.btn-evernote.active:focus, .btn-color.btn-evernote.active.focus, .btn-color.btn-evernote:not([disabled]):not(.disabled):active:hover, .btn-color.btn-evernote:not([disabled]):not(.disabled):active:focus, .btn-color.btn-evernote:not([disabled]):not(.disabled):active.focus, .btn-color.btn-evernote:not([disabled]):not(.disabled).active:hover, .btn-color.btn-evernote:not([disabled]):not(.disabled).active:focus, .btn-color.btn-evernote:not([disabled]):not(.disabled).active.focus,
    .show > .btn-color.btn-evernote.dropdown-toggle:hover,
    .show > .btn-color.btn-evernote.dropdown-toggle:focus,
    .show > .btn-color.btn-evernote.dropdown-toggle.focus {
      color: #FFFFFF;
      background-color: #27a553;
      border-color: transparent;
      border-top-color: #1c783d;
      box-shadow: inset 0 2px 0 #23954b; }
      .btn-color.btn-evernote:active:hover .icon, .btn-color.btn-evernote:active:focus .icon, .btn-color.btn-evernote:active.focus .icon, .btn-color.btn-evernote.active:hover .icon, .btn-color.btn-evernote.active:focus .icon, .btn-color.btn-evernote.active.focus .icon, .btn-color.btn-evernote:not([disabled]):not(.disabled):active:hover .icon, .btn-color.btn-evernote:not([disabled]):not(.disabled):active:focus .icon, .btn-color.btn-evernote:not([disabled]):not(.disabled):active.focus .icon, .btn-color.btn-evernote:not([disabled]):not(.disabled).active:hover .icon, .btn-color.btn-evernote:not([disabled]):not(.disabled).active:focus .icon, .btn-color.btn-evernote:not([disabled]):not(.disabled).active.focus .icon,
      .show > .btn-color.btn-evernote.dropdown-toggle:hover .icon,
      .show > .btn-color.btn-evernote.dropdown-toggle:focus .icon,
      .show > .btn-color.btn-evernote.dropdown-toggle.focus .icon {
        color: #FFFFFF; }
  .btn-color.btn-evernote:active, .btn-color.btn-evernote.active, .btn-color.btn-evernote:not([disabled]):not(.disabled):active, .btn-color.btn-evernote:not([disabled]):not(.disabled).active,
  .show > .btn-color.btn-evernote.dropdown-toggle {
    background-image: none; }
  .btn-color.btn-evernote.disabled, .btn-color.btn-evernote.disabled:hover, .btn-color.btn-evernote.disabled:focus, .btn-color.btn-evernote.disabled.focus, .btn-color.btn-evernote.disabled:active, .btn-color.btn-evernote.disabled.active, .btn-color.btn-evernote[disabled], .btn-color.btn-evernote[disabled]:hover, .btn-color.btn-evernote[disabled]:focus, .btn-color.btn-evernote[disabled].focus, .btn-color.btn-evernote[disabled]:active, .btn-color.btn-evernote[disabled].active,
  fieldset[disabled] .btn-color.btn-evernote,
  fieldset[disabled] .btn-color.btn-evernote:hover,
  fieldset[disabled] .btn-color.btn-evernote:focus,
  fieldset[disabled] .btn-color.btn-evernote.focus,
  fieldset[disabled] .btn-color.btn-evernote:active,
  fieldset[disabled] .btn-color.btn-evernote.active {
    background-color: #2DBE60;
    border-color: #2DBE60; }
  .btn-color.btn-evernote .badge {
    color: #2DBE60;
    background-color: #FFFFFF; }

.btn .icon {
  font-size: 1.231rem;
  line-height: 15px;
  vertical-align: middle; }
  .btn .icon.icon-left {
    margin-right: 1px; }
  .btn .icon.icon-right {
    margin-left: 1px; }
  .btn .icon.fa {
    font-size: 1.154rem; }

.btn.btn-lg .icon, .btn-group-lg > .btn .icon {
  font-size: 1.615rem;
  line-height: 20px; }

.btn.btn-sm .icon, .btn-group-sm > .btn .icon {
  font-size: 1.154rem;
  line-height: 15px; }

.btn.btn-xs .icon {
  font-size: 1.077rem;
  line-height: 14px; }

.btn-group .btn.dropdown-toggle.btn-primary,
.btn-group .btn.dropdown-toggle.btn-success,
.btn-group .btn.dropdown-toggle.btn-warning,
.btn-group .btn.dropdown-toggle.btn-danger {
  border-right-color: transparent; }
  .btn-group .btn.dropdown-toggle.btn-primary:hover,
  .btn-group .btn.dropdown-toggle.btn-success:hover,
  .btn-group .btn.dropdown-toggle.btn-warning:hover,
  .btn-group .btn.dropdown-toggle.btn-danger:hover {
    border-right-color: transparent; }

.btn.dropdown-toggle .mdi-chevron-down,
.btn.dropdown-toggle .mdi-chevron-up {
  font-size: 1.385rem;
  vertical-align: middle;
  line-height: 17px; }

.btn.dropdown-toggle .icon-dropdown {
  font-size: 1.385rem;
  line-height: 17px;
  margin-left: 6px; }
  .btn.dropdown-toggle .icon-dropdown-left {
    margin-left: 0;
    margin-right: 6px; }

.btn-big {
  padding: 10px 13px;
  text-align: center;
  line-height: 13px;
  min-width: 62px; }
  .btn-big > .icon {
    display: block;
    font-size: 2rem;
    line-height: 20px;
    margin-bottom: 7px; }

.btn-outline.btn-primary {
  background-color: transparent;
  color: #FFFFFF;
  border-color: rgba(255, 255, 255, 0.5);
  border-width: 2px; }
  .btn-outline.btn-primary:hover {
    border-color: #FFFFFF;
    box-shadow: none;
    background-color: rgba(255, 255, 255, 0.1); }
    .btn-outline.btn-primary:hover + .btn {
      border-left-color: transparent; }
  .btn-outline.btn-primary:active, .btn-outline.btn-primary.active {
    background-color: #FFFFFF;
    color: #5f99f5;
    border-color: transparent;
    border-width: 2px;
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.05); }
  .btn-outline.btn-primary:focus {
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.05); }

.btn-outline.btn-secondary {
  background-color: transparent;
  color: #FFFFFF;
  border-color: rgba(255, 255, 255, 0.5);
  border-width: 2px; }
  .btn-outline.btn-secondary:hover {
    border-color: #FFFFFF;
    box-shadow: none;
    background-color: rgba(255, 255, 255, 0.1); }
    .btn-outline.btn-secondary:hover + .btn {
      border-left-color: transparent; }
  .btn-outline.btn-secondary:active, .btn-outline.btn-secondary.active {
    background-color: #FFFFFF;
    color: #d9d9d9;
    border-color: transparent;
    border-width: 2px;
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.05); }
  .btn-outline.btn-secondary:focus {
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.05); }

.btn-outline.btn-success {
  background-color: transparent;
  color: #FFFFFF;
  border-color: rgba(255, 255, 255, 0.5);
  border-width: 2px; }
  .btn-outline.btn-success:hover {
    border-color: #FFFFFF;
    box-shadow: none;
    background-color: rgba(255, 255, 255, 0.1); }
    .btn-outline.btn-success:hover + .btn {
      border-left-color: transparent; }
  .btn-outline.btn-success:active, .btn-outline.btn-success.active {
    background-color: #FFFFFF;
    color: #37b358;
    border-color: transparent;
    border-width: 2px;
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.05); }
  .btn-outline.btn-success:focus {
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.05); }

.btn-outline.btn-warning {
  background-color: transparent;
  color: #FFFFFF;
  border-color: rgba(255, 255, 255, 0.5);
  border-width: 2px; }
  .btn-outline.btn-warning:hover {
    border-color: #FFFFFF;
    box-shadow: none;
    background-color: rgba(255, 255, 255, 0.1); }
    .btn-outline.btn-warning:hover + .btn {
      border-left-color: transparent; }
  .btn-outline.btn-warning:active, .btn-outline.btn-warning.active {
    background-color: #FFFFFF;
    color: #f6c163;
    border-color: transparent;
    border-width: 2px;
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.05); }
  .btn-outline.btn-warning:focus {
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.05); }

.btn-outline.btn-danger {
  background-color: transparent;
  color: #FFFFFF;
  border-color: rgba(255, 255, 255, 0.5);
  border-width: 2px; }
  .btn-outline.btn-danger:hover {
    border-color: #FFFFFF;
    box-shadow: none;
    background-color: rgba(255, 255, 255, 0.1); }
    .btn-outline.btn-danger:hover + .btn {
      border-left-color: transparent; }
  .btn-outline.btn-danger:active, .btn-outline.btn-danger.active {
    background-color: #FFFFFF;
    color: #eb6357;
    border-color: transparent;
    border-width: 2px;
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.05); }
  .btn-outline.btn-danger:focus {
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.05); }

.btn-group > .btn.btn-secondary:hover + .btn,
.btn-group > .btn.btn-secondary.active + .btn, .input-group-btn > .btn.btn-secondary:hover + .btn,
.input-group-btn > .btn.btn-secondary.active + .btn, .input-group-prepend > .btn.btn-secondary:hover + .btn,
.input-group-prepend > .btn.btn-secondary.active + .btn, .input-group-append > .btn.btn-secondary:hover + .btn,
.input-group-append > .btn.btn-secondary.active + .btn {
  border-left-color: #bababa; }

.btn-group > .btn.btn-primary, .input-group-btn > .btn.btn-primary, .input-group-prepend > .btn.btn-primary, .input-group-append > .btn.btn-primary {
  border-left-color: #206ff2;
  border-right-color: #206ff2; }
  .btn-group > .btn.btn-primary:hover, .btn-group > .btn.btn-primary:active, .btn-group > .btn.btn-primary.active, .input-group-btn > .btn.btn-primary:hover, .input-group-btn > .btn.btn-primary:active, .input-group-btn > .btn.btn-primary.active, .input-group-prepend > .btn.btn-primary:hover, .input-group-prepend > .btn.btn-primary:active, .input-group-prepend > .btn.btn-primary.active, .input-group-append > .btn.btn-primary:hover, .input-group-append > .btn.btn-primary:active, .input-group-append > .btn.btn-primary.active {
    border-left-color: #0d5fe6;
    border-right-color: #0d5fe6;
    border-bottom-color: transparent; }
  .btn-group > .btn.btn-primary.active, .btn-group > .btn.btn-primary:active, .input-group-btn > .btn.btn-primary.active, .input-group-btn > .btn.btn-primary:active, .input-group-prepend > .btn.btn-primary.active, .input-group-prepend > .btn.btn-primary:active, .input-group-append > .btn.btn-primary.active, .input-group-append > .btn.btn-primary:active {
    box-shadow: inset 0 2px 0 #1266f1; }
  .btn-group > .btn.btn-primary:hover + .btn,
  .btn-group > .btn.btn-primary:active + .btn,
  .btn-group > .btn.btn-primary.active + .btn, .input-group-btn > .btn.btn-primary:hover + .btn,
  .input-group-btn > .btn.btn-primary:active + .btn,
  .input-group-btn > .btn.btn-primary.active + .btn, .input-group-prepend > .btn.btn-primary:hover + .btn,
  .input-group-prepend > .btn.btn-primary:active + .btn,
  .input-group-prepend > .btn.btn-primary.active + .btn, .input-group-append > .btn.btn-primary:hover + .btn,
  .input-group-append > .btn.btn-primary:active + .btn,
  .input-group-append > .btn.btn-primary.active + .btn {
    border-left-color: #0d5fe6; }
  .btn-group > .btn.btn-primary:first-child, .input-group-btn > .btn.btn-primary:first-child, .input-group-prepend > .btn.btn-primary:first-child, .input-group-append > .btn.btn-primary:first-child {
    border-left-color: transparent; }
  .btn-group > .btn.btn-primary:last-child, .input-group-btn > .btn.btn-primary:last-child, .input-group-prepend > .btn.btn-primary:last-child, .input-group-append > .btn.btn-primary:last-child {
    border-right-color: transparent; }

.btn-group > .btn.btn-success, .input-group-btn > .btn.btn-success, .input-group-prepend > .btn.btn-success, .input-group-append > .btn.btn-success {
  border-left-color: #2c8d46;
  border-right-color: #2c8d46; }
  .btn-group > .btn.btn-success:hover, .btn-group > .btn.btn-success:active, .btn-group > .btn.btn-success.active, .input-group-btn > .btn.btn-success:hover, .input-group-btn > .btn.btn-success:active, .input-group-btn > .btn.btn-success.active, .input-group-prepend > .btn.btn-success:hover, .input-group-prepend > .btn.btn-success:active, .input-group-prepend > .btn.btn-success.active, .input-group-append > .btn.btn-success:hover, .input-group-append > .btn.btn-success:active, .input-group-append > .btn.btn-success.active {
    border-left-color: #24753a;
    border-right-color: #24753a;
    border-bottom-color: transparent; }
  .btn-group > .btn.btn-success.active, .btn-group > .btn.btn-success:active, .input-group-btn > .btn.btn-success.active, .input-group-btn > .btn.btn-success:active, .input-group-prepend > .btn.btn-success.active, .input-group-prepend > .btn.btn-success:active, .input-group-append > .btn.btn-success.active, .input-group-append > .btn.btn-success:active {
    box-shadow: inset 0 2px 0 #288140; }
  .btn-group > .btn.btn-success:hover + .btn,
  .btn-group > .btn.btn-success:active + .btn,
  .btn-group > .btn.btn-success.active + .btn, .input-group-btn > .btn.btn-success:hover + .btn,
  .input-group-btn > .btn.btn-success:active + .btn,
  .input-group-btn > .btn.btn-success.active + .btn, .input-group-prepend > .btn.btn-success:hover + .btn,
  .input-group-prepend > .btn.btn-success:active + .btn,
  .input-group-prepend > .btn.btn-success.active + .btn, .input-group-append > .btn.btn-success:hover + .btn,
  .input-group-append > .btn.btn-success:active + .btn,
  .input-group-append > .btn.btn-success.active + .btn {
    border-left-color: #24753a; }
  .btn-group > .btn.btn-success:first-child, .input-group-btn > .btn.btn-success:first-child, .input-group-prepend > .btn.btn-success:first-child, .input-group-append > .btn.btn-success:first-child {
    border-left-color: transparent; }
  .btn-group > .btn.btn-success:last-child, .input-group-btn > .btn.btn-success:last-child, .input-group-prepend > .btn.btn-success:last-child, .input-group-append > .btn.btn-success:last-child {
    border-right-color: transparent; }

.btn-group > .btn.btn-warning, .input-group-btn > .btn.btn-warning, .input-group-prepend > .btn.btn-warning, .input-group-append > .btn.btn-warning {
  border-left-color: #d9a203;
  border-right-color: #d9a203; }
  .btn-group > .btn.btn-warning:hover, .btn-group > .btn.btn-warning:active, .btn-group > .btn.btn-warning.active, .input-group-btn > .btn.btn-warning:hover, .input-group-btn > .btn.btn-warning:active, .input-group-btn > .btn.btn-warning.active, .input-group-prepend > .btn.btn-warning:hover, .input-group-prepend > .btn.btn-warning:active, .input-group-prepend > .btn.btn-warning.active, .input-group-append > .btn.btn-warning:hover, .input-group-append > .btn.btn-warning:active, .input-group-append > .btn.btn-warning.active {
    border-left-color: #bb8c03;
    border-right-color: #bb8c03;
    border-bottom-color: transparent; }
  .btn-group > .btn.btn-warning.active, .btn-group > .btn.btn-warning:active, .input-group-btn > .btn.btn-warning.active, .input-group-btn > .btn.btn-warning:active, .input-group-prepend > .btn.btn-warning.active, .input-group-prepend > .btn.btn-warning:active, .input-group-append > .btn.btn-warning.active, .input-group-append > .btn.btn-warning:active {
    box-shadow: inset 0 2px 0 #ca9703; }
  .btn-group > .btn.btn-warning:hover + .btn,
  .btn-group > .btn.btn-warning:active + .btn,
  .btn-group > .btn.btn-warning.active + .btn, .input-group-btn > .btn.btn-warning:hover + .btn,
  .input-group-btn > .btn.btn-warning:active + .btn,
  .input-group-btn > .btn.btn-warning.active + .btn, .input-group-prepend > .btn.btn-warning:hover + .btn,
  .input-group-prepend > .btn.btn-warning:active + .btn,
  .input-group-prepend > .btn.btn-warning.active + .btn, .input-group-append > .btn.btn-warning:hover + .btn,
  .input-group-append > .btn.btn-warning:active + .btn,
  .input-group-append > .btn.btn-warning.active + .btn {
    border-left-color: #bb8c03; }
  .btn-group > .btn.btn-warning:first-child, .input-group-btn > .btn.btn-warning:first-child, .input-group-prepend > .btn.btn-warning:first-child, .input-group-append > .btn.btn-warning:first-child {
    border-left-color: transparent; }
  .btn-group > .btn.btn-warning:last-child, .input-group-btn > .btn.btn-warning:last-child, .input-group-prepend > .btn.btn-warning:last-child, .input-group-append > .btn.btn-warning:last-child {
    border-right-color: transparent; }

.btn-group > .btn.btn-danger, .input-group-btn > .btn.btn-danger, .input-group-prepend > .btn.btn-danger, .input-group-append > .btn.btn-danger {
  border-left-color: #e42718;
  border-right-color: #e42718; }
  .btn-group > .btn.btn-danger:hover, .btn-group > .btn.btn-danger:active, .btn-group > .btn.btn-danger.active, .input-group-btn > .btn.btn-danger:hover, .input-group-btn > .btn.btn-danger:active, .input-group-btn > .btn.btn-danger.active, .input-group-prepend > .btn.btn-danger:hover, .input-group-prepend > .btn.btn-danger:active, .input-group-prepend > .btn.btn-danger.active, .input-group-append > .btn.btn-danger:hover, .input-group-append > .btn.btn-danger:active, .input-group-append > .btn.btn-danger.active {
    border-left-color: #c82315;
    border-right-color: #c82315;
    border-bottom-color: transparent; }
  .btn-group > .btn.btn-danger.active, .btn-group > .btn.btn-danger:active, .input-group-btn > .btn.btn-danger.active, .input-group-btn > .btn.btn-danger:active, .input-group-prepend > .btn.btn-danger.active, .input-group-prepend > .btn.btn-danger:active, .input-group-append > .btn.btn-danger.active, .input-group-append > .btn.btn-danger:active {
    box-shadow: inset 0 2px 0 #d62516; }
  .btn-group > .btn.btn-danger:hover + .btn,
  .btn-group > .btn.btn-danger:active + .btn,
  .btn-group > .btn.btn-danger.active + .btn, .input-group-btn > .btn.btn-danger:hover + .btn,
  .input-group-btn > .btn.btn-danger:active + .btn,
  .input-group-btn > .btn.btn-danger.active + .btn, .input-group-prepend > .btn.btn-danger:hover + .btn,
  .input-group-prepend > .btn.btn-danger:active + .btn,
  .input-group-prepend > .btn.btn-danger.active + .btn, .input-group-append > .btn.btn-danger:hover + .btn,
  .input-group-append > .btn.btn-danger:active + .btn,
  .input-group-append > .btn.btn-danger.active + .btn {
    border-left-color: #c82315; }
  .btn-group > .btn.btn-danger:first-child, .input-group-btn > .btn.btn-danger:first-child, .input-group-prepend > .btn.btn-danger:first-child, .input-group-append > .btn.btn-danger:first-child {
    border-left-color: transparent; }
  .btn-group > .btn.btn-danger:last-child, .input-group-btn > .btn.btn-danger:last-child, .input-group-prepend > .btn.btn-danger:last-child, .input-group-append > .btn.btn-danger:last-child {
    border-right-color: transparent; }

.btn-group > .btn:last-of-type:not(.btn-secondary), .input-group-btn > .btn:last-of-type:not(.btn-secondary), .input-group-prepend > .btn:last-of-type:not(.btn-secondary), .input-group-append > .btn:last-of-type:not(.btn-secondary) {
  border-right-color: transparent; }
  .btn-group > .btn:last-of-type:not(.btn-secondary):hover, .input-group-btn > .btn:last-of-type:not(.btn-secondary):hover, .input-group-prepend > .btn:last-of-type:not(.btn-secondary):hover, .input-group-append > .btn:last-of-type:not(.btn-secondary):hover {
    border-right-color: transparent; }

.btn-group > .btn + .dropdown-toggle, .input-group-btn > .btn + .dropdown-toggle, .input-group-prepend > .btn + .dropdown-toggle, .input-group-append > .btn + .dropdown-toggle {
  padding-left: 7px;
  padding-right: 7px; }

.btn-group:first-child, .input-group-btn:first-child, .input-group-prepend:first-child, .input-group-append:first-child {
  border-right-color: transparent; }

.btn-group:last-child, .input-group-btn:last-child, .input-group-prepend:last-child, .input-group-append:last-child {
  border-right-color: transparent; }

.btn-group.open > .btn.btn-primary:hover, .btn-group.open > .btn.btn-success:hover, .btn-group.open > .btn.btn-warning:hover, .btn-group.open > .btn.btn-danger:hover, .input-group-btn.open > .btn.btn-primary:hover, .input-group-btn.open > .btn.btn-success:hover, .input-group-btn.open > .btn.btn-warning:hover, .input-group-btn.open > .btn.btn-danger:hover, .input-group-prepend.open > .btn.btn-primary:hover, .input-group-prepend.open > .btn.btn-success:hover, .input-group-prepend.open > .btn.btn-warning:hover, .input-group-prepend.open > .btn.btn-danger:hover, .input-group-append.open > .btn.btn-primary:hover, .input-group-append.open > .btn.btn-success:hover, .input-group-append.open > .btn.btn-warning:hover, .input-group-append.open > .btn.btn-danger:hover {
  box-shadow: inset 0 2px 0 rgba(0, 0, 0, 0.1); }

.btn-group.open > .btn + .btn-primary,
.btn-group.open > .btn + .btn-success,
.btn-group.open > .btn + .btn-warning,
.btn-group.open > .btn + .btn-danger, .input-group-btn.open > .btn + .btn-primary,
.input-group-btn.open > .btn + .btn-success,
.input-group-btn.open > .btn + .btn-warning,
.input-group-btn.open > .btn + .btn-danger, .input-group-prepend.open > .btn + .btn-primary,
.input-group-prepend.open > .btn + .btn-success,
.input-group-prepend.open > .btn + .btn-warning,
.input-group-prepend.open > .btn + .btn-danger, .input-group-append.open > .btn + .btn-primary,
.input-group-append.open > .btn + .btn-success,
.input-group-append.open > .btn + .btn-warning,
.input-group-append.open > .btn + .btn-danger {
  border-left-color: rgba(0, 0, 0, 0.15); }

.btn-group-justified {
  display: table;
  width: 100%;
  table-layout: fixed;
  border-collapse: separate; }
  .btn-group-justified .btn,
  .btn-group-justified .btn-group {
    float: none;
    display: table-cell;
    width: 1%; }
    .btn-group-justified .btn .btn,
    .btn-group-justified .btn-group .btn {
      width: 100%; }
    .btn-group-justified .btn .dropdown-menu,
    .btn-group-justified .btn-group .dropdown-menu {
      left: auto; }
  .btn-group-justified > .btn {
    border-right-width: 0; }
    .btn-group-justified > .btn:last-child {
      border-right-width: 1px; }

.btn-group-vertical > .btn.btn-secondary:hover, .btn-group-vertical > .btn.btn-secondary:focus {
  box-shadow: none; }

.btn-group-vertical > .btn.btn-secondary:active, .btn-group-vertical > .btn.btn-secondary.active {
  box-shadow: inset 0 2px 0 rgba(0, 0, 0, 0.06); }

.btn-group-vertical > .btn.btn-primary {
  border-top-color: #206ff2;
  border-bottom-color: #206ff2; }
  .btn-group-vertical > .btn.btn-primary:hover, .btn-group-vertical > .btn.btn-primary:active, .btn-group-vertical > .btn.btn-primary.active {
    box-shadow: none;
    border-top-color: #0d5fe6;
    border-bottom-color: #0d5fe6; }
  .btn-group-vertical > .btn.btn-primary:first-child {
    border-top-color: transparent; }
  .btn-group-vertical > .btn.btn-primary:last-child {
    border-bottom-color: transparent; }
  .btn-group-vertical > .btn.btn-primary:active, .btn-group-vertical > .btn.btn-primary.active {
    box-shadow: inset 0 1px 0 #0d5fe6;
    border-top-color: #0d5fe6;
    border-bottom-color: #0d5fe6; }

.btn-group-vertical > .btn.btn-success {
  border-top-color: #2c8d46;
  border-bottom-color: #2c8d46; }
  .btn-group-vertical > .btn.btn-success:hover, .btn-group-vertical > .btn.btn-success:active, .btn-group-vertical > .btn.btn-success.active {
    box-shadow: none;
    border-top-color: #24753a;
    border-bottom-color: #24753a; }
  .btn-group-vertical > .btn.btn-success:first-child {
    border-top-color: transparent; }
  .btn-group-vertical > .btn.btn-success:last-child {
    border-bottom-color: transparent; }
  .btn-group-vertical > .btn.btn-success:active, .btn-group-vertical > .btn.btn-success.active {
    box-shadow: inset 0 1px 0 #24753a;
    border-top-color: #24753a;
    border-bottom-color: #24753a; }

.btn-group-vertical > .btn.btn-warning {
  border-top-color: #d9a203;
  border-bottom-color: #d9a203; }
  .btn-group-vertical > .btn.btn-warning:hover, .btn-group-vertical > .btn.btn-warning:active, .btn-group-vertical > .btn.btn-warning.active {
    box-shadow: none;
    border-top-color: #bb8c03;
    border-bottom-color: #bb8c03; }
  .btn-group-vertical > .btn.btn-warning:first-child {
    border-top-color: transparent; }
  .btn-group-vertical > .btn.btn-warning:last-child {
    border-bottom-color: transparent; }
  .btn-group-vertical > .btn.btn-warning:active, .btn-group-vertical > .btn.btn-warning.active {
    box-shadow: inset 0 1px 0 #bb8c03;
    border-top-color: #bb8c03;
    border-bottom-color: #bb8c03; }

.btn-group-vertical > .btn.btn-danger {
  border-top-color: #e42718;
  border-bottom-color: #e42718; }
  .btn-group-vertical > .btn.btn-danger:hover, .btn-group-vertical > .btn.btn-danger:active, .btn-group-vertical > .btn.btn-danger.active {
    box-shadow: none;
    border-top-color: #c82315;
    border-bottom-color: #c82315; }
  .btn-group-vertical > .btn.btn-danger:first-child {
    border-top-color: transparent; }
  .btn-group-vertical > .btn.btn-danger:last-child {
    border-bottom-color: transparent; }
  .btn-group-vertical > .btn.btn-danger:active, .btn-group-vertical > .btn.btn-danger.active {
    box-shadow: inset 0 1px 0 #c82315;
    border-top-color: #c82315;
    border-bottom-color: #c82315; }

.input-group-btn > .btn + .dropdown-toggle, .input-group-prepend > .btn + .dropdown-toggle, .input-group-append > .btn + .dropdown-toggle {
  padding-left: 9px;
  padding-right: 9px; }

/*Breadcrumbs*/
.breadcrumb {
  background-color: whitesmoke;
  margin-bottom: 18px;
  padding: 7px 20px 6px;
  line-height: 16px; }
  .breadcrumb .icon {
    font-size: 1.231rem; }
  .breadcrumb > li + li:before {
    color: #4d4c4c; }
  .breadcrumb > .active {
    color: #4d4c4c; }

/*------------------------------------------------------------------
  [Cards]
*/
.card {
  background-color: #FFFFFF;
  margin-bottom: 25px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.04);
  border-width: 0;
  border-radius: 3px;
  display: block; }
  .card th {
    border-top: 0;
    vertical-align: middle; }

.card-body {
  padding: 8px 20px 20px;
  border-radius: 0 0 3px 3px; }

.card-header {
  font-size: 18px;
  font-weight: 300;
  padding-left: 0;
  padding-right: 0;
  padding-top: 1.5384rem;
  margin: 0 1.538rem;
  border-bottom-width: 0;
  border-radius: 3px 3px 0 0;
  background-color: transparent; }
  .card-header .tools {
    float: right;
    font-size: 0; }
    .card-header .tools > a {
      display: inline-block; }
    .card-header .tools .icon {
      display: inline-block;
      font-size: 1.538rem;
      line-height: 25px;
      vertical-align: middle;
      cursor: pointer;
      color: #bababa;
      min-width: 1.538rem;
      text-align: center; }
      .card-header .tools .icon + .icon {
        margin-left: 2px; }
      .card-header .tools .icon:hover {
        color: #a6a6a6; }

.card-title {
  font-size: 18px;
  font-weight: 300;
  display: block;
  margin: 0; }

.card-subtitle {
  display: block;
  font-size: 1rem;
  color: #707070;
  margin-top: 0;
  margin-bottom: 0.4615rem; }

.card-header-divider {
  border-bottom: 1px solid #d9d9d9;
  margin: 0 1.538rem 8px;
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 1.2307rem; }
  .card-header-divider .card-subtitle {
    margin-top: 0;
    margin-bottom: 0; }

.card-flat {
  border: none;
  box-shadow: none; }

.card-border {
  box-shadow: none;
  border: 1px solid #d4d4d4; }

.card-contrast .card-header {
  margin: 0;
  padding: 20px 20px 11px; }

.card-contrast .card-body {
  padding-top: 16px; }

.card-header-contrast {
  background-color: whitesmoke; }

.card-body-contrast {
  background-color: whitesmoke; }

.card-border-color {
  border-top: 3px solid #c9c9c9; }

.card-border-color-primary {
  border-top-color: #4285f4; }

.card-border-color-danger {
  border-top-color: #ea4335; }

.card-border-color-warning {
  border-top-color: #fbbc05; }

.card-border-color-success {
  border-top-color: #34a853; }

.card-border-color-dark {
  border-top-color: #3d3c3c; }

.card-full .card-header {
  margin: 0;
  padding-left: 1.538rem;
  padding-right: 1.538rem; }
  .card-full .card-header .card-title {
    border-width: 0; }

.card-full-color .card-header {
  color: #FFFFFF;
  padding: 27px 20px 15px;
  margin: 0; }
  .card-full-color .card-header .card-subtitle, .card-full-color .card-header .icon {
    color: #FFFFFF; }

.card-full-color .card-body {
  padding: 20px; }

.card-full-primary {
  background-color: #5f99f5;
  color: #FFFFFF; }
  .card-full-primary .card-header-contrast {
    background-color: #6da2f6;
    padding: 20px 20px 15px;
    margin: 0; }
  .card-full-primary .tools .icon {
    color: #FFFFFF; }
    .card-full-primary .tools .icon:hover {
      color: #4287f3; }

.card-full-success {
  background-color: #37b358;
  color: #FFFFFF; }
  .card-full-success .card-header-contrast {
    background-color: #3bbf5e;
    padding: 20px 20px 15px;
    margin: 0; }
  .card-full-success .tools .icon {
    color: #FFFFFF; }
    .card-full-success .tools .icon:hover {
      color: #309c4c; }

.card-full-warning {
  background-color: #f6c163;
  color: #FFFFFF; }
  .card-full-warning .card-header-contrast {
    background-color: #f7c771;
    padding: 20px 20px 15px;
    margin: 0; }
  .card-full-warning .tools .icon {
    color: #FFFFFF; }
    .card-full-warning .tools .icon:hover {
      color: #f4b646; }

.card-full-danger {
  background-color: #eb6357;
  color: #FFFFFF; }
  .card-full-danger .card-header-contrast {
    background-color: #ed7065;
    padding: 20px 20px 15px;
    margin: 0; }
  .card-full-danger .tools .icon {
    color: #FFFFFF; }
    .card-full-danger .tools .icon:hover {
      color: #e84a3c; }

.card-full {
  background-color: #bdbdbd;
  color: #FFFFFF; }
  .card-full .card-header-contrast {
    background-color: #c4c4c4;
    padding: 20px 20px 15px;
    margin: 0; }
  .card-full .tools .icon {
    color: #FFFFFF; }
    .card-full .tools .icon:hover {
      color: #adadad; }

.card-full-dark {
  background-color: #333333;
  color: #FFFFFF; }
  .card-full-dark .card-header-contrast {
    background-color: #3b3b3b;
    padding: 20px 20px 15px;
    margin: 0; }
  .card-full-dark .tools .icon {
    color: #FFFFFF; }
    .card-full-dark .tools .icon:hover {
      color: #242424; }

.card-table .card-body {
  padding: 0; }

.card-fullwidth .card-header {
  margin: 0;
  padding-left: 1.538rem;
  padding-right: 1.538rem; }

.card-fullwidth .card-body {
  padding-top: 16px; }

/*------------------------------------------------------------------
  [Dropdown menu]
*/
.dropdown-toggle:after {
  display: none; }

.dropdown-showcase {
  margin: 0; }
  .dropdown-showcase .showcase {
    display: inline-block;
    margin-right: 20px;
    margin-bottom: 6px; }

.dropdown-menu {
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.12);
  border-radius: 3px;
  padding: 7px 0px;
  min-width: 185px;
  line-height: 18px; }
  .dropdown-menu > .dropdown-item {
    font-weight: 400;
    color: #504e4e;
    padding: 8px 20px; }
    .dropdown-menu > .dropdown-item .icon {
      font-size: 1.231rem;
      line-height: normal;
      vertical-align: middle;
      margin-right: 8px;
      color: dimgray; }
    .dropdown-menu > .dropdown-item:focus, .dropdown-menu > .dropdown-item:active {
      background-color: transparent;
      color: #504e4e; }
    .dropdown-menu > .dropdown-item:hover {
      background-color: whitesmoke; }
  .dropdown-menu .btn-group .btn {
    font-size: 1.231rem;
    color: dimgray;
    padding: 0 20px; }
  .dropdown-menu .dropdown-divider {
    margin: 6px 0;
    border-color: #e3e3e3; }

.dropdown-header {
  padding: 5px 20px 1px;
  font-weight: 600;
  color: #c2c1c1;
  cursor: default; }

.dropdown-tools {
  text-align: center; }

.dropdown-menu-primary {
  border-color: transparent;
  background-color: #5f99f5; }
  .dropdown-menu-primary.dropdown-menu > .dropdown-item {
    color: #FFFFFF; }
    .dropdown-menu-primary.dropdown-menu > .dropdown-item:focus, .dropdown-menu-primary.dropdown-menu > .dropdown-item:active {
      color: #FFFFFF; }
    .dropdown-menu-primary.dropdown-menu > .dropdown-item:hover {
      background-color: #478af4;
      color: #FFFFFF; }
    .dropdown-menu-primary.dropdown-menu > .dropdown-item .icon {
      color: #FFFFFF; }
  .dropdown-menu-primary.dropdown-menu .dropdown-tools .btn-group .btn {
    background-color: transparent;
    color: #FFFFFF;
    border-color: #b5d0fa;
    border-width: 2px; }
    .dropdown-menu-primary.dropdown-menu .dropdown-tools .btn-group .btn:hover {
      border-color: #FFFFFF;
      box-shadow: none;
      background-color: rgba(255, 255, 255, 0.1); }
      .dropdown-menu-primary.dropdown-menu .dropdown-tools .btn-group .btn:hover + .btn {
        border-left-color: transparent; }
    .dropdown-menu-primary.dropdown-menu .dropdown-tools .btn-group .btn:active, .dropdown-menu-primary.dropdown-menu .dropdown-tools .btn-group .btn.active {
      background-color: #FFFFFF;
      color: #5f99f5;
      border-color: transparent;
      border-width: 2px;
      box-shadow: 0 1px 0 rgba(0, 0, 0, 0.05); }
    .dropdown-menu-primary.dropdown-menu .dropdown-tools .btn-group .btn:focus {
      box-shadow: 0 1px 0 rgba(0, 0, 0, 0.05); }
  .dropdown-menu-primary .dropdown-divider {
    border-color: #478af4; }

.dropdown-menu-success {
  border-color: transparent;
  background-color: #37b358; }
  .dropdown-menu-success.dropdown-menu > .dropdown-item {
    color: #FFFFFF; }
    .dropdown-menu-success.dropdown-menu > .dropdown-item:focus, .dropdown-menu-success.dropdown-menu > .dropdown-item:active {
      color: #FFFFFF; }
    .dropdown-menu-success.dropdown-menu > .dropdown-item:hover {
      background-color: #319f4e;
      color: #FFFFFF; }
    .dropdown-menu-success.dropdown-menu > .dropdown-item .icon {
      color: #FFFFFF; }
  .dropdown-menu-success.dropdown-menu .dropdown-tools .btn-group .btn {
    background-color: transparent;
    color: #FFFFFF;
    border-color: #72d48c;
    border-width: 2px; }
    .dropdown-menu-success.dropdown-menu .dropdown-tools .btn-group .btn:hover {
      border-color: #FFFFFF;
      box-shadow: none;
      background-color: rgba(255, 255, 255, 0.1); }
      .dropdown-menu-success.dropdown-menu .dropdown-tools .btn-group .btn:hover + .btn {
        border-left-color: transparent; }
    .dropdown-menu-success.dropdown-menu .dropdown-tools .btn-group .btn:active, .dropdown-menu-success.dropdown-menu .dropdown-tools .btn-group .btn.active {
      background-color: #FFFFFF;
      color: #37b358;
      border-color: transparent;
      border-width: 2px;
      box-shadow: 0 1px 0 rgba(0, 0, 0, 0.05); }
    .dropdown-menu-success.dropdown-menu .dropdown-tools .btn-group .btn:focus {
      box-shadow: 0 1px 0 rgba(0, 0, 0, 0.05); }
  .dropdown-menu-success .dropdown-divider {
    border-color: #319f4e; }

.dropdown-menu-warning {
  border-color: transparent;
  background-color: #f6c163; }
  .dropdown-menu-warning.dropdown-menu > .dropdown-item {
    color: #FFFFFF; }
    .dropdown-menu-warning.dropdown-menu > .dropdown-item:focus, .dropdown-menu-warning.dropdown-menu > .dropdown-item:active {
      color: #FFFFFF; }
    .dropdown-menu-warning.dropdown-menu > .dropdown-item:hover {
      background-color: #f5b74b;
      color: #FFFFFF; }
    .dropdown-menu-warning.dropdown-menu > .dropdown-item .icon {
      color: #FFFFFF; }
  .dropdown-menu-warning.dropdown-menu .dropdown-tools .btn-group .btn {
    background-color: transparent;
    color: #FFFFFF;
    border-color: #fbe3ba;
    border-width: 2px; }
    .dropdown-menu-warning.dropdown-menu .dropdown-tools .btn-group .btn:hover {
      border-color: #FFFFFF;
      box-shadow: none;
      background-color: rgba(255, 255, 255, 0.1); }
      .dropdown-menu-warning.dropdown-menu .dropdown-tools .btn-group .btn:hover + .btn {
        border-left-color: transparent; }
    .dropdown-menu-warning.dropdown-menu .dropdown-tools .btn-group .btn:active, .dropdown-menu-warning.dropdown-menu .dropdown-tools .btn-group .btn.active {
      background-color: #FFFFFF;
      color: #f6c163;
      border-color: transparent;
      border-width: 2px;
      box-shadow: 0 1px 0 rgba(0, 0, 0, 0.05); }
    .dropdown-menu-warning.dropdown-menu .dropdown-tools .btn-group .btn:focus {
      box-shadow: 0 1px 0 rgba(0, 0, 0, 0.05); }
  .dropdown-menu-warning .dropdown-divider {
    border-color: #f5b74b; }

.dropdown-menu-danger {
  border-color: transparent;
  background-color: #eb6357; }
  .dropdown-menu-danger.dropdown-menu > .dropdown-item {
    color: #FFFFFF; }
    .dropdown-menu-danger.dropdown-menu > .dropdown-item:focus, .dropdown-menu-danger.dropdown-menu > .dropdown-item:active {
      color: #FFFFFF; }
    .dropdown-menu-danger.dropdown-menu > .dropdown-item:hover {
      background-color: #e84e40;
      color: #FFFFFF; }
    .dropdown-menu-danger.dropdown-menu > .dropdown-item .icon {
      color: #FFFFFF; }
  .dropdown-menu-danger.dropdown-menu .dropdown-tools .btn-group .btn {
    background-color: transparent;
    color: #FFFFFF;
    border-color: #f5afa9;
    border-width: 2px; }
    .dropdown-menu-danger.dropdown-menu .dropdown-tools .btn-group .btn:hover {
      border-color: #FFFFFF;
      box-shadow: none;
      background-color: rgba(255, 255, 255, 0.1); }
      .dropdown-menu-danger.dropdown-menu .dropdown-tools .btn-group .btn:hover + .btn {
        border-left-color: transparent; }
    .dropdown-menu-danger.dropdown-menu .dropdown-tools .btn-group .btn:active, .dropdown-menu-danger.dropdown-menu .dropdown-tools .btn-group .btn.active {
      background-color: #FFFFFF;
      color: #eb6357;
      border-color: transparent;
      border-width: 2px;
      box-shadow: 0 1px 0 rgba(0, 0, 0, 0.05); }
    .dropdown-menu-danger.dropdown-menu .dropdown-tools .btn-group .btn:focus {
      box-shadow: 0 1px 0 rgba(0, 0, 0, 0.05); }
  .dropdown-menu-danger .dropdown-divider {
    border-color: #e84e40; }

/*------------------------------------------------------------------
  [Collapse cards style]
*/
.accordion {
  margin-bottom: 40px;
  padding: 0; }
  .accordion .card {
    margin: 0;
    padding: 0;
    border-radius: 3px; }
    .accordion .card + .card {
      margin-top: .384rem; }
  .accordion .card-header {
    padding: 0;
    margin: 0;
    border-radius: 3px; }
    .accordion .card-header button {
      font-size: 1.385rem;
      font-weight: 300;
      padding: 1.385rem 1.692rem;
      display: block;
      color: #4285f4;
      width: 100%;
      color: #404040;
      background-color: transparent;
      text-align: left; }
      .accordion .card-header button.btn {
        box-shadow: none;
        border: 0; }
      .accordion .card-header button .icon {
        transition: -webkit-transform 200ms ease-in-out;
        transition: transform 200ms ease-in-out;
        transition: transform 200ms ease-in-out, -webkit-transform 200ms ease-in-out;
        font-size: 1.923rem;
        vertical-align: middle;
        position: relative;
        top: -1px;
        display: inline-block;
        margin-right: 10px; }
      .accordion .card-header button[aria-expanded='true'] {
        color: #4285f4; }
        .accordion .card-header button[aria-expanded='true'] .icon {
          -webkit-transform: rotate(90deg);
                  transform: rotate(90deg); }
  .accordion .card-body {
    padding: 0 1.538rem 1.538rem 1.538rem;
    border-top: 0;
    line-height: 22px;
    border-radius: 0 0 3px 3px; }

.accordion-color .card .card-header button {
  color: #FFFFFF; }
  .accordion-color .card .card-header button[aria-expanded='true'] {
    color: #FFFFFF; }

.form-check {
  margin-bottom: 0.5rem; }

.custom-control {
  min-height: 1.692rem;
  min-width: 1.692rem;
  margin-bottom: .923rem; }

.custom-control-input .custom-control-label {
  line-height: 1.6; }

.custom-control-input:checked ~ .custom-control-label::after {
  color: #4285f4; }

.custom-control-input:checked ~ .custom-control-label::before {
  border-color: #4285f4; }

.custom-control-input:checked ~ .custom-control-color::after {
  background-color: #4285f4;
  color: #FFFFFF;
  border-radius: 3px; }
  .custom-radio .custom-control-input:checked ~ .custom-control-color::after {
    border-radius: 50%; }

.custom-checkbox .custom-control-label::before {
  content: "";
  width: 1.699rem;
  height: 1.699rem;
  border: 1px solid #ccc;
  border-radius: 3px; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  content: "\f26b";
  font-size: 1.5452rem;
  font-family: "Material Icons";
  font-weight: normal;
  text-align: center;
  line-height: 1.1; }

.custom-radio .custom-control-label::before {
  border: 1px solid #d5d8de;
  width: 1.77592rem;
  height: 1.77592rem;
  content: ""; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  content: "\f26d";
  width: 1.77592rem;
  height: 1.77592rem;
  font-size: 1rem;
  font-family: "Material Icons";
  text-align: center;
  line-height: 1.8; }

.custom-radio-icon {
  padding: 7px 0;
  width: 52px;
  height: 52px; }
  .custom-radio-icon .custom-control-label::after, .custom-radio-icon .custom-control-label::before {
    display: none; }
  .custom-radio-icon .custom-control-label {
    cursor: pointer;
    margin: 0;
    width: 52px;
    height: 52px;
    line-height: 52px;
    border: 1px solid #d5d8de;
    border-radius: 50%;
    text-align: center;
    font-size: 36px;
    vertical-align: middle;
    color: gray; }
    .custom-radio-icon .custom-control-label:hover {
      border-color: #b8bdc8; }
  .custom-radio-icon input[type="radio"]:checked + .custom-control-label {
    border-color: #4285f4;
    color: #4285f4;
    background-color: #FFFFFF; }
    .custom-radio-icon input[type="radio"]:checked + .custom-control-label i {
      color: #4285f4; }
  .custom-radio-icon input[type="radio"]:focus + .custom-control-label {
    box-shadow: none; }

.was-validated .form-control-feedback:valid, .form-control-feedback.is-valid, .was-validated
.col-form-label:valid,
.col-form-label.is-valid, .was-validated
.form-check-label:valid,
.form-check-label.is-valid, .was-validated
.custom-control:valid,
.custom-control.is-valid {
  color: #404040; }

.was-validated .form-control:valid, .form-control.is-valid, .was-validated
.custom-select:valid,
.custom-select.is-valid {
  border-color: #34a853; }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus, .was-validated
  .custom-select:valid:focus,
  .custom-select.is-valid:focus {
    box-shadow: none; }

.was-validated .custom-file-input:valid ~ .custom-file-control, .custom-file-input.is-valid ~ .custom-file-control {
  border-color: #34a853; }
  .was-validated .custom-file-input:valid ~ .custom-file-control::before, .custom-file-input.is-valid ~ .custom-file-control::before {
    border-color: inherit; }

.was-validated .custom-file-input:valid:focus, .custom-file-input.is-valid:focus {
  box-shadow: none; }

.was-validated .custom-control-input:valid ~ .custom-control-label::after, .custom-control-input.is-valid ~ .custom-control-label::after {
  color: #34a853; }

.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  background-color: #FFFFFF;
  border-color: #34a853;
  color: #34a853; }

.was-validated .custom-control-input:checked:valid ~ .custom-control-color::after, .custom-control-input:checked.is-valid ~ .custom-control-color::after {
  background-color: #34a853;
  color: #FFFFFF; }

.was-validated .form-control-feedback:invalid, .form-control-feedback.is-invalid, .was-validated
.col-form-label:invalid,
.col-form-label.is-invalid, .was-validated
.form-check-label:invalid,
.form-check-label.is-invalid, .was-validated
.custom-control:invalid,
.custom-control.is-invalid {
  color: #404040; }

.was-validated .form-control:invalid, .form-control.is-invalid, .was-validated
.custom-select:invalid,
.custom-select.is-invalid {
  border-color: #ea4335; }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus, .was-validated
  .custom-select:invalid:focus,
  .custom-select.is-invalid:focus {
    box-shadow: none; }

.was-validated .custom-file-input:invalid ~ .custom-file-control, .custom-file-input.is-invalid ~ .custom-file-control {
  border-color: #ea4335; }
  .was-validated .custom-file-input:invalid ~ .custom-file-control::before, .custom-file-input.is-invalid ~ .custom-file-control::before {
    border-color: inherit; }

.was-validated .custom-file-input:invalid:focus, .custom-file-input.is-invalid:focus {
  box-shadow: none; }

.was-validated .custom-control-input:invalid ~ .custom-control-label::after, .custom-control-input.is-invalid ~ .custom-control-label::after {
  color: #ea4335; }

.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  background-color: #FFFFFF;
  border-color: #ea4335;
  color: #ea4335; }

.was-validated .custom-control-input:checked:invalid ~ .custom-control-color::after, .custom-control-input:checked.is-invalid ~ .custom-control-color::after {
  background-color: #ea4335;
  color: #FFFFFF; }

.custom-control-sm {
  min-width: 1.38462rem;
  min-height: 1.38462rem; }
  .custom-control-sm.custom-control {
    top: 2px; }
  .custom-control-sm .custom-control-input ~ .custom-control-label::before {
    width: 1.38462rem;
    height: 1.38462rem; }
  .custom-control-sm .custom-control-input:checked ~ .custom-control-label::after {
    width: 1.38462rem;
    height: 1.38462rem;
    font-size: 15px;
    right: 0;
    line-height: 1.3;
    vertical-align: top; }

.be-addon .dropdown-toggle:after {
  display: inline-block; }

.custom-select {
  border-radius: 0; }
  .custom-select:focus {
    box-shadow: 0 0 0 transparent; }

/*------------------------------------------------------------------
  [Tabs]
*/
.nav-tabs {
  background-color: #FFFFFF;
  border-bottom: 2px solid #ebebeb; }
  .nav-tabs > li.nav-item {
    margin-bottom: -2px;
    margin-right: 2px; }
    .nav-tabs > li.nav-item a.nav-link {
      padding: 11px 20px;
      margin-right: 0;
      min-width: 60px;
      text-align: center;
      border-radius: 0;
      color: #404040;
      border-width: 0; }
      .nav-tabs > li.nav-item a.nav-link .icon {
        font-size: 1.538rem;
        vertical-align: middle;
        margin-right: 6px;
        line-height: 17px; }
      .nav-tabs > li.nav-item a.nav-link:hover {
        background: transparent;
        color: #4285f4; }
      .nav-tabs > li.nav-item a.nav-link:active {
        background-color: transparent; }
      .nav-tabs > li.nav-item a.nav-link.active {
        background: transparent;
        border-bottom: 2px solid #4285f4; }
        .nav-tabs > li.nav-item a.nav-link.active .icon {
          color: #555; }
        .nav-tabs > li.nav-item a.nav-link.active:hover, .nav-tabs > li.nav-item a.nav-link.active:focus {
          color: #404040; }

.tab-content {
  background: #FFFFFF;
  padding: 20px;
  margin-bottom: 40px;
  border-radius: 0 0 3px 3px; }
  .tab-content .tab-pane h1:first-child, .tab-content .tab-pane h2:first-child, .tab-content .tab-pane h3:first-child, .tab-content .tab-pane h4:first-child, .tab-content .tab-pane h5:first-child, .tab-content .tab-pane h6:first-child {
    margin-top: 5px; }

.nav-tabs-success > li.nav-item a.nav-link:hover, .nav-tabs-success > li.nav-item a.nav-link:focus {
  color: #34a853; }

.nav-tabs-success > li.nav-item a.nav-link.active {
  border-bottom: 2px solid #34a853; }

.nav-tabs-warning > li.nav-item a.nav-link:hover, .nav-tabs-warning > li.nav-item a.nav-link:focus {
  color: #fbbc05; }

.nav-tabs-warning > li.nav-item a.nav-link.active {
  border-bottom: 2px solid #fbbc05; }

.nav-tabs-danger > li.nav-item a.nav-link:hover, .nav-tabs-danger > li.nav-item a.nav-link:focus {
  color: #ea4335; }

.nav-tabs-danger > li.nav-item a.nav-link.active {
  border-bottom: 2px solid #ea4335; }

/*------------------------------------------------------------------
  [Tooltips]
*/
.tooltip {
  font-size: 0.952rem;
  line-height: 1.5; }

/*------------------------------------------------------------------
  [Modals]
*/
.modal-content {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  overflow: hidden;
  background-color: #FFFFFF;
  color: #404040;
  border: none; }

.modal-header {
  border: 0;
  padding: 19px; }
  .modal-header h1, .modal-header h2, .modal-header h3, .modal-header h4, .modal-header h5, .modal-header h6 {
    display: block;
    margin: 0; }
  .modal-header .close {
    font-size: 1.538rem;
    opacity: 0.4;
    text-shadow: none;
    margin-top: -17px; }
    .modal-header .close:hover {
      opacity: 0.8; }
    .modal-header .close:focus {
      outline: none; }

.modal-body {
  padding: 10px 20px 20px; }
  .modal-body .row:last-child .form-group {
    margin-bottom: 0; }

.modal-main-icon {
  display: inline-block;
  height: 45px;
  width: 45px;
  line-height: normal;
  font-size: 3.846rem; }

.modal-footer {
  padding: 20px;
  border: none; }
  .modal-footer > :not(:first-child) {
    margin-left: 4px; }
  .modal-footer > :not(:last-child) {
    margin-right: 4px; }

.modal-open .modal {
  padding: 0 !important; }

.modal-full-color .modal-header {
  padding: 19px; }

.modal-full-color .modal-content {
  color: #FFFFFF;
  background-color: transparent; }
  .modal-full-color .modal-content .close {
    color: #FFFFFF;
    line-height: 40px;
    opacity: 0.6; }
    .modal-full-color .modal-content .close:hover {
      opacity: 1; }
  .modal-full-color .modal-content .modal-footer {
    border: none; }

.modal-full-color .btn-secondary {
  border-width: 2px;
  border-color: transparent; }

.modal-full-color .btn-primary,
.modal-full-color .btn-success,
.modal-full-color .btn-warning,
.modal-full-color .btn-danger {
  background-color: transparent;
  border-color: rgba(255, 255, 255, 0.5);
  border-width: 2px; }
  .modal-full-color .btn-primary:focus,
  .modal-full-color .btn-success:focus,
  .modal-full-color .btn-warning:focus,
  .modal-full-color .btn-danger:focus {
    background-color: transparent;
    border-color: rgba(255, 255, 255, 0.4); }
  .modal-full-color .btn-primary:hover, .modal-full-color .btn-primary .hover,
  .modal-full-color .btn-success:hover,
  .modal-full-color .btn-success .hover,
  .modal-full-color .btn-warning:hover,
  .modal-full-color .btn-warning .hover,
  .modal-full-color .btn-danger:hover,
  .modal-full-color .btn-danger .hover {
    box-shadow: none;
    background-color: rgba(255, 255, 255, 0.1);
    border-color: rgba(255, 255, 255, 0.8); }
  .modal-full-color .btn-primary:not([disabled]):not(.disabled):active, .modal-full-color .btn-primary:not([disabled]):not(.disabled).active, .modal-full-color .btn-primary.active, .modal-full-color .btn-primary:active,
  .modal-full-color .btn-success:not([disabled]):not(.disabled):active,
  .modal-full-color .btn-success:not([disabled]):not(.disabled).active,
  .modal-full-color .btn-success.active,
  .modal-full-color .btn-success:active,
  .modal-full-color .btn-warning:not([disabled]):not(.disabled):active,
  .modal-full-color .btn-warning:not([disabled]):not(.disabled).active,
  .modal-full-color .btn-warning.active,
  .modal-full-color .btn-warning:active,
  .modal-full-color .btn-danger:not([disabled]):not(.disabled):active,
  .modal-full-color .btn-danger:not([disabled]):not(.disabled).active,
  .modal-full-color .btn-danger.active,
  .modal-full-color .btn-danger:active {
    box-shadow: none;
    background-color: rgba(255, 255, 255, 0.1);
    border-color: rgba(255, 255, 255, 0.8); }

.modal-full-color-success .modal-header {
  background-color: #37b358; }

.modal-full-color-success .modal-body, .modal-full-color-success .modal-footer {
  background-color: #37b358; }

.modal-full-color-success .btn-secondary {
  color: #37b358; }
  .modal-full-color-success .btn-secondary:active {
    color: #37b358; }

.modal-full-color-primary .modal-header {
  background-color: #5f99f5; }

.modal-full-color-primary .modal-body, .modal-full-color-primary .modal-footer {
  background-color: #5f99f5; }

.modal-full-color-primary .btn-secondary {
  color: #5f99f5; }
  .modal-full-color-primary .btn-secondary:active {
    color: #5f99f5; }

.modal-full-color-warning .modal-header {
  background-color: #f6c163; }

.modal-full-color-warning .modal-body, .modal-full-color-warning .modal-footer {
  background-color: #f6c163; }

.modal-full-color-warning .btn-secondary {
  color: #f6c163; }
  .modal-full-color-warning .btn-secondary:active {
    color: #f6c163; }

.modal-full-color-danger .modal-header {
  background-color: #eb6357; }

.modal-full-color-danger .modal-body, .modal-full-color-danger .modal-footer {
  background-color: #eb6357; }

.modal-full-color-danger .btn-secondary {
  color: #eb6357; }
  .modal-full-color-danger .btn-secondary:active {
    color: #eb6357; }

.modal-full-color-dark .modal-header {
  background-color: #3d3c3c; }

.modal-full-color-dark .modal-body, .modal-full-color-dark .modal-footer {
  background-color: #3d3c3c; }

.modal-full-color-dark .btn-secondary {
  color: #3d3c3c; }
  .modal-full-color-dark .btn-secondary:active {
    color: #3d3c3c; }

.full-width {
  width: 90%;
  max-width: none; }
  @media (max-width: 767.98px) {
    .full-width {
      width: 100%; } }
  .full-width.modal-container {
    width: 100%; }
    @media (max-width: 767.98px) {
      .full-width.modal-container {
        width: 100%; } }
    .full-width.modal-container .modal-content {
      width: 81%; }
      @media (max-width: 767.98px) {
        .full-width.modal-container .modal-content {
          width: 100%; } }
  .full-width .modal-content {
    width: 100%;
    max-width: none; }

.custom-width .modal-dialog {
  width: 700px;
  max-width: none; }

.custom-width .modal-content {
  width: 700px;
  max-width: none; }

@media (max-width: 767.98px) {
  .custom-width .modal-content, .custom-width .modal-dialog, .custom-width .modal-header {
    width: 100% !important; } }

.colored-header .modal-content .modal-header {
  background-color: #e6e5e5;
  color: #FFFFFF;
  padding: 30px 20px;
  border-top-left-radius: 0;
  border-top-right-radius: 0; }
  .colored-header .modal-content .modal-header .modal-title {
    font-size: 1.538rem; }
  .colored-header .modal-content .modal-header .close {
    color: rgba(0, 0, 0, 0.7);
    margin-top: -13px; }

.colored-header .modal-content .modal-body {
  padding: 35px 20px 20px;
  background-color: #FFFFFF;
  color: #404040; }

.colored-header .modal-content .modal-footer {
  background-color: #FFFFFF; }

.colored-header-success .modal-content .modal-header {
  background-color: #37b358; }

.colored-header-primary .modal-content .modal-header {
  background-color: #5f99f5; }

.colored-header-warning .modal-content .modal-header {
  background-color: #f6c163; }

.colored-header-danger .modal-content .modal-header {
  background-color: #eb6357; }

.colored-header-dark .modal-content .modal-header {
  background-color: #3d3c3c; }

@media (max-width: 767.98px) {
  .modal-dialog {
    margin: 0 auto; } }

.be-modal-open {
  overflow-x: visible; }

/*------------------------------------------------------------------
  [Alerts]
*/
.alert {
  padding: 0;
  position: relative;
  line-height: 25px;
  border-width: 0;
  margin-bottom: 18px;
  color: #FFFFFF; }
  .alert .icon {
    width: 55px;
    font-size: 1.846rem;
    vertical-align: middle;
    text-align: center;
    line-height: 22px;
    display: table-cell;
    cursor: default;
    padding-top: 1px; }
    .alert .icon span {
      width: 25px; }
  .alert .close {
    position: absolute;
    font-size: 1.538rem;
    right: 1.385rem;
    text-shadow: none;
    padding: 1.5388rem 0;
    opacity: .2; }
  .alert .message {
    display: table-cell;
    padding: 1.385rem 2.1542rem 1.385rem 0.231rem;
    border-left-width: 0; }
    @media (max-width: 575.98px) {
      .alert .message {
        padding: 1.385rem 2.539rem 1.385rem 0.231rem; } }
    .alert .message > p,
    .alert .message > ul {
      margin-bottom: 0; }
    .alert .message > p + p {
      margin-top: 5px; }

.alert-primary {
  background-color: #3d82f4; }

.alert-success {
  background-color: #33a451; }

.alert-info {
  background-color: #d5ebf6; }

.alert-warning {
  background-color: #f7b904; }

.alert-danger {
  background-color: #ea3f30; }

.alert-dismissible.alert-primary .close {
  color: #093d94; }
  .alert-dismissible.alert-primary .close:hover {
    opacity: 0.5; }

.alert-dismissible.alert-success .close {
  color: #103319; }
  .alert-dismissible.alert-success .close:hover {
    opacity: 0.5; }

.alert-dismissible.alert-warning .close {
  color: #654c02; }
  .alert-dismissible.alert-warning .close:hover {
    opacity: 0.5; }

.alert-dismissible.alert-danger .close {
  color: #79150d; }
  .alert-dismissible.alert-danger .close:hover {
    opacity: 0.5; }

.alert-contrast {
  padding: 0;
  position: relative; }
  .alert-contrast > .icon {
    display: table-cell;
    width: 60px;
    text-align: center;
    vertical-align: middle;
    background-color: rgba(255, 255, 255, 0.08); }
  .alert-contrast > .message {
    display: table-cell;
    padding: 1.385rem 1.385rem;
    border-left-width: 0;
    color: #FFFFFF; }
    @media (max-width: 575.98px) {
      .alert-contrast > .message {
        padding: 1.385rem 2.539rem 1.385rem 1.385rem; } }

.alert-icon {
  background-color: #FFFFFF;
  border: 0;
  padding: 0;
  position: relative;
  width: 100%;
  display: table;
  table-layout: fixed;
  box-shadow: 0.5px 0.9px 0 rgba(0, 0, 0, 0.07); }
  .alert-icon > .icon {
    position: relative;
    display: table-cell;
    width: 60px;
    text-align: center;
    vertical-align: middle;
    cursor: default;
    color: #FFFFFF; }
    .alert-icon > .icon:after {
      position: absolute;
      right: -10px;
      top: 50%;
      margin-top: -10px;
      width: 0;
      height: 0;
      content: '';
      z-index: 2;
      border-left: 11px solid #FFFFFF;
      -moz-transform: scale(0.9999);
      border-bottom: 11px solid transparent;
      border-top: 11px solid transparent; }
  .alert-icon > .message {
    display: table-cell;
    padding: 1.385rem 1.385rem;
    color: #404040;
    border: 1px solid #d4d4d4;
    border-left-width: 0; }
    @media (max-width: 575.98px) {
      .alert-icon > .message {
        padding: 1.385rem 2.539rem 1.385rem 1.385rem; } }
  .alert-icon.alert-dismissible .close {
    color: #404040;
    opacity: 0.35; }
    .alert-icon.alert-dismissible .close:hover {
      opacity: 0.5; }
  .alert-icon.alert-primary > .icon {
    background-color: #4285f4; }
    .alert-icon.alert-primary > .icon:after {
      border-left-color: #4285f4; }
  .alert-icon.alert-success > .icon {
    background-color: #34a853; }
    .alert-icon.alert-success > .icon:after {
      border-left-color: #34a853; }
  .alert-icon.alert-warning > .icon {
    background-color: #fbbc05; }
    .alert-icon.alert-warning > .icon:after {
      border-left-color: #fbbc05; }
  .alert-icon.alert-danger > .icon {
    background-color: #ea4335; }
    .alert-icon.alert-danger > .icon:after {
      border-left-color: #ea4335; }

.alert-icon-border.alert-primary .message {
  border-color: #4285f4; }

.alert-icon-border.alert-success .message {
  border-color: #34a853; }

.alert-icon-border.alert-warning .message {
  border-color: #fbbc05; }

.alert-icon-border.alert-danger .message {
  border-color: #ea4335; }

.alert-icon.alert-icon-colored {
  border: 1px solid #d4d4d4; }
  .alert-icon.alert-icon-colored > .icon {
    position: relative;
    background-color: #f8f8f8;
    border-width: 0; }
    .alert-icon.alert-icon-colored > .icon:after {
      border-left-color: #f8f8f8;
      position: absolute;
      right: -11px;
      top: 50%;
      margin-top: -11px;
      width: 0;
      height: 0;
      content: '';
      z-index: 2;
      border-left: 11px solid #f8f8f8;
      -moz-transform: scale(0.9999);
      border-bottom: 11px solid transparent;
      border-top: 11px solid transparent; }
  .alert-icon.alert-icon-colored > .message {
    border-width: 0; }
  .alert-icon.alert-icon-colored.alert-primary > .icon {
    color: #4285f4; }
  .alert-icon.alert-icon-colored.alert-success > .icon {
    color: #34a853; }
  .alert-icon.alert-icon-colored.alert-warning > .icon {
    color: #fbbc05; }
  .alert-icon.alert-icon-colored.alert-danger > .icon {
    color: #ea4335; }

.alert-simple {
  border: 1px solid #d4d4d4;
  background-color: #FFFFFF;
  color: #404040;
  box-shadow: 0.5px 0.9px 0 rgba(0, 0, 0, 0.07); }
  .alert-simple.alert-primary > .icon {
    color: #4285f4; }
  .alert-simple.alert-success > .icon {
    color: #34a853; }
  .alert-simple.alert-warning > .icon {
    color: #fbbc05; }
  .alert-simple.alert-danger > .icon {
    color: #ea4335; }

/*------------------------------------------------------------------
  [Tables]
*/
.table > thead > tr > th {
  border-bottom-width: 2px;
  font-weight: 700; }

.table > thead.primary > tr > th {
  color: #4285f4; }

.table > thead.success > tr > th {
  color: #34a853; }

.table > tbody > tr > td {
  font-weight: 400;
  vertical-align: middle; }

.table-condensed > thead > tr > th {
  padding-top: 10px;
  padding-bottom: 8px; }

.table-bordered > thead > tr > th {
  padding: 14px 10px; }

.table-bordered tbody > tr td:first-child {
  padding-left: 10px; }

.table-bordered tbody > tr td:last-child {
  padding-right: 10px; }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #4285f4;
  color: #FFFFFF; }

.table-hover .table-primary:hover {
  background-color: #2a75f3;
  color: #FFFFFF; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #2a75f3;
    color: #FFFFFF; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #34a853;
  color: #FFFFFF; }

.table-hover .table-success:hover {
  background-color: #2e9549;
  color: #FFFFFF; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #2e9549;
    color: #FFFFFF; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #4285f4;
  color: #FFFFFF; }

.table-hover .table-info:hover {
  background-color: #2a75f3;
  color: #FFFFFF; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #2a75f3;
    color: #FFFFFF; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #fbbc05;
  color: #FFFFFF; }

.table-hover .table-warning:hover {
  background-color: #e3aa04;
  color: #FFFFFF; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #e3aa04;
    color: #FFFFFF; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #ea4335;
  color: #FFFFFF; }

.table-hover .table-danger:hover {
  background-color: #e82e1e;
  color: #FFFFFF; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #e82e1e;
    color: #FFFFFF; }

.table-responsive {
  border: 0;
  margin-bottom: 0; }
  .table-responsive tbody > tr > td {
    padding-top: 11px;
    padding-bottom: 11px; }
    @media (max-width: 767.98px) {
      .table-responsive tbody > tr > td {
        white-space: nowrap; } }

.table tr th .custom-control, .table tr td .custom-control {
  padding: 0;
  margin: 0;
  vertical-align: middle; }
  .table tr th .custom-control .custom-control-indicator, .table tr td .custom-control .custom-control-indicator {
    top: 0; }

.table tr th.actions, .table tr td.actions {
  text-align: center; }
  .table tr th.actions .icon, .table tr td.actions .icon {
    color: #a1a1a1;
    font-size: 1.3846rem;
    line-height: 18px; }
    .table tr th.actions .icon:hover, .table tr td.actions .icon:hover {
      color: #807f7f; }

.table tbody tr td.user-avatar img {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  margin-right: 10px; }
  @media (max-width: 767.98px) {
    .table tbody tr td.user-avatar img {
      display: none; } }

.table tbody tr td.cell-detail span {
  display: block; }

.table tbody tr td.cell-detail .cell-detail-description {
  display: block;
  font-size: 0.8462rem;
  color: #999999; }

.table tbody tr td.user-info img {
  float: left; }

.table tbody tr td.user-info span {
  padding-left: 40px; }
  @media (max-width: 767.98px) {
    .table tbody tr td.user-info span {
      padding-left: 0; } }

.table tbody tr td.milestone {
  min-width: 100px; }
  .table tbody tr td.milestone span {
    display: block;
    font-size: 0.8462rem;
    color: #999999;
    margin-bottom: 6px; }
    .table tbody tr td.milestone span.completed {
      float: right; }
  .table tbody tr td.milestone .progress {
    height: 6px;
    background-color: #e6e5e5;
    margin-bottom: 0; }
    .table tbody tr td.milestone .progress .progress-bar {
      border-radius: 2px; }

.table tbody tr.online td:first-child {
  border-left: 3px solid #5f99f5;
  padding-left: 17px; }

.table .number {
  text-align: right; }

.table.table-borderless tbody tr td {
  border-top-width: 0; }

.card-table table {
  margin-bottom: 0; }

.card-table tr th:first-child, .card-table tr td:first-child {
  padding-left: 20px; }

.card-table tr th:last-child, .card-table tr td:last-child {
  padding-right: 20px; }

.card-table thead tr th {
  padding-top: 15px;
  padding-bottom: 10px; }

/*------------------------------------------------------------------
  [Badges]
*/
.badge {
  font-size: .8462rem;
  font-weight: 700;
  line-height: 1.55;
  border: 1px solid #d9d9d9; }

.badge-primary {
  color: #FFFFFF;
  background-color: #5f99f5;
  border: transparent; }

.badge-secondary {
  background-color: #FFFFFF;
  color: #404040; }

.badge-success {
  color: #FFFFFF;
  background-color: #37b358;
  border: transparent; }

.badge-info {
  color: #FFFFFF;
  background-color: #4285f4;
  border: transparent; }

.badge-warning {
  color: #FFFFFF;
  background-color: #f6c163;
  border: transparent; }

.badge-danger {
  color: #FFFFFF;
  background-color: #eb6357;
  border: transparent; }

.popover {
  font-size: 1rem;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2); }
  .popover .popover-header {
    margin: 0; }
  .popover.bottom > .arrow {
    border-bottom-color: #f7f7f7;
    top: -10px; }
    .popover.bottom > .arrow:after {
      background-color: transparent;
      top: -2px;
      margin-left: -10px;
      border-bottom-color: rgba(0, 0, 0, 0.2);
      border-top-width: 1px;
      z-index: -1; }

/*------------------------------------------------------------------
  [List group]
*/
.list-group-item {
  padding: 13px 15px; }
  .list-group-item.active .icon {
    color: #FFFFFF !important; }
  .list-group-item.active .badge {
    background: #FFFFFF;
    color: #4285f4; }
  .list-group-item.active + .active {
    border-top: solid 1px #FFFFFF; }
  .list-group-item .icon {
    margin-right: 18px;
    font-size: 1.231rem; }
  .list-group-item .text {
    -ms-flex: 1 1 auto;
        flex: 1 1 auto; }
  .list-group-item .badge {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto; }
    .active .list-group-item .badge {
      background: #FFFFFF;
      color: #4285f4; }

/*------------------------------------------------------------------
  [Typography]
*/
h1 small,
h1 .small, h2 small,
h2 .small, h3 small,
h3 .small, h4 small,
h4 .small, h5 small,
h5 .small, h6 small,
h6 .small,
.h1 small,
.h1 .small, .h2 small,
.h2 .small, .h3 small,
.h3 .small, .h4 small,
.h4 .small, .h5 small,
.h5 .small, .h6 small,
.h6 .small {
  font-weight: 300; }

h1, .h1,
h2, .h2,
h3, .h3 {
  margin-top: 20px;
  margin-bottom: 10px; }

h4, .h4,
h5, .h5,
h6, .h6 {
  margin-top: 15px;
  margin-bottom: 10px; }
  h4 small,
  h4 .small, .h4 small,
  .h4 .small,
  h5 small,
  h5 .small, .h5 small,
  .h5 .small,
  h6 small,
  h6 .small, .h6 small,
  .h6 .small {
    font-size: 75%; }

p {
  margin: 0 0 15px; }

.lead {
  font-size: 1.616rem;
  line-height: 26px; }

p small,
p .small {
  font-size: .8462rem; }

mark,
.mark {
  background-color: #fff7e2;
  padding: 0; }

.text-bold {
  font-weight: 500 !important; }

.text-success a {
  color: #34a853 !important; }

.text-success aa:hover, .text-success aa:focus {
  color: #288140 !important; }

.text-warning a {
  color: #fbbc05 !important; }

.text-warning aa:hover, .text-warning aa:focus {
  color: #ca9703 !important; }

.text-danger a {
  color: #ea4335 !important; }

.text-danger aa:hover, .text-danger aa:focus {
  color: #d62516 !important; }

ul,
ol {
  line-height: 20px; }
  ul ul,
  ul ol,
  ol ul,
  ol ol {
    padding-left: 25px; }

.list-unstyled ul,
.list-unstyled ol {
  padding-left: 40px; }

blockquote, .blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }
  blockquote p, .blockquote p {
    margin-bottom: 18px; }
  blockquote p:last-child,
  blockquote ul:last-child,
  blockquote ol:last-child, .blockquote p:last-child,
  .blockquote ul:last-child,
  .blockquote ol:last-child {
    margin-bottom: 0; }
  blockquote footer,
  blockquote small,
  blockquote .small, .blockquote footer,
  .blockquote small,
  .blockquote .small {
    font-size: 1rem; }

.display-heading {
  font-size: 40px;
  font-weight: 100;
  margin: 20px 0 15px; }
  @media (max-width: 767.98px) {
    .display-heading {
      font-size: 32px; } }

.display-description {
  font-size: 18px;
  font-weight: 100;
  line-height: 29px;
  margin: 0 0 50px; }
  @media (max-width: 767.98px) {
    .display-description {
      font-size: 15px;
      line-height: 24px; } }

address {
  line-height: 20px; }

/*------------------------------------------------------------------
  [Progress bars]
*/
.progress {
  height: 22px;
  box-shadow: none; }

.progress-bar {
  box-shadow: none;
  line-height: 22px; }

/*------------------------------------------------------------------
  [Pagination]
*/
.pagination {
  padding-left: 0;
  border-radius: 2px; }

.page-item:first-child .page-link {
  border-radius: 2px; }

.page-item:last-child .page-link {
  border-radius: 2px; }

.pagination-rounded .page-item:first-child .page-link {
  border-radius: 50%; }

.pagination-rounded .page-item:last-child .page-link {
  border-radius: 50%; }

.page-link {
  font-size: 1.077rem;
  line-height: 14px;
  font-weight: 400;
  padding: 0.7692rem 0.9231rem;
  color: #404040;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 2px;
  margin-left: 4px; }
  .page-link > span {
    line-height: 12px; }

.pagination-rounded .page-link {
  border-radius: 50%;
  width: 35px;
  height: 35px;
  text-align: center;
  line-height: 14px; }

/*------------------------------------------------------------------
  [Forms]
*/
.form-control {
  border-width: 1px;
  border-top-color: #bdc0c7;
  box-shadow: none;
  padding: 10px 12px;
  font-size: 1.1538rem;
  transition: none;
  height: 3.692rem; }

.form-control[multiple] {
  padding: 0; }
  .form-control[multiple] option {
    padding: 8px 12px; }

.col-form-label {
  padding-bottom: 0; }
  @media (max-width: 575.98px) {
    .col-form-label {
      padding-bottom: 8px; } }

input.form-control, textarea.form-control {
  -webkit-appearance: none; }

textarea.form-control, select[multiple], select[size] {
  height: auto; }

label {
  font-weight: 400;
  font-size: 1rem;
  margin-bottom: 8px;
  color: #666666; }

.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1; }
  .inputfile + label {
    padding: 0 10px;
    font-size: 1rem;
    line-height: 28px;
    border: 1px solid transparent;
    border-color: #d5d8de;
    border-radius: 2px;
    display: inline-block;
    cursor: pointer; }
    .inputfile + label i {
      margin-right: 10px; }

.form-control-xs {
  height: 30px;
  font-size: 0.9231rem;
  padding: 6px 9px; }

select.form-control-xs:not([size]):not([multiple]) {
  height: 3.1384rem; }

.form-control-sm, .input-group-sm > .form-control,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  height: 37px;
  font-size: 1rem;
  padding: 4px 12px; }

.form-control-lg, .input-group-lg > .form-control,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  height: 57px;
  padding: 12px 14px;
  font-size: 1.385rem;
  font-weight: 300;
  border-radius: 2px; }

.form-group.row {
  margin-bottom: 0;
  padding: 12px 0; }
  .form-horizontal .form-group.row .col-form-label {
    text-align: right; }
  .form-group.row label {
    white-space: nowrap; }

.input-group-prepend .custom-control, .input-group-append .custom-control {
  width: 1.77592rem;
  height: 1.77592rem;
  top: 2px; }

.input-group-prepend .input-group-text, .input-group-append .input-group-text {
  padding: 10px 12px; }

.input-group-prepend .custom-control, .input-group-append .custom-control {
  margin: 0;
  padding: 0; }

.custom-select {
  line-height: 1.2;
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  -moz-appearance: none;
  -webkit-appearance: none; }

.form-control-success,
.form-control-error {
  padding: 10px 45px 10px 12px; }
  .form-control-success + span,
  .form-control-error + span {
    padding-right: 2.25rem;
    background-repeat: no-repeat;
    background-position: center right 0.923rem;
    background-size: 1.846rem 1.846rem;
    font-size: 19px;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    display: block;
    width: 48px;
    height: 48px;
    line-height: 48px;
    text-align: center;
    pointer-events: none; }
  .form-control-success.is-valid + span,
  .form-control-error.is-valid + span {
    color: #34a853; }
  .form-control-success.is-invalid + span,
  .form-control-error.is-invalid + span {
    color: #ea4335; }

/*------------------------------------------------------------------
  [Input Groups]
*/
.input-search input {
  border: 1px solid #d5d8de;
  border-radius: 0;
  display: block;
  font-size: 1rem; }
  .input-search input:focus {
    background-color: transparent; }

.input-search .input-group-btn {
  position: absolute;
  right: 0;
  display: block;
  width: auto;
  top: 1px;
  right: 1px;
  z-index: 3; }
  .input-search .input-group-btn button {
    box-shadow: none;
    border: none;
    height: 3.5382rem; }
    .input-search .input-group-btn button:active {
      background-color: transparent;
      box-shadow: none; }
    .input-search .input-group-btn button .icon {
      font-size: 1.923rem;
      color: #737373; }
    .input-search .input-group-btn button:hover .icon {
      color: #5a5959; }

.input-search.input-group-lg .input-group-btn button {
  height: 2.6922rem; }

.input-search.input-group-sm .input-group-btn button {
  height: 2.693rem; }
  .input-search.input-group-sm .input-group-btn button .icon {
    font-size: 1.692rem; }

/*------------------------------------------------------------------
  [Custom pages]
*/
/*------------------------------------------------------------------
  [Login]
*/
.row.login-tools {
  padding: 15px 0 0;
  margin-bottom: 16px; }

.login-forgot-password {
  line-height: 2.3;
  text-align: right; }

.login-submit, .row.login-submit {
  padding: 19px 0 0;
  margin-bottom: 1.3842rem; }
  .login-submit .btn, .row.login-submit .btn {
    width: 100%; }
  .login-submit > div:first-child, .row.login-submit > div:first-child {
    padding-right: 10px; }
  .login-submit > div:last-child, .row.login-submit > div:last-child {
    padding-left: 10px; }

/*------------------------------------------------------------------
  [Error pages]
*/
.be-error .error-container {
  text-align: center;
  margin-top: 100px; }
  @media (max-width: 767.98px) {
    .be-error .error-container {
      margin-top: 50px; } }

.be-error .error-number {
  display: block;
  font-size: 11.54rem;
  font-weight: 700;
  line-height: 240px;
  margin-bottom: 30px;
  max-height: 240px; }
  .be-error .error-number:after {
    padding-top: 0.25%;
    display: block;
    content: ''; }
  @media (max-width: 767.98px) {
    .be-error .error-number {
      padding: 20px 0;
      font-size: 6.923rem;
      margin-bottom: 10px;
      line-height: 100px; } }

.be-error .error-description {
  font-size: 2.308rem;
  font-weight: 300;
  line-height: 32px;
  margin-bottom: 30px; }
  @media (max-width: 767.98px) {
    .be-error .error-description {
      font-size: 1.462rem; } }

.be-error .error-goback-text {
  font-size: 1.692rem;
  font-weight: 300;
  margin-bottom: 30px;
  margin-top: 15px; }
  @media (max-width: 767.98px) {
    .be-error .error-goback-text {
      font-size: 1.15rem; } }

.be-error .error-goback-button {
  margin-bottom: 30px; }
  .be-error .error-goback-button .btn {
    min-width: 153px;
    text-align: center; }

/*------------------------------------------------------------------
  [Page 404]
*/
.be-error-404 .error-container .error-number {
  background: url("./../../../vendor/beagle-v1.4.2/BS4/dist/html/assets/img/404-bg.png") no-repeat center center;
  background-size: contain; }
  @media (max-width: 575.98px) {
    .be-error-404 .error-container .error-number {
      padding: 0;
      font-size: 5.385rem; } }
  @media (max-width: 340px) {
    .be-error-404 .error-container .error-number {
      font-size: 3.846rem; } }
  @media (max-width: 295px) {
    .be-error-404 .error-container .error-number {
      font-size: 3.077rem; } }

/*------------------------------------------------------------------
  [Sign up]
*/
.splash-container.sign-up {
  max-width: 433px;
  margin: 10px auto; }
  .splash-container.sign-up .card .card-header {
    margin-bottom: 0; }

.signup-password.row {
  padding: 0;
  margin-bottom: 1.3842rem; }

.signup-password .btn {
  width: 100%; }

.signup-password > div:first-child {
  padding-right: 10px; }

.signup-password > div:last-child {
  padding-left: 10px; }

/*------------------------------------------------------------------
  [Forgot password]
*/
.splash-container.forgot-password .card .card-header {
  margin-bottom: 5px; }

/*------------------------------------------------------------------
  [Gallery]
*/
.gallery-container .item {
  width: 25%;
  margin-bottom: 20px;
  padding: 0 10px; }
  .gallery-container .item .photo {
    background: #FFFFFF; }
    .gallery-container .item .photo .img {
      position: relative; }
      .gallery-container .item .photo .img img {
        width: 100%; }
      .gallery-container .item .photo .img .over {
        top: 0;
        opacity: 0;
        position: absolute;
        height: 100%;
        width: 100%;
        background: rgba(66, 133, 244, 0.8);
        transition: opacity 300ms ease;
        -webkit-transition: opacity 300ms ease;
        overflow: hidden; }
        .gallery-container .item .photo .img .over .info-wrapper {
          display: table;
          height: 100%;
          width: 100%;
          margin-top: -80px;
          transition: margin-top 200ms ease-in-out; }
        .gallery-container .item .photo .img .over .info {
          display: table-cell;
          vertical-align: middle;
          position: relative;
          top: 0;
          text-align: center;
          color: #FFFFFF;
          padding: 0 10px; }
        .gallery-container .item .photo .img .over .title {
          font-size: 1.38462rem;
          font-weight: 300; }
        .gallery-container .item .photo .img .over .description {
          margin: 10px 0 18px;
          line-height: 15px; }
        .gallery-container .item .photo .img .over .func a {
          display: inline-block;
          height: 30px;
          width: 30px;
          margin-right: 10px;
          background: rgba(255, 255, 255, 0.2);
          border-radius: 50%; }
          .gallery-container .item .photo .img .over .func a:hover {
            background: rgba(255, 255, 255, 0.3); }
          .gallery-container .item .photo .img .over .func a i {
            font-size: 1.385rem;
            color: #FFFFFF;
            line-height: 30px; }
      .gallery-container .item .photo .img:hover .over {
        opacity: 1; }
        .gallery-container .item .photo .img:hover .over .info-wrapper {
          margin-top: 0; }
    .gallery-container .item .photo .description {
      line-height: 20px;
      display: table;
      width: 100%; }
      .gallery-container .item .photo .description .icon {
        display: table-cell;
        background-color: #e6e5e5;
        padding: 20px;
        vertical-align: middle;
        width: 20px; }
        .gallery-container .item .photo .description .icon a {
          color: #4d4d4d; }
          .gallery-container .item .photo .description .icon a i {
            font-size: 2.308rem; }
            @media (max-width: 575.98px) {
              .gallery-container .item .photo .description .icon a i {
                font-size: 1.538rem; } }
          .gallery-container .item .photo .description .icon a:hover {
            color: #4285f4; }
        @media (max-width: 575.98px) {
          .gallery-container .item .photo .description .icon {
            padding: 15px; } }
      .gallery-container .item .photo .description .desc {
        display: table-cell;
        padding: 10px 20px;
        vertical-align: middle; }
        .gallery-container .item .photo .description .desc h4 {
          margin: 0;
          font-size: 1.385rem; }
          @media (max-width: 575.98px) {
            .gallery-container .item .photo .description .desc h4 {
              font-size: 1.154rem; } }
        .gallery-container .item .photo .description .desc span {
          color: #8a8888;
          font-size: .9231rem;
          font-weight: 300; }
        @media (max-width: 575.98px) {
          .gallery-container .item .photo .description .desc {
            padding: 10px; } }

.gallery-container .item.w2 {
  width: 50%; }

@media (max-width: 1199.98px) {
  .gallery-container .item {
    width: 33.333%; } }

@media (max-width: 991.98px) {
  .gallery-container .item {
    width: 50%; } }

@media (max-width: 575.98px) {
  .gallery-container {
    max-width: 767px; }
    .gallery-container .item {
      width: 100%;
      padding: 0; }
    .gallery-container .item.w2 {
      width: 100%; } }

.aside-compose {
  text-align: center;
  padding: 20px 25px;
  margin-top: 10px; }

.email-inbox-header {
  background-color: #FFFFFF;
  padding: 37px 25px; }
  @media (min-width: 768px) and (max-width: 991.98px) {
    .email-inbox-header {
      padding: 20px 25px; } }

.email-title {
  display: block;
  margin: 3px 0 0;
  font-size: 1.769rem;
  font-weight: 300; }
  @media (max-width: 991.98px) {
    .email-title {
      margin-bottom: 10px; } }
  .email-title .icon {
    font-size: 1.769rem;
    color: #737373; }
  .email-title .new-messages {
    font-size: 1rem;
    color: #8d8c8c;
    margin-left: 3px; }

.email-filters {
  padding: 0 20px 30px;
  border-bottom: 1px solid #e6e5e5;
  background-color: #FFFFFF;
  display: table;
  width: 100%; }
  .email-filters .dropdown-toggle:after {
    display: inline-block; }
  .email-filters > div {
    display: table-cell;
    vertical-align: middle; }
    @media (max-width: 991.98px) {
      .email-filters > div {
        vertical-align: top; } }
  .email-filters .btn-group + .btn-group {
    margin-left: 10px; }
    @media (min-width: 768px) and (max-width: 991.98px) {
      .email-filters .btn-group + .btn-group {
        margin-left: 0;
        margin-top: 10px; } }
    @media (max-width: 575.98px) {
      .email-filters .btn-group + .btn-group {
        margin-left: 0;
        margin-top: 10px; } }
  .email-filters .be-select-all.custom-checkbox {
    display: inline-block;
    vertical-align: middle;
    padding: 0;
    margin: .5rem 1rem 0 0; }
    .email-filters .be-select-all.custom-checkbox .custom-control-label::before {
      margin-right: 18px; }
  .email-filters .email-filters-right {
    text-align: right; }
  .email-filters input {
    margin-right: 8px; }

.email-pagination-indicator {
  display: inline-block;
  vertical-align: middle;
  margin-right: 13px; }
  @media (min-width: 768px) and (max-width: 991.98px) {
    .email-pagination-indicator {
      margin-right: 0; } }

.email-pagination-nav .btn {
  min-width: 30px; }
  .email-pagination-nav .btn i {
    font-size: 1.615rem;
    vertical-align: middle; }

.email-list {
  background: #FFFFFF; }

.email-list-item {
  border-bottom: 1px solid #e6e5e5;
  padding: 14px 20px;
  display: table;
  width: 100%;
  cursor: pointer;
  position: relative;
  background-color: #fafafa; }
  .email-list-item--unread {
    background-color: #FFFFFF; }
    .email-list-item--unread:after {
      content: '';
      display: block;
      position: absolute;
      width: 3px;
      top: -1px;
      bottom: -1px;
      left: 0;
      background-color: #4285f4; }
    .email-list-item--unread .email-list-detail .from, .email-list-item--unread .email-list-detail .date {
      font-weight: 500; }
  .email-list-item .icon {
    margin-right: 7px;
    font-size: 1.154rem;
    vertical-align: middle;
    color: #666666; }
  .email-list-item .from {
    display: block;
    font-weight: 400;
    font-size: 1rem;
    margin: 0 0 3px 0; }
  .email-list-item .msg {
    margin: 0;
    color: #737373; }
  .email-list-item:hover {
    background-color: #f7f7f7; }

.email-list-detail, .email-list-actions {
  vertical-align: top;
  display: table-cell; }

.email-list-actions {
  width: 40px; }
  .email-list-actions .custom-checkbox {
    padding: 0;
    margin-top: 2px; }
    .email-list-actions .custom-checkbox.custom-control {
      margin-bottom: 0; }
  .email-list-actions .favorite {
    display: block;
    padding-top: 2px;
    padding-left: 3px;
    line-height: 15px; }
    .email-list-actions .favorite span {
      font-size: 1.538rem;
      line-height: 17px;
      color: #c0bfbf; }
    .email-list-actions .favorite:hover span {
      color: #8d8c8c; }
    .email-list-actions .favorite.active span {
      color: #FFC600; }

.email-head {
  background-color: #FFFFFF; }

.email-head-title {
  padding: 25px 25px;
  border-bottom: 1px solid #e6e5e5;
  display: block;
  font-weight: 400;
  color: #5a5959;
  font-size: 1.769rem; }
  .email-head-title .icon {
    color: dimgray;
    margin-right: 12px;
    vertical-align: middle;
    line-height: 31px;
    position: relative;
    top: -1px;
    float: left;
    font-size: 1.538rem; }

.email-head-subject {
  padding: 25px 25px;
  border-bottom: 1px solid #e6e5e5; }
  .email-head-subject .title {
    display: block;
    font-size: 1.769rem;
    font-weight: 300;
    color: #5a5959; }
    .email-head-subject .title > a .icon {
      color: #c0bfbf;
      font-size: 1.385rem;
      margin-right: 6px;
      vertical-align: middle;
      line-height: 31px;
      position: relative;
      top: -1px; }
    .email-head-subject .title > a:hover .icon {
      color: #8d8c8c; }
    .email-head-subject .title > a.active .icon {
      color: #FFC600; }
  .email-head-subject .icons {
    font-size: 1.538rem;
    float: right; }
    .email-head-subject .icons .icon {
      color: #737373;
      margin-left: 12px;
      vertical-align: middle; }
      .email-head-subject .icons .icon:hover {
        color: #5a5959; }

.email-head-sender {
  padding: 13px 25px;
  line-height: 40px; }
  .email-head-sender .avatar {
    float: left;
    margin-right: 10px; }
    .email-head-sender .avatar img {
      max-width: 40px;
      max-height: 40px;
      border-radius: 50%; }
  .email-head-sender .sender {
    font-size: 1.15rem; }
    .email-head-sender .sender .actions {
      display: inline-block;
      position: relative; }
    .email-head-sender .sender .icon {
      font-size: 1.538rem;
      line-height: 16px;
      color: #5a5959;
      margin-left: 7px; }
      .email-head-sender .sender .icon i {
        line-height: 16px;
        vertical-align: middle; }
  .email-head-sender .date {
    float: right; }
    .email-head-sender .date a {
      vertical-align: middle;
      margin-right: 5px; }
    .email-head-sender .date .icon {
      font-size: 1.538rem; }

.email-body {
  background-color: #FFFFFF;
  border-top: 1px solid #e6e5e5;
  padding: 30px 28px; }
  .email-body p {
    line-height: 25px;
    margin-bottom: 20px; }
    .email-body p:last-child {
      margin-bottom: 0; }

.email-attachments {
  background-color: #FFFFFF;
  padding: 25px 28px;
  border-top: 1px solid #e6e5e5; }
  .email-attachments .title {
    display: block;
    font-weight: 500; }
    .email-attachments .title span {
      font-weight: 400; }
  .email-attachments ul {
    list-style: none;
    margin: 15px 0 0;
    padding: 0; }
    .email-attachments ul > li {
      line-height: 23px; }
      .email-attachments ul > li a {
        color: #404040;
        font-weight: 500; }
        .email-attachments ul > li a:hover {
          color: #4285f4; }
      .email-attachments ul > li span {
        font-weight: 400; }
      .email-attachments ul > li .icon {
        vertical-align: middle;
        color: #737373;
        margin-right: 2px; }

.compose-title {
  font-weight: 400;
  font-size: 1.769rem;
  height: auto;
  padding: 25px 23px;
  border: 0;
  width: 100%;
  outline: none;
  background-color: #FFFFFF; }
  .compose-title .icon {
    float: left;
    font-size: 1.538rem;
    margin-right: 15px;
    color: dimgray;
    margin-top: 6px; }

.email-compose-fields {
  background-color: #FFFFFF;
  border-bottom: 1px solid #e6e5e5;
  padding: 30px 30px 20px; }
  .email-compose-fields label {
    padding-top: 15px; }

.email.editor {
  background-color: #FFFFFF;
  padding: 30px; }
  .email.editor .note-toolbar {
    border: 0; }
  .email.editor .form-group {
    text-align: right;
    margin-top: 15px; }
    .email.editor .form-group .icon {
      font-size: 1.769rem; }

.code-editor {
  height: 100%; }

.aside-content .nav {
  display: block; }

.aside-content .nav li a {
  display: block;
  position: relative; }

.aside-content .nav li a:hover {
  text-decoration: none;
  background-color: #EEEEEE; }

.code-editor #console {
  height: 100%; }

.code-editor #console .CodeMirror {
  height: 100%; }

.codeditor .page-title {
  font-size: 2rem;
  margin-bottom: 0;
  margin-top: 10px; }

.codeditor .description {
  color: #888; }

.page-aside.codeditor .compose {
  margin-top: 10px;
  font-size: 1.077rem;
  font-weight: 300;
  text-transform: uppercase;
  text-align: center;
  padding: 20px 25px; }

.page-aside.codeditor + .main-content {
  height: calc(100vh - 61px);
  width: auto; }
  @media (max-width: 767.98px) {
    .page-aside.codeditor + .main-content {
      height: auto; } }

.page-aside.codeditor .compose .btn {
  width: 100%;
  border: 0;
  border-radius: 3px !important;
  max-width: 180px; }

.page-aside.codeditor .mail-nav {
  display: block;
  margin-top: 13px;
  padding: 0; }

.page-aside.codeditor .mail-nav ul li {
  margin: 0; }

.page-aside.codeditor .mail-nav ul li a {
  border-radius: 0;
  color: #555;
  padding: 12px 15px; }

.page-aside.codeditor .mail-nav ul li a:hover {
  background: rgba(0, 0, 0, 0.01); }

.page-aside.codeditor .mail-nav ul li.active a {
  color: #555;
  background: rgba(0, 0, 0, 0.025); }

.page-aside.codeditor .mail-nav ul li a .label {
  border-radius: 2px; }

.page-aside.codeditor .mail-nav ul li a .label.label-default {
  background: #b5b5b5; }

.page-aside.codeditor .mail-nav ul li a .label.label1 {
  background: #19B698; }

.page-aside.codeditor .mail-nav ul li a .label.label2 {
  background: #E85647; }

.page-aside.codeditor .mail-nav ul li a .label.label3 {
  background: #F4A425; }

.page-aside.codeditor ul li a span.unsaved {
  position: absolute;
  right: 22px;
  top: 50%;
  margin-top: -5px;
  content: '';
  background-color: #4285f4;
  border-radius: 100%;
  display: block;
  height: 10px;
  width: 10px;
  line-height: 10px; }

.page-aside.codeditor .mail-nav ul li a i {
  font-size: 1.077rem;
  width: 24px;
  text-align: center; }

.page-aside.codeditor .mail-nav .title {
  color: #888;
  margin: 5px 0 0 0;
  padding: 8px 13px 4px;
  border-bottom: 1px solid #E5E5E5; }

/*inbox*/
.mail-inbox .head {
  background: #FFF;
  padding: 20px 25px; }

.mail-inbox .head h3 {
  margin: 9px 0 15px 0; }

.mail-inbox .head h3 span {
  color: #888;
  font-weight: 300;
  font-size: 1.231rem; }

.mail-inbox .head input[type="text"] {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.01) inset;
  border: 1px solid #D8D8D8;
  border-radius: 3px; }

.mail-inbox .head input[type="text"]:focus {
  border-color: #43b6ff; }

.mail-inbox .filters {
  padding: 9px 20px 10px 20px;
  border-bottom: 1px solid #E5E5E5; }

.mail-inbox .filters .checkbox {
  margin-right: 8px; }

.mail-inbox .mails {
  background: #FFF; }

.mail-inbox .mails .item {
  border-bottom: 1px solid #E5E5E5;
  padding: 17px 20px;
  display: table;
  width: 100%;
  cursor: pointer; }

.mail-inbox .mails .item:nth-child(even) {
  background: rgba(0, 0, 0, 0.015); }

.mail-inbox .mails .item:hover {
  background: rgba(0, 0, 0, 0.035); }

.mail-inbox .mails .item > div {
  vertical-align: top;
  display: table-cell; }

.mail-inbox .mails .item > div:first-child {
  width: 35px; }

.mail-inbox .mails .item > div:first-child > div {
  margin-top: 2px; }

.mail-inbox .checkbox {
  background-color: #FFF; }

.mail-inbox .mails .item .from {
  font-weight: 600;
  font-size: 1rem;
  margin: 0 0 3px 0; }

.mail-inbox .mails .item .date i {
  margin-right: 7px;
  font-size: 1.077rem; }

.mail-inbox .mails .item .msg {
  font-size: .9231rem;
  margin: 0; }

/*Message*/
.message .head {
  background: #FFF;
  padding: 20px 25px 24px; }

.message .head h3 {
  margin: 9px 0 15px 0; }

.message .head h3 span {
  font-weight: 300;
  font-size: 1.538rem;
  float: right; }

.message .head h3 span a {
  color: #888;
  margin-right: 15px; }

.message .head h4 span {
  font-weight: 100;
  font-size: 1.154rem;
  float: right; }

.message .head h4 span a {
  color: #FEA200;
  margin-right: 10px; }

.message .mail {
  background: #FFF;
  border-top: 1px solid #E5E5E5;
  padding: 30px;
  font-size: 2rem; }

.message .mail p {
  font-size: 1.154rem;
  font-weight: 100; }

.message .mails .item {
  border-bottom: 1px solid #E5E5E5;
  padding: 17px 20px;
  display: table;
  width: 100%;
  cursor: pointer; }

.message .mails .item:nth-child(even) {
  background: rgba(0, 0, 0, 0.015); }

.message .mails .item:hover {
  background: rgba(0, 0, 0, 0.035); }

.message .mails .item > div {
  vertical-align: top;
  display: table-cell; }

.message .mails .item > div:first-child {
  width: 35px; }

.message .mails .item > div:first-child > div {
  margin-top: 2px; }

.message .checkbox {
  background-color: #FFF; }

.message .mails .item .from {
  font-weight: 600;
  font-size: 1rem;
  margin: 0 0 3px 0; }

.message .mails .item .date i {
  margin-right: 7px;
  font-size: 1.077rem; }

.message .mails .item .msg {
  font-size: .9231rem;
  margin: 0; }

@media (max-width: 767px) {
  .cl-mcont.aside {
    display: block; }
  .page-aside.codeditor {
    display: block; }
  .aside .content {
    display: block; }
  .page-aside > div {
    width: 100%; }
  .cl-mcont.aside > .content {
    display: block; }
  .page-aside.codeditor .mail-nav ul li {
    display: block; }
  .page-aside.codeditor .mail-nav {
    margin-top: 0;
    padding: 0;
    width: 100%;
    border-bottom: 1px solid #E5E5E5; }
  .page-aside.codeditor .mail-nav .compose {
    border-top: 1px solid #E5E5E5;
    background: #FCFCFC; }
  .page-aside.codeditor div.content {
    border-bottom: 1px solid #E5E5E5; }
  .page-aside.codeditor .mail-nav.collapse.show {
    display: block; }
  .page-aside.codeditor .mail-nav.collapse {
    display: none; }
  .message .mail {
    border-top: 0; } }

.CodeMirror {
  height: auto; }

.CodeMirror-scroll {
  overflow-y: hidden;
  overflow-x: auto; }

.be-booking-promos.row {
  padding: 26px; }
  @media (max-width: 767.98px) {
    .be-booking-promos.row {
      margin: 0;
      padding: 15px 0; } }

.be-booking-promo {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
  -ms-flex-pack: justify;
      justify-content: space-between;
  border-radius: 3px;
  background-color: #FFFFFF;
  border-left: 3px solid #4285f4;
  padding: 23px 30px 26px;
  margin-bottom: 26px; }
  @media (max-width: 575.98px) {
    .be-booking-promo {
      -ms-flex-direction: column;
          flex-direction: column; } }
  .be-booking-promo.be-booking-promo-danger {
    border-left-color: #ea4335; }
  .be-booking-promo.be-booking-promo-warning {
    border-left-color: #fbbc05; }
  .be-booking-promo.be-booking-promo-success {
    border-left-color: #34a853; }
  .be-booking-promo.be-booking-promo-soldout {
    border-left-color: #e3e3e3; }
    .be-booking-promo.be-booking-promo-soldout:before {
      position: absolute;
      top: 0;
      right: 0;
      display: block;
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 72px 72px 0;
      border-color: transparent;
      border-right-color: #e3e3e3; }
    .be-booking-promo.be-booking-promo-soldout .be-soldout-title {
      position: absolute;
      font-size: .8462rem;
      color: #969696;
      top: 20px;
      right: 5px;
      -webkit-transform: rotate(45deg);
              transform: rotate(45deg); }

.be-booking-desc {
  -ms-flex: 0 1 60%;
      flex: 0 1 60%; }
  @media (max-width: 575.98px) {
    .be-booking-desc {
      margin-bottom: 20px;
      text-align: center; } }

.be-booking-desc-title {
  padding-bottom: 15px;
  font-size: 1.538rem;
  margin: 0 0 13px;
  border-bottom: 1px solid #ebebeb; }

.be-booking-desc-details {
  font-size: 1.077rem; }

.be-booking-promo-price {
  text-align: center; }

.be-booking-promo-amount {
  position: relative;
  margin: 3px 0 19px; }
  .be-booking-promo-soldout .be-booking-promo-amount {
    margin-top: 41px; }
  .be-booking-promo-amount .currency {
    font-size: 1.154rem;
    line-height: 1;
    vertical-align: top; }
  .be-booking-promo-amount .price {
    font-size: 3.154rem;
    line-height: 1; }
  .be-booking-promo-amount .frecuency {
    font-size: 1.769rem;
    line-height: 1;
    font-weight: 300; }

.btn.be-booking-btn-price {
  padding: 0 21px;
  line-height: 34px; }

.be-booking-promo-big {
  padding: 25px 30px 35px;
  margin-top: -5px;
  margin-bottom: 20px; }
  .be-booking-promo-big .be-booking-desc-title {
    font-size: 1.538rem;
    font-weight: 400; }
  .be-booking-promo-big .be-promo-big-title {
    position: absolute;
    font-size: 1.462rem;
    color: #FFFFFF;
    top: 13px;
    right: 14px; }
  .be-booking-promo-big:before {
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 72px 72px 0;
    border-color: transparent; }
  .be-booking-promo-big.be-booking-promo-danger .be-booking-desc-title {
    color: #ea4335; }
  .be-booking-promo-big.be-booking-promo-danger:before {
    border-right-color: #ea4335; }
  .be-booking-promo-big.be-booking-promo-primary .be-booking-desc-title {
    color: #4285f4; }
  .be-booking-promo-big.be-booking-promo-primary:before {
    border-right-color: #4285f4; }
  .be-booking-promo-big.be-booking-promo-success .be-booking-desc-title {
    color: #34a853; }
  .be-booking-promo-big.be-booking-promo-success:before {
    border-right-color: #34a853; }
  .be-booking-promo-big.be-booking-promo-warning .be-booking-desc-title {
    color: #fbbc05; }
  .be-booking-promo-big.be-booking-promo-warning:before {
    border-right-color: #fbbc05; }

.be-booking-promo-color {
  color: #FFFFFF; }
  .be-booking-promo-color.be-booking-promo-danger {
    background-color: #ea4335; }
  .be-booking-promo-color.be-booking-promo-primary {
    background-color: #4285f4; }
  .be-booking-promo-color.be-booking-promo-success {
    background-color: #34a853; }
  .be-booking-promo-color.be-booking-promo-warning {
    background-color: #fbbc05; }

/*------------------------------------------------------------------
  [dataTables]
*/
table.dataTable {
  margin: 0 !important;
  border-collapse: collapse !important; }
  table.dataTable thead .sorting:before,
  table.dataTable thead .sorting:after,
  table.dataTable thead .sorting_asc:before,
  table.dataTable thead .sorting_asc:after,
  table.dataTable thead .sorting_desc:before,
  table.dataTable thead .sorting_desc:after {
    font-size: 1.538rem;
    bottom: .5rem; }

div.dataTables_wrapper {
  -ms-flex-flow: column;
      flex-flow: column;
  -ms-flex-align: stretch;
      align-items: stretch;
  padding-left: 0;
  padding-right: 0; }
  div.dataTables_wrapper div.dataTables_info {
    color: #616161;
    padding-top: 5px; }
  div.dataTables_wrapper div.dataTables_paginate ul.pagination {
    margin: 0;
    -ms-flex-pack: end;
        justify-content: flex-end; }
  div.dataTables_wrapper div.dataTables_length label, div.dataTables_wrapper div.dataTables_filter label {
    margin-bottom: 0;
    font-size: 1rem;
    color: #616161; }
  div.dataTables_wrapper div.dataTables_length label {
    -ms-flex-pack: start;
        justify-content: flex-start; }
    div.dataTables_wrapper div.dataTables_length label select.form-control-sm, div.dataTables_wrapper div.dataTables_length label .input-group-sm > select.form-control, div.dataTables_wrapper div.dataTables_length label
    .input-group-sm > .input-group-prepend > select.input-group-text, div.dataTables_wrapper div.dataTables_length label
    .input-group-sm > .input-group-append > select.input-group-text, div.dataTables_wrapper div.dataTables_length label
    .input-group-sm > .input-group-prepend > select.btn, div.dataTables_wrapper div.dataTables_length label
    .input-group-sm > .input-group-append > select.btn {
      height: 37px;
      padding: 2px;
      margin: 0 5px; }
  div.dataTables_wrapper div.dataTables_filter label {
    -ms-flex-pack: end;
        justify-content: flex-end; }
    div.dataTables_wrapper div.dataTables_filter label select {
      padding: 2px; }

.be-datatable-header {
  border-bottom: 1px solid #dee2e6;
  margin: 2px 0 0;
  padding: 15px 10px 22px; }
  .be-datatable-header .dt-buttons .btn embed {
    outline: none; }

.be-datatable-footer {
  margin: 0;
  padding: 15px 3px 13px;
  background-color: #f7f7f7;
  border-top: 1px solid #dee2e6; }

/*------------------------------------------------------------------
  [Bootstrap dateTime Picker]
*/
.datetimepicker {
  padding: 4px 12px; }
  .datetimepicker.input-group {
    padding: 4px 0; }
  .datetimepicker .input-group-append > button {
    padding: 0 13px;
    font-size: 1.846rem;
    line-height: 23px; }
    .datetimepicker .input-group-append > button > i {
      vertical-align: middle; }
  .datetimepicker.input-group-sm .input-group-append > button {
    font-size: 1.538rem;
    line-height: 21px;
    padding: 0 11px; }
  .datetimepicker.input-group-lg .input-group-append > button {
    padding: 0 15px; }
  .datetimepicker table {
    border-collapse: separate;
    border-spacing: 7px 2px; }
    .datetimepicker table thead tr th {
      padding: 10px 4px 8px; }
      .datetimepicker table thead tr th.prev, .datetimepicker table thead tr th.next {
        padding: 0; }
        .datetimepicker table thead tr th.prev > .icon, .datetimepicker table thead tr th.next > .icon {
          font-size: 1.615rem; }
        .datetimepicker table thead tr th.prev:hover, .datetimepicker table thead tr th.next:hover {
          background-color: transparent;
          color: #4285f4; }
      .datetimepicker table thead tr th.switch {
        font-weight: 600;
        font-size: 1.077rem; }
        .datetimepicker table thead tr th.switch:hover {
          background-color: transparent;
          color: #5a5959; }
      .datetimepicker table thead tr th.dow {
        font-weight: 400;
        font-size: 1.077rem;
        padding-top: 10px; }
    .datetimepicker table tbody tr td {
      line-height: 31px;
      padding: 0 8px; }
      .datetimepicker table tbody tr td.day {
        border-radius: 50%;
        color: #737373; }
        .datetimepicker table tbody tr td.day.old, .datetimepicker table tbody tr td.day.new {
          color: #b3b3b3; }
        .datetimepicker table tbody tr td.day.active {
          background: #4285f4;
          color: #FFFFFF;
          text-shadow: none; }
          .datetimepicker table tbody tr td.day.active:hover {
            background: #1266f1; }
      .datetimepicker table tbody tr td .year, .datetimepicker table tbody tr td .month, .datetimepicker table tbody tr td .hour, .datetimepicker table tbody tr td .minute {
        color: #737373; }
        .datetimepicker table tbody tr td .year.old, .datetimepicker table tbody tr td .year.new, .datetimepicker table tbody tr td .month.old, .datetimepicker table tbody tr td .month.new, .datetimepicker table tbody tr td .hour.old, .datetimepicker table tbody tr td .hour.new, .datetimepicker table tbody tr td .minute.old, .datetimepicker table tbody tr td .minute.new {
          color: #b3b3b3; }
        .datetimepicker table tbody tr td .year.active, .datetimepicker table tbody tr td .month.active, .datetimepicker table tbody tr td .hour.active, .datetimepicker table tbody tr td .minute.active {
          background: #4285f4;
          color: #FFFFFF;
          text-shadow: none; }
          .datetimepicker table tbody tr td .year.active:hover, .datetimepicker table tbody tr td .month.active:hover, .datetimepicker table tbody tr td .hour.active:hover, .datetimepicker table tbody tr td .minute.active:hover {
            background: #1266f1; }
      .datetimepicker table tbody tr td fieldset legend {
        font-size: 1.308rem;
        font-weight: 400;
        color: #5a5959;
        margin-bottom: 5px; }
  .datetimepicker.dropdown-menu {
    border-color: rgba(0, 0, 0, 0.1);
    padding: 10px; }
    .datetimepicker.dropdown-menu:before {
      border-bottom-color: rgba(0, 0, 0, 0.08); }

/*------------------------------------------------------------------
  [DropZone]
*/
form.dropzone {
  position: relative;
  border: 2px dashed #c3c3c3;
  padding: 70px 60px 80px;
  background: transparent; }
  form.dropzone .icon {
    background-color: #e6e5e5;
    border-radius: 50%;
    display: inline-block;
    height: 100px;
    width: 100px;
    margin-bottom: 15px;
    padding: 20px;
    text-align: center; }
    form.dropzone .icon span {
      font-size: 4.615rem;
      color: #696767; }
  form.dropzone h2 {
    margin-bottom: 30px;
    font-size: 3.077rem;
    font-weight: 300; }
  form.dropzone .note {
    font-size: 1.231rem; }
  form.dropzone .dz-preview.dz-file-preview .dz-image, form.dropzone .dz-preview.dz-image-preview .dz-image {
    border: 3px solid #4285f4;
    border-radius: 3px;
    background: #ededed;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-pack: center;
        justify-content: center; }
  form.dropzone .dz-preview.dz-file-preview.dz-error .dz-image, form.dropzone .dz-preview.dz-image-preview.dz-error .dz-image {
    border-color: #ea4335; }
  form.dropzone .dz-preview.dz-file-preview.dz-success .dz-image, form.dropzone .dz-preview.dz-image-preview.dz-success .dz-image {
    border-color: #4285f4; }
  form.dropzone .dz-preview .dz-details .dz-filename span, form.dropzone .dz-preview .dz-details .dz-size span {
    background: transparent; }
  form.dropzone .dz-preview .dz-error-message {
    word-break: break-word; }

.dropzone-mobile-trigger {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0; }

.tooltip-chart {
  display: -ms-flexbox;
  display: flex; }
  .tooltip-chart .content-chart {
    background-color: rgba(0, 0, 0, 0.8);
    color: #FFF;
    border-radius: 3px;
    padding: 5px 12px 5px;
    position: relative; }
    .tooltip-chart .content-chart:before {
      content: '';
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid rgba(0, 0, 0, 0.8);
      position: absolute;
      bottom: -5px;
      margin-left: 50%;
      left: -5px; }
    .tooltip-chart .content-chart.arrow-none:before {
      display: none; }
    .tooltip-chart .content-chart span {
      display: block;
      font-size: 12px;
      font-weight: 600;
      margin-bottom: -2px;
      text-align: center;
      color: #bad3fb; }
    .tooltip-chart .content-chart .label {
      font-size: 11px;
      text-align: center; }
      .tooltip-chart .content-chart .label .label-x, .tooltip-chart .content-chart .label .label-y {
        display: inline-block; }

/*------------------------------------------------------------------
  [Full calendar]
*/
.full-calendar .fc-toolbar {
  padding-top: 25px;
  margin-bottom: 30px; }

.full-calendar .fc-view-container .fc-event-container .fc-event {
  background: #4285f4;
  border-color: #1b6cf2;
  color: #FFFFFF;
  border-radius: 0; }

.full-calendar .fc-view-container .fc-border-separate .fc-today {
  background-color: #f7f7f7; }

.full-calendar .fc-view-container .fc-cell-overlay {
  background-color: fade(#4285f4, 50%); }

.full-calendar .fc-button {
  color: #404040;
  background: #FFFFFF;
  border-color: #dbdbdb;
  height: auto;
  padding: 10px;
  font-size: 1rem;
  line-height: 28px; }
  .full-calendar .fc-button:focus, .full-calendar .fc-button.focus {
    color: #404040;
    background-color: #FFFFFF;
    border-color: #dbdbdb;
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.05);
    outline: none; }
  .full-calendar .fc-button:hover, .full-calendar .fc-button.hover {
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);
    background-color: #FFFFFF;
    border-color: #bababa; }
  .full-calendar .fc-button:active, .full-calendar .fc-button.fc-state-active, .full-calendar .fc-button.active,
  .open > .full-calendar .fc-button.dropdown-toggle {
    box-shadow: inset 0 2px 0 rgba(0, 0, 0, 0.06);
    color: #272626;
    background-color: #e3e3e3;
    border-color: #bababa; }
    .full-calendar .fc-button:active:hover, .full-calendar .fc-button:active:focus, .full-calendar .fc-button:active.focus, .full-calendar .fc-button.fc-state-active:hover, .full-calendar .fc-button.fc-state-active:focus, .full-calendar .fc-button.fc-state-active.focus, .full-calendar .fc-button.active:hover, .full-calendar .fc-button.active:focus, .full-calendar .fc-button.active.focus,
    .open > .full-calendar .fc-button.dropdown-toggle:hover,
    .open > .full-calendar .fc-button.dropdown-toggle:focus,
    .open > .full-calendar .fc-button.dropdown-toggle.focus {
      color: #272626;
      background-color: #e3e3e3;
      border-color: #bababa;
      box-shadow: inset 0 2px 0 rgba(0, 0, 0, 0.06); }
  .full-calendar .fc-button:active, .full-calendar .fc-button.active,
  .open > .full-calendar .fc-button.dropdown-toggle {
    background-image: none; }
  .full-calendar .fc-button.disabled, .full-calendar .fc-button.disabled:hover, .full-calendar .fc-button.disabled:focus, .full-calendar .fc-button.disabled.focus, .full-calendar .fc-button.disabled:active, .full-calendar .fc-button.disabled.active, .full-calendar .fc-button[disabled], .full-calendar .fc-button[disabled]:hover, .full-calendar .fc-button[disabled]:focus, .full-calendar .fc-button[disabled].focus, .full-calendar .fc-button[disabled]:active, .full-calendar .fc-button[disabled].active,
  fieldset[disabled] .full-calendar .fc-button,
  fieldset[disabled] .full-calendar .fc-button:hover,
  fieldset[disabled] .full-calendar .fc-button:focus,
  fieldset[disabled] .full-calendar .fc-button.focus,
  fieldset[disabled] .full-calendar .fc-button:active,
  fieldset[disabled] .full-calendar .fc-button.active {
    background-color: #FFFFFF;
    border-color: #dbdbdb; }
  .full-calendar .fc-button.fc-prev-button, .full-calendar .fc-button.fc-next-button {
    padding-left: 7px;
    padding-right: 7px; }
  .full-calendar .fc-button .fc-icon:after {
    position: static;
    font-weight: 400;
    font-size: 1.615rem; }

.full-calendar .fc-state-default.fc-corner-left {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px; }

.full-calendar .fc-state-default.fc-corner-right {
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px; }

@media (max-width: 767.98px) {
  .full-calendar {
    max-width: 767px; }
    .full-calendar .fc-toolbar .fc-left, .full-calendar .fc-toolbar .fc-center, .full-calendar .fc-toolbar .fc-right {
      text-align: center;
      display: block;
      width: 100%; }
    .full-calendar .fc-toolbar .fc-left {
      padding-bottom: 20px; }
    .full-calendar .fc-toolbar .fc-center {
      padding: 0; } }

.fullcalendar-external-events .fc-event {
  margin: 2px 0;
  padding: 5px;
  background: #4285f4;
  color: #FFFFFF;
  font-size: .85em;
  cursor: pointer;
  display: inline-block;
  border-width: 0; }

.fullcalendar-external-events p input {
  margin-top: 15px; }

.fullcalendar-external-events p label {
  font-weight: 300;
  vertical-align: middle; }

/*------------------------------------------------------------------
  [Perfect Scrollbar]
*/
/*Perfect Scrollbar*/
.ps-container > .ps-scrollbar-y-rail {
  width: 6px;
  background-color: rgba(0, 0, 0, 0.1); }
  .ps-container > .ps-scrollbar-y-rail > .ps-scrollbar-y {
    right: 0;
    height: 6px;
    width: 6px;
    border-radius: 3px;
    background-color: rgba(0, 0, 0, 0.25); }
  .ps-container > .ps-scrollbar-y-rail:hover > .ps-scrollbar-y, .ps-container > .ps-scrollbar-y-rail:active > .ps-scrollbar-y {
    width: 6px; }

/*------------------------------------------------------------------
  [Notifications]
*/
#gritter-notice-wrapper {
  width: 360px;
  top: 82px; }

.gritter-item-wrapper {
  background: url("./../../../vendor/beagle-v1.4.2/BS4/dist/html/assets/lib/jquery.gritter/images/gritter-bg.png");
  box-shadow: 0 0px 4px rgba(0, 0, 0, 0.2); }
  .gritter-item-wrapper .gritter-item {
    font-family: 'Open Sans',sans-serif;
    background: transparent;
    padding: 0;
    display: table;
    table-layout: fixed;
    width: 100%; }
    .gritter-item-wrapper .gritter-item .gritter-img-container {
      display: table-cell;
      width: 26%;
      vertical-align: middle;
      text-align: center;
      background-color: #302f2f; }
      .gritter-item-wrapper .gritter-item .gritter-img-container .gritter-image {
        float: none; }
    .gritter-item-wrapper .gritter-item .gritter-content {
      width: auto; }
      .gritter-item-wrapper .gritter-item .gritter-content.gritter-with-image, .gritter-item-wrapper .gritter-item .gritter-content.gritter-without-image {
        padding: 15px 35px 17px 15px;
        color: #d9d9d9;
        width: 267px;
        display: table-cell; }
        .gritter-item-wrapper .gritter-item .gritter-content.gritter-with-image .gritter-close, .gritter-item-wrapper .gritter-item .gritter-content.gritter-without-image .gritter-close {
          display: block;
          top: 0;
          right: 0;
          left: auto;
          height: 30px;
          width: 30px;
          font-size: 0;
          background: transparent;
          text-indent: inherit;
          opacity: 0.4; }
          .gritter-item-wrapper .gritter-item .gritter-content.gritter-with-image .gritter-close:hover, .gritter-item-wrapper .gritter-item .gritter-content.gritter-without-image .gritter-close:hover {
            opacity: 0.8; }
          .gritter-item-wrapper .gritter-item .gritter-content.gritter-with-image .gritter-close:after, .gritter-item-wrapper .gritter-item .gritter-content.gritter-without-image .gritter-close:after {
            content: '\f136';
            font-family: "Material Icons";
            color: #FFFFFF;
            font-size: 1.538rem;
            text-shadow: none;
            line-height: 35px;
            margin-left: 7px; }
        .gritter-item-wrapper .gritter-item .gritter-content.gritter-with-image .gritter-title, .gritter-item-wrapper .gritter-item .gritter-content.gritter-without-image .gritter-title {
          text-shadow: none;
          font-weight: 300;
          font-size: 1.308rem; }
        .gritter-item-wrapper .gritter-item .gritter-content.gritter-with-image p, .gritter-item-wrapper .gritter-item .gritter-content.gritter-without-image p {
          font-size: .9231rem;
          line-height: 19px; }
      .gritter-item-wrapper .gritter-item .gritter-content .gritter-without-image {
        border-left: 0; }
  .gritter-item-wrapper.img-rounded {
    border-radius: 0; }
    .gritter-item-wrapper.img-rounded .gritter-img-container .gritter-image {
      border-radius: 50%; }
  .gritter-item-wrapper.clean {
    background: #FFFFFF; }
    .gritter-item-wrapper.clean .gritter-item {
      color: #404040; }
      .gritter-item-wrapper.clean .gritter-item .gritter-img-container {
        background-color: #fafafa; }
      .gritter-item-wrapper.clean .gritter-item .gritter-content {
        color: #404040; }
        .gritter-item-wrapper.clean .gritter-item .gritter-content .gritter-close {
          opacity: 0.5; }
          .gritter-item-wrapper.clean .gritter-item .gritter-content .gritter-close:after {
            color: #404040; }
  .gritter-item-wrapper.color .gritter-item .gritter-content {
    color: #FFFFFF; }
    .gritter-item-wrapper.color .gritter-item .gritter-content .gritter-close {
      opacity: 0.7; }
      .gritter-item-wrapper.color .gritter-item .gritter-content .gritter-close:hover {
        opacity: 1; }
  .gritter-item-wrapper.primary {
    background: #4285f4; }
  .gritter-item-wrapper.success {
    background: #34a853; }
  .gritter-item-wrapper.warning {
    background: #fbbc05; }
  .gritter-item-wrapper.danger {
    background: #ea4335; }
  .gritter-item-wrapper.dark {
    background: #3d3c3c; }
  .gritter-item-wrapper.facebook {
    background: #5d82d1; }
    .gritter-item-wrapper.facebook .gritter-item .gritter-img-container {
      background-color: #5179ce; }
  .gritter-item-wrapper.twitter {
    background: #50bff5; }
    .gritter-item-wrapper.twitter .gritter-item .gritter-img-container {
      background-color: #42baf4; }
  .gritter-item-wrapper.google-plus {
    background: #eb5e4c; }
    .gritter-item-wrapper.google-plus .gritter-item .gritter-img-container {
      background-color: #e9523e; }
  .gritter-item-wrapper.dribbble {
    background: #f7659c; }
    .gritter-item-wrapper.dribbble .gritter-item .gritter-img-container {
      background-color: #f65693; }
  .gritter-item-wrapper.flickr {
    background: #0063dc; }
    .gritter-item-wrapper.flickr .gritter-item .gritter-img-container {
      background-color: #005ccd; }
  .gritter-item-wrapper.linkedin {
    background: #238cc8; }
    .gritter-item-wrapper.linkedin .gritter-item .gritter-img-container {
      background-color: #2183bb; }
  .gritter-item-wrapper.youtube {
    background: #ef4e41; }
    .gritter-item-wrapper.youtube .gritter-item .gritter-img-container {
      background-color: #ee4133; }
  .gritter-item-wrapper.pinterest {
    background: #e13138; }
    .gritter-item-wrapper.pinterest .gritter-item .gritter-img-container {
      background-color: #df242b; }
  .gritter-item-wrapper.github {
    background: #333; }
    .gritter-item-wrapper.github .gritter-item .gritter-img-container {
      background-color: #2b2b2b; }
  .gritter-item-wrapper.tumblr {
    background: #426d9b; }
    .gritter-item-wrapper.tumblr .gritter-item .gritter-img-container {
      background-color: #3d6590; }

.ms-container .ms-selectable {
  background-color: transparent; }

.ms-container .ms-selectable li.ms-hover,
.ms-container .ms-selection li.ms-hover {
  background-color: #4285f4; }

.ms-container .ms-list {
  background-color: #FFFFFF; }

.ms-container .ms-list.ms-focus {
  box-shadow: none;
  border-color: #4285f4; }

.ms-container .search-input {
  height: 30px;
  margin-bottom: 10px; }

/*------------------------------------------------------------------
  [Select2]
*/
.select2-container--default .select2-selection--single, .select2-container--default .select2-selection--multiple {
  border: 0.07692rem solid #d5d8de;
  border-radius: 2px; }

.select2-container--default .select2-selection--single {
  height: 3.692rem; }
  .select2-container--default .select2-selection--single .select2-selection__rendered {
    padding: 0 15px;
    height: 3.53816rem;
    line-height: 3.53816rem;
    font-size: 1.077rem;
    color: #404040; }
    .select2-container--default .select2-selection--single .select2-selection__rendered .select2-selection__clear {
      right: 25px;
      font-size: 1.538rem; }
  .select2-container--default .select2-selection--single .select2-selection__arrow {
    height: 3.5382rem;
    width: 30px; }
    .select2-container--default .select2-selection--single .select2-selection__arrow b {
      border: 0;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      margin: 0; }
      .select2-container--default .select2-selection--single .select2-selection__arrow b:after {
        content: "\f2f9";
        font-family: "Material Icons";
        font-size: 1.923rem;
        font-weight: normal;
        line-height: 3.5382rem;
        color: #404040; }

.select2-container--default .select2-selection--multiple {
  min-height: 3.53816rem; }
  .select2-container--default .select2-selection--multiple .select2-selection__clear {
    margin-top: 8px;
    margin-right: 0;
    font-size: 1.538rem; }
  .select2-container--default .select2-selection--multiple .select2-selection__rendered {
    padding: 4px 12px; }
  .select2-container--default .select2-selection--multiple .select2-selection__choice {
    border-radius: 0;
    background-color: #f2f2f2;
    color: #5a5959;
    border-width: 0;
    padding: 4px 6px;
    line-height: 18px; }
  .select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
    color: #666666;
    margin-right: 3px; }
    .select2-container--default .select2-selection--multiple .select2-selection__choice__remove:hover {
      color: #4d4d4d; }
  .select2-container--default .select2-selection--multiple .select2-search--inline .select2-search__field {
    line-height: 2rem; }

.select2-container--default.select2-container--default.select2-container--focus .select2-selection--multiple {
  border: 0.07692rem solid #d5d8de; }

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b:after {
  content: "\f2f9"; }

.select2-container--default .select2-results__group {
  font-size: 0.9231rem;
  color: #5a5959; }

.select2-container--default .select2-results__option {
  padding: 10px 6px; }

.select2-container--default .select2-results__option[aria-selected="true"] {
  background-color: #f7f7f7; }

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #4285f4; }

.select2-container--default .select2-dropdown {
  border-width: 0.07692rem;
  border-color: #d5d8de; }
  .select2-container--default .select2-dropdown--above {
    border-radius: 2px 2px 0 0;
    box-shadow: 0 -2px 6px rgba(0, 0, 0, 0.12); }
  .select2-container--default .select2-dropdown--below {
    border-radius: 0 0 2px 2px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.12); }

.select2-container--default .select2-search--dropdown {
  background-color: #f2f3f4;
  border-bottom: 0.07692rem solid #d5d8de; }
  .select2-container--default .select2-search--dropdown .select2-search__field {
    background-color: transparent;
    border-width: 0;
    outline: none; }

/*------------------------------------------------------------------
  [Slider]
*/
/*Slider*/
.slider.slider-vertical {
  margin-right: 13px; }
  .slider.slider-vertical .slider-handle {
    margin-left: -1px; }

.slider.slider-horizontal {
  height: 24px; }
  .slider.slider-horizontal .slider-track {
    height: 8px; }

.slider-selection {
  background: #4285f4;
  border: 1px solid #4285f4;
  box-shadow: none; }

.slider-track {
  background: #f2f2f2;
  box-shadow: none; }

.slider-handle {
  opacity: 1;
  background: #FFFFFF;
  border: 1px solid #f0f0f0;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.12);
  height: 22px;
  width: 22px;
  transition: box-shadow 200ms ease-in-out; }

/*------------------------------------------------------------------
  [Nestable list]
*/
.dd {
  position: relative;
  display: block;
  margin: 0;
  padding: 0;
  max-width: 600px;
  list-style: none;
  font-size: 1rem;
  line-height: 20px; }

.dd-list {
  display: block;
  position: relative;
  margin: 0;
  padding: 0;
  list-style: none; }
  .dd-list .dd-item .dd-handle, .dd-list .dd3-item .dd-handle {
    cursor: default;
    display: block;
    margin: 5px 0;
    padding: 7px 10px;
    text-decoration: none;
    border: 1px solid #dedede;
    background: #FFFFFF; }
    .dd-list .dd-item .dd-handle:hover, .dd-list .dd3-item .dd-handle:hover {
      color: #FFFFFF;
      background: #4285f4;
      border-color: #4285f4; }
  .dd-list .dd-item > button, .dd-list .dd3-item > button {
    font-family: "Material Icons";
    display: block;
    position: relative;
    cursor: pointer;
    float: left;
    width: 25px;
    height: 20px;
    margin: 8px 2px;
    padding: 0;
    text-indent: 100%;
    white-space: nowrap;
    overflow: hidden;
    border: 0;
    background: transparent;
    font-size: 1.231rem;
    line-height: 1;
    text-align: center; }
    .dd-list .dd-item > button:before, .dd-list .dd3-item > button:before {
      content: '\f278';
      display: block;
      position: absolute;
      width: 100%;
      text-align: center;
      text-indent: 0; }
    .dd-list .dd-item > button[data-action="collapse"]:before, .dd-list .dd3-item > button[data-action="collapse"]:before {
      content: '\f273'; }
  .dd-list .dd-item .dd3-content, .dd-list .dd3-item .dd3-content {
    display: block;
    margin: 5px 0;
    padding: 7px 10px 7px 40px;
    text-decoration: none;
    background: none repeat scroll 0 0 #FFFFFF;
    border: 1px solid #dedede; }
    .dd-list .dd-item .dd3-content:hover, .dd-list .dd3-item .dd3-content:hover {
      background: #FFFFFF; }
  .dd-list .dd-item .dd3-handle, .dd-list .dd3-item .dd3-handle {
    position: absolute;
    margin: 0;
    left: 0;
    top: 0;
    cursor: all-scroll;
    width: 34px;
    text-indent: 100%;
    white-space: nowrap;
    overflow: hidden;
    border: 1px solid #4285f4;
    background: #4285f4;
    height: 36px; }
    .dd-list .dd-item .dd3-handle:before, .dd-list .dd3-item .dd3-handle:before {
      content: '=';
      display: block;
      position: absolute;
      left: 0;
      top: 5px;
      width: 100%;
      text-align: center;
      text-indent: 0;
      color: #FFFFFF;
      font-size: 1.769rem;
      line-height: 24px;
      font-weight: normal; }
    .dd-list .dd-item .dd3-handle:hover, .dd-list .dd3-item .dd3-handle:hover {
      background: #5a95f5;
      border-color: #5a95f5; }
  .dd-list .dd3-item > button {
    margin-left: 35px; }
  .dd-list .dd-item, .dd-list .dd-empty, .dd-list .dd-placeholder {
    display: block;
    position: relative;
    margin: 0;
    padding: 0;
    min-height: 20px;
    font-size: 1rem;
    line-height: 20px; }
  .dd-list .dd-placeholder, .dd-list .dd-empty {
    margin: 5px 0;
    padding: 0;
    min-height: 30px;
    background: #FFF;
    border: 1px dashed #b3b2b2;
    box-sizing: border-box;
    -moz-box-sizing: border-box; }
  .dd-list .dd-empty {
    border: 1px dashed #bbb;
    min-height: 100px;
    background-color: #e5e5e5;
    background-image: linear-gradient(45deg, #FFFFFF 25%, transparent 25%, transparent 75%, #FFFFFF 75%, #FFFFFF), linear-gradient(45deg, #FFFFFF 25%, transparent 25%, transparent 75%, #FFFFFF 75%, #FFFFFF);
    background-size: 60px 60px;
    background-position: 0 0, 30px 30px; }
  .dd-list .dd-collapsed .dd-list {
    display: none; }
  .dd-list.dd-dragel {
    position: absolute;
    pointer-events: none;
    z-index: 9999; }
    .dd-list.dd-dragel > .dd-item .dd-handle {
      margin-top: 0; }
    .dd-list.dd-dragel .dd-handle, .dd-list.dd-dragel .dd3-handle {
      box-shadow: 2px 4px 6px 0 rgba(0, 0, 0, 0.1); }
  .dd-list .dd-list {
    padding-left: 30px; }

/*------------------------------------------------------------------
  [Parsley]
*/
.parsley-errors-list.filled {
  margin-top: 10px;
  margin-bottom: 0;
  padding: 15px 20px;
  position: relative;
  background-color: #eb6357;
  color: #FFFFFF; }
  .parsley-errors-list.filled:after {
    content: "";
    display: inline-block;
    position: absolute;
    top: -6px;
    left: 20px;
    width: 0;
    height: 0;
    content: '';
    z-index: 2;
    border-bottom: 7px solid #eb6357;
    -moz-transform: scale(0.9999);
    border-left: 7px solid transparent;
    border-right: 7px solid transparent; }
  .parsley-errors-list.filled > li {
    list-style: none; }

/*------------------------------------------------------------------
  [Summernote]
*/
.note-editor.note-frame {
  margin-bottom: 0;
  border-width: 0;
  box-shadow: none; }
  .note-editor.note-frame .note-toolbar {
    margin: 0;
    padding-bottom: 5px; }
    .note-editor.note-frame .note-toolbar .btn .fa {
      font-size: 1.077rem; }
    .note-editor.note-frame .note-toolbar > .btn-group {
      margin-left: 0;
      margin-right: 5px;
      margin-top: 5px; }
      .note-editor.note-frame .note-toolbar > .btn-group .btn-light {
        border-color: #d5d8de; }
        .note-editor.note-frame .note-toolbar > .btn-group .btn-light:hover {
          border-color: #bdbdbd; }
        .note-editor.note-frame .note-toolbar > .btn-group .btn-light:not([disabled]):not(.disabled):active, .note-editor.note-frame .note-toolbar > .btn-group .btn-light:not([disabled]):not(.disabled).active,
        .show > .note-editor.note-frame .note-toolbar > .btn-group .btn-light.dropdown-toggle {
          border-color: #bababa; }
        .note-editor.note-frame .note-toolbar > .btn-group .btn-light.dropdown-toggle:after {
          display: inline-block; }
        .note-editor.note-frame .note-toolbar > .btn-group .btn-light + .dropdown-menu blockquote {
          padding: 10px 20px; }
    .note-editor.note-frame .note-toolbar .note-color .dropdown-toggle {
      padding-left: 5px;
      width: 20px; }
  .note-editor.note-frame .note-statusbar {
    background-color: #f6f6f6; }
    .note-editor.note-frame .note-statusbar .note-resizebar {
      height: auto;
      padding-top: 3px;
      padding-bottom: 4px; }
  .note-editor.note-frame .note-editable {
    border: 1px solid #d5d8de;
    padding: 10px 12px;
    margin-top: 6px;
    border-radius: 0; }
  .note-editor.note-frame .note-editing-area .note-editable {
    color: #404040; }

/*------------------------------------------------------------------
  [Vector Maps]
*/
.jvectormap-zoomin,
.jvectormap-zoomout {
  line-height: 11px; }

.jqvmap-zoomin,
.jqvmap-zoomout {
  width: 30px;
  height: 30px;
  background-color: #d9d9d9;
  color: #7a7a7a;
  font-size: 22px;
  line-height: 24px;
  text-align: center;
  border-radius: 0;
  top: auto;
  left: 0;
  bottom: 0; }
  .jqvmap-zoomin:hover,
  .jqvmap-zoomout:hover {
    background-color: #c9c9c9; }

.jqvmap-zoomin {
  left: 43px; }

/*------------------------------------------------------------------
  [Wizard]
*/
.fuelux .wizard {
  *zoom: 1;
  border: 1px solid #d4d4d4;
  border-radius: 4px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.065);
  background-color: #f9f9f9;
  position: relative;
  min-height: 48px;
  overflow: hidden; }

.fuelux .wizard:before,
.fuelux .wizard:after {
  display: table;
  content: "";
  line-height: 0; }

.fuelux .wizard:after {
  clear: both; }

.fuelux .wizard > .steps-container > .steps {
  list-style: none outside none;
  padding: 0;
  margin: 0; }

.fuelux .wizard > .steps-container > .steps.previous-disabled li.complete {
  cursor: default; }

.fuelux .wizard > .steps-container > .steps.previous-disabled li.complete:hover {
  background: #f3f4f5;
  color: #468847;
  cursor: default; }

.fuelux .wizard > .steps-container > .steps.previous-disabled li.complete:hover .chevron:before {
  border-left-color: #f3f4f5; }

.fuelux .wizard > .steps-container > .steps li {
  float: left;
  margin: 0;
  padding: 0 20px 0 30px;
  height: 46px;
  line-height: 46px;
  position: relative;
  background: #ededed;
  color: #999999;
  font-size: 1.231rem;
  cursor: not-allowed; }

.fuelux .wizard > .steps-container > .steps li .chevron {
  border: 24px solid transparent;
  border-left: 14px solid #d4d4d4;
  border-right: 0;
  display: block;
  position: absolute;
  right: -14px;
  top: 0;
  z-index: 1; }

.fuelux .wizard > .steps-container > .steps li .chevron:before {
  border: 24px solid transparent;
  border-left: 14px solid #ededed;
  border-right: 0;
  content: "";
  display: block;
  position: absolute;
  right: 1px;
  top: -24px; }

.fuelux .wizard > .steps-container > .steps li.complete {
  background: #f3f4f5;
  color: #468847; }

.fuelux .wizard > .steps-container > .steps li.complete:hover {
  background: #e7eff8;
  cursor: pointer; }

.fuelux .wizard > .steps-container > .steps li.complete:hover .chevron:before {
  border-left: 14px solid #e7eff8; }

.fuelux .wizard > .steps-container > .steps li.complete .chevron:before {
  border-left: 14px solid #f3f4f5; }

.fuelux .wizard > .steps-container > .steps li.active {
  background: #f1f6fc;
  color: #3a87ad;
  cursor: default; }

.fuelux .wizard > .steps-container > .steps li.active .chevron:before {
  border-left: 14px solid #f1f6fc; }

.fuelux .wizard > .steps-container > .steps li.active .badge {
  background-color: #3a87ad; }

.fuelux .wizard > .steps-container > .steps li .badge {
  margin-right: 8px; }

.fuelux .wizard > .steps-container > .steps li .badge-success {
  background-color: #468847; }

.fuelux .wizard > .steps-container > .steps li:first-child {
  border-radius: 4px 0 0 4px;
  padding-left: 20px; }

.fuelux .wizard > .actions {
  z-index: 1000;
  position: absolute;
  right: 0;
  line-height: 46px;
  float: right;
  padding-left: 15px;
  padding-right: 15px;
  vertical-align: middle;
  background-color: #e5e5e5;
  border-left: 1px solid #d4d4d4; }

.fuelux .wizard > .actions a {
  line-height: 45px;
  font-size: .9231rem;
  margin-right: 8px; }

.fuelux .wizard > .actions .btn-prev[disabled] {
  cursor: not-allowed; }

.fuelux .wizard > .actions .btn-prev span {
  margin-right: 5px; }

.fuelux .wizard > .actions .btn-next[disabled] {
  cursor: not-allowed; }

.fuelux .wizard > .actions .btn-next span {
  margin-left: 5px; }

.fuelux .wizard .step-content {
  border-top: 1px solid #D4D4D4;
  padding: 10px;
  float: left;
  width: 100%; }

.fuelux .wizard .step-content .step-pane {
  display: none; }

.fuelux .wizard .step-content > .active {
  display: block; }

.fuelux .wizard .step-content > .active .btn-group .active {
  display: inline-block; }

.fuelux .wizard.complete > .actions .glyphicon-arrow-right:before {
  display: none; }

.fuelux .wizard.complete > .actions .glyphicon-arrow-right {
  margin-left: 0; }

.block-wizard {
  padding: 0; }

.fuelux .wizard {
  box-shadow: none;
  border-radius: 0;
  -webkit-border-radius: 0;
  border: 0;
  background: #FFFFFF; }
  .fuelux .wizard label.col-form-label {
    font-weight: 600; }
  .fuelux .wizard .wizard-title {
    margin: 10px 0 0; }
  .fuelux .wizard > .actions {
    line-height: 48px;
    background: #f7f7f7;
    padding: 0;
    font-size: 0; }
    .fuelux .wizard > .actions .btn {
      line-height: 48px;
      border-width: 0;
      border-radius: 0;
      vertical-align: top;
      padding: 0 14px;
      box-shadow: none;
      font-weight: 400;
      color: #949494; }
      .fuelux .wizard > .actions .btn:focus, .fuelux .wizard > .actions .btn:active {
        box-shadow: none;
        border-width: 0; }
      .fuelux .wizard > .actions .btn:hover {
        background-color: #f7f7f7; }
      .fuelux .wizard > .actions .btn:first-child {
        border-right: 1px solid #d4d4d4; }
      .fuelux .wizard > .actions .btn .icon {
        font-size: 1.308rem;
        line-height: 17px;
        position: relative;
        top: -1px;
        color: #949494; }
      .fuelux .wizard > .actions .btn.btn-previous i {
        margin-right: 5px; }
      .fuelux .wizard > .actions .btn.btn-next i {
        margin-left: 5px; }
  .fuelux .wizard > .steps-container > .steps > li {
    height: 48px;
    background-color: #f7f7f7; }
    .fuelux .wizard > .steps-container > .steps > li .chevron {
      border-left-color: #e0e0e0; }
      .fuelux .wizard > .steps-container > .steps > li .chevron:before {
        border-left: 14px solid #f7f7f7; }
  .fuelux .wizard > .steps-container > .steps li {
    font-size: 1.154rem; }
    .fuelux .wizard > .steps-container > .steps li:first-child {
      border-radius: 0; }
    .fuelux .wizard > .steps-container > .steps li.active {
      background: #FFFFFF;
      color: #4285f4; }
      .fuelux .wizard > .steps-container > .steps li.active .chevron:before {
        -moz-transform: scale(0.9999);
        border-left: 14px solid #FFFFFF; }
    .fuelux .wizard > .steps-container > .steps li.complete {
      color: #FFFFFF;
      background: #4285f4; }
      .fuelux .wizard > .steps-container > .steps li.complete .chevron {
        border-left-color: #1b6cf2; }
        .fuelux .wizard > .steps-container > .steps li.complete .chevron:before {
          border-left: 14px solid #4285f4; }
      .fuelux .wizard > .steps-container > .steps li.complete:hover {
        background: #508ef5; }
        .fuelux .wizard > .steps-container > .steps li.complete:hover .chevron {
          border-left-color: #2572f2; }
          .fuelux .wizard > .steps-container > .steps li.complete:hover .chevron:before {
            border-left-color: #508ef5; }
    .fuelux .wizard > .steps-container > .steps li .chevron {
      -moz-transform: scale(0.9999); }
  .fuelux .wizard > .steps-container > .steps.previous-disabled li.complete:hover {
    color: #FFFFFF;
    background: #4285f4; }
    .fuelux .wizard > .steps-container > .steps.previous-disabled li.complete:hover .chevron:before {
      border-left-color: #4285f4; }
  .fuelux .wizard > .actions button {
    font-size: .9231rem; }
    .fuelux .wizard > .actions button i {
      font-size: .8462rem;
      line-height: 17px; }
    .fuelux .wizard > .actions button.btn-prev i {
      margin-right: 3px; }
    .fuelux .wizard > .actions button.btn-next i {
      margin-left: 3px; }
  .fuelux .wizard .step-content {
    padding: 20px 30px;
    clear: both;
    border-top: 1px solid #E2E2E2; }

@media (max-width: 767px) {
  .wizard-row .fuelux {
    position: static; } }

/*------------------------------------------------------------------
  [Calendar Widget]
*/
.widget-calendar .ui-datepicker {
  position: relative;
  margin: 10px 0 32px; }
  .widget-calendar .ui-datepicker .ui-datepicker-header {
    border-bottom: 1px solid rgba(255, 255, 255, 0.6);
    padding-bottom: 5px;
    margin-bottom: 20px; }
    .widget-calendar .ui-datepicker .ui-datepicker-header .ui-datepicker-title {
      cursor: default;
      line-height: 24px;
      margin: 0 2.3em;
      text-align: center;
      font-weight: 300;
      font-size: 1.385rem; }
    .widget-calendar .ui-datepicker .ui-datepicker-header .ui-datepicker-prev, .widget-calendar .ui-datepicker .ui-datepicker-header .ui-datepicker-next {
      height: 1.8em;
      position: absolute;
      top: -5px;
      width: 1.8em;
      color: #404040;
      font-size: 1.923rem; }
      .widget-calendar .ui-datepicker .ui-datepicker-header .ui-datepicker-prev span, .widget-calendar .ui-datepicker .ui-datepicker-header .ui-datepicker-next span {
        overflow: hidden;
        display: block;
        text-indent: -99999px; }
        .widget-calendar .ui-datepicker .ui-datepicker-header .ui-datepicker-prev span:after, .widget-calendar .ui-datepicker .ui-datepicker-header .ui-datepicker-next span:after {
          cursor: pointer;
          content: "\f2fa";
          font-family: "Material Icons";
          display: block;
          position: absolute;
          width: 16px;
          height: 16px;
          top: 0;
          text-indent: 0; }
    .widget-calendar .ui-datepicker .ui-datepicker-header .ui-datepicker-prev {
      left: 8px; }
    .widget-calendar .ui-datepicker .ui-datepicker-header .ui-datepicker-next {
      right: 2px; }
      .widget-calendar .ui-datepicker .ui-datepicker-header .ui-datepicker-next span:after {
        content: "\f2fb";
        right: 2px; }
  .widget-calendar .ui-datepicker table.ui-datepicker-calendar {
    width: 100%; }
    .widget-calendar .ui-datepicker table.ui-datepicker-calendar tr td:first-child, .widget-calendar .ui-datepicker table.ui-datepicker-calendar tr th:first-child {
      padding-left: 0; }
    .widget-calendar .ui-datepicker table.ui-datepicker-calendar tr td:last-child, .widget-calendar .ui-datepicker table.ui-datepicker-calendar tr th:last-child {
      padding-right: 0; }
    .widget-calendar .ui-datepicker table.ui-datepicker-calendar th {
      border: 0;
      padding: 3px;
      text-align: center;
      font-weight: 400;
      color: #404040; }
      .widget-calendar .ui-datepicker table.ui-datepicker-calendar th span {
        font-size: 1.077rem; }
    .widget-calendar .ui-datepicker table.ui-datepicker-calendar tr td {
      border: 0;
      padding: 6px 0;
      text-align: center; }
      .widget-calendar .ui-datepicker table.ui-datepicker-calendar tr td a {
        display: inline-block;
        padding: 0;
        text-align: center;
        text-decoration: none;
        color: #737373;
        width: 27px;
        height: 27px;
        line-height: 27px; }
        .widget-calendar .ui-datepicker table.ui-datepicker-calendar tr td a:hover {
          color: #404040; }
        .widget-calendar .ui-datepicker table.ui-datepicker-calendar tr td a.ui-state-active, .widget-calendar .ui-datepicker table.ui-datepicker-calendar tr td a.ui-state-highlight {
          background-color: #e6e5e5;
          border-radius: 50%; }
        .widget-calendar .ui-datepicker table.ui-datepicker-calendar tr td a.ui-state-highlight {
          background-color: #4285f4;
          color: #FFFFFF; }
      .widget-calendar .ui-datepicker table.ui-datepicker-calendar tr td.has-events a {
        position: relative;
        background-color: #ebf2fe;
        border-radius: 50%; }
      .widget-calendar .ui-datepicker table.ui-datepicker-calendar tr td.ui-datepicker-other-month a {
        color: #cccccc; }
  .widget-calendar .ui-datepicker.ui-datepicker-6rows {
    margin-bottom: 28px; }
    .widget-calendar .ui-datepicker.ui-datepicker-6rows table.ui-datepicker-calendar tr td {
      padding: 3px 0; }

.widget.widget-map .map-container > div {
  height: 229px; }

/*------------------------------------------------------------------
  [Tile widgets]
*/
.widget.widget-tile {
  padding: 24px 20px;
  margin-bottom: 25px;
  display: table;
  table-layout: fixed;
  width: 100%; }
  .widget.widget-tile .data-info {
    display: table-cell;
    text-align: right; }
    .widget.widget-tile .data-info .value {
      font-size: 1.693rem;
      font-weight: 300; }
    .widget.widget-tile .data-info .indicator {
      font-size: 1.923rem;
      margin-right: 7px;
      line-height: 24px;
      vertical-align: middle; }
      .widget.widget-tile .data-info .indicator-equal {
        color: #4285f4; }
      .widget.widget-tile .data-info .indicator-positive {
        color: #34a853; }
      .widget.widget-tile .data-info .indicator-negative {
        color: #ea4335; }
    .widget.widget-tile .data-info .desc {
      font-size: 1.077rem;
      line-height: 18px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap; }
  .widget.widget-tile .chart {
    width: 85px;
    min-height: 45px;
    padding: 5px 0;
    display: table-cell;
    vertical-align: middle; }
  .widget.widget-tile.widget-tile-wide {
    display: table;
    width: 100%; }
    .widget.widget-tile.widget-tile-wide > div {
      display: table-cell;
      width: 50%;
      vertical-align: middle; }
    .widget.widget-tile.widget-tile-wide .data-info {
      vertical-align: top; }
      .widget.widget-tile.widget-tile-wide .data-info .title {
        font-size: 1.231rem;
        line-height: 22px;
        margin-bottom: 2px; }
      .widget.widget-tile.widget-tile-wide .data-info .desc {
        color: #807f7f;
        font-size: .9231rem; }
    .widget.widget-tile.widget-tile-wide .tile-value {
      text-align: right; }
      .widget.widget-tile.widget-tile-wide .tile-value > span {
        display: block;
        padding-right: 10px;
        font-weight: 300;
        font-size: 2.385rem; }
    .widget.widget-tile.widget-tile-wide .icon {
      float: none;
      line-height: 43px;
      vertical-align: top;
      margin-right: 5px; }

/*------------------------------------------------------------------
  [User profile widget]
*/
.user-display {
  margin-bottom: 30px;
  border-radius: 2px;
  background-color: #FFFFFF; }
  .user-display-bg {
    max-height: 300px;
    overflow: hidden; }
    .user-display-bg img {
      width: 100%;
      border-radius: 2px 2px 0 0; }
      @media (max-width: 767.98px) {
        .user-display-bg img {
          min-height: 130px; } }
  .user-display-bottom {
    position: relative;
    padding: 11px 20px; }
  .user-display-avatar {
    position: absolute;
    left: 20px;
    top: -50px;
    border-radius: 50%;
    background-color: #FFFFFF; }
    .user-display-avatar img {
      width: 110px;
      height: 110px;
      border-radius: 50%;
      border: 3px solid #FFFFFF; }
  .user-display-info {
    line-height: 20px;
    padding-left: 130px; }
    .user-display-info .name {
      display: block;
      font-size: 1.385rem;
      font-weight: 500;
      line-height: 22px; }
    .user-display-info .nick {
      line-height: 21px;
      color: #5f5f5f; }
  .user-display-details {
    margin-top: 15px; }
    .user-display-details .title {
      text-transform: uppercase;
      font-size: .9231rem;
      color: #5f5f5f; }
    .user-display-details .counter {
      font-size: 1.385rem; }

/*------------------------------------------------------------------
  [User info list widget]
*/
.user-info-list table {
  width: 100%;
  table-layout: fixed; }
  .user-info-list table tr td {
    padding: 12px 0; }
    .user-info-list table tr td.icon {
      width: 33px; }
      .user-info-list table tr td.icon > span {
        font-size: 1.538rem;
        vertical-align: middle;
        margin-bottom: 1px; }
    .user-info-list table tr td.item {
      width: 34%; }

/*------------------------------------------------------------------
  [User current progress widget]
*/
.user-progress {
  margin-bottom: 15px; }
  .user-progress .title {
    display: block;
    margin-bottom: 10px; }
  .user-progress .value {
    display: block;
    text-align: right;
    font-size: 1.385rem;
    padding-top: 18px;
    overflow-wrap: normal; }
  .user-progress .progress {
    height: 10px;
    border-radius: 5px;
    margin-bottom: 0; }
    .user-progress .progress-bar {
      border-radius: 5px; }
  .user-progress-small {
    margin-bottom: 20px; }
    .user-progress-small .title {
      display: inline-block; }
    .user-progress-small .progress {
      margin-top: 5px;
      height: 5px; }

/*------------------------------------------------------------------
  [User timeline widget]
*/
.user-timeline {
  padding: 0;
  margin: 5px 0 15px;
  list-style: none;
  position: relative; }
  .user-timeline:before {
    content: "";
    position: absolute;
    left: 7px;
    top: 2px;
    height: 100%;
    width: 2px;
    background-color: #4285f4; }
  .user-timeline:after {
    content: "";
    position: absolute;
    left: 4px;
    bottom: -2px;
    height: 8px;
    width: 8px;
    background-color: #4285f4;
    border-radius: 50%; }
  .user-timeline > li {
    display: block;
    position: relative;
    padding-left: 30px;
    padding-bottom: 27px; }
    .user-timeline > li:before {
      content: "";
      position: absolute;
      left: 0;
      top: 2px;
      height: 16px;
      width: 16px;
      background-color: #FFFFFF;
      border-radius: 50%;
      border: 1px solid #4285f4; }
    .user-timeline > li.latest:before {
      background-color: #4285f4; }
  .user-timeline-date {
    text-transform: uppercase;
    color: #737373;
    margin-bottom: 8px; }
  .user-timeline-title {
    font-weight: 500; }
  .user-timeline-compact .user-timeline-date {
    float: right;
    text-transform: capitalize;
    margin-bottom: 0;
    line-height: 22px; }
  .user-timeline-compact .user-timeline-title {
    font-size: 1.385rem;
    font-weight: 300; }
  .user-timeline-compact .user-timeline-description {
    color: #737373;
    margin-top: 4px; }
  .user-timeline-compact:before {
    background-color: #f6c163; }
  .user-timeline-compact:after {
    background-color: #f6c163; }
  .user-timeline-compact > li {
    padding-bottom: 35px; }
    .user-timeline-compact > li:last-child {
      padding-bottom: 25px; }
    .user-timeline-compact > li:before {
      border: 1px solid #f6c163; }
    .user-timeline-compact > li.latest:before {
      background-color: #f6c163; }
