

.be-datatable-header {
  padding-left: 0;
  padding-right: 0;
}

// Fix for data tables headers inside a table-responsive div.

div.table-responsive > div.dt-container > div.be-datatable-header > div[class^="col-"]:first-child {
  padding-left: 15px;
}

div.table-responsive > div.dt-container > div.be-datatable-header > div[class^="col-"]:last-child {
  padding-right: 15px;
}

.card div[data-controller="data-table"]:last-child .be-datatable-footer {
  // so that the card bottom is still rounded with a datatable
  border-radius: $rounded-bottom;
}

.card > .dt-container:last-child .be-datatable-footer {
  // so that the card bottom is still rounded with a datatable
  border-radius: $rounded-bottom;
}

.be-datatable-header, .be-datatable-footer {
  margin: 0;
}

// Fix for DataTables horizontal scroll
.be-datatable-body {
  overflow-x: auto;
}


.page-link {
  padding: 5px;
}

.page-item.active .page-link {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}

table.dataTable thead > tr > th.sorting_asc, table.dataTable thead > tr > th.sorting_desc, table.dataTable thead > tr > th.sorting,
table.dataTable thead > tr > td.sorting_asc,
table.dataTable thead > tr > td.sorting_desc,
table.dataTable thead > tr > td.sorting {
  padding-right: 40px;
}

div.dt-container > div.row {
  margin-bottom: 0;
}

/* These are copied from beagle but updated for newer datatables */
/* TODO fold these in to the base theme */

div.dt-container {
  flex-flow: column;
  align-items: stretch;
  padding-left: 0;
  padding-right: 0;

  div.dt-info {
    color: lighten($body-color, 13%);
    padding-top: 5px;
  }

  div.dt-paging {

    ul.pagination {
      margin: 0;
      //justify-content: flex-end;
      flex-wrap: nowrap;
    }
  }

  div.dt-length label, div.dt-search label {
    margin-bottom: 0;
    font-size: 1rem;
    color: lighten($body-color, 13%);
  }

  div.dt-length label {
    justify-content: flex-start;

    select.form-control-sm {
      height: 37px;
      padding: 2px;
      margin: 0 5px;
    }
  }

  div.dt-search label {
    justify-content: flex-end;

    select {
      padding: 2px;
    }
  }

  div.dt-search {
    display: flex;
    align-items: center;
  }
}
