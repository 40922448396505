
/* Collapse */

.collapse-header {
  color: inherit;
  cursor: pointer;
  width: 100%;
}

.collapse-header > .icon {
  transition: transform 200ms ease-in-out;
  display: inline-block;
}

.collapse-header[aria-expanded='true'] > .icon {
  transform: rotate(90deg);
}

.list-group-flush:first-child div:not(:first-child) .list-group-item:first-child {
  border-top: 1px solid #dfdfdf;
}

.list-group-item {
  padding: 8px;
  color: inherit;
  border-color: #dfdfdf;
}

.list-group-item .tools {
  @extend .nowrap;
}

.list-group-item .tools > :last-child {
  margin-right: 0;
}

.list-group-item-action {
  cursor: pointer;
}

.list-group-item-row {
  @extend .d-flex;
  @extend .justify-content-between;
  @extend .align-items-center;
  padding: 12px 20px;
}

.list-group-item .icon:last-child {
  margin-right: 0;
}
