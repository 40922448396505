
/* Image utilities */

.image-drop-shadow {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

img.image-size-constrained {
  max-width: 100%;
  height: auto;
}

img.contain {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

img.cover {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.fit-bg-img {
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.fill-bg-img {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

// Force height to match width
.square {
  display: inline-block;
  position: relative;
  &::after {
    content: '';
    display: block;
    margin-top: 100%;
  }
}

.img-no-pad {
  // fix inline padding
  font-size: 0;
}

.zoom-hover {
  transition: transform 400ms;
}

.zoom-hover:hover {
  transform: scale(1.1);
}
