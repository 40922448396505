

/* ********************* */
/* Monthly filing styles */
/* ********************* */

.filing-approved {
  background-color: #71dd8a;
}

.filing-closed {
  background-color: #dddddd;
}

.filing-open {
  background-color: #dddddd;
}

.filing-open-overdue {
  background-color: #ffd0d0;
}
