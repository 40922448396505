
/* Animation */

:focus > .spin-on-click:before, .spin-on-click:focus:before {
  animation: mdi-spin 2s infinite linear;
}

.loading-shimmer {
  background: linear-gradient(-45deg, #eee 40%, #fafafa 50%, #eee 60%);
  background-size: 800%;
  background-position-x: 100%;
  animation: shimmer-2 1s 20 linear;
}

@keyframes shimmer {
  to {
    background-position-x: 0;
  }
}
