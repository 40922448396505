/* OES KS Annual Billing */

.billing-title {
  font-size: xx-large;
}

.billing-subtitle {
  font-size: x-large;
  padding-top: 20px;
}

#oesks-billing-top {
  padding-top: 25px;
  width: 99%;
}

.explanation {
  font-size: x-small;
}

.full-underlined {
  border-bottom-width: thin;
  border-color: #0a001f;
  border-bottom-style: solid;
}

.print-highlight {
  border-style: solid;
  border-color: red;
  border-width: medium;
}

#oesks-billing-bottom {
  padding-top: 50px;
}

.empty-line {
  padding-top: 1.5em;
}

#seal-box {
  border-color: #0a001f;
  border-style: solid;
  text-align: center;
  vertical-align: center;
  color: lightgrey;
  height: 8em;
}

.hr-thick {
  border-bottom-color: black;
  border-bottom-style: solid;
  border-bottom-width: thick;
}
