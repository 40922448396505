

/* ************ */
/* App Switcher */
/* ************ */

.be-icons-nav > li.dropdown.show > a:after, .be-icons-nav > li.dropdown.show > a:before {
  z-index: 1031;
}

.be-icons-nav > li.dropdown > a:after {
  display: none;
}

.be-icons-nav > li.dropdown.show > a:before {
  border-bottom-color: $menu-border-color;
  bottom: -13px;
}

.be-icons-nav > li.dropdown .dropdown-menu.be-connections {
  width: 250px;
  margin-right: 0;

  .connection-item {
    .icon {
      font-size: 3rem;
      color: var(--primary-color);
    }
    .badge-primary {
      background-color: var(--primary-color);
    }
    &:hover .icon {
      color: var(--primary-color-darker);
    }
    &:hover .badge-primary {
      background-color: var(--primary-color-darker);
    }
  }
}

@media (max-width: 575.98px) {
  .be-icons-nav > li.dropdown .dropdown-menu.be-connections {
    width: 100%;
    left: 0;
    border: 0;
    border-radius: 0;
    box-shadow: $menu-drop-shadow;
  }
  .be-connections .content {
    padding: 20px 15px;
  }
}

.more {
  &::before {
    content: 'Less';
  }
  &.collapsed::before {
    content: 'More';
  }
}

.row.collapse.show {
  display: flex;
}
