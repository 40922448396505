
/* ************ */
/* Modal Styles */
/* ************ */

.modal-body {
  .default-card {
    border: 0;
    border-top: 0;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;

    .default-card-header {
      background-color: white;
      border-bottom: 2px #0a001f solid;
      background: none;
      padding-left: 0;
      font-size: 22px;
      font-weight: bold;
    }

    .card-body {
      padding-left: 0;
      padding-right: 0;
    }
  }

}

.modal-line-items {
  @extend .modal-body;
  background-color: #ecf9ff;
  box-shadow: inset 0 4px 6px -6px rgb(0 0 0 / 30%);
}

.modal-body .row:last-child .form-group {
  margin-bottom: 1.538rem;
}

.modal-content {
  border-radius: ($card-border-radius * 2);
}

.modal-header .close {
  margin: -1rem -1rem -1rem auto;
}

.full-height {
  .modal-content {
    max-height: calc(100vh - 60px);
    .scrollable-table-container {
      max-height: calc(100vh - 130px);
    }
    .modal-body {
      .scrollable-table-container {
        max-height: calc(100vh - 160px);
      }
    }
  }
}
