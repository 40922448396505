//body {
//  //--fc-small-font-size: .85em;
//  //--fc-page-bg-color: #fff;
//  //--fc-neutral-bg-color: rgba(208, 208, 208, 0.3);
//  //--fc-neutral-text-color: #808080;
//  //--fc-border-color: #ddd;
//
//  --fc-button-text-color: #fff;
//  --fc-button-bg-color: var(--primary-color);
//  --fc-button-border-color: var(--primary-color);
//  --fc-button-hover-bg-color: var(--primary-color-darker);
//  --fc-button-hover-border-color: var(--primary-color-darkest);
//  --fc-button-active-bg-color: var(--primary-color-darkest);
//  --fc-button-active-border-color: var(--primary-color-darkest);
//
//  //--fc-event-bg-color: #3788d8;
//  //--fc-event-border-color: #3788d8;
//  //--fc-event-text-color: #fff;
//  //--fc-event-selected-overlay-color: rgba(0, 0, 0, 0.25);
//  //
//  //--fc-more-link-bg-color: #d0d0d0;
//  //--fc-more-link-text-color: inherit;
//  //
//  //--fc-event-resizer-thickness: 8px;
//  //--fc-event-resizer-dot-total-width: 8px;
//  //--fc-event-resizer-dot-border-width: 1px;
//  //
//  //--fc-non-business-color: rgba(215, 215, 215, 0.3);
//  //--fc-bg-event-color: rgb(143, 223, 130);
//  //--fc-bg-event-opacity: 0.3;
//  //--fc-highlight-color: rgba(188, 232, 241, 0.3);
//  //--fc-today-bg-color: rgba(255, 220, 40, 0.15);
//  //--fc-now-indicator-color: red;
//}

/* FullCalendar Tweaks */

.fc {
  .fc-toolbar {
    align-items: start;
  }

  .fc-toolbar-title {
    font-size: 1.5em;
    padding: 0 15px;
  }

  .fc-daygrid-day-frame {
    min-height: 4em;
  }
}
