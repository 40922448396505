
/* ***************** */
/* Multi-step wizard */
/* ***************** */

.fuelux .wizard > .steps-container > .steps li {
  &.active {
    color: white;
    background: var(--primary-color);

    .chevron {
      border-left-color: white;

      &:before {
        border-left-color: var(--primary-color);
      }
    }
  }

  &.complete {
    color: white;
    background: var(--primary-color-dark);

    .chevron {
      border-left-color: white;

      &:before {
        border-left-color: var(--primary-color-dark);
      }
    }

    &:hover {
      color: white;
      background: var(--primary-color-light);

      .chevron {
        border-left-color: white;

        &:before {
          border-left-color: var(--primary-color-light);
        }
      }
    }
  }
}
