

.default-table {
  @extend .table;
  @extend .table-striped;
  @extend .table-hover;
  @extend .table-tight;
}

.table {

  &.table-tight {
    th, td {
      padding: 5px;
    }
  }
}

.table-striped {
  tbody {
    tr {
      &:nth-of-type(odd) {
        background-color: #ffffff;
      }
      &:nth-of-type(even) {
        background-color: rgba(0, 0, 0, 0.04);
      }
    }
  }
}

.table-column-striped {
  tbody {
    td {
      &:nth-of-type(even) {
        background-color: rgba(0, 0, 0, 0.04);
      }
    }
  }
}

.table-hover {
  tbody {
    tr {
      &:nth-of-type(odd) {
        &:hover {
          background-color: var(--primary-color-light);
        }
      }
      &:nth-of-type(even) {
        &:hover {
          background-color: var(--primary-color-light);
        }
      }
    }
  }
}

@mixin table-row-variant($state, $background, $hover-background, $color) {
  // Exact selectors below required to override `.table-striped` and prevent
  // inheritance to nested tables.
  .table-#{$state} {
    &,
    > th,
    > td {
      background-color: $background;
      color: $color;
    }
  }

  // Hover states for `.table-hover`
  // Note: this is not available for cells or rows within `thead` or `tfoot`.
  .table-hover {
    //$hover-background: darken($background, 5%);

    .table-#{$state} {
      &:hover {
        background-color: $hover-background;
        color: $color;

        > td,
        > th {
          background-color: $hover-background;
          color: $color;
        }
      }
    }
  }
}

@include table-row-variant(primary, var(--primary-color), var(--primary-color-dark), $body-color);
@include table-row-variant(success, $light-success-color, darken($light-success-color, 5%), $body-color);
@include table-row-variant(info, var(--primary-color-lightest), var(--primary-color-lighter), $body-color);
@include table-row-variant(warning, $light-warning-color, darken($light-warning-color, 5%), $body-color);
@include table-row-variant(danger, $light-danger-color, darken($light-danger-color, 5%), $body-color);

@mixin table-row-border-variant($state, $color) {
  .table tbody tr.#{$state} td:first-child {
    border-left: 3px solid $color;
    padding-left: 17px;
  }
}

@include table-row-border-variant(primary, var(--primary-color));
@include table-row-border-variant(success, $success);
@include table-row-border-variant(warning, $warning);
@include table-row-border-variant(danger, $danger);

tr.table-highlight {
  td {
    background-color: #cfc;
    color: #404040;
  }
}

tr.table-highlight-warning {
  td {
    background-color: #ffc;
    color: #404040;
  }
}

.tab-warning, td.td-warning {
  background-color: $warning-color;
  color: white;
}

.scrollable-table-container {
  // create a scrollable viewport
  position: relative;
  max-height: 60vh;
  overflow: auto;
  // prevent navigation on swipe
  overscroll-behavior-x: contain;
  //margin-bottom: 1rem;

  table {
    margin-bottom: 0 !important;
    // allow each cell to "own" it's border
    border-collapse: separate;
    border-spacing: 0;

    // attach the head and foot to the top and bottom of the viewport
    thead, tfoot {
      position: sticky;
      background: #fff;
      z-index: 12;
    }

    thead {
      top: 0;
    }

    tfoot {
      bottom: 0;
    }

  }
}

.scrollable-table-container.fixed-left {
  table {
    tr > :first-child {
      position: sticky;
      background: #fff;
      //background: linear-gradient(to right, #fff 95%, transparent);
      z-index: 11;
      left: 0;
    }
  }
}
