
@media print {
  body {
    background: #fff !important;
    color: #000;
    height: auto;
    font-size: 12pt !important;
  }
  body, h1, h2, h3, h4, h5, h6 {
    font-weight: normal;
  }
  a, .link-like, .clickable {
    color: #000 !important;
    text-decoration: none !important;
  }
  .be-content {
    position: relative;
    margin-top: 0;
    display: block;
  }
  .main-content {
    padding: 0;
  }
  .card {
    border: 0;
    &:last-child {
      margin-bottom: 0;
    }
    .card-header {
      background: none;
    }
  }
  .break-before {
    break-before: page;
  }
  .break-after {
    break-after: page;
  }
  .no-break-before {
    break-before: avoid;
  }
  .no-break-after {
    break-after: avoid;
  }
  /* Hide Zendesk widget for print */
  #launcher {
    display: none !important;
  }

  .row {
    margin-bottom: 18pt;
  }

  table.m2-print-bordered-table td {
    border: 1px solid #dee2e6;
  }

  table.m2-print-bordered-table td.print-border-color-red {
    border-color: red;
  }

  table.m2-print-bordered-table td.print-border-emphasis {
    border-width: 4px;
  }

  .m2-print-header {
    padding-bottom: .75em;
  }

  .m2-print-h1 {
    font-size: 1.5em;
    padding-bottom: 0.75em;
  }

  .m2-print-h2 {
    font-size: 1.25em;
    padding-bottom: 0.5em;
  }

  .m2-print-h3 {
    font-size: 1.25em;
    font-weight: bold;
  }

  .m2-print-with-borders td {
    border: 1px solid #aaaaaa;
  }

  .m2-print-with-borders th {
    border-top: 1px solid #aaaaaa;
    border-bottom: 1px solid #aaaaaa;
  }


  .m2-print-with-borders {
    border: 1px solid #aaaaaa;
  }


  .m2-print-bold {
    font-weight: bold;
  }

  .be-datatable-header, .tools  {
    display: none;
  }

}
