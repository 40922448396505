
/* Full Calendar */

.fc-toolbar h2 {
  font-size: 1.5em;
}

.be-spinner {
  padding: 2em 0;
}

// Render Chart.js tooltips on top
.chartjs-render-monitor {
  position: relative;
  z-index: 1;
}

// Summernote
.note-editor.note-frame .note-toolbar {
  background: none;
  border: 0;
  border-radius: 0;
  padding: 0;
  text-transform: unset;
  text-shadow: unset;
}

/* direct uploads */
.direct-upload-progress {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  border-bottom: 4px solid var(--primary-color-light);
  transition: width 120ms ease-out, opacity 50ms 500ms ease;
  transform: translate3d(0, 0, 0);
}

.local-inactive {
  color: #ea4335;
}

.settings-list {
  margin-bottom: 0;
}

.calendar-date-icon {
  width: 80px;
  flex-shrink: 0;
  flex-grow: 0;
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(0, 0, 0, 0.4);
  text-align: center;
  background: linear-gradient(#e3e3e3, #fff);
  color: inherit;

  .month {
    border-radius: 3px 3px 0 0;
    background: #ea4335;
    background: linear-gradient(#e72919, #ed5d51);
    color: white;
    border-bottom: 1px solid rgba(0, 0, 0, 0.4);
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    margin: 0;
    font-weight: bold;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-size: 14px;
    padding: 1px;
  }
  .weekday {
    padding: 6px 6px 0;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: bold;
  }
  .day {
    //padding: 6px;
    font-size: 24px;
    text-shadow: 0 1px 1px #fff;
  }
  .time {
    padding: 0 6px 6px;
    font-size: 10px;
    color: #555;
  }
}

// Magnific-Popup Plugin
.mfp-fade .mfp-container,
.mfp-fade.mfp-bg {
  /* ideally, transition speed should match zoom duration */
  transition: all 0.3s ease-out;
  opacity: 0;
}

.mfp-fade.mfp-ready .mfp-container {
  opacity: 1;
}
.mfp-fade.mfp-ready.mfp-bg {
  opacity: 0.8;
}

.mfp-fade.mfp-removing .mfp-container,
.mfp-fade.mfp-removing.mfp-bg {
  opacity: 0;
}

.placeholder-border {
  border: 2px dashed;
}

.comment-container {
  .replies {
    @extend .pl-4;
    border-left: thick solid $gray-300;
  }
}

.bg-black {
  background-color: #000;
}

.sparkline {
  --sparkline-bar-width: 6px;
  --sparkline-bar-spacing: 1px;

  // do not display anything while the sparkline is loading
  display: none;

  &.show {
    display: flex;
  }

  // pseudo element sets the height based on current font size
  &:before {
    content: '|';
    visibility: hidden;
    width: 0;
  }

  // bar container
  > div {
    display: flex;
    align-items: end;
    margin-right: var(--sparkline-bar-spacing);

    // bar fill
    > div {
      width: var(--sparkline-bar-width);
      background-color: var(--primary-color);
    }

    &:hover > div {
      background-color: var(--primary-color-light);
    }
  }
}
