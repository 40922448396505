
@font-face {
  font-family: 'groupable';
  font-style: normal;
  font-weight: 700;
  src: url(./../../../public/fonts/groupablelogo.woff2) format('woff2');
}

.be-top-header {
  a {
    color: white;
  }

  a:not(.connection-item):hover {
    color: white;
    text-shadow: 0 0 3px rgba(255, 255, 255, 0.5);
  }

  .be-navbar-header .navbar-brand {
    //background-image: url(./images/groupable-m2-logo-v1.png);
    width: 182px;
  }

  .be-user-nav > li.dropdown .dropdown-menu:after {
    border-bottom-color: #fff;
  }

  .page-title {
    display: none;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .search-container {
    .input-group {
      max-width: unset;
    }
    max-width: 800px;
    min-width: 200px;
    flex-shrink: 0;
  }

  .be-right-navbar-flex {
    justify-content: flex-end;
  }

  .icon {
    color: white;
    font-size: 1.846rem;
    vertical-align: middle;
    line-height: 60px;
  }
}

// display the page title on larger screens
@media (min-width: 576px) {
  .be-top-header .page-title {
    display: inline;
    text-align: left;
  }
}

.always-show-title .be-top-header .page-title {
  display: inline;
}

.breadcrumb {
  text-shadow: 0 1px 1px #fff;
  background-color: unset;
  text-transform: uppercase;
  padding-left: 0;
}

.be-color-header .be-top-header {
  background: var(--primary-color-dark);
  background: linear-gradient(180deg, var(--primary-color-dark) 0%, var(--primary-color) 78%);
  border-bottom: 4px solid var(--primary-color-light);
  box-shadow: $drop-shadow;
  // text only branding
  .be-navbar-header {
    width: unset;
    .navbar-link {
      line-height: 60px;
      font-size: 18px;
    }
    .navbar-brand {
      background: none;
      line-height: 64px;
      font-size: 22px;
      font-family: groupable, sans-serif;
      width: unset;

      //&:hover {
      //  color: white;
      //  text-shadow: 0 0 3px rgba(255, 255, 255, 0.5);
      //}

      &.beta::after {
        content: 'BETA';
        float: right;
        margin-top: -25px;
        font-size: 11px;
        line-height: 14px;
      }
    }
  }
}

.be-color-header-warning .be-top-header {
  background-color: $warning;
  border-bottom-color: $warning;
}

.be-top-header .be-right-navbar {
  background: none;
  border: none;
  position: relative;
}

.be-offcanvas-menu .be-top-header .be-navbar-header {
  background: none;
  .be-toggle-left-sidebar .icon {
    color: white;
  }
}

.be-sub-navigation {
  margin-bottom: 0;
  margin-left: 230px;
}

#global-search-input-button {
  border-color: white;
}

#flash {
  margin: 25px 25px -25px;
  >div {
    box-shadow: $drop-shadow;
  }
}

.be-left-sidebar {
  border-right: 0;
  box-shadow: $drop-shadow;
  .left-sidebar-content {
    padding-top: 18px;
  }
  .progress-widget {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .sidebar-elements {
    >li {
      >a {
        line-height: 32px;
        >.badge {
          margin-top: 6px;
        }
      }
      ul {
        padding-top: 5px;
        padding-bottom: 5px;
        li {
          >a {
            padding-top: 5px;
            padding-bottom: 5px;
          }
        }
      }
    }
  }
  &:before {
    border-right: 0;
  }
}

.be-left-sidebar .sidebar-elements > li.active > a,
.be-left-sidebar .sidebar-elements > li ul li.active > a {
  color: var(--primary-color-darker);
}

/* create an overlay when sidebar is open to catch clicks */
/* this allows us to click outside to close the menu, even over an iframe */
body.open-left-sidebar::after {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.user-sub-menu {
  display: none;
}

@media (max-width: 767.98px) {
  .be-top-header {
    position: fixed;
    .be-right-navbar {
      padding-right: 5px;
      .search-container {
        display: inline;
        min-width: 50%;
        .search-help {
          display: none;
        }
      }
      .be-user-nav {
        display: none;
      }
    }
  }
  .user-sub-menu {
    display: inline;
  }
}

.be-fixed-sidebar-desktop {
  .be-top-header .be-toggle-left-sidebar {
    display: none;
  }

  .be-left-sidebar {
    z-index: 1029;
    background-color: #f5f5f5;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    left: 0;
  }

  .be-content {
    margin-left: 230px;
  }
}

@media (max-width: 991.98px) {
  .be-fixed-sidebar-desktop {
    .be-top-header .be-toggle-left-sidebar {
      display: inline-block;
    }

    .be-left-sidebar {
      z-index: 1031;
      background-color: #FFFFFF;
      box-shadow: 0 6px 18px 0 rgba(0, 0, 0, 0.18);
      transform: translate3d(-105%, 0, 0);
    }

    .be-content {
      margin-left: 0;
    }
  }

  body.open-left-sidebar .be-left-sidebar {
    transform: translate3d(0, 0, 0);
  }
}

.nav-tabs {
  background-color: #FFFFFF;
  border-bottom: 2px solid #ebebeb;
  border-radius: $rounded-top;
}

.nav-tabs.floating-nav {
  box-shadow: $drop-shadow;
  border-radius: $card-border-radius;
  padding: 0 $card-border-radius;
  margin-bottom: $grid-gutter-width;
}

.nav-tabs > li.nav-item {
  margin-bottom: -2px;
  margin-right: 2px;
}

.nav-tabs > li.nav-item a.nav-link {
  padding: 11px 20px;
  margin-right: 0;
  min-width: 60px;
  text-align: center;
  border-radius: 0;
  color: #404040;
  border-width: 0;
}

.nav-tabs > li.nav-item a.nav-link .icon {
  font-size: 1.538rem;
  vertical-align: middle;
  margin-right: 6px;
  line-height: 17px;
}

.nav-tabs > li.nav-item a.nav-link:hover {
  background: transparent;
  color: var(--primary-color-darker);
}

.nav-tabs > li.nav-item a.nav-link:active {
  background-color: transparent;
}

.nav-tabs > li.nav-item a.nav-link.active {
  background: transparent;
  border-bottom: 2px solid var(--primary-color);
  // create a glow effect to improve contrast and visibility of active tab
  box-shadow: 0 20px 30px -12px #fff;
}

.nav-tabs > li.nav-item a.nav-link.active .icon {
  color: #555;
}

.nav-tabs > li.nav-item a.nav-link.active:hover, .nav-tabs > li.nav-item a.nav-link.active:focus {
  color: #404040;
}
