
.timeline-table {
  --timeline-color: transparent;
  --timeline-top-color: transparent;
  --timeline-bottom-color: transparent;
  --timeline-width: 2px;
  --timeline-offset: 20px;
  --timeline-content-border-width: 2px;
  --timeline-content-caret-size: 12px;

  background-color: rgba(0, 0, 0, 0.08);

  .timeline-stripe {
    &:before, &:after {
      position: absolute;
      z-index: 1;
      left: var(--timeline-offset);
      content: '';
      display: block;
      border-left: var(--timeline-width) solid var(--timeline-color);
      filter: brightness(1.4);
    }

    &:before {
      border-color: var(--timeline-top-color);
      top: 0;
      bottom: 50%;
    }

    &:after {
      border-color: var(--timeline-bottom-color);
      top: 50%;
      bottom: 0;
    }
  }

  .timeline-item:before {
    top: 50%;
    left: calc(var(--timeline-width) / 2 + var(--timeline-offset));
    margin: -8px;
    border-color: var(--timeline-color) !important;
    background-color: var(--timeline-color);
    z-index: 2;
  }

  .timeline-content {
    margin-left: 32px;
    min-height: unset;
    border: var(--timeline-content-border-width) solid transparent;
    border-radius: 6px;

    &:before, &:after {
      top: 50%;
      margin: calc((0px - var(--timeline-content-border-width) - var(--timeline-content-caret-size)) / 2);
      left: calc(var(--timeline-content-border-width) / 2);
      border: var(--timeline-content-border-width) solid transparent;
      width: var(--timeline-content-caret-size);
      height: var(--timeline-content-caret-size);
    }
  }
}

.timeline-table {
  tbody {
    tr {
      &:nth-of-type(odd) {
        &:hover {
          background-color: var(--primary-color-light);
          > td:first-child {
            background-color: var(--primary-color-light);
          }
        }
      }
      &:nth-of-type(even) {
        &:hover {
          background-color: var(--primary-color-light);
          > td:first-child {
            background-color: var(--primary-color-light);
          }
        }
      }
    }
  }
}

.table-hover {
  tr:hover {
    .timeline-item:before {
      background-color: color-mix(in srgb, var(--timeline-color), 40% #fff);
    }

    .timeline-content {
      border-color: var(--timeline-color);

      &:before {
        border-bottom-color: var(--timeline-color);
        border-left-color: var(--timeline-color);
      }
    }
  }
}
