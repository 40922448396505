
/* Material Design Icon overrides */

.be-top-header .be-toggle-top-header-menu:before {
  content: '\F0360'; // mdi-menu-up
  font-family: "Material Design Icons";
}

.be-top-header .be-toggle-top-header-menu.collapsed:before {
  content: '\F035D'; // mdi-menu-down
}

.be-left-sidebar .left-sidebar-toggle:before {
  content: '\F035D'; // mdi-menu-down
  font-family: "Material Design Icons";
}

.be-left-sidebar .left-sidebar-toggle.open:before {
  content: '\F0360'; // mdi-menu-up
}

.be-left-sidebar .sidebar-elements li.parent > a:before {
  content: '\F035D'; // mdi-menu-down
  font-family: "Material Design Icons";
}

.be-left-sidebar .sidebar-elements li.parent.open > a:before {
  content: '\F0360'; // mdi-menu-up
}

.be-scroll-top:before {
  content: '\F0143'; // mdi-chevron-up
  font-family: "Material Design Icons";
}

.be-sub-navigation-collapse.collapsed:before {
  content: '\F035D'; // mdi-menu-down
}

.be-sub-navigation-collapse:before {
  content: '\F0360'; // mdi-menu-up
  font-family: 'Material Design Icons';
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  content: "\F012C"; // mdi-check
  font-family: "Material Design Icons";
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  content: "\F012F"; // mdi-checkbox-blank-circle
  font-family: "Material Design Icons";

}

.gritter-item-wrapper .gritter-item .gritter-content.gritter-with-image .gritter-close:after, .gritter-item-wrapper .gritter-item .gritter-content.gritter-without-image .gritter-close:after {
  content: '\F0156'; // mdi-close
  font-family: "Material Design Icons";
}

.select2-container--default .select2-selection--single .select2-selection__arrow b:after {
  content: "\F0140"; // mdi-chevron-down
  font-family: "Material Design Icons";
}

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b:after {
  content: "\F0140"; // mdi-chevron-down
}

.dd-list .dd-item > button, .dd-list .dd3-item > button {
  font-family: "Material Design Icons";
}

.dd-list .dd-item > button:before, .dd-list .dd3-item > button:before {
  content: '\F0415'; // mdi-plus
}

.dd-list .dd-item > button[data-action="collapse"]:before, .dd-list .dd3-item > button[data-action="collapse"]:before {
  content: '\F0374'; // mdi-minus
}

.widget-calendar .ui-datepicker .ui-datepicker-header .ui-datepicker-prev span:after, .widget-calendar .ui-datepicker .ui-datepicker-header .ui-datepicker-next span:after {
  content: "\F0141"; // mdi-chevron-left
  font-family: "Material Design Icons";
}

.widget-calendar .ui-datepicker .ui-datepicker-header .ui-datepicker-next span:after {
  content: "\F0142"; // mdi-chevron-right
}

/* Material Design Icon aliases */

.mdi-account-box-mail::before {
  content: '\F018E'; // mdi-card-account-mail
}

.mdi-accounts::before {
  content: '\F000E'; // mdi-account-multiple
}

.mdi-alert-triangle::before {
  content: '\F0026'; // mdi-alert
}

.mdi-archive::before {
  content: '\F03D4'; // mdi-package-down
}

.mdi-arrow-merge::before {
  content: '\F00F8'; // mdi-call-merge
}

.mdi-assignment-alert::before {
  content: '\F0149'; // mdi-clipboard-alert
}

.mdi-assignment-check::before {
  content: '\F014E'; // mdi-clipboard-check
}

.mdi-audio::before {
  content: '\F0387'; // mdi-music-note
}

.mdi-balance::before {
  content: '\F0070'; // mdi-bank
}

.mdi-block::before {
  content: '\F073A'; // mdi-cancel
}

.mdi-card::before {
  content: '\F019B'; // mdi-credit-card-outline
}

.mdi-close-circle-o::before {
  content: '\F015A'; // mdi-close-circle-outline
}

.mdi-collection-folder-image::before {
  content: '\F0254'; // mdi-folder-multiple-image
}

.mdi-collection-text::before {
  content: '\F0AB7'; // mdi-text-box-multiple
}

.mdi-comments::before {
  content: '\F028C'; // mdi-forum
}

.mdi-copy::before {
  content: '\F018F'; // mdi-content-copy
}

.mdi-edit::before {
  content: '\F03EB'; // mdi-pencil
}

.mdi-file-text::before {
  content: '\F0219'; // mdi-file-document
}

.mdi-fullscreen-alt::before {
  content: '\F1005'; // mdi-overscan
}

.mdi-help::before {
  content: '\F02D7'; // mdi-help-circle
}

.mdi-image-o::before {
  content: '\F0976'; // mdi-image-outline
}

.mdi-inbox::before {
  content: '\F02FB'; // mdi-inbox-arrow-down
}

.mdi-info-outline::before {
  content: '\F02FD'; // mdi-information-outline
}

.mdi-labels::before {
  content: '\F08B5'; // mdi-palette-swatch
}

.mdi-local-shipping::before {
  content: '\F053D'; // mdi-truck
}

.mdi-long-arrow-left::before {
  content: '\F19B1'; // mdi-arrow-left-thin
}

.mdi-long-arrow-right::before {
  content: '\F19B0'; // mdi-arrow-right-thin
}

.mdi-mail-send::before {
  content: '\F048A'; // mdi-send
}

.mdi-markunread-mailbox::before {
  content: '\F0B92'; // mdi-email-mark-as-unread
}

.mdi-money::before {
  content: '\F01C1'; // mdi-currency-usd
}

.mdi-money-box::before {
  content: '\F0114'; // mdi-cash
}

.mdi-notifications::before {
  content: '\F009A'; // mdi-bell
}

.mdi-open-in-browser::before {
  content: '\F03CB'; // mdi-open-in-app
}

.mdi-pin-account::before {
  content: '\F000C'; // mdi-tooltip-account
}

.mdi-pin-drop::before {
  content: '\F034E'; // mdi-map-marker
}

.mdi-print::before {
  content: '\F042A'; // mdi-printer
}

.mdi-refresh::before {
  content: '\F006A'; // mdi-autorenew
}

.mdi-refresh-alt::before {
  content: '\F00E8'; // mdi-cached
}

.mdi-refresh-sync-alert::before {
  content: '\F04E7'; // mdi-sync-alert
}

.mdi-search::before {
  content: '\F0349'; // mdi-magnify
}

.mdi-smartphone::before {
  content: '\F011C'; // mdi-cellphone
}
.mdi-square-right::before {
  content: '\F02FA'; // mdi-import
}

.mdi-sun::before {
  content: '\F05A8'; // mdi-white-balance-sunny
}

.mdi-trending-flat::before {
  content: '\F0534'; // mdi-trending-neutral
}

.mdi-unfold-less::before {
  content: '\F054E'; // mdi-unfold-less-horizontal
}

.mdi-unfold-more::before {
  content: '\F054F'; // mdi-unfold-more-horizontal
}

.mdi-youtube-play::before {
  content: '\F05C3'; // mdi-youtube
}

/* Icon utilities */

.icon-inline {
  padding-left: 5px;
  padding-right: 5px;
  font-size: 1.2em;
  line-height: 1em;
}

.icon-stroke {
  -webkit-text-stroke: 3px #fff;
  paint-order: stroke fill;
  text-shadow: none;
}

.icon-lg {
  font-size: 3em;
}

/* Composed icons */

.youtube-icon {
  @extend .mdi-youtube-play;
  @extend .icon-inline;
  @extend .icon-stroke;
  color: red !important;
}

.card-header .tools .icon, .default-card-header .tools .icon {
  font-size: 1.7rem;
}
