
.left {
  @extend .text-left;
}

.center {
  @extend .text-center;
}

.right {
  @extend .text-right;
}

.bold {
  @extend .font-weight-bold;
}

.underline {
  text-decoration: underline;
}

.text-line-through {
  text-decoration: line-through;
}

.text-primary {
  color: var(--primary-color) !important;
}

.text-danger {
  color: #FF7364 !important;
}

small, .small {
  font-size: 90%;
}

// alias for .text-nowrap
.nowrap {
  white-space: nowrap;
}

.prewrap {
  white-space: pre-wrap;
}

.dropdown-item.disabled {
  @extend .text-grey;
  @extend .not-allowed;
}

.text-shadow-inset {
  text-shadow: 0 1px 1px #fff;
}

.line-clamp-2 {
  overflow: hidden;
  display: -webkit-box;
  display: -moz-box;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -webkit-line-clamp: 2;
  line-clamp: 2;
}

.upcase {
  text-transform: uppercase;
}

.groupable-font {
  font-family: groupable;
}

.divider-text {
  display: flex;
  align-items: center;
  &::before, &::after {
    content: "";
    display: block;
    border-top: 1px solid $card-border-color;
    width: 100%;
    margin: 0 1rem;
  }
}
