/* ****************************************************** */
/* Move content of app/assets/stylesheets/application.css */
/* ****************************************************** */

select.form-control-sm:not([size]):not([multiple]),
.input-group-sm > select.form-control:not([size]):not([multiple]),
.input-group-sm > .input-group-prepend > select.input-group-text:not([size]):not([multiple]),
.input-group-sm > .input-group-append > select.input-group-text:not([size]):not([multiple]),
.input-group-sm > .input-group-prepend > select.btn:not([size]):not([multiple]),
.input-group-sm > .input-group-append > select.btn:not([size]):not([multiple]) {
  height: 37px;
}

.btn, .form-control,
.form-control-sm, .input-group-sm > .form-control,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  border-radius: 6px;
}

.input-group > .input-group-append > .btn, .input-group > .input-group-append > .input-group-text, .input-group > .input-group-prepend:not(:first-child) > .btn, .input-group > .input-group-prepend:not(:first-child) > .input-group-text, .input-group > .input-group-prepend:first-child > .btn:not(:first-child), .input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}



.custom-radio-button {
  @extend .custom-radio-icon;
  height: unset;
  padding: 0 !important;
  margin: 2px;

  >.custom-control-label {
    height: unset;
    width: unset;
    line-height: unset;
    border-radius: 0;
    font-size: 20px;
    font-weight: lighter;
    user-select: none;
  }
}

/* Adds rounded corners to blocks of custom radio buttons */
.form-row {
  &:first-of-type {
    .custom-radio-button {
      &:first-child > .custom-control-label {
        border-top-left-radius: 6px;
      }
      &:last-child > .custom-control-label {
        border-top-right-radius: 6px;
      }
    }
  }

  &:last-of-type {
    .custom-radio-button {
      &:first-child > .custom-control-label {
        border-bottom-left-radius: 6px;
      }
      &:last-child > .custom-control-label {
        border-bottom-right-radius: 6px;
      }
    }
  }

}

/* Override for select2, ensures selection text color takes precedence over member status colors */
.select2-container--default .select2-results__option--highlighted[aria-selected] {
  color: #fff !important;
}

/* Error hints for select2 */
.form-group-invalid > .select2-container > .selection > .select2-selection {
  border-color: #ea4335;
}
