
.tab-content {
  padding: $grid-gutter-width;
  border-radius: $rounded-bottom;
  margin-bottom: 0;
}

.g-tab-content {
  background-color: rgba(0, 0, 0, 0);
  padding: 0;
}

.g-tab-content-no-margin {
  margin: 0;
}

.tab-content-table {
  padding: 0;
}
