

/* Gritter customizations */

#gritter-container {
  width: 360px;
  top: 82px;
}

.gritter-item-wrapper {
  border-radius: 8px;

  .gritter-item {
    display: flex;

    .gritter-icon-container {
      //flex-grow: 1;
      padding: 0 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 4rem;
      background-color: rgba(255, 255, 255, .08);
    }

    // close icon
    .gritter-content.gritter-with-image .gritter-close:after, .gritter-content.gritter-without-image .gritter-close:after {
      margin: 0;
      line-height: 40px;
    }

    .gritter-content.gritter-without-image {
      width: auto;
    }
  }
}
