

.fit-content, .flex-grow-0 {
  flex-grow: 0;
}

.flex-grow-1 {
  flex-grow: 1;
}

.w-0 {
  width: 0;
}

/* *********** */
/* DIV heights */
/* *********** */

[class^='max-height-'], [class*=' max-height-'] {
  overflow: hidden auto;
}

.max-height-400 {
  max-height: 400px;
}

.max-height-600 {
  max-height: 600px;
}

.max-height-800 {
  max-height: 800px;
}

.max-height-1000 {
  max-height: 1000px;
}

.scroll-viewport {
  overflow: auto;
}

@media print {
  [class^='max-height-'], [class*=' max-height-'] {
    max-height: unset;
  }
}
