

.display-none {
  display: none;
}

.overflow-hidden {
  overflow: hidden;
}

.v-hidden {
  visibility: hidden;
}

.disabled .hide-when-disabled, .show-when-disabled {
  display: none;
}

.disabled .show-when-disabled {
  display: unset;
}

.fade-to-bottom-0-60 {
  mask-image: linear-gradient(rgb(0 0 0 / 100%), transparent 60%)
}
