
/* Grid Customizations */

.row {
  margin-left: ($grid-gutter-width / -2);
  margin-right: ($grid-gutter-width / -2);
  &.no-gutters {
    margin-left: 0;
    margin-right: 0;
  }
}

.col,.col-1,.col-2,.col-3,.col-4,.col-5,.col-6,.col-7,.col-8,.col-9,.col-10,.col-11,.col-12,.col-auto,.col-lg,.col-lg-1,.col-lg-2,.col-lg-3,.col-lg-4,.col-lg-5,.col-lg-6,.col-lg-7,.col-lg-8,.col-lg-9,.col-lg-10,.col-lg-11,.col-lg-12,.col-lg-auto,.col-md,.col-md-1,.col-md-2,.col-md-3,.col-md-4,.col-md-5,.col-md-6,.col-md-7,.col-md-8,.col-md-9,.col-md-10,.col-md-11,.col-md-12,.col-md-auto,.col-sm,.col-sm-1,.col-sm-2,.col-sm-3,.col-sm-4,.col-sm-5,.col-sm-6,.col-sm-7,.col-sm-8,.col-sm-9,.col-sm-10,.col-sm-11,.col-sm-12,.col-sm-auto,.col-xl,.col-xl-1,.col-xl-2,.col-xl-3,.col-xl-4,.col-xl-5,.col-xl-6,.col-xl-7,.col-xl-8,.col-xl-9,.col-xl-10,.col-xl-11,.col-xl-12,.col-xl-auto {
  padding-left: ($grid-gutter-width / 2);
  padding-right: ($grid-gutter-width / 2);
}

@media (max-width: 575.98px) {
  .row {
    margin-left: ($mobile-grid-gutter-width / -2);
    margin-right: ($mobile-grid-gutter-width / -2);
  }

  .col,.col-1,.col-2,.col-3,.col-4,.col-5,.col-6,.col-7,.col-8,.col-9,.col-10,.col-11,.col-12,.col-auto,.col-lg,.col-lg-1,.col-lg-2,.col-lg-3,.col-lg-4,.col-lg-5,.col-lg-6,.col-lg-7,.col-lg-8,.col-lg-9,.col-lg-10,.col-lg-11,.col-lg-12,.col-lg-auto,.col-md,.col-md-1,.col-md-2,.col-md-3,.col-md-4,.col-md-5,.col-md-6,.col-md-7,.col-md-8,.col-md-9,.col-md-10,.col-md-11,.col-md-12,.col-md-auto,.col-sm,.col-sm-1,.col-sm-2,.col-sm-3,.col-sm-4,.col-sm-5,.col-sm-6,.col-sm-7,.col-sm-8,.col-sm-9,.col-sm-10,.col-sm-11,.col-sm-12,.col-sm-auto,.col-xl,.col-xl-1,.col-xl-2,.col-xl-3,.col-xl-4,.col-xl-5,.col-xl-6,.col-xl-7,.col-xl-8,.col-xl-9,.col-xl-10,.col-xl-11,.col-xl-12,.col-xl-auto {
    padding-left: ($mobile-grid-gutter-width / 2);
    padding-right: ($mobile-grid-gutter-width / 2);
  }
}

/* Alternative grid implementation using CSS `grid` instead of `flexbox` */
/* This implementation provides responsive row spans as well as column spans */
/* It also allows a subgrid which references the rows and columns of the parent grid */
/* Simply put, this enables much finer control over vertical alignment without resorting to <table> */

.d-grid {
  display: grid;
  grid-gap: $grid-gutter-width;
  grid-template-columns: repeat(12, 1fr);
}

@media (max-width: 575.98px) {
  .d-grid {
    grid-gap: $mobile-grid-gutter-width;
  }
}

.subgrid-row {
  display: grid;
  grid-template-rows: subgrid;
}

.subgrid-col {
  display: grid;
  grid-template-columns: subgrid;
}

.gap-0 {
  grid-gap: 0;
}

.gap-xs {
  grid-gap: 3px;
}

.gap-sm {
  grid-gap: 12px;
}

// Responsive row/col spans implemented below
@import '~bootstrap/scss/mixins/breakpoints';

@each $breakpoint in map-keys($grid-breakpoints) {
  $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
  @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
    @for $i from 1 through 12 {
      .grid-col#{$infix}-#{$i} {
        grid-column: span $i;
      }
      .grid-row#{$infix}-#{$i} {
        grid-row: span $i;
      }
    }
  }
}
