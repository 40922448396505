/*
 Semantic default classes based on our conventional usages.
 */

.default-card {
  @extend .card;
  @extend .card-contrast
}

.default-widget-card {
  @extend .card;
  padding: 24px 20px;

  .card-body {
    .data-element {
      font-weight: bold;
      font-size: 2rem;
      padding-top: 5px;
      padding-bottom: 5px;
      text-align: center;
    }

    .data-sub-element {
      font-size: 1.5rem;
      text-align: center;
    }

    .data-desc {
      text-align: center;
    }
  }
}

.default-full-color-card {
  @extend .card;
  @extend .card-full-color;
  @extend .card-full-primary;
}

.default-table-card {
  @extend .default-card;
  @extend .card-table
}

.default-card-header {
  @extend .card-header;
  @extend .card-header-contrast;
}



.card {
  box-shadow: $drop-shadow;

  margin-bottom: $grid-gutter-width;
  border-radius: $card-border-radius + $card-border-width * 2;
  border: $card-border-width solid $card-border-color;

  .card-header {
    // prevent tools from overflowing header
    @extend .clearfix;

    border-bottom: 1px solid $card-border-color;
    border-top: 1px solid rgba(255, 255, 255, .7);
    // force consistent padding
    margin: 0;
    padding: 20px 20px 11px;

    font-weight: 400;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-size: 16px;

    .card-subtitle {
      color: inherit;
    }

    .breadcrumb {
      text-shadow: inherit;
      .breadcrumb-item::before {
        color: inherit;
      }
    }

    a {
      color: inherit;
    }

    .tools {
      // avoid tools getting too close to header text
      margin-left: 16px;
      margin-top: -4px;

      font-weight: 300;
      letter-spacing: initial;
      text-transform: initial;

      .hint {
        font-size: 14px;
        vertical-align: top;
      }

      .icon {
        color: var(--default-card-header-icon);
        padding-left: 10px;
        cursor: pointer;
      }

      .icon:hover {
        color: var(--default-card-header-icon-hover);
      }

      // fix alignment of select2 within card headers
      >.input-group {
        margin: -8px 0;
        select {
          height: 2.692rem;
        }
        >.select2 > .selection {
          >.select2-selection {
            height: 2.692rem;
            >.select2-selection__rendered {
              height: 2.53816rem;
              line-height: 2.53816rem;
            }
            >.select2-selection__arrow {
              height: 2.5382rem;
              >b:after {
                line-height: 2.5382rem;
              }
            }
          }
        }
      }
    }
  }

  .card-body {
    // allow card to scroll horizontally iff needed
    overflow-x: auto;
    // don't collapse flexbox when scroll appears
    width: 100%;
  }

  > :first-child {
    border-top-left-radius: $card-border-radius;
    border-top-right-radius: $card-border-radius;
  }

  > :last-child {
    border-bottom-left-radius: $card-border-radius;
    border-bottom-right-radius: $card-border-radius;
  }

  > .list-group:last-child > .list-group-item:last-child {
    border-bottom-left-radius: $card-border-radius;
    border-bottom-right-radius: $card-border-radius;
  }
}

// Consistent with DataTables footer
.card-footer {
  background-color: #f7f7f7;
  border-top: 1px solid #dee2e6;
}

.connect-app {
  .card .card-header {
    font-weight: 500;
    letter-spacing: unset;
    text-transform: unset;
  }
}

@media (max-width: 575.98px) {
  .card {
    margin-bottom: $mobile-grid-gutter-width;
  }
}

.card-subtitle {
  font-size: 1.1rem;
}

.card-table .card-body {
  padding: 16px 20px 20px;
}

.card table:last-child:not(.dataTable) > :last-child > tr:last-child {
  > td {
    &:first-child {
      border-bottom-left-radius: $card-border-radius;
    }
    &:last-child {
      border-bottom-right-radius: $card-border-radius;
    }
  }
}

.default-card .default-card-header {
  background: var(--default-card-header-bg-bottom);
  background: linear-gradient(180deg, var(--default-card-header-bg-top) 0%, var(--default-card-header-bg-bottom) 100%);
  color: var(--default-card-header-color);
  //border-bottom: 2px solid var(--primary-color);
  text-shadow: 0 1px 1px var(--default-card-header-text-shadow);

  .tools {
    .icon {
      color: var(--default-card-header-icon);
    }
    .icon:hover {
      color: var(--default-card-header-icon-hover);
    }
  }
}

.default-primary-card, .default-info-card, .default-success-card, .default-warning-card, .default-danger-card {
  @extend .default-card;

  .default-card-header {
    //text-shadow: 0 1px 1px black;
    text-shadow: 0 1px 1px rgba(0, 0, 0, .5);
    color: white;

    .card-subtitle {
      color: white;
    }

    .tools {
      .icon {
        color: white;
      }
      .icon:hover {
        color: #eee;
      }
    }
  }
}

.default-primary-card .default-card-header {
  background: var(--primary-color);
  background: linear-gradient(var(--primary-color-darker), var(--primary-color));
  border-top: 1px solid rgba(255, 255, 255, .2);
}

.default-info-card .default-card-header {
  background: $info;
  background: linear-gradient(darken($info, 6%), lighten($info, 6%));
}

.default-success-card .default-card-header {
  background: $success-color;
  background: linear-gradient(180deg, lighten($success-color, 6%) 0%, darken($success-color, 6%) 100%);
}

.default-warning-card .default-card-header {
  background: $warning-color;
  background: linear-gradient(#ffaa17, $warning);
  text-shadow: 0 -1px 1px rgba(0, 0, 0, .5);
  font-weight: bold;
}

.default-danger-card .default-card-header {
  background: $danger;
  background: linear-gradient(darken($danger, 6%), lighten($danger, 6%));
}

.card.card-full-color.card-full-primary {
  .card-body {
    a {
      color: white;
      text-decoration: underline;
    }
  }
}


.classic-card {
  font-size: 0;
  iframe {
    border: 0;
    width: 100%;
  }
}

.full-height-card {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  margin-bottom: 0;

  > .card-body, > iframe, > table {
    overflow: hidden;
    flex-grow: 1;
    border: 0;
    width: 100%;
  }
}
